import React from "react";
import { Navigate } from "react-router-dom";
import Page from "../../../Page";
import Training from "./Training";
import Inferencing from "./Inferencing";
import { Metadata } from "../../../types/pageMetadata";
import { useAuth } from "../../../../contexts/AuthContext";

const playgroundMetadata: Metadata = {
    title: "Playground - Narkis.ai (Testers and Admins Only)",
    description: "Narkis.ai internal playground for testing and administration purposes.",
    h1: "Narkis.ai Playground - Internal Testing Environment For Photo Generation",
    canonical: "https://narkis.ai/playground",
    keywords: [], // Empty array as we don't want to optimize for search
    author: "Narkis.ai",
    robots: "noindex, nofollow", // Prevent indexing and following links
    ogTitle: "Narkis.ai - Tester and Admin Playground",
    ogDescription: "Internal testing environment for Narkis.ai testers and administrators.",
    ogImage: "https://narkis.ai/images/admin-preview.jpg", // Consider using a generic or admin-specific image
    ogUrl: "https://narkis.ai/playground",
    twitterCard: "summary",
    twitterTitle: "Narkis.ai Tester and Admin Playground",
    twitterDescription: "Internal testing playground for Narkis.ai team members.",
    twitterImage: "https://narkis.ai/images/admin-preview.jpg", // Same as ogImage
    twitterCreator: "@narkisai"
};

const Playground: React.FC = () => {
    const { user } = useAuth();
    const isTester = user?.is_tester;
    const isPrivileged = user?.is_privileged;

    if (!isTester && !isPrivileged) {
        return <Navigate to="/" replace />;
    }

    return (
        <Page {...playgroundMetadata}>
            <div className="flex flex-col w-full">
                <div className="w-full flex flex-col items-center justify-center min-h-screen bg-gray-900 p-4 ">
                    <h1 className="text-3xl font-bold my-4">Model Training and Image Generation Playground</h1>
                    {/* <section className="w-full max-w-4xl bg-white p-6 rounded-lg shadow-md mb-6 text-black">
                    <h2 className="text-2xl font-semibold mb-4">Training Section</h2>
                    <Training />
                </section> */}
                    <section className="w-full shadow-md text-black">
                        <Inferencing />
                    </section>
                </div>
            </div>
        </Page>
    );
};

export default Playground;
