import { useTranslation } from "react-i18next";

interface InstructionsProps {
    language: string;
}

const Instructions: React.FC<InstructionsProps> = ({ language }) => {
    const isFirefox = typeof navigator !== "undefined" && navigator.userAgent.toLowerCase().includes("firefox");
    const { t } = useTranslation("training");

    return (
        <div
            className={`
                bg-narkis-bg bg-opacity-90 border border-narkis-border-darker2 rounded-[9px] shadow-md overflow-hidden p-4 text-narkis-text-bright max-w-[425px]
                ${isFirefox ? "" : "backdrop-filter backdrop-blur-md "}
            `}
        >
            <h2 className="text-2xl font-bold mb-4">{t("instructions.title")}</h2>
            <p className="mb-4">{t("instructions.content")}</p>
            <ul className="text-md list-disc pl-5 mb-4">
                <li>{t("instructions.instruct1")}</li>
                <li>{t("instructions.instruct2")}</li>
                <li>{t("instructions.instruct3")}</li>
                <li>{t("instructions.instruct4")}</li>
            </ul>
            <p className="text-md">{t("instructions.footer")}</p>
        </div>
    );
};

export default Instructions;
