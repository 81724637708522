import { useTranslation } from "react-i18next";
const REACT_APP_NARKIS_PUBLIC_CLOUDFRONT_URL = process.env.REACT_APP_NARKIS_PUBLIC_CLOUDFRONT_URL;
const headerImage = `${REACT_APP_NARKIS_PUBLIC_CLOUDFRONT_URL}/blog/iphone-model-header.webp`;

export default function IphoneModel() {
    const { t } = useTranslation("blog");

    return (
        <article className="prose lg:prose-xl max-w-none">
            <h1 className="text-4xl font-bold mb-6 text-primary">
                Unleash Your Creativity: Using Your Narkis.ai AI Model on iOS
            </h1>

            <img
                src={headerImage}
                alt="AI Model on iPhone"
                className="rounded-lg shadow-lg mb-8 w-full h-auto"
            />

            <p className="lead text-xl mb-8">
                At Narkis.ai, we're passionate about putting the power of AI in your hands. We believe that your AI model should
                be truly yours, giving you the freedom to create stunning, unique images whenever and wherever inspiration
                strikes. That's why we've made it easy for you to download your personalized AI model and use it offline on your
                iPhone with the free Draw Things app.
            </p>

            <p className="mb-8">
                In this guide, we'll walk you through the simple process of downloading your AI model from Narkis.ai and
                unleashing its potential on your iPhone. Get ready to embark on a thrilling creative journey!
            </p>

            <h2 className="text-3xl font-semibold mt-12 mb-4 text-primary">Downloading Your AI Model: A Step-by-Step Guide</h2>
            <p className="mb-4">First things first, let's get your AI model from the Narkis.ai platform onto your device:</p>

            <ol className="list-decimal pl-6 mb-8">
                <li className="mb-6">
                    <h3 className="text-2xl font-semibold mb-2">Navigate to the 'My Models' Section</h3>
                    <p className="mb-2">
                        Log in to your Narkis.ai account and head over to the 'My Models' section from the main menu. Here, you'll
                        find all the incredible AI models you've created.
                    </p>
                    <div className="w-full h-48 bg-gray-200 mb-2 rounded-lg shadow-md"></div>
                    <p className="text-sm text-gray-600">User navigating to 'My Models' section</p>
                </li>
                <li className="mb-6">
                    <h3 className="text-2xl font-semibold mb-2">Select Your Model</h3>
                    <p className="mb-2">
                        Browse through your collection of AI models and choose the one you want to download. Take a moment to
                        marvel at your creations!
                    </p>
                    <div className="w-full h-48 bg-gray-200 mb-2 rounded-lg shadow-md"></div>
                    <p className="text-sm text-gray-600">User selecting a model</p>
                </li>
                <li className="mb-6">
                    <h3 className="text-2xl font-semibold mb-2">Download Your Model</h3>
                    <p className="mb-2">
                        Click on the 'Download' button next to your selected model and follow the on-screen instructions to
                        complete the download process. Your AI model is now ready to be unleashed on your iPhone!
                    </p>
                    <div className="w-full h-48 bg-gray-200 mb-2 rounded-lg shadow-md"></div>
                    <p className="text-sm text-gray-600">User clicking the 'Download' button</p>
                </li>
            </ol>

            <h2 className="text-3xl font-semibold mt-12 mb-4 text-primary">
                Unleashing Your AI Model on iPhone with Draw Things
            </h2>
            <p className="mb-4">
                Now that you've downloaded your AI model, you can use it on your iPhone with the Draw Things app. Here's what you
                need to get started:
            </p>

            <ul className="list-disc pl-6 mb-8">
                <li>iPhone model iPhone XS or later (iOS 15.4+)</li>
                <li>iPad model iPad Air or later (iOS 15.4+)</li>
                <li>
                    The{" "}
                    <a
                        href="https://apps.apple.com/us/app/draw-things-ai-generation/id6444050820"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Draw Things app
                    </a>{" "}
                    installed from the iOS App Store
                </li>
                <li>Your downloaded Narkis.ai model</li>
            </ul>

            <p className="mb-4">Let's get started:</p>

            <ol className="list-decimal pl-6 mb-8">
                <li className="mb-6">
                    <h3 className="text-2xl font-semibold mb-2">Download and Install Draw Things</h3>
                    <p className="mb-2">
                        Open the iOS App Store on your iPhone and search for "Draw Things." Download and install the app, and get
                        ready to embark on your mobile AI art adventure!
                    </p>
                    <div className="w-full h-48 bg-gray-200 mb-2 rounded-lg shadow-md"></div>
                    <p className="text-sm text-gray-600">Draw Things app in the App Store</p>
                </li>
                <li className="mb-6">
                    <h3 className="text-2xl font-semibold mb-2">Import Your Model into Draw Things</h3>
                    <p className="mb-2">
                        Launch the Draw Things app on your iPhone and tap on the menu icon. Select "Import Model" to begin the
                        process of bringing your AI model into the app.
                    </p>
                    <div className="w-full h-48 bg-gray-200 mb-2 rounded-lg shadow-md"></div>
                    <p className="text-sm text-gray-600">Menu icon and 'Import Model' option in Draw Things</p>
                </li>
                <li className="mb-6">
                    <h3 className="text-2xl font-semibold mb-2">Select Your Downloaded Model</h3>
                    <p className="mb-2">
                        Navigate to the location where you saved your downloaded AI model from Narkis.ai. Select the model file to
                        import it into Draw Things. Your AI model is now ready to work its magic!
                    </p>
                    <div className="w-full h-48 bg-gray-200 mb-2 rounded-lg shadow-md"></div>
                    <p className="text-sm text-gray-600">User selecting the model file</p>
                </li>
                <li className="mb-6">
                    <h3 className="text-2xl font-semibold mb-2">Create, Generate, and Be Amazed</h3>
                    <p className="mb-2">
                        With your AI model imported, the creative possibilities are endless. Use the app's intuitive features to
                        generate stunning photos, edit them to perfection, and watch your ideas come to life right on your iPhone!
                    </p>
                    <div className="w-full h-48 bg-gray-200 mb-2 rounded-lg shadow-md"></div>
                    <p className="text-sm text-gray-600">User generating photos in Draw Things</p>
                </li>
                
            </ol>

            <h3 className="text-2xl font-semibold mb-2">Consider the Alternatives</h3>
            <p className="mb-4">
                Of course, using AI models on your iPhone can be a bit slow. At narkis.ai, you'll benefit from:
            </p>
            <ul className="list-disc pl-6 mb-4">
                <li>Blazing fast photo generation speeds</li>
                <li>Easy access to your photos from any device</li>
                <li>Curated presets and prompts, eliminating technical hassles</li>
            </ul>
            <p>
                With Narkis.ai, you don't have to worry about all the technical stuff - just focus on your creativity!
            </p>

            <h2 className="text-3xl font-semibold mt-12 mb-4 text-primary">Dive Deeper</h2>
            <p className="mb-4">
                Want to take your AI-generated content to the next level? Use your Narkis.ai model to craft captivating visual stories starring <strong>you</strong> as the protagonist. Simply input a basic storyline, and watch as <a href="https://monogen.ai" className="text-blue-500 hover:underline bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 bg-clip-text text-transparent font-bold">Monogen.ai</a> transforms it into a mesmerizing sequence of images, complete with voiceover and music. It's time to become the star of your own AI-powered narratives!
                </p>
        </article>
    );
}
