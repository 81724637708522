import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

import AuthContainer from "./components/v2/pages/login/Auth";
import Generate from "./components/v2/pages/generate/Generate";
import Waitlist from "./components/pages/waitlist/Waitlist";
import PricingPage from "./components/v2/pages/pricing/Pricing";
import AdminPanel from "./components/v2/pages/admin/AdminPanel";
import DashboardMobile from "./components/v2/pages/mobile/DashboardMobile";
import Landing from "./components/v2/pages/landing/landing";
import BlogMain from "./components/v2/pages/blog/BlogMain";
import BlogArticle from "./components/v2/pages/blog/BlogArticle";
import Privacy from "./components/v2/pages/privacy/privacy";
import Playground from "./components/v2/pages/playground/Playground";
import TrainedModelManager from "./components/TrainedModelManager";
import About from "./components/v2/pages/about/about";
import NotFound from "./components/v2/pages/not-found/not-found";
import FalImageGenerator from "./components/v2/pages/fal/FalGenerator";
import ContactUs from "./components/v2/pages/contact/contact";

import ProtectedRoute from "./components/ProtectedRoute";
import { useAuth } from "./contexts/AuthContext";
import Sitemap from "./components/Sitemap";
import GAListener from "./utils/galistener";

import "./App.css";
import ButtonPage from "./components/v2/pages/playground/ButtonPage";
import TermsOfService from "./components/v2/pages/terms/terms";
import CookiePolicy from "./components/v2/pages/cookie/cookie";

function App() {
    useEffect(() => {
        function captureUTMParameters() {
            const urlParams = new URLSearchParams(window.location.search);

            const utmSource = urlParams.get("utm_source");
            const utmMedium = urlParams.get("utm_medium");
            const utmCampaign = urlParams.get("utm_campaign");
            const utmTerm = urlParams.get("utm_term");
            const utmContent = urlParams.get("utm_content");
            if (utmSource) sessionStorage.setItem("utm_source", utmSource);
            if (utmMedium) sessionStorage.setItem("utm_medium", utmMedium);
            if (utmCampaign) sessionStorage.setItem("utm_campaign", utmCampaign);
            if (utmTerm) sessionStorage.setItem("utm_term", utmTerm);
            if (utmContent) sessionStorage.setItem("utm_content", utmContent);
        }

        captureUTMParameters();
    }, []);

    const { token, refreshToken } = useAuth();

    useEffect(() => {
        refreshToken();
    }, [refreshToken]);

    const isMobile = window.innerWidth <= 768;

    return (
        <Router>
            <GAListener />
            <Routes>
                <Route path="/landing" element={<Landing />} />
                <Route path="/" element={<Navigate replace to={token ? "/dashboard" : "/landing"} />} />
                <Route path="/waitlist" element={<Waitlist />} />
                <Route path="/pricing" element={<PricingPage />} />
                <Route path="/login" element={<AuthContainer formName="login" />} />
                <Route path="/login-with-token" element={<AuthContainer formName="tokenLogin" />} />
                <Route path="/reset-password" element={<AuthContainer formName="resetPassword" />} />
                <Route path="/register" element={<AuthContainer formName="register" />} />
                <Route path="/confirm-email" element={<AuthContainer formName="confirmEmail" />} />
                
                <Route path="/dashboard" element={<ProtectedRoute />}>
                    <Route path="" element={isMobile ? <DashboardMobile /> : <Generate />} />
                </Route>
                <Route path="/admin" element={<ProtectedRoute />}>
                    <Route path="" element={<AdminPanel />} />
                </Route>
                <Route path="/sitemap" element={<Sitemap />} />
                <Route path="/blog" element={<BlogMain />} />
                <Route path="/blog/:articleId" element={<BlogArticle />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<TermsOfService />} />
                <Route path="/cookie-policy" element={<CookiePolicy />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/playground" element={<ProtectedRoute />}>
                    <Route path="" element={<Playground />} />
                    <Route path="button-page" element={<ButtonPage />} />
                </Route>
                <Route path="/models" element={<ProtectedRoute />}>
                    <Route path="" element={<TrainedModelManager />} />
                </Route>
                <Route path="/image-gen" element={<FalImageGenerator />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>
    );
}

export default App;
