import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Tabs, Collapse } from 'antd';
import { MatrixGeneration } from './types/matrixGeneration';
import { GeneratedImage} from './types/generatedImage';
import ImageCard from './ImageCard';
import ImageModal from './ImageModal';

const { TabPane } = Tabs;
const { Panel } = Collapse;


interface MatrixGenerationDisplayProps {
  selectedGeneration: MatrixGeneration;
}

const MatrixGenerationDisplay: React.FC<MatrixGenerationDisplayProps> = ({ selectedGeneration }) => {
  const [modalImage, setModalImage] = useState<GeneratedImage | null>(null);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);

  const groupedImages = useMemo(() => {
    const groups: { [key: string]: { [key: string]: { [key: string]: GeneratedImage[] } } } = {};

    selectedGeneration.generated_images.images.forEach((image) => {
      const promptIndex = selectedGeneration.prompts.findIndex(
        (prompt) => 
          prompt.positive === image.generation_parameters.positive_prompt &&
          prompt.negative === image.generation_parameters.negative_prompt
      );
      const promptKey = `Prompt ${promptIndex + 1}`;
      const resolutionKey = `${image.generation_parameters.width}x${image.generation_parameters.height}`;
      const modelKey = image.model_id;

      if (!groups[promptKey]) groups[promptKey] = {};
      if (!groups[promptKey][resolutionKey]) groups[promptKey][resolutionKey] = {};
      if (!groups[promptKey][resolutionKey][modelKey]) groups[promptKey][resolutionKey][modelKey] = [];

      groups[promptKey][resolutionKey][modelKey].push(image);
    });

    return groups;
  }, [selectedGeneration]);

  const allImages = useMemo(() => {
    return selectedGeneration.generated_images.images;
  }, [selectedGeneration]);

  const navigateImage = useCallback((direction: 'next' | 'prev') => {
    if (!modalImage) return;
    const currentIndex = allImages.findIndex(img => img.image_id === modalImage.image_id);
    let newIndex;
    if (direction === 'next') {
      newIndex = (currentIndex + 1) % allImages.length;
    } else {
      newIndex = (currentIndex - 1 + allImages.length) % allImages.length;
    }
    setModalImage(allImages[newIndex]);
    setCurrentImageIndex(newIndex);
  }, [modalImage, allImages]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (modalImage) {
        if (e.key === 'ArrowRight') {
          navigateImage('next');
        } else if (e.key === 'ArrowLeft') {
          navigateImage('prev');
        } else if (e.key === 'Escape') {
          setModalImage(null);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [navigateImage, modalImage]);

  const renderImageCard = (image: GeneratedImage) => (
    <ImageCard
      key={image.image_id}
      image={image}
      onClick={() => {
        setModalImage(image);
        setCurrentImageIndex(allImages.findIndex(img => img.image_id === image.image_id));
      }}
    />
  );

  return (
    <div>
      <Tabs defaultActiveKey="0">
        {Object.entries(groupedImages).map(([promptKey, resolutions], promptIndex) => (
          <TabPane tab={promptKey} key={promptIndex.toString()}>
            <Collapse defaultActiveKey={['0']}>
              {Object.entries(resolutions).map(([resolutionKey, models], resolutionIndex) => (
                <Panel header={resolutionKey} key={resolutionIndex.toString()}>
                  <Tabs 
                    tabPosition="left" 
                    tabBarStyle={{ width: '150px' }}
                    renderTabBar={(props, DefaultTabBar) => (
                      <DefaultTabBar {...props}>
                        {(node) => (
                          <div title={node.key || ''}>
                            {node}
                          </div>
                        )}
                      </DefaultTabBar>
                    )}
                  >
                    {Object.entries(models).map(([modelKey, images], modelIndex) => (
                      <TabPane 
                        tab={modelKey.slice(0, 15) + (modelKey.length > 15 ? '...' : '')} 
                        key={modelKey}
                      >
                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
                          {images.map(renderImageCard)}
                        </div>
                      </TabPane>
                    ))}
                  </Tabs>
                </Panel>
              ))}
            </Collapse>
          </TabPane>
        ))}
      </Tabs>

      {modalImage && (
        <ImageModal
          modalImage={modalImage}
          currentImageIndex={currentImageIndex}
          totalImages={allImages.length}
          onClose={() => setModalImage(null)}
          onNavigate={navigateImage}
        />
      )}
    </div>
  );
};

export default MatrixGenerationDisplay;
