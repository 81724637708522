import React from "react";
import { ReloadIcon } from "@radix-ui/react-icons";
import { useTranslation } from "react-i18next";

const StatusLabel = ({ status }: { status: string }) => {
    const { t } = useTranslation("imagemenu");

    const getStatusLabelElement = (status: string) => {
        switch (status) {
            case "GENERATING":
                return (
                    <div className="p-2 border rounded-[7.5px] border-narkis-border-darker2 flex items-center text-green-500 animate-color-cycle">
                        <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                        <span
                            className="font-extrabold
                            bg-gradient-to-tr from-green-400 via-blue-400 to-purple-400 bg-clip-text text-transparent 
                            transition-transform ease-in-out
                            hover:-translate-y-0.5  
                            animate-gradient-x bg-[length:400%_400%] cursor-pointer"
                        >
                            {t("imageStatus.generating")}
                        </span>
                    </div>
                );
            case "PREPARING":
                return (
                    <div className="p-2 border rounded-[7.5px] border-narkis-border-darker2 flex items-center text-orange-300">
                        <ReloadIcon className="mr-2 h-4 w-4 animate-spin visible" />
                        <span
                            className=" font-extrabold
                            bg-gradient-to-tr from-yellow-400 via-orange-400 to-red-400 bg-clip-text text-transparent 
                            transition-transform ease-in-out
                            hover:-translate-y-0.5  
                            animate-gradient-x bg-[length:400%_400%] cursor-pointer"
                        >
                            {t("imageStatus.preparing")}
                        </span>
                    </div>
                );
            case "IN_PROGRESS":
                return (
                    <div className="p-2 border rounded-[7.5px] border-narkis-border-darker2 flex items-center text-blue-500 animate-color-cycle">
                        <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                        <span
                            className=" font-extrabold
                            bg-gradient-to-tr from-blue-400 via-indigo-400 to-purple-400 bg-clip-text text-transparent 
                            transition-transform ease-in-out
                            hover:-translate-y-0.5  
                            animate-gradient-x bg-[length:400%_400%] cursor-pointer"
                        >
                            {t("imageStatus.inProgress")}
                        </span>
                    </div>
                );
            case "FAILED":
                return (
                    <div className="p-2 border rounded-[7.5px] border-narkis-border-darker2 flex items-center text-red-500">
                        <span
                            className=" font-extrabold
                            bg-gradient-to-tr from-red-500 via-pink-500 to-purple-500 bg-clip-text text-transparent 
                            transition-transform ease-in-out
                            hover:-translate-y-0.5  
                            animate-gradient-x bg-[length:400%_400%] cursor-pointer"
                        >
                            {t("imageStatus.failed")}
                        </span>
                    </div>
                );
            case "CANCELLED":
                return (
                    <div className="p-2 border rounded-[7.5px] border-narkis-border-darker2 flex items-center text-pink-500">
                        <span
                            className=" font-extrabold
                            bg-gradient-to-tr from-gray-400 via-gray-500 to-gray-600 bg-clip-text text-transparent 
                            transition-transform ease-in-out
                            hover:-translate-y-0.5  
                            animate-gradient-x bg-[length:400%_400%] cursor-pointer"
                        >
                            {t("imageStatus.cancelled")}
                        </span>
                    </div>
                );
            case "COMPLETED":
                return (
                    <div className="p-2 border rounded-[7.5px] border-narkis-border-darker2 flex items-center text-emerald-400 animate-gradient-x">
                        <span
                            className=" font-extrabold
                            bg-gradient-to-tr from-teal-400 via-green-400 to-lime-400 bg-clip-text text-transparent 
                            transition-transform ease-in-out
                            hover:-translate-y-0.5  
                            animate-gradient-x bg-[length:400%_400%]"
                        >
                            {t("imageStatus.completed")}
                        </span>
                    </div>
                );
            default:
                return (
                    <div className="p-2 border rounded-[7.5px] border-narkis-border-darker2 flex items-center text-green-500">
                        <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                        <span
                            className=" font-extrabold
                            bg-gradient-to-tr from-orange-400 via-orange-200 to-orange-600 bg-clip-text text-transparent 
                            transition-transform ease-in-out
                            hover:-translate-y-0.5  
                            animate-gradient-x bg-[length:400%_400%]"
                        >
                            {t("imageStatus.pleaseWait")} {status}
                        </span>
                    </div>
                );
            }
    };

    return getStatusLabelElement(status);
};

export default StatusLabel;
