import React from 'react';
import Newsletter from './pages/blog/newsletter';
import TrustSecurity from './pages/landing/trust-security';
import ScheduleCall from './pages/landing/schedule-call';

import fbLogo from "../../assets/facebook2.svg";
import igLogo from "../../assets/instagram.svg";
import tiktokLogo from "../../assets/tiktok.svg";
import linkedinLogo from "../../assets/linkedin.svg";
import xLogo from "../../assets/twitter.svg";
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
    return (
        <footer className="bg-narkis-bg w-full text-narkis-text-bright pt-8 pb-4">
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-[1fr_1.5fr_1.5fr_1.5fr] gap-8 mb-8">
                    {/* Navigation */}
                    <div className="row-span-2">
                        <h4 className="font-bold mb-2">Navigation</h4>
                        <ul className="space-y-2">
                            <li>
                                <Link
                                    to="/"
                                    className="text-narkis-text-label hover:text-narkis-text-bright hover:animate-neon-glow"
                                >
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/pricing"
                                    className="text-narkis-text-label hover:text-narkis-text-bright hover:animate-neon-glow"
                                >
                                    Pricing
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/about"
                                    className="text-narkis-text-label hover:text-narkis-text-bright hover:animate-neon-glow"
                                >
                                    About Us
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/blog"
                                    className="text-narkis-text-label hover:text-narkis-text-bright hover:animate-neon-glow"
                                >
                                    Blog
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/contact-us"
                                    className="text-narkis-text-label hover:text-narkis-text-bright hover:animate-neon-glow"
                                >
                                    Contact Us
                                </Link>
                            </li>
                        </ul>
                    </div>

                    {/* Legal */}
                    <div>
                        <h4 className="font-bold mb-2">Legal</h4>
                        <ul className="space-y-2">
                            <li>
                                <Link
                                    to="/privacy"
                                    className="text-narkis-text-label hover:text-narkis-text-bright hover:animate-neon-glow"
                                >
                                    Privacy Policy
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/terms"
                                    className="text-narkis-text-label hover:text-narkis-text-bright hover:animate-neon-glow"
                                >
                                    Terms of Service
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/cookie-policy"
                                    className="text-narkis-text-label hover:text-narkis-text-bright hover:animate-neon-glow"
                                >
                                    Cookie Policy
                                </Link>
                            </li>
                        </ul>
                    </div>

                    {/* Newsletter */}
                    <div>
                        <Newsletter
                            tagline="Stay updated with the latest news, offers, and AI advancements."
                            compact={true}
                        />
                    </div>

                    {/* Contact Info */}
                    <div>
                        <h4 className="font-bold mb-2">Contact Information</h4>
                        <p>
                            Email:{" "}
                            <a
                                href="mailto:support@narkis.ai"
                                className="text-narkis-text-label hover:text-narkis-text-bright hover:animate-neon-glow"
                            >
                                support@narkis.ai
                            </a>
                        </p>
                        <p>Address: Luxembourg, Luxembourg</p>
                    </div>

                    {/* Trust & Security */}
                    <div>
                        <TrustSecurity />
                    </div>

                    {/* Social Media */}
                    <div className="lg:px-4">
                        <h4 className="font-bold mb-2">Follow Us</h4>
                        <ul className="flex flex-wrap gap-2 mb-6">
                            {[
                                { name: "Facebook", logo: fbLogo, url: "https://facebook.com/narkis_ai" },
                                { name: "Twitter", logo: xLogo, url: "https://twitter.com/narkis_ai" },
                                { name: "Instagram", logo: igLogo, url: "https://instagram.com/narkis_ai" },
                                { name: "TikTok", logo: tiktokLogo, url: "https://tiktok.com/@narkisai" },
                                { name: "LinkedIn", logo: linkedinLogo, url: "https://linkedin.com/company/NarkisAI" },
                            ].map((social) => (
                                <li key={social.name}>
                                    <a
                                        href={social.url}
                                        className="text-narkis-text-label hover:text-narkis-text-bright hover:animate-neon-glow"
                                    >
                                        <img src={social.logo} alt={social.name} className="h-8 w-8" />
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* Schedule Call */}
                    <div>
                        <ScheduleCall />
                    </div>
                </div>

                <div className="text-center text-narkis-text-label border-t border-gray-700 pt-4">
                    © 2024 Narkis.ai. All rights reserved.
                </div>
            </div>
        </footer>
    );
};

export default Footer;
