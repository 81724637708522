import React, { useState, useEffect } from "react";
import { Label } from "../../../shadcn/ui/label";
import { Button } from "../../../shadcn/ui/button";
import { useLocalStorageState } from "../../../../hooks/useLocalStorageState";
import { SquareIcon, LandscapeIcon, PortraitIcon } from "./ImageLayoutIcons";
import { generateImagesPresets } from "../../../../services/api";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../shadcn/ui/select";
import { Switch } from "../../../shadcn/ui/switch";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "../../../shadcn/ui/collapsible";
import { useToast } from "../../../shadcn/ui/use-toast";
import { Loader2, UnfoldVerticalIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useUserDataContext } from "../../../../contexts/UserDataContext";
import GradientMeshBackground from "../../../../components/GradientMeshButton";

const PresetsTab = ({ selectedModel, addGeneratedImage, presets }: { selectedModel: any; addGeneratedImage: any; presets: any[] }) => {
    const { t } = useTranslation("generate");
    const [selectedPresets, setSelectedPresets] = useState<Set<any>>(new Set());
    const [count, setCount] = useLocalStorageState("presetImageCount", 5);
    const [seed, setSeed] = useLocalStorageState("presetSeed", -1);


    const [imageLayout, setImageLayout] = useLocalStorageState("imageLayout", "square");

    const [adetailerCollapsed, setAdetailerCollapsed] = useLocalStorageState("adetailerCollapsed", false);
    const [useAdetailer, setUseAdetailer] = useLocalStorageState("useAdetailer", false);
    const [adetailerDenoisingStrength, setAdetailerDenoisingStrength] = useLocalStorageState("adetailerDenoisingStrength", 0.5);

    const { toast } = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const { setTrainingCredits ,setImageCredits } = useUserDataContext();

    const onPresetHover = (preset: any) => {
        // todo: show example images
    };

    const handleGenerateClick = async () => {
        setIsLoading(true);
        try {
            if (selectedPresets.size === 0) {
                throw new Error(t("errors.noPresetsSelected"));
            }

            let response;
            console.log(selectedPresets);
            response = await generateImagesPresets(
                "", // userId: not used
                selectedModel.model_id as string,
                Array.from(selectedPresets),
                count,
                imageLayout,
                useAdetailer,
                adetailerDenoisingStrength,
                seed,
            );

            toast({
                title: t("yourPhotographsAreQueued.title"),
                description: t("yourPhotographsAreQueued.description"),
            });
            if (response) {
                const { credits, gift_credits, generated_images } = response.data;
                // Assuming addImageToGallery is a function to add images to the gallery
                generated_images.forEach((image: any) => {
                    addGeneratedImage(image);
                });
                setImageCredits(credits, gift_credits);
            }
        } catch (error: any) {
            console.error("Error generating images:", error);
            toast({
                variant: "destructive",
                title: "Failed to generate images",
                description: error.message,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const [selectedModelGender, setSelectedModelGender] = useState<string>("");
    useEffect(() => {
        if (selectedModel) {
            setSelectedModelGender(selectedModel.gender);
        }
    }, [selectedModel]);

    const handleLayoutChange = (value: string) => {
        setImageLayout(value);
    };

    const imageLayoutOptions = [
        { 
            value: "square", 
            label: t("imageLayout.square"),
            illustration: <SquareIcon />
        },
        { 
            value: "landscape", 
            label: t("imageLayout.landscape"),
            illustration: <LandscapeIcon />
        },
        { 
            value: "portrait", 
            label: t("imageLayout.portrait"),
            illustration: <PortraitIcon />
        },
    ];

    const isMobile = window.innerWidth <= 768;
    const [dragging, setDragging] = useState(false);

    return (
        <div className="flex flex-col h-full gap-4 text-narkis-text text-sm">
            <div className="flex items-center text-center gap-2 justify-between">
                {/* image layout */}
                <div>
                    <Label htmlFor="imageLayout" className="block">
                        {t("imageLayout.mainLabel")}
                    </Label>
                    <Select onValueChange={handleLayoutChange} value={imageLayout}>
                        <SelectTrigger
                            aria-label="Choose image layout"
                            className="h-7 w-36 rounded-[7.5px] bg-narkis-bg border-narkis-border mt-1"
                        >
                            <SelectValue placeholder={t(`imageLayout.${imageLayout}`)} />
                        </SelectTrigger>
                        <SelectContent>
                            {imageLayoutOptions.map((option) => (
                                <SelectItem key={option.value} value={option.value}>
                                    <div className="flex items-center">
                                        {option.illustration}
                                        <span className="ml-2">{option.label}</span>
                                    </div>
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>

                {/* count */}
                <div>
                    <Label htmlFor="count" className="block">
                        {t("count.mainLabel")}
                    </Label>
                    <div className="flex items-center mt-1 h-7 bg-narkis-bg border border-narkis-border rounded-[7.5px]">
                        <Button
                            className="h-full w-1 bg-narkis-bg hover:bg-narkis-bg-lighterpt-1"
                            onClick={() => setCount(Math.max(1, count - 1))}
                        >
                            -
                        </Button>
                        <input
                            className="rounded-none text-center h-full w-7 border-0 m-0 p-0 bg-narkis-bg focus:outline-none"
                            value={count}
                            onChange={(e) => {
                                const newValue = parseInt(e.target.value);
                                setCount(isNaN(newValue) ? 1 : Math.max(1, newValue));
                            }}
                        />
                        <Button
                            className="h-full w-1 bg-narkis-bg hover:bg-narkis-bg-lighterpt-1"
                            onClick={() => setCount(count + 1)}
                        >
                            +
                        </Button>
                    </div>
                </div>
            </div>

            {/* presets container */}
            <div className="text-sm border border-narkis-border-lighter rounded-[7.5px] pb-4">
                <div className="flex justify-center items-center py-2">
                    <Label className="text-sm text-center text-narkis-text-label">
                        {t("selectOneOrMultiplePresets.mainLabel")}
                    </Label>
                </div>
                {/* preset prompts button toggles*/}
                <div
                    className="grid grid-cols-2 gap-1 px-2"
                    onMouseDown={(e) => {
                        setDragging(true);
                    }}
                    onMouseUp={() => setDragging(false)}
                    onMouseLeave={() => setDragging(false)}
                >
                    {presets.map((preset) => {
                        if (preset.gender === selectedModelGender) {
                            return (
                                <Button
                                    key={preset.id}
                                    variant="outline"
                                    onMouseDown={() =>
                                        setSelectedPresets((prevSelectedPresets) => {
                                            const newSelectedPresets = new Set(prevSelectedPresets);
                                            if (newSelectedPresets.has(preset.id)) {
                                                newSelectedPresets.delete(preset.id);
                                            } else {
                                                newSelectedPresets.add(preset.id);
                                            }
                                            return newSelectedPresets;
                                        })
                                    }
                                    onMouseEnter={() => {
                                        if (!isMobile && dragging) {
                                            setSelectedPresets((prevSelectedPresets) => {
                                                const newSelectedPresets = new Set(prevSelectedPresets);
                                                if (newSelectedPresets.has(preset.id)) {
                                                    newSelectedPresets.delete(preset.id);
                                                } else {
                                                    newSelectedPresets.add(preset.id);
                                                }
                                                return newSelectedPresets;
                                            });
                                        }
                                        onPresetHover(preset.id);
                                    }}
                                    onMouseLeave={() => onPresetHover(null)}
                                    className={
                                        selectedPresets.has(preset.id)
                                            ? `h-7 px-2 text-sm rounded-[7.5px] shadow-md 
                                            transition duration-100 ease-in-out transform active:translate-y-0.5
                                            text-stone-100 bg-red-700 border-narkis-border-darker
                                            hover:bg-red-700  hover:text-black
                                            active:bg-red-900`
                                            : `h-7 px-2 text-sm rounded-[7.5px] shadow-md 
                                            transition duration-100 ease-in-out transform hover:-translate-y-0.5 hover:scale-101 
                                            text-white font-semibold bg-narkis-bg border border-narkis-border-darker
                                            hover:font-extrabold hover:shadow-buttonGlow hover:bg-narkis-bg
                                            active:bg-red-700 active:translate-y-0`
                                    }
                                >
                                    {preset.name}
                                </Button>
                            );
                        }
                        return null;
                    })}
                </div>
            </div>

            {/* adetailer */}
            <Collapsible
                defaultOpen={adetailerCollapsed}
                onOpenChange={setAdetailerCollapsed}
                className={`
                    bg-narkis-bg border-narkis-border-darker rounded-[7.5px]
                    transition-all duration-100 ease-in-out p-2
                    ${adetailerCollapsed ? " border-b-4" : " border-b"} 
                `}
            >
                <CollapsibleTrigger className="cursor-pointer text-narkis-text-label hover:text-narkis-text-label">
                    <div className="flex items-center justify-between text-lg">
                        <span> {t("adetailerCollapse.mainLabel")}</span>
                        <UnfoldVerticalIcon className="ml-1 w-5 h-5"> </UnfoldVerticalIcon>
                    </div>
                </CollapsibleTrigger>
                <CollapsibleContent className="my-2">
                    {/* use adetailer */}
                    <div className="flex justify-between items-center gap-4">
                        <div className="flex items-center gap-1">
                            <Label htmlFor="useAdetailerSwitch" className="flex items-center">
                                {t("useAdetailer.mainLabel")}
                            </Label>
                            <Switch
                                id="useAdetailerSwitch"
                                checked={useAdetailer}
                                onCheckedChange={(checked) => setUseAdetailer(checked)}
                            />
                        </div>
                        <div className="flex items-center gap-1">
                            <Label htmlFor="adetailerDenoisingStrength">{t("adetailerDenoisingStrength.mainLabel")}</Label>

                            <div className="flex items-center mt-1 h-7 bg-narkis-bg border border-narkis-border rounded-[7.5px]">
                                <Button
                                    className="h-full w-1 bg-narkis-bg hover:bg-narkis-bg-lighter pt-1"
                                    onClick={() => setAdetailerDenoisingStrength(Math.max(0, Math.min(1, adetailerDenoisingStrength - 0.05)))}
                                >
                                    -
                                </Button>
                                <input
                                    className="rounded-none text-center h-full w-8 border-0 m-0 p-0 bg-narkis-bg focus:outline-none"
                                    value={`${(adetailerDenoisingStrength * 100).toFixed(0)}%`}
                                    onChange={(e) => {
                                        let value = parseFloat(e.target.value.replace('%', ''));
                                        value = Math.max(0, Math.min(100, value));
                                        setAdetailerDenoisingStrength(value / 100);
                                    }}
                                />
                                <Button
                                    className="h-full w-1 bg-narkis-bg hover:bg-narkis-bg-lighter pt-1"
                                    onClick={() => setAdetailerDenoisingStrength(Math.max(0, Math.min(1, adetailerDenoisingStrength + 0.05)))}
                                >
                                    +
                                </Button>
                            </div>
                        </div>
                    </div>
                </CollapsibleContent>
            </Collapsible>

            <div className="relative overflow-hidden border-narkis-border brightness-125 border rounded-[7.5px]">
                <GradientMeshBackground className="absolute inset-0" />
                <button
                    disabled={isLoading}
                    onClick={handleGenerateClick}
                    className="
                     rounded-[10px]
                    relative w-full px-7 py-4 bg-black brightness-125 bg-opacity-0 leading-none flex items-center justify-center overflow-hidden 
                    transition-all duration-300 ease-in-out
                    hover:bg-opacity-10  shadow-black shadow-inner hover:brightness-200
                    active:bg-opacity-0 transition-transform-200 active:scale-95 active:shadow-sm
                    disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-opacity-30 disabled:hover:shadow-none disabled:active:scale-100"
                >
                    <span className="relative z-10 flex items-center justify-center">
                        {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                        {t("generateButton.mainLabel")}
                    </span>
                </button>
            </div>
        </div>
    );
};

export default PresetsTab;
