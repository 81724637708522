import { useState, useEffect, Dispatch, SetStateAction } from 'react';

function useLocalStorageState<T>(key: string, defaultValue: T): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T>(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue !== null ? JSON.parse(storedValue) : defaultValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  const setValueAndStorage: Dispatch<SetStateAction<T>> = (newValue) => {
    setValue((prev) => {
      const resolvedValue = newValue instanceof Function ? newValue(prev) : newValue;
      localStorage.setItem(key, JSON.stringify(resolvedValue));
      return resolvedValue;
    });
  };

  return [value, setValueAndStorage];
}

export { useLocalStorageState };