import { createContext, useContext, useState } from 'react';

interface TesterOptionsContextType {
    testerExtraOptionsSwitch: boolean;
    handleTesterOptionsChange: (isChecked: boolean) => void;
}

const TesterOptionsContext = createContext<TesterOptionsContextType | null>(null);

export const useTesterOptions = () => useContext(TesterOptionsContext);

export const TesterOptionsProvider = ({ children }: { children: React.ReactNode }) => {
    const [testerExtraOptionsSwitch, setTesterExtraOptionsSwitch] = useState(localStorage.getItem("tester_options") === "true");

    const handleTesterOptionsChange = (isChecked: boolean) => {
        console.log("handleTesterOptionsChange", isChecked);
        localStorage.setItem("tester_options", isChecked ? "true" : "false"); // Ensure the value is stored as a string
        setTesterExtraOptionsSwitch(isChecked);
    };

    return (
        <TesterOptionsContext.Provider value={{ testerExtraOptionsSwitch, handleTesterOptionsChange }}>
            {children}
        </TesterOptionsContext.Provider>
    );
};