import { StarFilledIcon } from '@radix-ui/react-icons';
import React from 'react';

const RatingSection: React.FC = () => {
  return (
    <div className="flex flex-col md:flex-row justify-center align-center gap-3 inet">
      <div className="-space-x-5 avatar-group justify-start flex flex-wrap">
        <div className="w-10 h-10 rounded-full border-2 border-narkis-bg bg-pink-200 mr-0 m-1"></div>
        <div className="w-10 h-10 rounded-full border-2 border-narkis-bg bg-blue-200 m-1"></div>
        <div className="w-10 h-10 rounded-full border-2 border-narkis-bg bg-green-200 m-1"></div>
        <div className="w-10 h-10 rounded-full border-2 border-narkis-bg bg-yellow-200 m-1"></div>
        <div className="w-10 h-10 rounded-full border-2 border-narkis-bg bg-purple-200 m-1"></div>
      </div>
      <div className="flex flex-col justify-center items-center md:items-start gap-1">
        <div className="rating flex flex-row">
            <StarFilledIcon className="w-5 h-5 text-yellow-400" />
            <StarFilledIcon className="w-5 h-5 text-yellow-400" />
            <StarFilledIcon className="w-5 h-5 text-yellow-400" />
            <StarFilledIcon className="w-5 h-5 text-yellow-400" />
            <StarFilledIcon className="w-5 h-5 text-yellow-400" />
        </div>
        <div className="text-base text-base-content/80">
          <span className="font-semibold text-base-content">10,000+</span> photos generated
        </div>
      </div>
    </div>
  );
};

export default RatingSection;