// UserAccountView.tsx
import React from "react";

interface UserAccountViewProps {
    user: any;
}

const UserAccountView: React.FC<UserAccountViewProps> = ({ user }) => {
    return (
        <div className="p-4 border-2 border-pink-500">
            <h2>User Account View</h2>
        </div>
    );
};

export default UserAccountView;
