import React, { useState, useEffect } from "react";
import GradientMeshBackground from "./GradientMeshButton";
import { Gift, X } from "lucide-react";
import { useToast } from "./shadcn/ui/use-toast";
import { useAuth } from "../contexts/AuthContext";

interface DailyGiftModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const DailyGiftModal: React.FC<DailyGiftModalProps> = ({ isOpen, onClose }) => {
    const [isVisible, setIsVisible] = useState(false);
    const { toast } = useToast();
    const { redeemDailyGift } = useAuth();

    useEffect(() => {
        if (isOpen) {
            setIsVisible(true);
        } else {
            const timer = setTimeout(() => setIsVisible(false), 300);
            return () => clearTimeout(timer);
        }
    }, [isOpen]);

    const handleClaimGift = async () => {
        try {
            const result = await redeemDailyGift();
            if (result && result.success) {
                setIsVisible(false);
                toast({
                    title: "Success",
                    description: result?.message || "Daily gift claimed successfully",
                    variant: "default",
                });
                return result;
            } else {
                setIsVisible(false);
                toast({
                    title: "Error",
                    description: result?.message || "Failed to claim daily gift. Please try again.",
                    variant: "destructive",
                });
            }
        } catch (error: any) {
            throw error;
        }
    };

    const handleClaim = async (e: React.MouseEvent) => {
        e.stopPropagation();
        await handleClaimGift();
    };

    if (!isVisible) return null;

    return (
        <div
            className={`text-white fixed bottom-4 right-4 w-72 bg-narkis-bg border border-narkis-border  rounded-lg shadow-lg overflow-hidden transition-all duration-1000 ease-in-out ${
                isOpen ? "opacity-100 translate-y-0" : "opacity-0 translate-y-full"
            }`}
            style={{ zIndex: 9999 }} // Add a high z-index to ensure it's on top
        >
            <div className="relative p-4">
                <GradientMeshBackground className="absolute inset-0 opacity-90" />
                <button
                    onClick={onClose}
                    className="absolute top-2 right-2 text-narkis-text-title hover:text-narkis-text-bright transition-colors"
                >
                    <X size={16} />
                </button>
                <h3 className="text-lg font-bold text-narkis-text-title opacity-100 brightness-105 mb-2">Daily Gift</h3>
                <p className="text-sm text-narkis-text-bright brightness-100 mb-4">Your daily credits are ready!</p>
                <button
                    onClick={handleClaim}
                    className="
                    relative overflow-hidden
                    shadow-inner shadow-black 
                    w-full py-2 px-4 
                    bg-gradient-to-r from-emerald-600 to-blue-600 
                    text-white rounded-md font-semibold transition-all duration-300 ease-in-out transform 
                    hover:from-emerald-500 hover:to-blue-600 hover:scale-102 ring-0 
                    focus:outline-none focus:border-emerald-400
                    active:scale-98 active:shadow-lg active:shadow-emerald-700/50
                    before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-gradient-to-r before:from-transparent before:via-white/30 before:to-transparent before:translate-x-[-200%] before:skew-x-[-25deg] before:transition-transform before:duration-1000
                    active:before:translate-x-[200%]
                    after:content-[''] after:absolute after:inset-0 after:bg-[radial-gradient(circle,_rgba(255,255,255,0.8)_10%,_transparent_10.01%)] after:bg-no-repeat after:bg-center after:scale-[0] after:opacity-0 after:transition-[transform,opacity] after:duration-700 after:ease-in-out
                    active:after:scale-[10] active:after:opacity-50
                    group
                    "
                >
                    <span className="relative z-10 text-xl transition-transform duration-300 group-active:scale-90 flex items-center justify-center">
                        <Gift className="inline-block mr-2 h-6 w-6 transition-transform duration-300 group-active:rotate-[720deg]" />
                        Claim Gift
                    </span>
                    <span className="absolute inset-0 bg-gradient-to-r from-emerald-400 to-blue-400 opacity-0 transition-opacity duration-300 group-active:animate-pulse-subtle"></span>
                </button>
            </div>
        </div>
    );
};

export default DailyGiftModal;
