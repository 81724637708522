import React, { useState, useEffect } from 'react';
import { AlertTriangle } from 'lucide-react';

const NonRegisteredUserInfo: React.FC = () => {
    const [isExpanded, setIsExpanded] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsExpanded(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 py-2 mb-4 transition-all duration-300 ease-in-out overflow-hidden">
            <div 
                className="flex items-center cursor-pointer" 
                onClick={() => setIsExpanded(!isExpanded)}
            >
                <AlertTriangle className="h-6 w-6 mr-2" />
                <p className="font-bold">Important Information for Non-Registered Users</p>
            </div>
            <div 
                className={`mt-2 transition-all duration-300 ease-in-out ${
                    isExpanded ? 'max-h-48 opacity-100' : 'max-h-0 opacity-0'
                }`}
            >
                <ul className="list-disc list-inside text-sm">
                    <li>Your generated images are temporarily stored in your browser.</li>
                    <li>These images will be lost if you clear your browser data or switch devices.</li>
                    <li>Register now to permanently save your creations and access them from anywhere!</li>
                </ul>
            </div>
        </div>
    );
};

export default NonRegisteredUserInfo;
