import React from "react";
import { Link } from "react-router-dom";
import Page from "../../../Page";
import { blogMetadata, blogPosts, guides } from "./blogMetadata";
import Newsletter from "./newsletter";
import Footer from "../../Footer";

const BlogMain: React.FC = () => {
    return (
        <Page {...blogMetadata}>
            <div className="container mx-auto px-4 md:px-8 lg:px-16 xl:px-32 py-8">
                <h2 className="text-3xl font-bold mb-6 text-primary">Articles</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {blogPosts.map((post) => (
                        <Link key={post.id} to={`/blog/${post.id}`} className="block">
                            <div className=" rounded-lg shadow-lg overflow-hidden transition-all duration-300 h-[400px] flex flex-col relative group">
                                <div className="relative h-48">
                                    <img
                                        src={post.imageUrl}
                                        alt={post.title}
                                        className="w-full h-full object-cover transition-all duration-300 filter brightness-[40%] group-hover:brightness-100"
                                    />
                                    <h2 className="absolute inset-0 flex items-center justify-center text-xl font-semibold text-center transition-all duration-300 transform group-hover:-translate-y-full">
                                        <span
                                            className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600 filter drop-shadow-lg py-1 rounded"
                                            style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
                                        >
                                            {post.title}
                                        </span>
                                    </h2>
                                </div>
                                <div className="p-6 flex flex-col flex-grow relative bg-stone-200">
                                    <h2 className="text-xl font-semibold text-red-600 mb-2 absolute top-0 left-6 right-6 opacity-0 group-hover:opacity-100 transition-all duration-300 transform -translate-y-full group-hover:translate-y-0">
                                        {post.title}
                                    </h2>
                                    <div className="flex flex-col justify-between h-full transition-all duration-300 transform group-hover:translate-y-2 group-hover:pt-6">
                                        <p className="text-stone-950 mb-4 group-hover:mb-2 transition-all duration-300 flex-grow overflow-hidden">{post.description}</p>
                                        <div className="flex justify-between items-center text-sm text-stone-800 mt-auto pb-4">
                                            <span>{post.author}</span>
                                            <span>{post.date}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>

                <h2 className="text-3xl font-bold mt-12 mb-6 text-primary">Guides</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {guides.map((guide) => (
                        <Link key={guide.id} to={`/blog/${guide.id}`} className="block">
                            <div className="bg-stone-200 rounded-lg shadow-lg overflow-hidden transition-all duration-300 h-[400px] flex flex-col relative group">
                                <div className="relative h-48">
                                    <img
                                        src={guide.imageUrl}
                                        alt={guide.title}
                                        className="w-full h-full object-cover transition-all duration-300 filter brightness-50 group-hover:brightness-100"
                                    />
                                    <h2 className="absolute inset-0 flex items-center justify-center text-xl font-semibold text-center transition-all duration-300 transform group-hover:-translate-y-full">
                                        <span
                                            className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600 filter drop-shadow-lg py-1 rounded"
                                            style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
                                        >
                                            {guide.title}
                                        </span>
                                    </h2>
                                </div>
                                <div className="p-6 flex flex-col flex-grow relative">
                                    <h2 className="text-xl font-semibold text-red-600 mb-2 absolute top-0 left-6 right-6 opacity-0 group-hover:opacity-100 transition-all duration-300 transform -translate-y-full group-hover:translate-y-0">
                                        {guide.title}
                                    </h2>
                                    <div className="flex flex-col justify-between h-full transition-all duration-300 transform group-hover:translate-y-2 group-hover:pt-6">
                                        <p className="text-stone-950 mb-4 group-hover:mb-2 transition-all duration-300 flex-grow overflow-hidden">{guide.description}</p>
                                        <div className="flex justify-between items-center text-sm text-stone-800 mt-auto pb-4">
                                            <span>{guide.author}</span>
                                            <span>{guide.date}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
                <Newsletter tagline="Subscribe to our newsletter to get the latest tips and tricks on AI and coding." />
            </div>
            <Footer />
        </Page>
    );
};

export default BlogMain;
