import { Metadata } from "../../../types/pageMetadata";
import Page from "../../../Page";
import Footer from "../../Footer";

const termsMetadata: Metadata = {
    title: "Terms of Service - Narkis.ai | AI-Powered Personal Photography Studio",
    description:
        "Read the terms of service for Narkis.ai, your AI-powered personal photography studio. Understand our policies, user rights, and responsibilities.",
    h1: "Narkis.ai Terms of Service | AI-Powered Personal Photography Studio",
    canonical: "https://narkis.ai/terms",
    keywords: [
        "Narkis.ai Terms of Service",
        "AI Photography Terms",
        "AI User Agreement",
        "AI Image Generation Terms",
    ],
    author: "Narkis.ai",
    robots: "index, nofollow",
    ogTitle: "Narkis.ai Terms of Service - AI-Powered Personal Photography",
    ogDescription:
        "Understand your rights and responsibilities when using Narkis.ai, the AI-powered personal photography studio. Read our comprehensive terms of service.",
    ogImage: "summary-large-terms.png",
    ogUrl: "https://narkis.ai/terms",
    twitterCard: "summary",
    twitterTitle: "Narkis.ai Terms of Service - AI Photography Studio",
    twitterDescription:
        "Learn about the terms governing your use of Narkis.ai, the AI-powered personal photography platform. Understand our policies and your responsibilities.",
    twitterImage: "summary-large-terms.png",
    twitterCreator: "@narkisai",
};

const TermsOfService = () => {
    return (
        <Page {...termsMetadata}>
            <div className="rounded-lg border bg-card text-card-foreground shadow-sm w-full max-w-4xl mx-auto my-8 p-4">
                <div className="p-6 pt-0">
                    <article className="prose lg:prose-xl">
                        <h1 className="text-4xl font-bold mb-6 text-primary">Terms of Service</h1>

                        <p className="lead text-xl mb-8">
                            Welcome to Narkis.ai, the AI-powered personal photography studio. By using our service, you agree to
                            these terms. Please read them carefully.
                        </p>

                        <h2 className="text-3xl font-semibold mt-12 mb-4 text-primary">1. Service Description</h2>
                        <p className="mb-4">
                            Narkis.ai is a platform that uses artificial intelligence to create personalized photographs. Our key
                            features include:
                        </p>
                        <ul className="list-disc pl-6 mb-4">
                            <li>Personalized AI model training using user-uploaded photos</li>
                            <li>High-quality AI-generated image creation</li>
                            <li>Customizable presets and advanced options</li>
                            <li>Flexible subscription plans</li>
                        </ul>

                        <h2 className="text-3xl font-semibold mt-12 mb-4 text-primary">2. User Responsibilities</h2>
                        <p className="mb-4">As a user of Narkis.ai, you agree to:</p>
                        <ul className="list-disc pl-6 mb-4">
                            <li>Provide accurate and up-to-date information when creating an account</li>
                            <li>Upload only photos that you have the right to use</li>
                            <li>Use the service in compliance with all applicable laws and regulations</li>
                            <li>Not attempt to reverse-engineer or exploit our AI models or systems</li>
                        </ul>

                        <h2 className="text-3xl font-semibold mt-12 mb-4 text-primary">3. Intellectual Property and Privacy</h2>
                        <p className="mb-4">
                            You retain all rights to the photos you upload and the AI-generated images you create using our service. 
                            These are yours to use as you see fit. Narkis.ai does not claim any ownership over your uploaded photos 
                            or generated images.
                        </p>
                        <p className="mb-4">
                            We respect your privacy and the confidentiality of your content. Narkis.ai will not use, view, or access 
                            the photos you upload or the images you generate, unless you specifically request our assistance or give 
                            us explicit permission to do so. This policy ensures that your personal content remains private and secure.
                        </p>
                        <p className="mb-4">
                            You grant Narkis.ai a <strong>limited license</strong> to use your uploaded photos <strong>solely</strong> for the purpose of training your 
                            personalized AI model. This process is automated and does not involve human review of your photos.
                        </p>

                        <h2 className="text-3xl font-semibold mt-12 mb-4 text-primary">4. Privacy and Data Protection</h2>
                        <p className="mb-4">
                            We take your privacy seriously. Our use of your data is governed by our <a href="/privacy" className="text-blue-500 underline">Privacy Policy</a>. We implement
                            robust security measures to protect your photos and generated images. You have the option to delete
                            your trained model and associated data at any time.
                        </p>

                        <h2 className="text-3xl font-semibold mt-12 mb-4 text-primary">5. Subscription and Payments</h2>
                        <p className="mb-4">
                            Narkis.ai offers various subscription plans. By subscribing, you agree to pay the fees associated with
                            your chosen plan. We use Stripe for secure payment processing. Subscriptions will automatically renew
                            unless cancelled before the renewal date.
                        </p>

                        <h2 className="text-3xl font-semibold mt-12 mb-4 text-primary">6. Limitation of Liability</h2>
                        <p className="mb-4">
                            While we strive for high-quality outputs, Narkis.ai is not responsible for any dissatisfaction with
                            the AI-generated images. We do not guarantee specific results or outcomes from using our service.
                            However, we value your satisfaction and encourage you to contact us if you have any concerns. We will
                            always strive to address your issues and ensure your satisfaction with our service to the best of our abilities.
                        </p>

                        <h2 className="text-3xl font-semibold mt-12 mb-4 text-primary">7. Modifications to the Service</h2>
                        <p className="mb-4">
                            We continuously work to improve Narkis.ai. We reserve the right to modify, suspend, or discontinue
                            any part of the service at any time. We will provide notice of significant changes when possible.
                        </p>

                        <h2 className="text-3xl font-semibold mt-12 mb-4 text-primary">8. Termination</h2>
                        <p className="mb-4">
                            We reserve the right to terminate or suspend your account for violations of these terms or for any
                            other reason at our discretion. You may also terminate your account at any time by contacting our
                            support team.
                        </p>

                        <h2 className="text-3xl font-semibold mt-12 mb-4 text-primary">9. Contact Us</h2>
                        <p className="mb-4">
                            If you have any questions about these Terms of Service, please contact us at{" "}
                            <a href="mailto:support@narkis.ai" className="text-blue-500 underline">
                                support@narkis.ai
                            </a>
                            .
                        </p>

                        <p className="mt-8 text-sm text-gray-500">Last updated: July 2024</p>
                    </article>
                </div>
            </div>
            <Footer />
        </Page>
    );
};

export default TermsOfService;
