import React from 'react';
import { GeneratedImage} from './types/generatedImage';

interface ImageCardProps {
  image: GeneratedImage;
  onClick: () => void;
}

const ImageCard: React.FC<ImageCardProps> = ({ image, onClick }) => (
  <div key={image.image_id} className="border rounded-lg p-2 shadow-md">
    <div className="w-full pb-[100%] relative mb-2 cursor-pointer" onClick={onClick}>
      <img
        src={image.image_url}
        alt={`Generated ${image.image_id}`}
        className="absolute inset-0 w-full h-full object-contain rounded"
      />
    </div>
    <div className="text-xs space-y-2">
      <div>
        <p className="font-semibold">Basic Parameters:</p>
        <p>Sampler: {image.generation_parameters.sampler}</p>
        <p>Steps: {image.generation_parameters.steps}</p>
        <p>CFG Scale: {image.generation_parameters.cfg_scale}</p>
        <p>Seed: {image.generation_parameters.seed}</p>
      </div>
      {(image.generation_parameters.positive_loras?.length > 0 || image.generation_parameters.negative_loras?.length > 0) && (
        <div>
          <p className="font-semibold">LoRAs:</p>
          {image.generation_parameters.positive_loras?.length > 0 && (
            <p>Positive: {image.generation_parameters.positive_loras.join(', ')}</p>
          )}
          {image.generation_parameters.negative_loras?.length > 0 && (
            <p>Negative: {image.generation_parameters.negative_loras.join(', ')}</p>
          )}
        </div>
      )}
      <div>
        <p className="font-semibold">Additional Features:</p>
        <p>Use ADetailer: {image.generation_parameters.use_adetailer ? 'Yes' : 'No'}</p>
        <p>Improve Hands: {image.generation_parameters.improve_hands ? 'Yes' : 'No'}</p>
      </div>
      {image.generation_parameters.use_adetailer && (
        <div>
          <p className="font-semibold">ADetailer Settings:</p>
          <p>Confidence: {image.generation_parameters.adetailer_confidence}</p>
          <p>Denoising Strength: {image.generation_parameters.adetailer_denoising_strength}</p>
          <p>Model: {image.generation_parameters.adetailer_model_name}</p>
        </div>
      )}
    </div>
  </div>
);

export default ImageCard;
