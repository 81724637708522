import { ArrowBigRightDashIcon, LucideArrowBigDownDash } from "lucide-react";
import React from "react";
const REACT_APP_NARKIS_PUBLIC_CLOUDFRONT_URL = process.env.REACT_APP_NARKIS_PUBLIC_CLOUDFRONT_URL;
const headerImage = `${REACT_APP_NARKIS_PUBLIC_CLOUDFRONT_URL}/blog/ai-usage-guide-header.webp`;
const linearImage1 = `${REACT_APP_NARKIS_PUBLIC_CLOUDFRONT_URL}/blog/linear1.jpg`;
const linearImage2 = `${REACT_APP_NARKIS_PUBLIC_CLOUDFRONT_URL}/blog/linear2.jpg`;

const AIUsageGuide: React.FC = () => {
    const isMobile = window.innerWidth <= 768;

    return (
        <article className="prose lg:prose-xl max-w-none">
            <h1 className="text-4xl font-bold mb-6 text-primary">How to Actually Use AI to Improve Your Life</h1>

            <img
                src={headerImage}
                alt="AI Usage Guide Header"
                className="rounded-lg shadow-lg mb-8 w-full h-auto"
            />

            <p className="lead text-xl mb-8">
                Every day brings a new AI breakthrough, another "game-changing" model, a REVOLUTIONARY new agent scoring 47% on
                that one test. Endless hype. You just got off the NFT train and it's all a bit exhausting, isn't it? Let's cut
                through the nonsense and talk about how to actually use this stuff.
            </p>

            

            <h2 className="text-3xl font-semibold mt-12 mb-4 text-primary">The AI We're Actually Talking About</h2>
            <p className="mb-4">
                When we used to say <i>"AI"</i>, we meant classical machine learning. Data scientists – those boring people who dress
                like developers, talk like developers, and spend too much time in rooms containing developers – would do
                presentations during your company's Friday evening demo day, showing off glorified Excel spreadsheets with
                heatmaps and charts proving they can separate the color green from purple after only 4 months of company-expensed cloud
                compute.
            </p>
            <p className="mb-4">
                NO, that's not what we're talking about. If this is what you're looking for, get the F*** off my page. Nobody
                cares about that shit. You wasted 2 years in university instead of just learning Java.
            </p>
            <p className="mb-4">
                What we mean by AI today is <strong>Transformers</strong>. Remember that Michael Bay movie with robots looking for
                a cube on Earth to save their planet?  Well, we found it first. It's called <i>"Attention Is All You Need"</i>, and if you
                knew any better, you would have read the paper <a href="https://arxiv.org/abs/1706.03762" target="_blank" rel="noopener noreferrer">"Attention Is All You Need"</a> (spoiler: I haven't).
            </p>

            <h2 className="text-3xl font-semibold mt-12 mb-4 text-primary">Practical AI Applications</h2>
            <p className="mb-4">
                Forget the sci-fi dreams and nightmares. This new generation of AI shines at mundane tasks that you're probably
                wasting hours on right now:
            </p>

            <div className="flex flex-col justify-center my-10">
                <div className="flex justify-center">
                    <img
                        src={linearImage1}
                    alt="AI Usage Guide Header"
                        className="rounded-lg shadow-lg w-1/2 h-auto mx-2 transition-all duration-300 hover:brightness-110 hover:shadow-xl"
                    />
                
                {isMobile ? (
                    <div className="flex justify-center items-center hover:brightness-110 transition-all duration-200">
                        <LucideArrowBigDownDash size={40} className="text-narkis-text-label" />
                    </div>
                ) : (
                    <div className="flex justify-center items-center hover:brightness-110 transition-all duration-200">
                        <ArrowBigRightDashIcon size={40} className="text-narkis-text-label" />
                    </div>
                )}
                <img
                    src={linearImage2}
                    alt="AI Usage Guide Supplementary"
                    className="rounded-lg shadow-lg w-1/2 h-auto mx-2 transition-all duration-300 hover:brightness-110 hover:shadow-xl"
                />
                </div>
                <p className="text-center text-sm text-gray-400 mt-2">While building Narkis.ai, I created LinearGPT, a tool that translates informal ideas into structured tasks.</p>
            </div>

            <h4 className="text-xl font-semibold mt-6 mb-2 text-primary brightness-75 hover:brightness-100 transition-all duration-300">Text Processing</h4>
            <p className="mb-4">
                If you're still reading text in 2024, you're not going to make it. Start dumping
                everything into ChatGPT and cast this spell: "Using the Pareto 80/20 principle, break down the 20% most useful
                information that conveys 80% of the key insights in this text. Then, summarize those key points in a concise
                format." Congratulations, you've just wasted only 20% of your time instead of 100%.
            </p>

            <h4 className="text-xl font-semibold mt-6 mb-2 text-primary brightness-75 hover:brightness-100 transition-all duration-300">Natural Language Processing</h4>
            <p className="mb-4">
                ChatGPT might finally help you decipher what your project manager
                actually wants. Take the last confusing thing your, let's be honest here, cocaine addict CTO said and feed it to
                ChatGPT: "Could you help me understand what my crackhead CTO wants? After you give an answer, reflect on it and
                see if there is anything you misunderstood or missed and write an improved breakdown". If you're not doing this
                with at least 50% of things your A. Boss, B. Wife and C. parents ask of you, you are not going to make it past
                2026.
            </p>

            <h4 className="text-xl font-semibold mt-6 mb-2 text-primary brightness-75 hover:brightness-100 transition-all duration-300">Tool Building</h4>
            <p className="mb-4">
                While building Narkis.ai, I created LinearGPT out of sheer laziness. It translates
                my incoherent ramblings into actual tasks. No more opening Linear (Jira, but for smart people) and staring at a
                blank description field. Just mutter your thoughts, and voila – a perfectly formatted ticket appears. Despite
                being someone who hates ticket management, I've made over 240 issues, and believe it or not, it has been VERY
                useful.
            </p>

            <p className="mb-4">Once you've got the hang of it, AI opens up some interesting doors:</p>
            <ul className="list-disc pl-6 mb-4">
                <li>Content translation that actually captures nuance</li>
                <li>
                    AI-narrated content creation. Look, you're already knee-deep in this AI mess, so why not go all in? Use
                    ElevenLabs to clone voices with a 60-second sample. Bam! Your YouTube channel now running 24/7, voiced by your
                    digital clone. "But it's not really my v"- Irrelevant. "Your" voice? Replaced. Your thoughts? REPLACED. The AI
                    is now you. It's thinking your thoughts, speaking your words, and probably doing a better job at being you
                    than you ever did. You have just successfully replaced yourself.
                </li>
            </ul>

            <p className="mb-4">
                Look, AI isn't going to steal your job tomorrow. But it will definitely steal your job at some point. Be happy.
                Rejoice! Isn't this what you've always wanted?
            </p>
            <p className="mb-4">
                On a more serious note, the trick is to stay ahead of the curve. Learn to leverage these tools now, and you'll be
                the one building the AI systems of tomorrow instead of being replaced by them.
            </p>

            <h2 className="text-3xl font-semibold mt-12 mb-4 text-primary">The AI Apocalypse Survival Kit</h2>
            <p className="mb-4">
                So you've read this article, and despite my undeniable intellect and priceless advice you are still trying to hold
                on to the <a href="https://youtu.be/7DAT9EUlHe0?t=107" target="_blank" rel="noopener noreferrer">shit rope</a>. This is the best advice you will find on
                the net:
            </p>

            {/* Do you know what a shit rope is, Julian? it's a rope, covered with shit, that AI-sceptics are trying to hold on to. You see, the shit acts like grease; the harder you try to climb up, the tighter you try to hold on, the faster you slide down. */}
            <ul className="list-disc pl-6 mb-4">
                <li>Learn COBOL -- Nobody cares about COBOL and once you learn it, nobody will care about you either.</li>
                <li>A sourdough starter</li>
                <li>Join online Stop-AI communities: misery loves company</li>
                <li>Pick a problem that needs solving, and don't do anything about it.</li>
            </ul>

            <p className="mb-4">
                Subscribe to my email list for more emotional abuse. Or better yet, subscribe to my service and get some good
                photos of yourself. You look like shit. That's why you can't get past the recruiter interview (they don't know
                what "GCP" means).
            </p>
        </article>
    );
};

export default AIUsageGuide;
