// src/components/Login.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../shadcn/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../../shadcn/ui/card";
import { Input } from "../../../shadcn/ui/input";
import { Label } from "../../../shadcn/ui/label";
import { useAuth } from "../../../../contexts/AuthContext";
import { useToast } from "../../../shadcn/ui/use-toast";
import { Loader2 } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { forgotPassword } from "../../../../services/api";

const LoginForm = ({ onSwitchForm }: { onSwitchForm: any }) => {
    const { t } = useTranslation("login");
    const { toast } = useToast();
    //todo gradient background that moves with mouse movement
    const auth = useAuth();
    const { login } = auth;

    const navigate = useNavigate();

    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [showPopover, setShowPopover] = useState(false);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const username = urlParams.get("username");
        if (username) {
            console.log("Invite ID found:", username);
            setUsername(username);
        }

        const userEmail = urlParams.get("email");
        if (userEmail) {
            console.log("User email found:", userEmail);
            const safe = encodeURIComponent(userEmail);
            const sanitizedEmail = decodeURIComponent(safe);
            setUsername(sanitizedEmail);
        }
    }, []);

    const handleSubmit = async () => {
        setIsLoading(true);
        console.log("Logging in user ", username);
        try {
            await login(username, password);
            console.log("navigating to /dashboard...");
            navigate("/dashboard");
        } catch (error: any) {
            console.log("Login failed: " + error.message);
            toast({
                variant: "destructive",
                title: "Login failed",
                description: error.message,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleForgotPassword = async () => {
        try {
            await forgotPassword(email);
            toast({
                variant: "default",
                title: "Password reset email sent",
                description: "Please check your email for further instructions.",
            });
            setShowPopover(false);
        } catch (error: any) {
            console.error("Error requesting forgot password:", error);
            toast({
                variant: "destructive",
                title: "Failed to send reset email",
                description: "Please try again.",
            });
        }
    };

    const [isLoading, setIsLoading] = useState(false);

    return (
        <div>
            <Card className="w-full max-w-sm mx-auto align-middle rounded-[7.5px] bg-narkis-bg-lighter border-narkis-border-darker2 border font-semibold">
                <CardHeader>
                    <CardTitle className="text-2xl">{t("login.title")}</CardTitle>
                    <CardDescription>{t("login.description")}</CardDescription>
                </CardHeader>
                <CardContent>
                    <div className="grid gap-4">
                        <div className="grid gap-2">
                            <Label htmlFor="email">{t("login.username.mainLabel")}</Label>
                            <Input
                                id="username"
                                type="text"
                                placeholder={t("login.username.mainLabel")}
                                required
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                className="rounded-[7.5px] bg-black"
                                tabIndex={1}
                                autoCapitalize="none"
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleSubmit();
                                    }
                                }}
                            />
                        </div>
                        <div className="grid gap-2 ">
                            <div className="flex items-center justify-between">
                                <Label htmlFor="password">{t("login.password.mainLabel")}</Label>
                                <Popover className="relative">
                                    <PopoverButton className="ml-auto inline-block text-sm underline cursor-pointer text-stone-400 hover:text-stone-300">
                                        {t("login.forgotPassword")}
                                    </PopoverButton>
                                    <PopoverPanel
                                        className="
                                    absolute z-10 w-64 p-4 mt-2 bg-narkis-bg border-narkis-border-darker2 border rounded-[7.5px] shadow-lg
                                    "
                                    >
                                        <div className="grid gap-2">
                                            <Label
                                                className="text-narkis-text-bright p-1 text-sm"
                                                htmlFor="forgot-password-email"
                                            >
                                                {t("login.forgotPasswordEmailLabel")}
                                            </Label>
                                            <Input
                                                id="forgot-password-email"
                                                type="email"
                                                placeholder={t("login.forgotPasswordEmailPlaceholder")}
                                                required
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                className="rounded-[7.5px] bg-black"
                                                autoCapitalize="none"
                                            />
                                            <Button
                                                type="button"
                                                className="mt-2 rounded-[7.5px] w-full bg-gradient-to-r border border-narkis-border-darker from-stone-800 via-stone-900 to-stone-800 shadow-lg hover:shadow-2xl transform active:translate-y-0.5 transition-all duration-200 ease-in-out font-extrabold text-narkis-text-bright"
                                                onClick={handleForgotPassword}
                                            >
                                                {t("login.sendResetEmail")}
                                            </Button>
                                        </div>
                                    </PopoverPanel>
                                </Popover>
                            </div>
                            <Input
                                id="password"
                                type="password"
                                placeholder={t("login.password.mainLabel")}
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="rounded-[7.5px] bg-black"
                                autoCapitalize="none"
                                tabIndex={2}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleSubmit();
                                    }
                                }}
                            />
                        </div>
                        {isLoading ? (
                            <Button
                                disabled
                                className="rounded-[7.5px] w-full bg-gradient-to-r border border-narkis-border-darker from-stone-800 via-stone-900 to-stone-800 shadow-lg 
                            transform transition-all duration-200 ease-in-out font-extrabold text-narkis-text-bright"
                            >
                                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                {t("login.loggingIn")}
                            </Button>
                        ) : (
                            <Button
                                type="submit"
                                className="hover:shadow-buttonGlow rounded-[7.5px] w-full bg-gradient-to-r border border-narkis-border-darker from-stone-800 via-stone-900 to-stone-800 
                                shadow-2xl transform active:translate-y-0.5 transition-all duration-200 ease-in-out font-extrabold text-narkis-text-bright"
                                onClick={handleSubmit}
                            >
                                {t("login.buttonLogin")}
                            </Button>
                        )}
                    </div>
                    <div className="mt-4 text-center text-sm">
                        {t("login.dontHaveAccount")}
                        <Button variant="link" className="underline" onClick={() => onSwitchForm("register")}>
                            {t("login.signUp")}
                        </Button>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default LoginForm;
