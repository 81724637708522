import React, { useState, useEffect, useCallback } from "react";
import { useToast } from "../../../shadcn/ui/use-toast";
import { ToastAction } from "../../../shadcn/ui/toast";
import Page from "../../../Page";
import { Metadata } from "../../../types/pageMetadata";
import { generateFalImages, generatePublicFalImages, listPendingImages } from "../../../../services/api";
import { useLocalStorageState } from "../../../../hooks/useLocalStorageState";
import FalImageMenu from "./ImageMenu";
import FalGeneratorMenu from "./FalGeneratorMenu";
import { useUserDataContext } from "../../../../contexts/UserDataContext";
import { useAuth } from "../../../../contexts/AuthContext";
import RegisterModal from "./RegisterModal";
import { CheckIcon, ReloadIcon, StarIcon } from "@radix-ui/react-icons";
import { CheckSquare2Icon, LockOpenIcon } from "lucide-react";
import NonRegisteredUserInfo from "./NonRegisteredUserInfo";

const falGeneratorMetadata: Metadata = {
    title: "Free AI Image Generator - Narkis.ai | Generate AI Photos",
    description: "Use our free AI image generator to create stunning, custom photos. No account required.",
    h1: "Free AI Image Generator",
    canonical: "https://narkis.ai/image-gen",
    keywords: [
        "Free AI Image Generator",
        "Generate AI Pics",
        "AI-Generated Images",
        "Free AI Art",
        "Free AI Photography",
        "Text-to-Image AI",
        "Free AI Image Creation",
        "No Sign-up AI Generator",
        "Instant AI Photos",
        "Generate AI photos without an account",
    ],
    author: "Narkis.ai",
    robots: "index, follow",
    ogTitle: "Create Free AI-Generated Images with Narkis.ai",
    ogDescription: "Experience the power of AI image generation for free. No account needed.",
    ogImage: "summary-large-image-gen.jpg",
    ogUrl: "https://narkis.ai/image-gen",
    twitterCard: "summary_large_image",
    twitterTitle: "Free AI Image Generator | Generate AI Photos",
    twitterDescription:
        "Generate unique, AI-powered images for free with Narkis.ai. No account required. Start creating stunning visuals now!",
    twitterImage: "summary-large-image-gen.jpg",
    twitterCreator: "@narkisai",
};


const sdxlPrompts = [
        "A minimalist logo for a white t-shirt featuring a captivating and surreal cityscape during a rainy night. The logo showcases towering skyscrapers, wet pavements, and glowing city lights reflected in puddles. The scene is depicted with a sense of wonder and tranquility, using flowing lines and dreamlike elements to create a visually captivating design. The color palette is a mix of dark and intense colors, including shades of blue, purple",
        "Powerful liquid explosion, Green grapes, Green background, Commercial photography, A bright environment, Studio lighting, OC rendering, Solid color isolated platform, Professional photography, Color classification, Super detail",
        "a house is reflected in the water of a lake, canon 5d mark iii photo, wlop : :, summer camp, wide shot of a cabin interior, by Henrik Weber, lush landscaping, canon 5d 50 mm lens, baris yesilbas, dramatic photograph, peacefull",
        "A super detailed tiger in a lush jungle, magazine illustration",
        "Astronaut on Mars During sunset",
        "exterior frontal perspective shot of resort villa inspired by Mykonos architecture, sea view visualization, white and blue colours mood, moody lighting, high quality, 8k, real, high resolution photography",
        "female fashion model, faerietale couture, styled in D&G, dynamic light",
        "A striking living room interior, sofa furniture, a living room table, bookshelves, shelving, a fireplace, elegant interior design, perfect layout, consistent colors, moody, hazy, cinematic, surreal, highest resolution, high detail, intricate, best quality, masterpiece, golden ratio",
    ]

const fluxPrompts = [
    'Anime character in a suit is casually leaning on a black Mercedes W124, view from the ground. The text "Narkis.ai" is beautifully shown in yellow neon marker, pretty font, the anime character is drawn in anime style and the car is photorealistic, the surrounding is completely pitch black. The neon text is lighting the scene.',
    'Anime character is casually leaning on a black BMW E36, view from the ground. The text "Narkis.ai" is beautifully drawn in 3D neon marker, pretty font, The character is drawn in anime style and the car is photorealistic, Summer vibe, palm trees, miami seventies, evening, neon, the character is wearing a summer shirt,  detailed face',
    'Anime character is casually leaning on a black Audi A4, view from the ground. The text "Narkis.ai" is beautifully drawn in 3D neon marker, pretty font, The character is drawn in anime style and the car is photorealistic, Summer vibe, palm trees, miami seventies, evening, neon, the character is wearing a summer shirt, detailed face',
    'Anime man wearing a suit is casually leaning on a black Dodge Charger. The text "Narkis.ai" is beautifully drawn in 3D green neon marker, pretty font, The character is drawn in anime style and the car is photorealistic, Summer vibe, palm trees, miami seventies style, evening, neon, detailed face',
    'Anime man is casually leaning on a black Ford Mustang, view from the ground. The text "Narkis.ai" is beautifully drawn in 3D blue neon marker, pretty font, The character is drawn in anime style and the car is photorealistic, Summer vibe, palm trees, miami seventies style, evening, neon, the character is wearing a summer shirt, detailed face',
    'Anime man is casually leaning on a black Porsche 911, view from the ground. The text "Narkis.ai" is beautifully drawn in 3D cyan neon marker, pretty font, The character is drawn in anime style and the car is photorealistic, Summer vibe, palm trees, miami seventies style, evening, neon, the character is wearing a summer shirt, detailed face',
    'Anime man is casually leaning on a yellow Ferrari 488, view from the ground. The text "Narkis.ai" is beautifully drawn in 3D orange neon marker, pretty font, The character is drawn in anime style and the car is photorealistic, Summer vibe, palm trees, evening, neon, the character is wearing a summer shirt, detailed face',
    'Anime man is casually leaning on a white Lamborghini Countach, view from the ground. The text "Narkis.ai" is beautifully drawn in 3D lime neon marker, pretty font, The character is drawn in anime style and the car is photorealistic, Summer vibe, palm trees, miami seventies style, evening, neon, the character is wearing a summer shirt, detailed face',
    'Anime woman is casually leaning on an dark metallic emerald green G Wagon, view from the ground. The text "Narkis.ai" is beautifully drawn in 3D neon marker, pretty font, The girl is drawn in anime style and the car is photorealistic, Summer vibe, palm trees, miami seventies style, evening, neon, the girl is wearing a long white summer dress, detailed face',
    // 'Naruto is casually leaning on a black BMW E36, view from the ground. The text "Narkis.ai" is beautifully drawn in 3D neon green marker, pretty font, Naruto is drawn in anime style and the car is photorealistic, Summer vibe, palm trees, miami seventies, evening, neon, Naruto is wearing a summer shirt, muscular, detailed face'
]

const examplePrompts = {
    "SDXL Turbo": sdxlPrompts,
    "SDXL Lightning": sdxlPrompts,
    "SDXL LCM": sdxlPrompts,
    "SDXL": sdxlPrompts,
    "Auraflow": [
        "Close-up portrait of a majestic iguana with vibrant blue-green scales, piercing amber eyes, and orange spiky crest. Intricate textures and details visible on scaly skin. Wrapped in dark hood, giving regal appearance. Dramatic lighting against black background. Hyper-realistic, high-resolution image showcasing the reptile's expressive features and coloration.",
        "A bustling medieval marketplace with fantastical creatures",
        "A post-apocalyptic cityscape reclaimed by nature",
    ],
    "SD Cascade": [
        "comic portrait of a man wearing a cowboy hat (Tom|Ryan), explorer outfit, jungle in the background, lightning, dinosaurs scene, dinosaurs in the forest, dangerous, comic book art, stunning volumetric light from behind . graphic illustration, comic art, graphic novel art, vibrant, highly detailed",
        "A steampunk-inspired laboratory with intricate machinery",
        "A tranquil Japanese garden with cherry blossoms in full bloom",
    ],
    "Flux": fluxPrompts,
    "Flux Pro": fluxPrompts,
    "SD3": [
        "A photorealistic portrait of a mythical creature",
        "A breathtaking aerial view of an impossibly beautiful landscape",
        "A hyper-detailed macro shot of an alien insect",
    ],
};

const FalImageGenerator: React.FC = () => {
    const { toast } = useToast();
    const { user, token } = useAuth();
    const [model, setModel] = useLocalStorageState("imageGen_lastSelectedModel", "SDXL Lightning");
    const [positivePrompt, setPositivePrompt] = useState("");
    const [negativePrompt, setNegativePrompt] = useLocalStorageState("imageGen_negativePrompt", "");
    const [numImages, setNumImages] = useLocalStorageState("imageGen_numImages", 1);
    const [imageLayout, setImageLayout] = useLocalStorageState("imageGen_imageLayout", "square");
    const [steps, setSteps] = useLocalStorageState("imageGen_steps", 30);
    const [cfgScale, setCfgScale] = useLocalStorageState("imageGen_cfgScale", 7);
    const [seed, setSeed] = useLocalStorageState("imageGen_seed", "-1");
    const [isLoading, setIsLoading] = useState(false);
    const [generatedImages, setGeneratedImages] = useState<any[]>([]);
    const [showRegisterModal, setShowRegisterModal] = useState(false);

    const [sortBy] = useLocalStorageState("imageGen_sortBy", "newest");
    const [likedOnly] = useLocalStorageState("imageGen_likedOnly", false);
    const [allImages, setAllImages] = useState<any[]>([]);

    const updatePositivePrompt = useCallback((newPrompt: string) => {
        setPositivePrompt(newPrompt);
        localStorage.setItem("imageGen_positivePrompt", newPrompt);
    }, []);

    useEffect(() => {
        const storedPrompt = localStorage.getItem("imageGen_positivePrompt");
        if (storedPrompt) {
            setPositivePrompt(storedPrompt);
        } else {
            const promptsForSelectedModel = examplePrompts[model as keyof typeof examplePrompts];
            const randomIndex = Math.floor(Math.random() * promptsForSelectedModel.length);
            const randomPrompt = promptsForSelectedModel[randomIndex];
            setPositivePrompt(randomPrompt);
        }
    }, [model]);

    useEffect(() => {
        if (!user) {
            setGeneratedImages([]);
        }
    }, [user]);

    const validModels = [
        { name: "SDXL Turbo", cost: 1, public: true, free: true },
        { name: "SDXL Lightning", cost: 1, public: true, free: true },
        { name: "SDXL LCM", cost: 1, public: false, free: true },
        { name: "SDXL", cost: 3, public: false, free: true },
        { name: "Auraflow", cost: 3, public: false, free: true },
        // { name: "SD Cascade", cost: 5, public: false, free: true },
        { name: "Flux", cost: 15, public: false, free: false, new: true },
        { name: "Flux Pro", cost: 30, public: false, free: false, new: true },
        { name: "SD3", cost: 50, public: false, free: false },
    ];

    const { setTrainingCredits, setImageCredits } = useUserDataContext();

    const handleRegisterSuccess = () => {
        setShowRegisterModal(false);
        // Refresh user data or perform any other necessary actions
    };

    // Manual update mechanism
    useEffect(() => {
        let updateInterval: NodeJS.Timeout;
        let checkInterval = 1500; // Start with 1.5 seconds
        const maxInterval = 10000; // Max interval of 10 seconds

        const checkForUpdates = () => {
            const pendingStatuses = ["GENERATING", "QUEUED", "IN_PROGRESS", "PREPARING", "PENDING"];
            const pendingImages = generatedImages.filter((image) => pendingStatuses.includes(image.status));

            if (pendingImages.length > 0) {
                // Only fetch pending images
                listPendingImages(
                    "",
                    pendingImages.map((img) => img.image_id)
                )
                    .then((response) => {
                        const updatedImages = response.data.images;
                        setGeneratedImages((prevImages) => {
                            return prevImages.map((prevImage) => {
                                const updatedImage = updatedImages.find((img: any) => img.id === prevImage.id);
                                if (updatedImage && updatedImage.status !== prevImage.status) {
                                    return {
                                        ...prevImage,
                                        ...updatedImage,
                                        status: updatedImage.status,
                                        deleteTimeout: updatedImage.status === "FAILED" ? Date.now() + 5000 : undefined,
                                    };
                                }
                                return prevImage;
                            });
                        });

                        // Reset interval if there are still pending images
                        checkInterval = 1500;
                    })
                    .catch((error) => {
                        console.error("Error fetching updated images:", error);
                    });
            } else {
                // Increase interval if no pending images
                checkInterval = Math.min(checkInterval * 2, maxInterval);
            }

            updateInterval = setTimeout(checkForUpdates, checkInterval);
        };

        // Initial delay before starting the checks
        const initialDelay = setTimeout(() => {
            checkForUpdates();
        }, 1500);

        return () => {
            clearTimeout(initialDelay);
            clearTimeout(updateInterval);
        };
    }, [generatedImages, sortBy, likedOnly]);

    const [publicCredits, setPublicCredits] = useState<number | null>(null);

    useEffect(() => {
        if (!token) {
            const storedCredits = localStorage.getItem("publicCredits");
            setPublicCredits(storedCredits ? parseInt(storedCredits, 10) : null);
            const storedImages = JSON.parse(localStorage.getItem("generatedImages") || "[]");
            setAllImages(storedImages);
            setGeneratedImages(storedImages);
        }
    }, [token]);

    const handleLoggedInGenerate = async (isAdvancedMode: boolean) => {
        try {
            const response = await generateFalImages(
                model,
                positivePrompt,
                negativePrompt,
                numImages,
                imageLayout,
                steps,
                cfgScale,
                seed,
                isAdvancedMode
            );
            const { credits, gift_credits, images } = response.data;

            const newImages = images.map((image: any) => ({
                ...image,
                status: "COMPLETED",
                deleteTimeout: null,
            }));

            const updatedImages = [...newImages, ...allImages].slice(0, 20); // Keep only the latest 20 images
            localStorage.setItem("generatedImages", JSON.stringify(updatedImages));
            setAllImages(updatedImages);
            setGeneratedImages(updatedImages);
            setImageCredits(credits, gift_credits);
            toast({
                title: "Images generated successfully",
                description: `${images.length} images have been generated.`,
            });
        } catch (error: any) {
            console.error("Error generating images:", error);
            toast({
                variant: "destructive",
                title: "Failed to generate images",
                description: error.response?.data?.message || "Please try again later.",
            });
        }
    };

    const handleFreeTrialComplete = () => {
        toast({
            title: "Continue Your AI Journey",
            description: (
                <span>
                    Create a free account to:
                    <ul className="list-disc list-inside mt-1">
                        <li>
                            Receive <strong>50</strong> free credits instantly
                        </li>
                        <li>Redeem 10 free credits daily</li>
                        <li>Unlock advanced AI features</li>
                    </ul>
                </span>
            ),
            action: (
                <ToastAction
                altText="Join Now"
                onClick={() => setShowRegisterModal(true)}
                className="bg-transparent border-2 border-purple-400 text-purple-400 px-4 py-1.5 rounded-md text-sm font-semibold hover:bg-purple-400 hover:text-white transition-all duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-purple-300 focus:ring-opacity-50"
            >
                Join Now
            </ToastAction>
            ),
            duration: 15000, // 15 seconds
        });
    };

    const handlePublicGenerate = async () => {
        try {
            const response = await generatePublicFalImages(
                model,
                positivePrompt,
                negativePrompt,
                numImages,
                imageLayout,
                steps,
                cfgScale,
                seed
            );
            const { images, remaining_free_images } = response.data;

            const newImages = images.map((image: any) => ({
                ...image,
                status: "COMPLETED",
                deleteTimeout: null,
            }));

            const updatedImages = [...newImages, ...allImages].slice(0, 20); // Keep only the latest 20 images
            localStorage.setItem("generatedImages", JSON.stringify(updatedImages));
            setAllImages(updatedImages);
            setGeneratedImages(updatedImages);
            setPublicCredits(remaining_free_images);
            localStorage.setItem("publicCredits", remaining_free_images.toString());

            toast({
                title: "Images generated successfully",
                description: `${images.length} images have been generated. You have ${remaining_free_images} free generations left.`,
                action: (
                    <button onClick={() => setShowRegisterModal(true)} className="bg-blue-500 text-white px-4 py-2 rounded">
                        Register Now
                    </button>
                ),
            });
        } catch (error: any) {
            console.error("Error generating public images:", error);
            if (error.remaining_free_images !== undefined) {
                setPublicCredits(0);
                localStorage.setItem("publicCredits", "0");
            }
            handleFreeTrialComplete();
        }
    };

    const handleGenerate = async (isAdvancedMode: boolean) => {
        setIsLoading(true);
        try {
            if (token) {
                await handleLoggedInGenerate(isAdvancedMode);
            } else {
                await handlePublicGenerate();
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!token) {
            const storedImages = JSON.parse(localStorage.getItem("generatedImages") || "[]");
            setGeneratedImages(storedImages);
        }
    }, [token]);

    useEffect(() => {
        const interval = setInterval(() => {
            setGeneratedImages((prevImages) =>
                prevImages.filter((image) => {
                    if (image.status === "FAILED" && image.deleteTimeout && image.deleteTimeout < Date.now()) {
                        return false;
                    }
                    return true;
                })
            );
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <Page {...falGeneratorMetadata}>
            <div
                className="font-medium
                flex flex-col md:flex-row sm:max-h-[calc(100vh-70px)] overflow-y-hidden w-full
                text-narkis-text gap-0.5
                border-2 rounded-[10px] shadow-md border-narkis-border-darker2
                "
            >
                <div className="flex flex-col md:flex-row min-h-screen w-full text-narkis-text gap-4 p-4">
                    {/* Left menu */}
                    <div className="md:w-1/3 lg:w-1/4 xl:w-1/5 mb-4 md:mb-0 min-w-[310px] max-h-[calc(100vh-95px)] overflow-y-auto scrollbar-thin scrollbar-thumb-narkis-border-lighter scrollbar-track-transparent hover:scrollbar-thumb-narkis-border">
                        {!user && (
                            <>
                                <div className="bg-gradient-to-r from-emerald-950 to-blue-950 border-2 border-narkis-border rounded-lg p-4 mb-4 shadow-lg">
                                    <h1 className="text-xl font-bold text-narkis-text-title mb-2 text-center">
                                        Free AI Image Generator
                                    </h1>
                                    <ul className="list-none text-sm text-narkis-text mb-3">
                                        <li className="flex items-center mb-1">
                                            <CheckSquare2Icon className="text-emerald-500 mr-2 h-4 w-4 stroke-[3]" />
                                            <strong>50 FREE credits</strong>&nbsp;upon sign-up
                                        </li>
                                        <li className="flex items-center mb-1">
                                            <ReloadIcon className="text-blue-500 mr-2 h-4 w-4 stroke-[3]" />
                                            <strong>10 FREE credits</strong>&nbsp;every day
                                        </li>
                                        <li className="flex items-center mb-1">
                                            <LockOpenIcon className="text-purple-500 mr-2 h-4 w-4 stroke-[3]" />
                                            <strong>Unlimited access</strong>&nbsp;to your creations
                                        </li>
                                        <li className="flex items-center">
                                            <StarIcon className="text-pink-500 mr-2 h-4 w-4 fill-current" />
                                            <strong>Exclusive features</strong>&nbsp;for members
                                        </li>
                                    </ul>
                                    <button
                                        onClick={() => setShowRegisterModal(true)}
                                        className="
                                        relative overflow-hidden
                                        shadow-md shadow-emerald-700/30
                                        w-full py-2 px-4 
                                        bg-gradient-to-r from-emerald-500 to-blue-500 
                                        text-white rounded-full font-bold text-sm transition-all duration-300 ease-in-out transform 
                                        hover:from-emerald-400 hover:to-blue-400 hover:scale-105 
                                        focus:outline-none focus:ring-2 focus:ring-emerald-300
                                        active:scale-95 active:shadow-inner
                                        group
                                        "
                                    >
                                        <span className="relative z-10 transition-transform duration-300 group-hover:animate-pulse flex items-center justify-center">
                                            Claim Your Free Credits Now
                                        </span>
                                        <span className="absolute inset-0 bg-gradient-to-r from-yellow-400 via-red-500 to-pink-500 opacity-0 transition-opacity duration-300 group-hover:opacity-20"></span>
                                    </button>
                                    <p className="text-xs text-center mt-2 text-narkis-text-lighter">
                                        Join the AI art revolution - 10+ creators and growing!
                                    </p>
                                </div>
                                <NonRegisteredUserInfo />
                            </>
                        )}
                        <FalGeneratorMenu
                            isLoggedIn={!!user}
                            model={model}
                            setModel={setModel}
                            positivePrompt={positivePrompt}
                            setPositivePrompt={updatePositivePrompt}
                            negativePrompt={negativePrompt}
                            setNegativePrompt={setNegativePrompt}
                            numImages={numImages}
                            setNumImages={setNumImages}
                            imageLayout={imageLayout}
                            setImageLayout={setImageLayout}
                            steps={steps}
                            setSteps={setSteps}
                            cfgScale={cfgScale}
                            setCfgScale={setCfgScale}
                            seed={seed}
                            setSeed={setSeed}
                            isLoading={isLoading}
                            handleGenerate={handleGenerate}
                            validModels={validModels}
                        />
                    </div>

                    {/* Right side - Generated Images */}
                    <div className="md:flex-1 overflow-auto ">
                        <FalImageMenu
                            validModels={validModels}
                            images={allImages}
                            onImageUpdate={setAllImages}
                            isLoggedIn={!!user}
                        />
                    </div>
                </div>
            </div>
            {showRegisterModal && <RegisterModal onClose={() => setShowRegisterModal(false)} onSuccess={handleRegisterSuccess} />}
        </Page>
    );
};

export default FalImageGenerator;
