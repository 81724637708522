import React from "react";
import { useTranslation } from "react-i18next";

import visaLogo from "../../../../assets/visa.svg";
import mastercardLogo from "../../../../assets/mastercard.svg";
import amexLogo from "../../../../assets/amex.svg";
import jcbLogo from "../../../../assets/jcb.svg";
import unionpayLogo from "../../../../assets/unionpay.svg";
import discoverLogo from "../../../../assets/discover.svg";
import dinersLogo from "../../../../assets/diners.svg";
import { LockKeyholeIcon } from "lucide-react";

const TrustSecurity: React.FC = () => {
    const { t } = useTranslation("landing");

    return (
        <div className="">
            <h4 className="font-bold mb-2">Trust & Security</h4>
            <div className="flex flex-col space-y-3">
                <div className="flex flex-wrap gap-1">
                    <img
                        src={visaLogo}
                        alt="Visa Logo"
                        className="w-8 h-8 rounded-[2.5px] hover:brightness-110 hover:scale-110 transition-all duration-200"
                    />
                    <img
                        src={mastercardLogo}
                        alt="Mastercard Logo"
                        className="w-8 h-8 rounded-[2.5px] hover:brightness-110 hover:scale-110 transition-all duration-200"
                    />
                    <img
                        src={amexLogo}
                        alt="Amex Logo"
                        className="w-8 h-8 rounded-[2.5px] hover:brightness-110 hover:scale-110 transition-all duration-200"
                    />
                    <img
                        src={jcbLogo}
                        alt="JCB Logo"
                        className="w-8 h-8 rounded-[2.5px] hover:brightness-110 hover:scale-110 transition-all duration-200"
                    />
                    <img
                        src={unionpayLogo}
                        alt="Unionpay Logo"
                        className="w-8 h-8 rounded-[2.5px] hover:brightness-110 hover:scale-110 transition-all duration-200"
                    />
                    <img
                        src={discoverLogo}
                        alt="Discover Logo"
                        className="w-8 h-8 rounded-[2.5px] hover:brightness-110 hover:scale-110 transition-all duration-200"
                    />
                    <img
                        src={dinersLogo}
                        alt="Diners Logo"
                        className="w-8 h-8 rounded-[2.5px] hover:brightness-110 hover:scale-110 transition-all duration-200"
                    />
                </div>
                <div className="flex flex-row items-center w-fit justify-end gap-1 hover:animate-neon-glow">
                    <LockKeyholeIcon className="text-stone-300 w-4 h-4" />
                    <p className="text-stone-300 text-nowrap text-center font-semibold text-xs whitespace-nowrap">{t("hero.checkoutSecurity")}</p>
                    <div className="py-2 bg-gray-800 text-stone-200 px-2 h-[22px] rounded-[5px] border border-gray-700 shadow-md flex items-center justify-center hover:animate-neon-glow">
                        <span className="-ml-3 -mr-2.5 text-xs scale-75 text-nowrap font-medium">{t("poweredBy")}</span>
                        <span className="font-bold ml-1 -mr-1 tracking-tighter pb-0.5">stripe</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrustSecurity;
