import React, { useState, useRef, useEffect } from "react";
import { HeartFilledIcon, HeartIcon, TrashIcon } from "@radix-ui/react-icons";
import { Trash2 as Trash2Icon } from "lucide-react";
import { Label } from "../../../shadcn/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../shadcn/ui/select";
import { ToggleGroup, ToggleGroupItem } from "../../../shadcn/ui/toggle-group";
import { Card, CardContent, CardDescription, CardTitle } from "../../../shadcn/ui/card";
import StatusLabel from "./ImageStatusLabel";
import ImageViewer from "../../narkis-ui/image-viewer";
import { favoritePhoto, listGeneratedImages, deleteGeneratedImages } from "../../../../services/api";
import { useLocalStorageState } from "../../../../hooks/useLocalStorageState";
import { useToast } from "../../../shadcn/ui/use-toast";
import { useTranslation } from "react-i18next";

const ImageMenu = ({
    models,
    images,
    onImageGridModelChange,
    onImageGridSortChange,
    onImageGridFilterChange,
    onImageFavoritesFilterChange,
    onImageDeletedFilterChange,
    fetchImages,
    setImages,
}: {
    models: any;
    images: any;
    onImageGridModelChange: any;
    onImageGridSortChange: any;
    onImageGridFilterChange: any;
    onImageFavoritesFilterChange: any;
    onImageDeletedFilterChange: any;
    // fetch images should be like that async (page: number, size: number) => Promise<any>;
    fetchImages: (
        page: number,
        size: number,
        sortBy: string,
        filterSelectedModelId: string,
        filterFavorites: boolean,
        filterDeleted: boolean
    ) => Promise<any>;
    setImages: any;
}) => {
    const { t } = useTranslation("imagemenu");
    const { toast } = useToast();

    const [selectedModel, setSelectedModel] = useLocalStorageState("selectedModel", "");
    const [selectedImage, setSelectedImage] = useState<any>(null);

    const [selectedSort, setSelectedSort] = useLocalStorageState("selectedSort", "");
    const [filterFavorites, setFilterFavorites] = useState(false);
    const [filterDeleted, setFilterDeleted] = useState(false);

    // function to get non-old-failed images, that is, images that are failed more than x seconds ago
    const getNonOldFailedImages = (images: any) => {
        return images.filter((image: any) => {
            if (image.status === "FAILED") {
                if (image.deleteTimeout === undefined) return false;
                if (image.deleteTimeout === null) return false;
                if (image.deleteTimeout < Date.now()) return false;
            }
            return true;
        });
    };

    // update every second to get rid of failed images
    // TODO: make this only run when there are new failed images and stop when they are all hidden
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         //setRelevantImages(getNonOldFailedImages(images));
    //         setImages(getNonOldFailedImages(images));
    //     }, 1000);

    //     return () => clearInterval(interval);
    // }, [images]);

    const handleFilterChange = (filter: any) => {
        setPageToken(1);
        setHasMoreImages(true);
        setLoading(false);

        // the reason for below is to try and avoid
        if (filter === "favorite") {
            if (filterDeleted) {
                setFilterDeleted(false);
                onImageDeletedFilterChange(false);
            }

            setFilterFavorites(true);
            onImageFavoritesFilterChange(true);
            fetchImages(1, pageSize, selectedSort, selectedModel, true, false);
        } else if (filter === "deleted") {
            if (filterFavorites) {
                setFilterFavorites(false);
                onImageFavoritesFilterChange(false);
            }

            setFilterDeleted(true);
            onImageDeletedFilterChange(true);
            fetchImages(1, pageSize, selectedSort, selectedModel, false, true);
        } else {
            if (filterFavorites) {
                setFilterFavorites(false);
                onImageFavoritesFilterChange(false);
            }
            if (filterDeleted) {
                setFilterDeleted(false);
                onImageDeletedFilterChange(false);
            }
            fetchImages(1, pageSize, selectedSort, selectedModel, false, false);
        }
    };

    const [pageToken, setPageToken] = useState(2);
    const [pageSize, setPageSize] = useState(20);
    const [loading, setLoading] = useState(false);
    const [hasMoreImages, setHasMoreImages] = useState(true);
    const lastImageRef = useRef(null);

    // the container that holds the image grid
    const container = useRef(null);
    // intersect observer for detecting when the menu is scrolled to the bottom
    useEffect(() => {
        if (loading || !hasMoreImages) return;

        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    loadNextImages();
                }
            },
            {
                threshold: 0.1,
                root: container.current,
                rootMargin: "0px 0px 250% 0px",
            }
        );

        const lastImageElement = lastImageRef.current;
        if (lastImageElement) {
            observer.observe(lastImageElement);
        }

        return () => {
            if (lastImageElement) {
                observer.unobserve(lastImageElement);
            }
        };
    }, [loading, hasMoreImages, images]);

    const loadNextImages = async () => {
        setLoading(true);
        console.log("loadNextImages");
        try {
            // Make an API call to load the next set of images
            const response = await listGeneratedImages(
                "",
                pageToken,
                pageSize,
                selectedSort,
                selectedModel,
                filterFavorites,
                filterDeleted
            );

            //console.log("Images loaded:", response);

            if (response.data.pages === pageToken) {
                setHasMoreImages(false);
            }

            if (response.data.images.length === 0) {
                setHasMoreImages(false);
            } else {
                setImages([...images, ...response.data.images]);
                setPageToken(pageToken + 1);
            }
        } catch (error) {
            console.error("Error loading next images:", error);
            toast({
                title: t("errorLoadingNextImages.title"),
                description: t("errorLoadingNextImages.description", { error: error }),
            });
        }

        setLoading(false);
    };

    const sortOptions = ["newest", "oldest"];
    const gridSizeOptions = [3, 4, 6, 8, 10];

    const [selectedGridSize, setSelectedGridSize] = useLocalStorageState("selectedGridSize", 3);

    const handleGridSizeChange = (e: any) => {
        console.log("handleGridSizeChange", e);
        setSelectedGridSize(e);
    };

    const handleModelChange = (selectedModel: any) => {
        console.log("handleModelChange", selectedModel);
        const model = selectedModel === "*" ? "" : selectedModel;
        fetchImages(1, pageSize, selectedSort, model, filterFavorites, filterDeleted);
    };

    const handleSortChange = (selectedSort: any) => {
        console.log("handleSortChange", selectedSort);
        setSelectedSort(selectedSort);
        fetchImages(1, pageSize, selectedSort, selectedModel, filterFavorites, filterDeleted);
    };

    const handleImageClick = (image: any) => {
        setSelectedImage(image);
    };

    const handleImageViewerClose = () => {
        setSelectedImage(null);
    };

    const handlePreviousImage = () => {
        const currentIndex = images.findIndex((img: any) => img.image_id === selectedImage.image_id);
        if (currentIndex > 0) {
            setSelectedImage(images[currentIndex - 1]);
        }
    };

    const handleNextImage = () => {
        const currentIndex = images.findIndex((img: any) => img.image_id === selectedImage.image_id);
        if (currentIndex < images.length - 1) {
            setSelectedImage(images[currentIndex + 1]);
        }
    };

    const handleDeleteImage = async (targetImage: any) => {
        // Remove the image from the local state immediately
        setImages(getNonOldFailedImages(images.filter((image: any) => image.image_id !== targetImage.image_id)));
    
        try {
            // Call the API to delete the image on the server
            await deleteGeneratedImages([targetImage.image_id], "");
        } catch (error) {
            console.error("Error deleting image:", error);
            // Show a toast notification to inform the user about the deletion failure
            toast({
                title: t("errorDeletingImage.title"),
                description: t("errorDeletingImage.description", { error: error }),
            });
        }
    };

    const handleClickImageFavorite = async (targetImage: any) => {
        try {
            targetImage.favorited = !targetImage.favorited;
            setImages(
                images.map((img: any) =>
                    img.image_id === targetImage.image_id ? { ...img, favorited: targetImage.favorited } : img
                )
            );
            await favoritePhoto(targetImage.image_id, targetImage.favorited);
        } catch (error) {
            console.error("Error favoriting image:", error);
        }
    };

    const menuRef = useRef(null);
    useEffect(() => {
        const updateMenuHeight = () => {
            if (!menuRef.current) return;
            const menuElement = menuRef.current as HTMLElement; // Type assertion to HTMLElement
            if (!menuElement) return;
            const menuHeight = menuElement.offsetHeight;
            document.documentElement.style.setProperty("--menu-height", `${menuHeight}px`);
        };

        updateMenuHeight(); // Set initial height
        window.addEventListener("resize", updateMenuHeight); // Update on window resize

        return () => {
            window.removeEventListener("resize", updateMenuHeight);
        };
    }, []);
    
    return (
        <div className=" text-narkis-text">
            <div
                ref={menuRef}
                className="
                sticky top-1 z-10 flex justify-center items-center 
                gap-0.5 py-1 pb-3 h-10 mb w-full
                
                bg-narkis-bg border-b border-narkis-border-lighter"
            >
                {/* model filter */}
                <div className="flex items-center">
                    <Select onValueChange={handleModelChange}>
                        <SelectTrigger
                            aria-label="Select Model"
                            className="border-narkis-border-lighter hover:border-narkis-border bg-narkis-bg rounded-[7.5px]"
                        >
                            <SelectValue placeholder={t("model.all")} defaultValue={selectedModel} />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem key="all" value="*">
                                {t("model.all")}
                            </SelectItem>
                            {models.map((model: any) => (
                                <SelectItem key={model.model_id} value={model.model_id}>
                                    {model.model_name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>

                {/* favorites/deletes filter */}
                <div className="flex items-center border border-narkis-border-lighter hover:border-narkis-border bg-narkis-bg rounded-[7.5px]">
                    <Label htmlFor="filterSelection" className="sr-only">Filter Selection</Label>
                    <ToggleGroup
                        type="single"
                        variant="default"
                        value={filterFavorites ? "favorite" : filterDeleted ? "deleted" : "all"}
                        onValueChange={handleFilterChange}
                    >
                        <ToggleGroupItem value="favorite" aria-label="Show favorite images">
                            <HeartFilledIcon className="h-5 w-5 text-narkis-icons-favorite" />
                        </ToggleGroupItem>
                        <ToggleGroupItem value="deleted" aria-label="Show deleted images">
                            <Trash2Icon className="h-5 w-5 text-narkis-icons-delete" />
                        </ToggleGroupItem>
                    </ToggleGroup>
                </div>

                {/* sort */}
                <div className="flex items-center">
                    <Label htmlFor="sortSelection" className="sr-only">Sort Selection</Label>
                    <Select onValueChange={handleSortChange}>
                        <SelectTrigger
                            aria-label="Sort Images"
                            className="border-narkis-border-lighter hover:border-narkis-border bg-narkis-bg rounded-[7.5px]"
                        >
                            <SelectValue placeholder="newest" defaultValue={selectedSort} />
                        </SelectTrigger>
                        <SelectContent>
                            {sortOptions.map((option) => (
                                <SelectItem key={option} value={option}>
                                    {t(`sort.${option}`)}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>

                {/* grid size */}
                <div className="flex items-center">
                    <Select onValueChange={handleGridSizeChange}>
                        <SelectTrigger
                            aria-label="Select Grid Size"
                            className="border-narkis-border-lighter hover:border-narkis-border bg-narkis-bg rounded-[7.5px]"
                        >
                            <SelectValue placeholder={t("display.grid")} defaultValue={selectedGridSize} />
                        </SelectTrigger>
                        <SelectContent>
                            {gridSizeOptions.map((option) => (
                                <SelectItem key={option} value={option as any}>
                                    {option}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
            </div>

            {/* image grid */}
            <div
                ref={container}
                className={`grid grid-cols-${selectedGridSize} gap-0.5 max-h-[calc(100vh-132px)] pt-1 rounded-[5px] overflow-y-auto`}
                style={{ scrollbarWidth: "thin" }}
            >
                {images.map((image: any, index: any) => (
                    <div
                        key={index}
                        className="relative w-full aspect-square group"
                        ref={index === images.length - 1 ? lastImageRef : null}
                    >
                        <div className="w-full h-full">
                            {image.is_deleted ? (
                                <div className="w-full h-full flex justify-center items-center border border-gray-800 rounded-[5px]">
                                    <div className="text-gray-500">{t("imageDeleted")}</div>
                                </div>
                            ) : image.status === "COMPLETED" ? (
                                <div className="relative">
                                    <img
                                        src={image.image_url as string}
                                        alt={`Img ${index}`}
                                        className="w-full h-full object-cover"
                                        loading="lazy" // Enable lazy loading
                                        onClick={() => handleImageClick(image)}
                                    />
                                    <div className="absolute bottom-1 right-1">
                                        <div
                                            className="text-xs p-1 rounded border-2 border-black font-extrabold
                                            bg-yellow-400 text-black opacity-85 hover:opacity-100 transition-opacity cursor-pointer"
                                        >
                                            {(image.time_elapsed / 1000).toFixed(2)}s {image.is_deleted}
                                        </div>
                                    </div>

                                    {/* Favorite heart icon */}
                                    <div className={`absolute top-1 left-1 transition-opacity duration-200 ${image.favorited ? 'opacity-100' : 'opacity-50 group-hover:opacity-100'}`}>
                                        <button
                                            className="shadow-xl  p-1 "
                                            onClick={() => handleClickImageFavorite(image)}
                                        >
                                            {image.favorited ? (
                                                <HeartFilledIcon className="h-5 w-5 text-red-400 animate-[pulse_2s_ease-in-out_infinite]" />
                                            ) : (
                                                <HeartIcon className="rounded-full h-5 w-5 text-white hover:text-red-700 transition-colors" />
                                            )}
                                        </button>
                                    </div>

                                    {/* Delete button */}
                                    <div className="absolute top-1 right-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                                        <button
                                            className="shadow-xl p-1 rounded-full  transition-colors"
                                            onClick={() => handleDeleteImage(image)}
                                        >
                                            <Trash2Icon className="h-5 w-5 text-red-700 hover:text-red-500 transition-colors" />
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className="w-full h-full flex justify-center items-center border border-gray-800 rounded-[5px]">
                                    <StatusLabel status={image.status} />
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>

            {images.length === 0 && (
                <div className="text-center p-4">
                    <Card className="text-lg font-semibold bg-narkis-lighter p-4 space-y-4">
                        <CardTitle>{t("welcome.title")}</CardTitle>
                        <CardDescription>{t("welcome.description")}</CardDescription>
                        <CardContent>
                            <p className="text-sm mt-2">
                                {t("welcome.moreInfo")}
                                <a href="/tutorial" className="text-blue-500 underline">
                                    {t("welcome.tutorial1")}
                                </a>{" "}
                                {t("welcome.tutorial2")}
                            </p>
                        </CardContent>
                    </Card>
                </div>
            )}

            {selectedImage && (
                <ImageViewer
                    image={selectedImage}
                    onClose={handleImageViewerClose}
                    onPrevious={handlePreviousImage}
                    onNext={handleNextImage}
                />
            )}

            {/* to generate tailwind grid classes */}
            <div className="hidden grid-cols-3"></div>
            <div className="hidden grid-cols-4"></div>
            <div className="hidden grid-cols-5"></div>
            <div className="hidden grid-cols-6"></div>
            <div className="hidden grid-cols-7"></div>
            <div className="hidden grid-cols-8"></div>
            <div className="hidden grid-cols-9"></div>
            <div className="hidden grid-cols-10"></div>
        </div>
    );
};

export default ImageMenu;
