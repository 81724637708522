import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../../shadcn/ui/accordion";

export function FAQ() {
    return (
        <Accordion type="multiple" className="w-full space-y-4 " defaultValue={["item-1", "item-2"]}>
            <AccordionItem value="item-1" className="border border-narkis-bg-darker rounded-lg overflow-hidden" defaultValue="open">
                <AccordionTrigger defaultValue="open" className="p-4 py-2 text-lg font-semibold bg-narkis-bg-darker2 text-white rounded-t-lg text-left">
                    What is Narkis.ai?
                </AccordionTrigger>
                <AccordionContent defaultValue="open" className="p-4 pt-2 bg-narkis-bg text-neutral-100 rounded-b-lg space-y-2 leading-relaxed tracking-wide">
                    <p>
                        Narkis.ai allows you to create realistic, professional photos of anyone. Just upload 15 photos, and our AI
                        will learn how to recreate the person's look and style.
                    </p>
                    <p>
                        You never know what a good profile photo can bring. A captivating profile photo can open doors, spark
                        conversations, and create opportunities you never imagined. Make your first impression count with
                        Narkis.ai.
                    </p>
                </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-2" className="border border-narkis-bg-darker rounded-lg overflow-hidden">
                <AccordionTrigger className="p-4 py-2 text-lg font-semibold bg-narkis-bg-darker2 text-white rounded-t-lg text-left">
                    Can I make the photos look exactly like me?
                </AccordionTrigger>
                <AccordionContent className="p-4 pt-2 bg-narkis-bg text-neutral-100 rounded-b-lg space-y-2 leading-relaxed tracking-wide">
                    <p>
                        Absolutely! Our AI models are specifically designed to create images that exactly resemble the person you
                        choose. By uploading the right high-quality, well-lit photos, you can achieve stunningly accurate and
                        lifelike portraits.
                    </p>
                    <p>Our images will look as real as those taken by a professional photographer with a high-quality camera.</p>
                </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-3" className="border border-narkis-bg-darker rounded-lg overflow-hidden">
                <AccordionTrigger className="p-4 py-2  text-lg font-semibold bg-narkis-bg-darker2 text-white rounded-t-lg text-left">
                    What are the benefits of becoming a member?
                </AccordionTrigger>
                <AccordionContent className="p-4 pt-2 bg-narkis-bg text-neutral-100 rounded-b-lg space-y-2 leading-relaxed tracking-wide">
                    <p>
                        As a subscriber, you have the ability to create stunning photos of yourself, your loved ones, or anyone else.
                    </p>

                    <p>
                        Our photo dashboard allows you to create new models with just 15 photos of any person. These AI Models will learn that person's looks and allow you to create high-quality, photorealistic images that are indistinguishable from real photographs.
                    </p>

                    <p>
                        A standout profile photo can make all the difference. Whether it's for LinkedIn, Instagram, or your business portfolio, Narkis.ai will make sure you always look your best.
                    </p>

                    <p>
                        With the basic Spark plan, you can create up to 3 new models every month and receive 1080 credits to create new, unique and stunning photos.
                    </p>
                </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-11" className="border border-narkis-bg-darker rounded-lg overflow-hidden">
                <AccordionTrigger className="p-4 py-2  text-lg font-semibold bg-narkis-bg-darker2 text-white rounded-t-lg text-left">
                    Is this like Photoshop?
                </AccordionTrigger>
                <AccordionContent className="p-4 pt-2 bg-narkis-bg text-neutral-100 rounded-b-lg space-y-2 leading-relaxed tracking-wide">
                    <p>
                        Not quite! While Photoshop allows you to edit existing photos, Narkis.ai takes it a step further with
                        state-of-the-art technology. Our platform trains AI models to learn your unique features in detail. These
                        AI models then generate entirely new, stunning images from scratch, offering a level of personalization
                        and creativity that traditional photo editing tools can't match.
                    </p>
                </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-12" className="border border-narkis-bg-darker rounded-lg overflow-hidden">
                <AccordionTrigger className="p-4 py-2  text-lg font-semibold bg-narkis-bg-darker2 text-white rounded-t-lg text-left">
                    Will the created photos be like the ones I upload?
                </AccordionTrigger>
                <AccordionContent className="p-4 pt-2 bg-narkis-bg text-neutral-100 rounded-b-lg space-y-2 leading-relaxed tracking-wide">
                    <p>
                        No, the photos created by Narkis.ai will not be identical to the ones you upload. Instead, our advanced AI
                        model only uses your uploaded photos for learning the subject's looks and styles.
                    </p>
                    <p>
                        From that, our AI model generates entirely new, stunning and unique images that truly capture the looks of
                        the person you choose!
                    </p>
                </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-4" className="border border-narkis-bg-darker rounded-lg overflow-hidden">
                <AccordionTrigger className="p-4 py-2  text-lg font-semibold bg-narkis-bg-darker2 text-white rounded-t-lg text-left">
                    Can I upgrade my subscription tier later?
                </AccordionTrigger>
                <AccordionContent className="p-4 pt-2 bg-narkis-bg text-neutral-100 rounded-b-lg space-y-2 leading-relaxed tracking-wide">
                    Absolutely! You can upgrade your subscription tier whenever you like. Just head over to your account settings,
                    choose the new plan that fits your needs, and confirm the upgrade. The new tier benefits will be available to
                    you immediately, so you can start enjoying enhanced features right away.
                </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-5" className="border border-narkis-bg-darker rounded-lg overflow-hidden">
                <AccordionTrigger className="p-4 py-2  text-lg font-semibold bg-narkis-bg-darker2 text-white rounded-t-lg text-left">
                    What if I have unused credits left at the end of the month?
                </AccordionTrigger>
                <AccordionContent className="p-4 pt-2 bg-narkis-bg text-neutral-100 rounded-b-lg space-y-2 leading-relaxed tracking-wide">
                    No worries! Any unused credits at the end of the month will roll over to the next month. This way, you can
                    accumulate credits and use them whenever inspiration strikes. Whether you're planning a big creative project
                    or just want to take your time exploring our features, your credits will be waiting for you.
                </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-6" className="border border-narkis-bg-darker rounded-lg overflow-hidden">
                <AccordionTrigger className="p-4 py-2  text-lg font-semibold bg-narkis-bg-darker2 text-white rounded-t-lg text-left">
                    How much does the premium service cost?
                </AccordionTrigger>
                <AccordionContent className="p-4 pt-2 bg-narkis-bg0 text-neutral-100 rounded-b-lg space-y-2 leading-relaxed tracking-wide">
                    <p>
                        The premium service starts with the 'Spark' tier at $27/mo. With Spark, you can create 3 AI models of 3 different people and generate hundreds of images. 
                    </p>
                    <p>
                        Renews automatically every month. Cancel anytime.
                    </p>
                </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-7" className="border border-narkis-bg-darker rounded-lg overflow-hidden">
                <AccordionTrigger className="p-4 py-2  text-lg font-semibold bg-narkis-bg-darker2 text-white rounded-t-lg text-left">
                    How long does it take to create a model?
                </AccordionTrigger>
                <AccordionContent className="p-4 pt-2 bg-narkis-bg text-neutral-100 rounded-b-lg space-y-2 leading-relaxed tracking-wide">
                    Creating a model typically takes between two to four hours, depending on the complexity and the current demand
                    on our servers. You'll receive a email notification once your model is ready for use.
                </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-9" className="border border-narkis-bg-darker rounded-lg overflow-hidden">
                <AccordionTrigger className="p-4 py-2  text-lg font-semibold bg-narkis-bg-darker2 text-white rounded-t-lg text-left">
                    What kind of photos should I upload for the best results?
                </AccordionTrigger>
                <AccordionContent className="p-4 pt-2 bg-narkis-bg text-neutral-100 rounded-b-lg space-y-2 leading-relaxed tracking-wide">
                    <p>
                        For the best results, we recommend uploading high-quality photos that are well-lit and clear. Here are
                        some tips:
                    </p>
                    <ul className="list-disc list-inside">
                        <li>Use photos with different angles and expressions.</li>
                        <li>Avoid photos with heavy filters or obstructions.</li>
                        <li>Ensure the photos are in focus and have good lighting.</li>
                        <li>Include a mix of close-up and full-body shots.</li>
                    </ul>
                </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-8" className="border border-narkis-bg-darker rounded-lg overflow-hidden">
                <AccordionTrigger className="p-4 py-2  text-lg font-semibold bg-narkis-bg-darker2 text-white rounded-t-lg text-left">
                    Can I use my credits to create images without creating a model?
                </AccordionTrigger>
                <AccordionContent className="p-4 pt-2 bg-narkis-bg text-neutral-100 rounded-b-lg space-y-2 leading-relaxed tracking-wide">
                    Yes, you can use your free and premium credits to generate images using our commuity & public models. However, for
                    personalized results, we recommend creating a custom model with your photos.
                </AccordionContent>
            </AccordionItem>
        </Accordion>
    );
}
