import React, { useState, useEffect } from "react";
import { ReactComponent as Coin1 } from "../assets/coin1.svg";

interface SpinningCoinProps {
    className?: string;
}

const SpinningCoin: React.FC<SpinningCoinProps> = ({ className }) => {
    const [isSpinning, setIsSpinning] = useState(false);

    useEffect(() => {
        const startSpinning = () => {
            setIsSpinning(true);
            setTimeout(() => setIsSpinning(false), 2000); // Spin for 2 seconds
        };

        const interval = setInterval(() => {
            if (Math.random() < 0.9) {
                // 30% chance to start spinning
                startSpinning();
            }
        }, 5000); // Check every 5 seconds

        return () => clearInterval(interval);
    }, []);

    return (
        <div className={isSpinning ? "animate-spin transition-transform ease-in-out duration-1000" : "animate-spin-slow"}>
            <Coin1
                className={`h-4 w-4 fill-fruity-mangoOrange cursor-pointer transition-colors ease-in-out duration-[2000ms] ${
                    isSpinning ? "animate-color-cycle" : ""
                } ${className || ""}`}
            />
        </div>
    );
};

export default SpinningCoin;
