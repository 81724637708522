import React, { useState, useEffect } from 'react';
import { listTrainedModels, shareModel, revokeModelAccess, deleteModel } from '../services/api';
import TrainedModel from './types/trainedModel';
import { useAuth } from "../contexts/AuthContext";
import { useToast } from "../components/shadcn/ui/use-toast";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "../components/shadcn/ui/alert-dialog";

const TrainedModelManager: React.FC = () => {
  const auth = useAuth();
  const {user} = auth;
  const { toast } = useToast();
  const [ownedModels, setOwnedModels] = useState<TrainedModel[]>([]);
  const [sharedWithYouModels, setSharedWithYouModels] = useState<TrainedModel[]>([]);
  const [sharedWithOthersModels, setSharedWithOthersModels] = useState<TrainedModel[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [shareEmail, setShareEmail] = useState('');
  const [sharePermission, setSharePermission] = useState('view');
  const [selectedModelToShare, setSelectedModelToShare] = useState<string | null>(null);
  const [shareSuccess, setShareSuccess] = useState<string | null>(null);

  useEffect(() => {
    fetchModels();
  }, []);

  const fetchModels = async () => {
    try {
      const response = await listTrainedModels('', 1, 100);
      const allModels = response.data.models;
      setOwnedModels(allModels.filter((model: TrainedModel) => !model.is_shared && model.owner_id === user?.user_id));
      setSharedWithYouModels(allModels.filter((model: TrainedModel) => model.is_shared && model.owner_id !== user?.user_id));
      setSharedWithOthersModels(allModels.filter((model: TrainedModel) => 
        model.owner_id === user?.user_id && 
        model.shared_with && 
        Array.isArray(model.shared_with) && 
        model.shared_with.length > 0 &&
        model.shared_with.some(share => share.permission_type && share.user_id)
      ));
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch models');
      setLoading(false);
    }
  };

  const handleDeleteModel = async (modelId: string) => {
    try {
      await deleteModel(modelId);
      setOwnedModels(ownedModels.filter(model => model.model_id !== modelId));
      toast({
        title: "Success",
        description: "Model deleted successfully.",
      });
    } catch (err) {
      console.error("Error deleting model:", err);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to delete model. Please try again.",
      });
    }
  };

  const handleShareModel = async () => {
    if (!selectedModelToShare) {
      setError('Please select a model to share');
      return;
    }
    if (!shareEmail) {
      setError('Please enter an email address');
      return;
    }
    try {
      await shareModel(selectedModelToShare, shareEmail, sharePermission);
      setShareEmail('');
      setSharePermission('view');
      setSelectedModelToShare(null);
      setShareSuccess('Model shared successfully');
      fetchModels(); // Refresh the models list
      setTimeout(() => setShareSuccess(null), 3000); // Clear success message after 3 seconds
    } catch (err) {
      setError('Failed to share model');
    }
    setTimeout(() => setError(null), 3000); // Clear error message after 3 seconds
  };

  const handleRevokeAccess = async (modelId: string, targetUserEmail: string, permissionType: string) => {
    try {
      await revokeModelAccess(modelId, targetUserEmail, permissionType);
      fetchModels(); // Refresh the models list
    } catch (err) {
      setError('Failed to revoke access');
    }
  };

  if (loading) return <div className="text-center mt-4 text-stone-100">Loading...</div>;
  if (error) return <div className="text-center mt-4 text-red-500">{error}</div>;

  return (
    <div className="trained-model-manager p-6 bg-gradient-radial from-purple-900 via-indigo-950 to-black text-stone-100 min-h-screen">
      
      <div className="space-y-6">
        <div className="bg-stone-800 rounded-lg shadow-md p-6">
          <h3 className="text-2xl font-semibold mb-4 text-stone-200">Owned Models</h3>
          <div className="mb-6">
            <div className="flex flex-col space-y-3 mb-4">
              <select
                value={selectedModelToShare || ''}
                onChange={(e) => setSelectedModelToShare(e.target.value)}
                className="w-full px-3 py-2 rounded bg-stone-700 text-stone-100 border border-stone-600 text-sm"
              >
                <option value="">Select a model to share</option>
                {ownedModels.map(model => (
                  <option key={model.model_id} value={model.model_id}>{model.model_name}</option>
                ))}
              </select>
              <input
                type="email"
                placeholder="Email to share"
                value={shareEmail}
                onChange={(e) => setShareEmail(e.target.value)}
                className="w-full px-3 py-2 rounded bg-stone-700 text-stone-100 border border-stone-600 text-sm"
              />
              <div className="flex justify-between items-center">
                <select
                  value={sharePermission}
                  onChange={(e) => setSharePermission(e.target.value)}
                  className="w-1/2 px-3 py-2 rounded bg-stone-700 text-stone-100 border border-stone-600 text-sm"
                >
                  <option value="view">View</option>
                  <option value="edit">Edit</option>
                </select>
                <button onClick={handleShareModel} className="w-1/2 ml-2 bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded text-sm transition duration-300 ease-in-out">Share</button>
              </div>
            </div>
            {shareSuccess && <p className="text-green-500 text-sm text-center">{shareSuccess}</p>}
            {error && <p className="text-red-500 text-sm text-center">{error}</p>}
          </div>
          <div className="overflow-x-auto">
            <table className="w-full text-left border-collapse">
              <thead>
                <tr className="bg-stone-700">
                  <th className="p-2 text-xs font-medium text-stone-300">Model Name</th>
                  <th className="p-2 text-xs font-medium text-stone-300">Status</th>
                  <th className="p-2 text-xs font-medium text-stone-300">Created</th>
                  <th className="p-2 text-xs font-medium text-stone-300">Actions</th>
                </tr>
              </thead>
              <tbody>
                {ownedModels.map(model => (
                  <tr key={model.model_id} className="border-b border-stone-700 hover:bg-stone-750">
                    <td className="p-2 text-sm">{model.model_name}</td>
                    <td className="p-2 text-sm">{model.status}</td>
                    <td className="p-2 text-sm">{new Date(model.date_created).toLocaleDateString()}</td>
                    <td className="p-2">
                      <AlertDialog>
                        <AlertDialogTrigger asChild>
                          <button className="bg-red-600 hover:bg-red-700 text-white px-2 py-1 rounded text-xs transition duration-300 ease-in-out">Delete</button>
                        </AlertDialogTrigger>
                        <AlertDialogContent className="bg-stone-950">
                          <AlertDialogHeader>
                            <AlertDialogTitle className="text-gray-200">Confirm Deletion</AlertDialogTitle>
                            <AlertDialogDescription className="text-gray-200 text-sm">
                              <strong>Warning:</strong> This action is irreversible. 
                              Your model will be permanently deleted from our servers.
                            </AlertDialogDescription>
                          </AlertDialogHeader>
                          <AlertDialogFooter>
                            <AlertDialogCancel className="text-black bg-gray-300 hover:bg-gray-400 transition duration-300 ease-in-out">Cancel</AlertDialogCancel>
                            <AlertDialogAction onClick={() => handleDeleteModel(model.model_id)} className="bg-red-600 hover:bg-red-700 text-white transition duration-300 ease-in-out">
                              Delete
                            </AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {ownedModels.length === 0 && <p className="text-stone-400 text-sm mt-4">You don't have any trained models yet.</p>}
        </div>

        <div className="bg-stone-800 rounded-lg shadow-md p-6">
          <h3 className="text-2xl font-semibold mb-4 text-stone-200">Shared With You</h3>
          <div className="overflow-x-auto">
            <table className="w-full text-left border-collapse">
              <thead>
                <tr className="bg-stone-700">
                  <th className="p-2 text-xs font-medium text-stone-300">Model Name</th>
                  <th className="p-2 text-xs font-medium text-stone-300">Status</th>
                  <th className="p-2 text-xs font-medium text-stone-300">Created</th>
                  <th className="p-2 text-xs font-medium text-stone-300">Shared By</th>
                  <th className="p-2 text-xs font-medium text-stone-300">Actions</th>
                </tr>
              </thead>
              <tbody>
                {sharedWithYouModels.map(model => (
                  <tr key={model.model_id} className="border-b border-stone-700 hover:bg-stone-750">
                    <td className="p-2 text-sm">{model.model_name}</td>
                    <td className="p-2 text-sm">{model.status}</td>
                    <td className="p-2 text-sm">{new Date(model.date_created).toLocaleDateString()}</td>
                    <td className="p-2 text-sm">{model.owner_id}</td>
                    <td className="p-2">
                      <button onClick={() => handleRevokeAccess(model.model_id, 'current_user_email', 'view')} className="bg-yellow-600 hover:bg-yellow-700 text-white px-2 py-1 rounded text-xs transition duration-300 ease-in-out">Revoke My Access</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {sharedWithYouModels.length === 0 && <p className="text-stone-400 text-sm mt-4">No models have been shared with you.</p>}
        </div>

        <div className="bg-stone-800 rounded-lg shadow-md p-6">
          <h3 className="text-2xl font-semibold mb-4 text-stone-200">Shared With Others</h3>
          <div className="overflow-x-auto">
            <table className="w-full text-left border-collapse">
              <thead>
                <tr className="bg-stone-700">
                  <th className="p-2 text-xs font-medium text-stone-300">Model Name</th>
                  <th className="p-2 text-xs font-medium text-stone-300">Shared With</th>
                  <th className="p-2 text-xs font-medium text-stone-300">Permission</th>
                  <th className="p-2 text-xs font-medium text-stone-300">Actions</th>
                </tr>
              </thead>
              <tbody>
                {sharedWithOthersModels.flatMap(model => 
                  model.shared_with?.map((sharedUser, index) => (
                    <tr key={`${model.model_id}-${index}`} className="border-b border-stone-700 hover:bg-stone-750">
                      <td className="p-2 text-sm">{model.model_name}</td>
                      <td className="p-2 text-sm">{sharedUser.user_id}</td>
                      <td className="p-2 text-sm">{sharedUser.permission_type}</td>
                      <td className="p-2">
                        <button onClick={() => handleRevokeAccess(model.model_id, sharedUser.user_id, sharedUser.permission_type)} className="bg-yellow-600 hover:bg-yellow-700 text-white px-2 py-1 rounded text-xs transition duration-300 ease-in-out">Revoke Access</button>
                      </td>
                    </tr>
                  )) || []
                )}
              </tbody>
            </table>
          </div>
          {sharedWithOthersModels.length === 0 && <p className="text-stone-400 text-sm mt-4">You haven't shared any models with others.</p>}
        </div>
      </div>
    </div>
  );
};

export default TrainedModelManager;
