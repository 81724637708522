import { Card, CardContent } from "../../../shadcn/ui/card";

import { Metadata } from "../../../types/pageMetadata";
import Page from "../../../Page";
import Footer from "../../Footer";

const privacyMetadata: Metadata = {
    title: "Privacy Policy - Narkis.ai | Protecting Your Data and AI-Generated Content",
    description: "Learn how Narkis.ai safeguards your personal information and AI-generated images. Our privacy policy outlines our commitment to data protection and user privacy.",
    h1: "Narkis.ai Privacy Policy | Protecting Your Data",
    canonical: "https://narkis.ai/privacy",
    keywords: [
        "Narkis.ai Privacy Policy",
        "AI Content Security",
        "AI Data Protection",
        "GDPR and AI Compliance",
    ],
    author: "Narkis.ai",
    robots: "index, nofollow",
    ogImage: "summary-large-privacy.png",
    ogUrl: "https://narkis.ai/privacy",
    twitterCard: "summary",
    twitterTitle: "Narkis.ai Privacy Policy - Your Data, Protected",
    twitterDescription:
        "Narkis.ai is committed to protecting your privacy. Learn about our data practices and how we safeguard your personal information and AI-generated content.",
    twitterImage: "summary-large-privacy.png",
    twitterCreator: "@narkisai",
};

const PrivacyPolicy = () => {
    return (
        <Page {...privacyMetadata}>
            <Card className="w-full max-w-4xl mx-auto my-8 p-4">
                <CardContent>
                    <article className="prose lg:prose-xl max-w-none">
                        <h1 className="text-4xl font-bold mb-6 text-primary">Privacy Policy</h1>

                        <p className="lead text-xl mb-8">
                            At Narkis.ai, we are committed to protecting your privacy. This Privacy Policy outlines the types of information we collect, how we use it, and the steps we take to ensure it is protected.
                        </p>

                        <h2 className="text-3xl font-semibold mt-12 mb-4 text-primary">Information Collection</h2>
                        <ul className="list-disc pl-6 mb-4">
                            <li><strong>Personal Information:</strong> We collect personal information when you register, such as your name, email address, and photographs.</li>
                            <li><strong>Usage Data:</strong> We collect data on how you interact with our services, which helps us improve our offerings.</li>
                            <li><strong>Cookies:</strong> We use cookies to enhance your user experience, remember your preferences, and gather usage data.</li>
                        </ul>

                        <h2 className="text-3xl font-semibold mt-12 mb-4 text-primary">Use of Information</h2>
                        <p className="mb-4">We use the collected information for various purposes:</p>
                        <ul className="list-disc pl-6 mb-4">
                            <li>To provide and maintain our service</li>
                            <li>To notify you about changes to our service</li>
                            <li>To allow you to participate in interactive features when you choose to do so</li>
                            <li>To provide customer support</li>
                            <li>To gather analysis or valuable information so that we can improve our service</li>
                            <li>To monitor the usage of our service</li>
                            <li>To detect, prevent, and address technical issues</li>
                        </ul>

                        <h2 className="text-3xl font-semibold mt-12 mb-4 text-primary">Sharing of Information</h2>
                        <p className="mb-4">
                            We do not sell or rent your personal information to third parties. However, we may share information with trusted partners who help us operate our app, provided they agree to keep this information confidential.
                        </p>

                        <h2 className="text-3xl font-semibold mt-12 mb-4 text-primary">Security</h2>
                        <p className="mb-4">
                            We take significant measures to protect the security of your personal information. Despite our efforts, no security measures are perfect or impenetrable.
                        </p>

                        <h2 className="text-3xl font-semibold mt-12 mb-4 text-primary">Children's Privacy</h2>
                        <p className="mb-4">
                            Our service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13.
                        </p>

                        <h2 className="text-3xl font-semibold mt-12 mb-4 text-primary">Changes to This Privacy Policy</h2>
                        <p className="mb-4">
                            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                        </p>

                        <h2 className="text-3xl font-semibold mt-12 mb-4 text-primary">Contact Us</h2>
                        <p className="mb-4">
                            If you have any questions about this Privacy Policy, please contact us at{" "}
                            <a href="mailto:support@narkis.ai" className="text-blue-500 underline">
                                support@narkis.ai
                            </a>.
                        </p>

                        <p className="mt-8 text-sm text-gray-500">
                            Last updated: July 2024
                        </p>
                    </article>
                </CardContent>
            </Card>
            <Footer />
        </Page>
    );
};

export default PrivacyPolicy;
