import React, { useState } from "react";
import { Card, CardContent } from "../../../shadcn/ui/card";
import { Input } from "../../../shadcn/ui/input";
import { Button } from "../../../shadcn/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../shadcn/ui/select";
import { Label } from "../../../shadcn/ui/label";
import { Loader2, ChevronDownIcon, ChevronUpIcon, Settings } from "lucide-react";
import { LandscapeIcon, PortraitIcon, SquareIcon } from "../generate/ImageLayoutIcons";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "../../../shadcn/ui/collapsible";
import { Textarea } from "../../../shadcn/ui/textarea";
import GradientMeshBackground from "../../../GradientMeshButton";
import { Badge } from "../../../shadcn/ui/badge";
import { Switch } from "../../../shadcn/ui/switch";
import { useLocalStorageState } from "../../../../hooks/useLocalStorageState";

const ModelBadge = ({ text, extraClassName }: { text: string; extraClassName: string }) => (
    <Badge className={`ml-1 px-1 py-0 text-[10px] font-semibold ${extraClassName} rounded whitespace-nowrap`}>{text}</Badge>
);

const CreditBadge = ({ cost }: { cost: number }) => (
    <Badge className="ml-1 px-1 py-0 text-[10px] font-semibold bg-gray-700 text-gray-200 rounded border border-gray-500 whitespace-nowrap">
        {cost} credits
    </Badge>
);

interface FalGeneratorMenuProps {
    model: string;
    setModel: (model: string) => void;
    positivePrompt: string;
    setPositivePrompt: (prompt: string) => void;
    negativePrompt: string;
    setNegativePrompt: (prompt: string) => void;
    numImages: number;
    setNumImages: (num: number) => void;
    imageLayout: string;
    setImageLayout: (layout: string) => void;
    steps: number;
    setSteps: (steps: number) => void;
    cfgScale: number;
    setCfgScale: (scale: number) => void;
    seed: string;
    setSeed: (seed: string) => void;
    isLoading: boolean;
    handleGenerate: (isAdvancedMode: boolean) => void;
    validModels: any[];
    isLoggedIn: boolean;
}

const FalGeneratorMenu: React.FC<FalGeneratorMenuProps> = ({
    model,
    setModel,
    positivePrompt,
    setPositivePrompt,
    negativePrompt,
    setNegativePrompt,
    numImages,
    setNumImages,
    imageLayout,
    setImageLayout,
    steps,
    setSteps,
    cfgScale,
    setCfgScale,
    seed,
    setSeed,
    isLoading,
    handleGenerate,
    validModels,
    isLoggedIn,
}) => {
    const [isAdvancedMode, setIsAdvancedMode] = useLocalStorageState("imageGen_isAdvancedMode", false);
    const imageLayoutOptions = [
        { value: "square", label: "Square", illustration: <SquareIcon /> },
        { value: "landscape", label: "Landscape", illustration: <LandscapeIcon /> },
        { value: "portrait", label: "Portrait", illustration: <PortraitIcon /> },
    ];

    const getDefaultSteps = (selectedModel: string) => {
        // TODO: implement this these are not the correct steps
        switch (selectedModel) {
            case "SDXL":
            case "SDXL Lightning":
                return 20;
            case "Stable Cascade":
                return 15;
            case "SDXL Turbo":
                return 10;
            case "SD3":
                return 5;
            case "Flux":
                return 28;
            case "Flux Pro":
                return 28;
            default:
                return 30;
        }
    };

    const handleModelChange = (newModel: string) => {
        setModel(newModel);
        setSteps(getDefaultSteps(newModel));
    };

    return (
        <Card className="bg-gradient-to-br from-neutral-950 to-neutral-900 border-narkis-border-darker2 border">
            <CardContent className="p-2 sm:p-4">
                <div className="grid gap-4">
                    <div className="flex justify-between items-center">
                        <Label htmlFor="advancedMode" className="font-medium">
                            Advanced Mode
                        </Label>
                        <Switch id="advancedMode" checked={isAdvancedMode} onCheckedChange={setIsAdvancedMode} />
                    </div>
                    <div className="gap-1">
                        <Label htmlFor="model">Model</Label>
                        <Select onValueChange={handleModelChange} value={model}>
                            <SelectTrigger
                                id="model"
                                className="
                                border-fruity-peach focus:border-fruity-cherryRed active:border-fruity-cherryRed hover:border-fruity-cherryRed border-opacity-65 hover:border-opacity-100 active:border-opacity-100
                                bg-narkis-bg
                                transition-colors duration-200
                                rounded-[7.5px]
                                "
                            >
                                <SelectValue placeholder="Select a model" />
                            </SelectTrigger>
                            <SelectContent>
                                {validModels.map((model) => (
                                    <SelectItem key={model.name} value={model.name} disabled={!isLoggedIn && !model.public}>
                                        <div
                                            className={`flex items-center ${
                                                !isLoggedIn && !model.public ? "opacity-90 cursor-not-allowed" : ""
                                            }`}
                                        >
                                            <div className="flex font-semibold mr-2 min-w-[60px]">{model.name}</div>
                                            <div className="flex items-center">
                                                {model.new && (
                                                    <ModelBadge text="NEW" extraClassName="border border-black bg-yellow-500 text-black animate-chromatic-aberration-2" />
                                                )}
                                                {model.free && (
                                                    <ModelBadge text="FREE" extraClassName="bg-green-500 text-white" />
                                                )}
                                                {model.public && (
                                                    <ModelBadge text="PUBLIC" extraClassName="bg-blue-500 text-white" />
                                                )}
                                                {!model.public && (
                                                    <ModelBadge text="REGISTER" extraClassName="bg-purple-500 text-white" />
                                                )}
                                                <CreditBadge cost={model.cost} />
                                            </div>
                                        </div>
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <div className="grid gap-1">
                        <Label htmlFor="prompt">Prompt</Label>
                        <Textarea
                            id="prompt"
                            placeholder={
                                isAdvancedMode
                                    ? "Describe what you want to see in the image"
                                    : "Describe your image idea in natural language"
                            }
                            value={positivePrompt}
                            onChange={(e) => setPositivePrompt(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" && !e.shiftKey) {
                                    e.preventDefault();
                                    handleGenerate(isAdvancedMode);
                                }
                            }}
                            className="
                            border-fruity-peach focus:border-fruity-cherryRed active:border-fruity-cherryRed hover:border-fruity-cherryRed border-opacity-65 hover:border-opacity-100 active:border-opacity-100
                            bg-narkis-bg
                            transition-colors duration-200
                            rounded-[7.5px]"
                        />
                    </div>
                    {isAdvancedMode && (
                        <div className="gap-1">
                            <Label htmlFor="negativePrompt">Negative Prompt</Label>
                            <Textarea
                                id="negativePrompt"
                                placeholder="Describe what you don't want to see in the image"
                                value={negativePrompt}
                                onChange={(e) => setNegativePrompt(e.target.value)}
                                className="
                                border-fruity-peach focus:border-fruity-cherryRed active:border-fruity-cherryRed hover:border-fruity-cherryRed border-opacity-65 hover:border-opacity-100 active:border-opacity-100
                                bg-narkis-bg
                                transition-colors duration-200
                                rounded-[7.5px]"
                            />
                        </div>
                    )}
                    <div className="flex flex-wrap items-center gap-4">
                        <div className="flex-1 min-w-[120px]">
                            <Label htmlFor="imageLayout" className="block mb-1">
                                Layout
                            </Label>
                            <Select onValueChange={setImageLayout} value={imageLayout} disabled={!isLoggedIn}>
                                <SelectTrigger
                                    id="imageLayout"
                                    aria-label="Choose image layout"
                                    className="
                                    border-fruity-peach focus:border-fruity-cherryRed active:border-fruity-cherryRed hover:border-fruity-cherryRed border-opacity-65 hover:border-opacity-100 active:border-opacity-100
                                    bg-narkis-bg
                                    transition-colors duration-200
                                    rounded-[7.5px]
                                    h-7 mt-1
                                    "
                                >
                                    <SelectValue placeholder={`Image Layout: ${imageLayout}`} />
                                </SelectTrigger>
                                <SelectContent>
                                    {imageLayoutOptions.map((option) => (
                                        <SelectItem key={option.value} value={option.value}>
                                            <div className="flex items-center">
                                                {option.illustration}
                                                <span className="ml-2">{option.label}</span>
                                            </div>
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                        <div className="flex-shrink-0">
                            <Label htmlFor="count" className="block">
                                Count
                            </Label>
                            <div
                                className="
                                    border-fruity-peach focus:border-fruity-cherryRed active:border-fruity-cherryRed hover:border-fruity-cherryRed border-opacity-65 hover:border-opacity-100 active:border-opacity-100
                                    bg-narkis-bg
                                    transition-colors duration-200
                                    rounded-[7.5px]
                                    h-7 mt-1 border pb-1
                                    "
                            >
                                <Button
                                    className="h-full w-1 bg-narkis-bg hover:bg-narkis-bg-lighter"
                                    onClick={() => setNumImages(numImages - 1)}
                                >
                                    -
                                </Button>
                                <input
                                    className="rounded-none text-center h-full w-7 border-0 m-0 p-0 bg-narkis-bg focus:outline-none"
                                    value={numImages}
                                    onChange={(e) => setNumImages(parseInt(e.target.value))}
                                />
                                <Button
                                    className="h-full w-1 bg-narkis-bg hover:bg-narkis-bg-lighter "
                                    onClick={() => setNumImages(numImages + 1)}
                                >
                                    +
                                </Button>
                            </div>
                        </div>
                    </div>
                    {isLoggedIn && isAdvancedMode && (
                        <Collapsible>
                            <CollapsibleTrigger asChild>
                                <Button
                                    variant="outline"
                                    className="w-full justify-between 
                                border-fruity-peach focus:border-fruity-cherryRed active:border-fruity-cherryRed hover:border-fruity-cherryRed border-opacity-65 hover:border-opacity-100 active:border-opacity-100
                                "
                                >
                                    Advanced Settings
                                    <ChevronDownIcon className="h-4 w-4 transition-transform duration-200" />
                                </Button>
                            </CollapsibleTrigger>
                            <CollapsibleContent className="mt-2">
                                <Card
                                    className="bg-gradient-to-br from-neutral-800 to-neutral-700 border transition-colors duration-200
                                border-fruity-peach focus:border-fruity-cherryRed active:border-fruity-cherryRed hover:border-fruity-cherryRed border-opacity-65 hover:border-opacity-100 active:border-opacity-100
                            "
                                >
                                    <CardContent className="p-4">
                                        <div className="grid gap-4">
                                            <div className="gap-1">
                                                <Label htmlFor="steps">Sampling Steps: {steps}</Label>
                                                <Input
                                                    id="steps"
                                                    type="range"
                                                    min={10}
                                                    max={150}
                                                    value={steps}
                                                    onChange={(e) => setSteps(parseInt(e.target.value))}
                                                    className="w-full"
                                                />
                                            </div>
                                            <div className="gap-1">
                                                <Label htmlFor="cfgScale">CFG Scale: {cfgScale}</Label>
                                                <Input
                                                    id="cfgScale"
                                                    type="range"
                                                    min={1}
                                                    max={30}
                                                    step={0.5}
                                                    value={cfgScale}
                                                    onChange={(e) => setCfgScale(parseFloat(e.target.value))}
                                                    className="w-full"
                                                />
                                            </div>
                                            <div className="gap-1">
                                                <Label htmlFor="seed">Seed (optional)</Label>
                                                <Input
                                                    id="seed"
                                                    placeholder="Leave blank for random seed"
                                                    value={seed}
                                                    onChange={(e) => setSeed(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </CollapsibleContent>
                        </Collapsible>
                    )}
                    <div className="border-opacity-65 hover:border-opacity-100 active:border-opacity-100 relative overflow-hidden border-fruity-peach border rounded-[7.5px]">
                        <GradientMeshBackground className="absolute inset-0" />
                        <button
                            disabled={isLoading}
                            onClick={() => handleGenerate(isAdvancedMode)}
                            className="
                     rounded-[10px]
                    relative w-full px-7 py-4 bg-black brightness-125 bg-opacity-0 leading-none flex items-center justify-center overflow-hidden 
                    transition-all duration-300 ease-in-out
                    hover:bg-opacity-10  shadow-black shadow-inner hover:brightness-200
                    active:bg-opacity-0 transition-transform-200 active:scale-95 active:shadow-sm
                    disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-opacity-30 disabled:hover:shadow-none disabled:active:scale-100"
                        >
                            <span className="relative z-10 flex items-center justify-center">
                                {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                                Generate Images
                            </span>
                        </button>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default FalGeneratorMenu;
