import React, { useState, useEffect } from "react";
import { listTrainedModels, createMatrixGeneration } from "../../../../services/api";
import { useLocalStorageState } from "../../../../hooks/useLocalStorageState";
import MatrixGenerationView from "./MatrixGenerationView";
import { MatrixGeneration } from "./types/matrixGeneration";
import { ClipLoader } from "react-spinners";

const samplers = ["DPM++ 2M Karras", "Euler a", "DPM adaptive"];
const resolutions = ["768x768", "1024x1024", "512x512", "768x1024", "1024x768", "512x768", "768x512", "512x1024", "1024x512"];
const samplingStepsOptions = [15, 20, 25, 30, 40, 50, 70, 100];
const adetailerStrengthOptions = [0.05, 0.1, 0.15, 0.2, 0.3, 0.4, 0.5, 0.75, 0.9, 1];
const cfgScaleOptions = [2.5, 3, 4, 5, 7.5, 9, 11];

interface Model {
    model_id: string;
    name: string;
    status: string;
    favorited_image_url: string;
}

interface AdetailerOption {
    status: "ON" | "OFF";
    strength?: number;
}

interface Prompt {
    positive: string;
    negative: string;
    enabled: boolean;
}

const Inferencing: React.FC = () => {
    const [models, setModels] = useState<Model[]>([]);
    const [selectedModels, setSelectedModels] = useLocalStorageState<string[]>("playground_selectedModels", []);
    const [selectedSamplers, setSelectedSamplers] = useLocalStorageState<string[]>("playground_selectedSamplers", []);
    const [selectedResolutions, setSelectedResolutions] = useLocalStorageState<string[]>("playground_selectedResolutions", []);
    const [prompts, setPrompts] = useLocalStorageState<Prompt[]>("playground_prompts", [
        { positive: "", negative: "", enabled: true },
    ]);
    const [selectedSamplingSteps, setSelectedSamplingSteps] = useLocalStorageState<number[]>(
        "playground_selectedSamplingSteps",
        []
    );
    const [selectedCfgScale, setSelectedCfgScale] = useLocalStorageState<number[]>("playground_selectedCfgScale", []);
    const [seed, setSeed] = useLocalStorageState<string>("playground_seed", "-1");
    const [positiveLora, setPositiveLora] = useLocalStorageState<{ name: string; strength: number }[]>(
        "playground_positiveLora",
        []
    );
    const [negativeLora, setNegativeLora] = useLocalStorageState<{ name: string; strength: number }[]>(
        "playground_negativeLora",
        []
    );

    const [adetailerOn, setAdetailerOn] = useLocalStorageState<boolean>("playground_adetailerOn", false);
    const [adetailerOff, setAdetailerOff] = useLocalStorageState<boolean>("playground_adetailerOff", false);
    const [selectedAdetailerStrengths, setSelectedAdetailerStrengths] = useLocalStorageState<number[]>(
        "playground_selectedAdetailerStrengths",
        []
    );

    const [generations, setGenerations] = useState<MatrixGeneration[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchModels = async () => {
            try {
                const fetchedModels = await listTrainedModels("", 0, 100);
                setModels(fetchedModels.data.models);
            } catch (error) {
                console.error("Error fetching models:", error);
            }
        };
        fetchModels();

        setSelectedModels((prevSelectedModels) => prevSelectedModels.filter((model) => model !== null));
    }, []);

    const handleModelChange = (modelId: string) => {
        setSelectedModels((prev) => {
            const updatedModels = prev.includes(modelId) ? prev.filter((m) => m !== modelId) : [...prev, modelId];
            return updatedModels.filter((model) => model !== null);
        });
    };

    const handleSamplerChange = (sampler: string) => {
        setSelectedSamplers((prev) => (prev.includes(sampler) ? prev.filter((s) => s !== sampler) : [...prev, sampler]));
    };

    const handleResolutionChange = (resolution: string) => {
        setSelectedResolutions((prev) =>
            prev.includes(resolution) ? prev.filter((r) => r !== resolution) : [...prev, resolution]
        );
    };

    const handlePromptChange = (index: number, value: string, type: "positive" | "negative") => {
        const newPrompts = [...prompts];
        newPrompts[index][type] = value;
        setPrompts(newPrompts);
    };

    const addPrompt = () => {
        setPrompts([...prompts, { positive: "", negative: "", enabled: true }]);
    };

    const deletePrompt = (index: number) => {
        const newPrompts = [...prompts];
        newPrompts.splice(index, 1);
        setPrompts(newPrompts);
    };

    const togglePrompt = (index: number) => {
        const newPrompts = [...prompts];
        newPrompts[index].enabled = !newPrompts[index].enabled;
        setPrompts(newPrompts);
    };

    const handleSamplingStepsChange = (step: number) => {
        setSelectedSamplingSteps((prev) => (prev.includes(step) ? prev.filter((s) => s !== step) : [...prev, step]));
    };

    const handleAdetailerStatusChange = (status: "ON" | "OFF") => {
        if (status === "ON") {
            setAdetailerOn((prev) => !prev);
            if (!adetailerOn && selectedAdetailerStrengths.length === 0) {
                setSelectedAdetailerStrengths([0.5]); // Default strength when turning ON
            }
        } else {
            setAdetailerOff((prev) => !prev);
        }
    };

    const handleAdetailerStrengthChange = (strength: number) => {
        setSelectedAdetailerStrengths((prev) =>
            prev.includes(strength) ? prev.filter((s) => s !== strength) : [...prev, strength]
        );
    };

    const getAdetailerOptions = (): AdetailerOption[] => {
        const options: AdetailerOption[] = [];
        if (adetailerOn && selectedAdetailerStrengths.length > 0) {
            selectedAdetailerStrengths.forEach((strength) => {
                options.push({ status: "ON", strength });
            });
        }
        if (adetailerOff) {
            options.push({ status: "OFF" });
        }
        return options;
    };

    const handleCfgScaleChange = (scale: number) => {
        setSelectedCfgScale((prev) => (prev.includes(scale) ? prev.filter((s) => s !== scale) : [...prev, scale]));
    };

    const handleSeedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSeed(event.target.value);
    };

    const handleLoraChange = (
        index: number,
        field: "name" | "strength",
        value: string | number,
        type: "positive" | "negative"
    ) => {
        if (type === "positive") {
            const newLora = [...positiveLora];
            newLora[index] = { ...newLora[index], [field]: value };
            setPositiveLora(newLora);
        } else {
            const newLora = [...negativeLora];
            newLora[index] = { ...newLora[index], [field]: value };
            setNegativeLora(newLora);
        }
    };

    const startGeneration = async () => {
        setIsLoading(true);
        console.log("Starting image generation with configurations:", {
            selectedModels,
            selectedSamplers,
            selectedResolutions,
            prompts,
            selectedSamplingSteps,
            adetailerOn,
            adetailerOff,
            selectedAdetailerStrengths,
            selectedCfgScale,
            seed,
            positiveLora,
            negativeLora,
        });

        try {
            const matrixGenerationData = {
                models: selectedModels.filter((model) => model !== null),
                samplers: selectedSamplers,
                resolutions: selectedResolutions,
                prompts: prompts.filter((prompt) => prompt.enabled),
                sampling_steps: selectedSamplingSteps,
                adetailer_options: getAdetailerOptions(),
                cfg_scales: selectedCfgScale,
                seed: parseInt(seed),
                positive_lora: positiveLora.map((lora) => `${lora.name}:${lora.strength}`),
                negative_lora: negativeLora.map((lora) => `${lora.name}:${lora.strength}`),
            };

            const response = await createMatrixGeneration(matrixGenerationData);
            console.log("Matrix generation started:", response);
            const newGeneration: MatrixGeneration = {
                id: response.matrix_generation_id,
                created_at: new Date().toISOString(), // Use current time as creation time
                status: "pending", // Assume initial status is pending
                models: matrixGenerationData.models,
                samplers: matrixGenerationData.samplers,
                resolutions: matrixGenerationData.resolutions,
                prompts: matrixGenerationData.prompts,
                sampling_steps: matrixGenerationData.sampling_steps,
                adetailer_options: matrixGenerationData.adetailer_options,
                cfg_scales: matrixGenerationData.cfg_scales,
                seed: matrixGenerationData.seed.toString(),
                positive_lora: matrixGenerationData.positive_lora,
                negative_lora: matrixGenerationData.negative_lora,
                generated_images: {
                    current_page: 1,
                    pages: 1,
                    total: response.total_combinations,
                    images: response.generated_images || [],
                },
            };
            setGenerations((prevGenerations) => [newGeneration, ...prevGenerations]);
        } catch (error) {
            console.error("Error starting matrix generation:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="flex flex-col w-full gap-2 p-4 bg-gray-900 text-white">
            <section className="bg-stone-100 text-black p-6 rounded-lg shadow-md flex flex-col gap-2">
                <div className="flex flex-col gap-2 border px-4 py-2 border-stone-500 rounded-[5px]">
                    <h3 className="text-xl font-semibold">Select Trained Models</h3>
                    <div className="flex flex-wrap gap-4">
                        {models.map((model) => (
                            <div key={model.model_id} className={`relative ${model.status !== "COMPLETED" ? "opacity-50" : ""}`}>
                                {model.favorited_image_url ? (
                                    <div className="w-24 h-24 overflow-hidden rounded-md">
                                        <img
                                            src={model.favorited_image_url}
                                            alt={model.name}
                                            className="w-full h-full object-cover object-top"
                                        />
                                    </div>
                                ) : (
                                    <div className="w-24 h-24 bg-gray-200 rounded-md flex items-center justify-center">
                                        <span className="text-sm text-gray-500">No image</span>
                                    </div>
                                )}
                                <input
                                    type="checkbox"
                                    value={model.model_id}
                                    onChange={() => handleModelChange(model.model_id)}
                                    disabled={model.status !== "COMPLETED"}
                                    className="absolute top-2 right-2"
                                    checked={selectedModels.includes(model.model_id)}
                                />
                                <p className="mt-1 text-sm text-center">{model.name}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex flex-col gap-2 border px-4 py-2 border-stone-500 rounded-[5px]">
                    <h3 className="text-xl font-semibold">Select Samplers</h3>
                    <div className="flex flex-row gap-2">
                        {samplers.map((sampler) => (
                            <label key={sampler} className="block">
                                <input
                                    type="checkbox"
                                    value={sampler}
                                    onChange={() => handleSamplerChange(sampler)}
                                    className="mr-2"
                                    checked={selectedSamplers.includes(sampler)}
                                />
                                {sampler}
                            </label>
                        ))}
                    </div>
                </div>
                <div className="flex flex-col gap-2 border px-4 py-2 border-stone-500 rounded-[5px]">
                    <h3 className="text-xl font-semibold">Select Resolutions</h3>
                    <div className="flex flex-row gap-2">
                        {resolutions.map((resolution) => (
                            <label key={resolution} className="block">
                                <input
                                    type="checkbox"
                                    value={resolution}
                                    onChange={() => handleResolutionChange(resolution)}
                                    className="mr-2"
                                    checked={selectedResolutions.includes(resolution)}
                                />
                                {resolution}
                            </label>
                        ))}
                    </div>
                </div>
                <div className="flex flex-col gap-2 border px-4 py-2 border-stone-500 rounded-[5px]">
                    <h3 className="text-xl font-semibold">Prompts</h3>
                    {prompts.map((prompt, index) => (
                        <div key={index} className="flex gap-2 mb-2 relative">
                            <div className="flex-grow flex flex-col gap-2">
                                <input
                                    type="text"
                                    value={prompt.positive}
                                    onChange={(e) => handlePromptChange(index, e.target.value, "positive")}
                                    placeholder="Enter positive prompt"
                                    className={`w-full p-2 border rounded ${!prompt.enabled ? "opacity-50" : ""}`}
                                    disabled={!prompt.enabled}
                                />
                                <input
                                    type="text"
                                    value={prompt.negative}
                                    onChange={(e) => handlePromptChange(index, e.target.value, "negative")}
                                    placeholder="Enter negative prompt"
                                    className={`w-full p-2 border rounded ${!prompt.enabled ? "opacity-50" : ""}`}
                                    disabled={!prompt.enabled}
                                />
                            </div>
                            <div className="flex flex-col gap-2">
                                <button
                                    onClick={() => togglePrompt(index)}
                                    className={`h-full my-1 self-stretch px-2 ${
                                        prompt.enabled ? "bg-green-500" : "bg-gray-500"
                                    } text-white rounded`}
                                >
                                    {prompt.enabled ? "Enabled" : "Disabled"}
                                </button>
                                <button
                                    onClick={() => deletePrompt(index)}
                                    className=" h-full my-1 self-stretch px-2 bg-red-500 text-white rounded"
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    ))}
                    <button onClick={addPrompt} className="p-2 bg-blue-500 text-white rounded">
                        Add Prompt Set
                    </button>
                </div>
                <div className="flex flex-col gap-2 border px-4 py-2 border-stone-500 rounded-[5px]">
                    <h3 className="text-xl font-semibold">Sampling Steps</h3>
                    <div className="flex flex-row gap-2">
                        {samplingStepsOptions.map((step) => (
                            <label key={step} className="block">
                                <input
                                    type="checkbox"
                                    value={step}
                                    onChange={() => handleSamplingStepsChange(step)}
                                    className="mr-2"
                                    checked={selectedSamplingSteps.includes(step)}
                                />
                                {step}
                            </label>
                        ))}
                    </div>
                </div>
                <div className="flex flex-col gap-2 border px-4 py-2 border-stone-500 rounded-[5px]">
                    <h3 className="text-xl font-semibold">Adetailer</h3>
                    <div className="flex items-center gap-4">
                        <label className="inline-flex items-center cursor-pointer">
                            <input
                                type="checkbox"
                                checked={adetailerOn}
                                onChange={() => handleAdetailerStatusChange("ON")}
                                className="mr-2"
                            />
                            ON
                        </label>
                        <label className="inline-flex items-center cursor-pointer">
                            <input
                                type="checkbox"
                                checked={adetailerOff}
                                onChange={() => handleAdetailerStatusChange("OFF")}
                                className="mr-2"
                            />
                            OFF
                        </label>
                    </div>
                    {adetailerOn && (
                        <div>
                            <span>Adetailer Strength:</span>
                            <div className="flex flex-wrap gap-2">
                                {adetailerStrengthOptions.map((strength) => (
                                    <label key={strength} className="inline-flex items-center">
                                        <input
                                            type="checkbox"
                                            checked={selectedAdetailerStrengths.includes(strength)}
                                            onChange={() => handleAdetailerStrengthChange(strength)}
                                            className="mr-1"
                                        />
                                        {strength}
                                    </label>
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                <div className="flex flex-col gap-2 border px-4 py-2 border-stone-500 rounded-[5px]">
                    <h3 className="text-xl font-semibold">CFG Scale</h3>
                    <div className="flex flex-row gap-2">
                        {cfgScaleOptions.map((scale) => (
                            <label key={scale} className="block">
                                <input
                                    type="checkbox"
                                    value={scale}
                                    onChange={() => handleCfgScaleChange(scale)}
                                    checked={selectedCfgScale.includes(scale)}
                                    className="mr-2"
                                />
                                {scale}
                            </label>
                        ))}
                    </div>
                </div>
                <div className="flex flex-col gap-2 border px-4 py-2 border-stone-500 rounded-[5px]">
                    <h3 className="text-xl font-semibold">Seed</h3>
                    <input
                        type="text"
                        value={seed}
                        onChange={handleSeedChange}
                        placeholder="Enter seed"
                        className="w-full p-2 border rounded"
                    />
                </div>
                <div className="flex gap-4">
                    <div className="flex-1 border px-4 py-2 border-stone-500 rounded-[5px]">
                        <h3 className="text-lg font-semibold mb-2">Positive LoRA</h3>
                        {positiveLora.map((lora, index) => (
                            <div key={index} className="flex gap-2 mb-2">
                                <input
                                    type="text"
                                    value={lora.name}
                                    onChange={(e) => handleLoraChange(index, "name", e.target.value, "positive")}
                                    placeholder="LoRA name"
                                    className="w-1/2 p-1 border rounded text-sm"
                                />
                                <input
                                    type="number"
                                    value={lora.strength}
                                    onChange={(e) => handleLoraChange(index, "strength", Number(e.target.value), "positive")}
                                    placeholder="Strength"
                                    step="0.1"
                                    min="0"
                                    max="1"
                                    className="w-1/2 p-1 border rounded text-sm"
                                />
                            </div>
                        ))}
                        <button
                            onClick={() => setPositiveLora([...positiveLora, { name: "", strength: 0.5 }])}
                            className="p-1 bg-blue-500 text-white rounded text-sm"
                        >
                            Add +
                        </button>
                    </div>
                    <div className="flex-1 border px-4 py-2 border-stone-500 rounded-[5px]">
                        <h3 className="text-lg font-semibold mb-2">Negative LoRA</h3>
                        {negativeLora.map((lora, index) => (
                            <div key={index} className="flex gap-2 mb-2">
                                <input
                                    type="text"
                                    value={lora.name}
                                    onChange={(e) => handleLoraChange(index, "name", e.target.value, "negative")}
                                    placeholder="LoRA name"
                                    className="w-1/2 p-1 border rounded text-sm"
                                />
                                <input
                                    type="number"
                                    value={lora.strength}
                                    onChange={(e) => handleLoraChange(index, "strength", Number(e.target.value), "negative")}
                                    placeholder="Strength"
                                    step="0.1"
                                    min="0"
                                    max="1"
                                    className="w-1/2 p-1 border rounded text-sm"
                                />
                            </div>
                        ))}
                        <button
                            onClick={() => setNegativeLora([...negativeLora, { name: "", strength: 0.5 }])}
                            className="p-1 bg-blue-500 text-white rounded text-sm"
                        >
                            Add +
                        </button>
                    </div>
                </div>

                <button
                    onClick={startGeneration}
                    className="p-2 bg-green-500 text-white rounded flex items-center justify-center"
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <>
                            <ClipLoader size={20} color={"#ffffff"} className="mr-2" />
                            Starting Generation...
                        </>
                    ) : (
                        "Start Generation"
                    )}
                </button>
            </section>

            <section className="bg-stone-100 text-black p-6 rounded-lg shadow-md mt-6">
                <h2 className="text-2xl font-semibold mb-4">Image Comparison Matrix</h2>
                <MatrixGenerationView generations={generations} setGenerations={setGenerations} />
            </section>
        </div>
    );
};

export default Inferencing;
