// Header.tsx
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../contexts/AuthContext";
import { useUserDataContext } from "../../../../contexts/UserDataContext";
import { CircleUser, Diamond, VenetianMask } from "lucide-react";
import { Link } from "react-router-dom";
import { Button } from "../../../shadcn/ui/button";
import logo from "../../../../assets/logo.png";

interface HeaderProps {
    onUserAvatarClick: () => void;
}

const Header: React.FC<HeaderProps> = ({ onUserAvatarClick }) => {
    const navigate = useNavigate();
    const { user, token } = useAuth();
    const { credits, giftCredits, trainingCredits } = useUserDataContext();

    const getTierClass = (user_tier: string) => {
        switch (user_tier) {
            case "Free":
                return "text-blue-500";
            case "spark":
                return "text-yellow-500 animate-pulse";
            case "flow":
                return "text-green-500 animate-pulse";
            case "avantgarde":
                return "text-pink-500 animate-bounce";
            case "zenith":
                return "text-purple-500 animate-pulse";
            default:
                return "text-gray-500";
        }
    };

    return (
        <header className="flex items-center justify-between p-1 bg-gradient-to-tr from-stone-900 via-stone-800 to-stone-950 text-narkis-mobile-text-bright border-b border-narkis-mobile-border-darker2">
            <div className="flex items-center space-x-2">
                <Link to="/" className="flex items-center space-x-2">
                    <img src={logo} alt="Logo" className="h-8 w-auto transform scale-x-[-1]" />
                    <span
                        className="
                        bg-gradient-to-r from-purple-400 via-pink-500 to-yellow-500
                        bg-[length:1000%_400%] 
                        bg-fixed
                        bg-clip-text 
                        text-2xl 
                        font-extrabold 
                        text-transparent 
                        sm:text-4xl
                        animate-holographic
                        "
                    >
                        Narkis.ai
                    </span>
                </Link>
            </div>

            <div className="flex items-center space-x-4 font-extrabold">
                {user && token && (
                    <div className="flex items-center space-x-2">
                        <div className={`flex items-center space-x-1 ${getTierClass(user.user_tier)}`}>
                            <Diamond className="h-5 w-5 stroke-emerald-400" />
                            <span className="text-sm text-emerald-400">{credits}</span>
                        </div>

                        {giftCredits > 0 && (
                            <div className={`flex items-center space-x-1 ${getTierClass(user.user_tier)}`}>
                                <Diamond className="h-5 w-5" />
                                <span className="text-sm">+{giftCredits}</span>
                            </div>
                        )}

                        <div className={`flex items-center space-x-1 ${getTierClass(user.user_tier)}`}>
                            <VenetianMask className="h-6 w-6" />
                            <span className="text-sm">{trainingCredits}</span>
                        </div>
                    </div>
                )}

                <Button variant="outline" size="sm" className="relative rounded-full border-narkis-mobile-border-darker2 bg-narkis-mobile-lighter p-2">
                    <CircleUser className="h-6 w-6 stroke-narkis-text-bright"/>
                    <span className="sr-only">User menu</span>
                </Button>
            </div>
        </header>
    );
};

export default Header;
