import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { registerUser } from "../../../../services/api";
import { Button } from "../../../shadcn/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../../shadcn/ui/card";
import { Input } from "../../../shadcn/ui/input";
import { Label } from "../../../shadcn/ui/label";
import { useToast } from "../../../shadcn/ui/use-toast";
import { Loader2 } from "lucide-react";
import { useTranslation } from "react-i18next";

const RegisterForm = ({ onSwitchForm }: { onSwitchForm: any }) => {
    const { t } = useTranslation("login");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [realName, setRealName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [inviteId, setInviteId] = useState("");
    const [error, setError] = useState("");

    const navigate = useNavigate();
    const { toast } = useToast();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const urlInviteId = urlParams.get("invite_id");
        if (urlInviteId) {
            console.log("Invite ID found:", urlInviteId);
            setInviteId(urlInviteId);
        }

        const urlEmail = urlParams.get("email");
        if (urlEmail) {
            console.log("Email found:", urlEmail);
            setEmail(urlEmail);
        }
    }, []);

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async () => {
        if (password !== confirmPassword) {
            console.log("Passwords don't match");
            setError("Passwords do not match");
            return;
        }

        try {
            setIsLoading(true);
            const response = await registerUser(email, username, realName, password, inviteId);
            
            toast({
                variant: "default",
                title: t("register.registerSuccess.title"),
            });
            console.log("Registration successful");

            navigate(`/login?username=${encodeURIComponent(username)}`);
            onSwitchForm("login");
        } catch (error: any) {
            console.error("Registration error:", error.message);
            toast({
                variant: "destructive",
                title: t("register.registerFailed.title"),
                description: error.message,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmitClick = (event: React.FormEvent) => {
        event.preventDefault();
        handleSubmit();
    };

    const comparePasswords = (pwd1: string, pwd2: string) => {
        if (!pwd1 || !pwd2) return true;
        return pwd1 === pwd2;
    };

    const handlePasswordChanged = (pwd: string) => {
        setPassword(pwd);
        if (!comparePasswords(pwd, confirmPassword)) {
            setError("Passwords do not match");
            return;
        }
        setError("");
    };

    const handleConfirmPasswordChanged = (pwd: string) => {
        setConfirmPassword(pwd);
        if (!comparePasswords(password, pwd)) {
            setError("Passwords do not match");
            return;
        }
        setError("");
    };

    return (
        <Card className="w-full max-w-sm mx-auto align-middle rounded-[7.5px] bg-narkis-bg-lighter border-narkis-border-darker2 border font-semibold">
            <CardHeader>
                <CardTitle className="text-2xl">{t("register.title")}</CardTitle>
                <CardDescription>{t("register.description")}</CardDescription>
            </CardHeader>
            <CardContent>
                <div className="grid gap-4">
                    <div className="grid gap-2">
                        <Label htmlFor="email">{t("register.email.mainLabel")}</Label>
                        <Input
                            id="email"
                            type="email"
                            autoCapitalize="none"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="rounded-[7.5px] bg-black"
                        />
                    </div>
                    <div className="grid gap-2">
                        <Label htmlFor="username">{t("register.username.mainLabel")}</Label>
                        <Input
                            id="username"
                            type="text"
                            autoCapitalize="none"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                            className="rounded-[7.5px] bg-black"
                        />
                    </div>
                    <div className="grid gap-2">
                        <Label htmlFor="realName">{t("register.realName.mainLabel")}</Label>
                        <Input
                            id="realName"
                            autoCapitalize="none"
                            type="text"
                            value={realName}
                            onChange={(e) => setRealName(e.target.value)}
                            className="rounded-[7.5px] bg-black"
                        />
                    </div>
                    <div className="grid gap-2">
                        <Label htmlFor="password">{t("register.password.mainLabel")}</Label>
                        <Input
                            id="password"
                            type="password"
                            autoCapitalize="none"
                            value={password}
                            onChange={(e) => handlePasswordChanged(e.target.value)}
                            required
                            className="rounded-[7.5px] bg-black"
                        />
                    </div>
                    <div className="grid gap-2">
                        <Label htmlFor="confirmPassword">{t("register.confirmPassword.mainLabel")}</Label>
                        <Input
                            id="confirmPassword"
                            type="password"
                            autoCapitalize="none"
                            value={confirmPassword}
                            onChange={(e) => handleConfirmPasswordChanged(e.target.value)}
                            required
                            className="rounded-[7.5px] bg-black"
                        />
                        {error && <p className="text-red-500 text-sm">{error}</p>}
                    </div>
                    {isLoading ? (
                        <Button disabled className="rounded-[7.5px] w-full bg-gradient-to-r border border-narkis-border-darker from-stone-800 via-stone-900 to-stone-800 shadow-lg 
                        transform transition-all duration-200 ease-in-out font-extrabold text-narkis-text-bright">
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                            {t("register.registering")}
                        </Button>
                    ) : (
                        <Button
                            type="submit"
                            className="hover:shadow-buttonGlow rounded-[7.5px] w-full bg-gradient-to-r border border-narkis-border-darker from-stone-800 via-stone-900 to-stone-800 
                                shadow-2xl transform active:translate-y-0.5 transition-all duration-200 ease-in-out font-extrabold text-narkis-text-bright"
                            onClick={handleSubmitClick}
                            disabled={!email || !username || !password || !confirmPassword || error !== ""}
                        >
                            {t("register.register")}
                        </Button>
                    )}
                </div>
                <div className="mt-4 text-center text-sm">
                    {t("register.alreadyHaveAccount")}
                    <Button variant="link" className="" onClick={() => onSwitchForm("login")}>
                        {t("register.login")}
                    </Button>
                </div>
            </CardContent>
        </Card>
    );
};

export default RegisterForm;
