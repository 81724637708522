import React, { useState, useEffect } from "react";

import Instructions from "./Instructions";
import TrainModelForm from "./TrainModelForm";

const TrainModelModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
    const isFirefox = typeof navigator !== "undefined" && navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
    const queryParams = new URLSearchParams(window.location.search);
    const language = queryParams.get("lang") || "en"; // 'ru', 'tr', 'ar'

    const [isNotPhone, setIsNotPhone] = useState(window.innerWidth > 768);

    useEffect(() => {
        const handleResize = () => {
            setIsNotPhone(window.innerWidth > 768);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            {/* Create New Model */}
            {isOpen && (
                <>
                    <div
                        className={`fixed  bg-narkis-bg bg-opacity-70 z-40 ${
                            isFirefox ? "" : "backdrop-filter backdrop-blur-sm"
                        } ${isNotPhone ? "inset-0" : ""}`}
                        onClick={onClose}
                    />

                    <div onClick={onClose} className={`${isNotPhone ? "inset-0 flex justify-center items-center " : ""} z-50 fixed gap-10`}>
                        {/* modal */}
						<div onClick={(e) => e.stopPropagation()}>
							<TrainModelForm language={language} onClose={onClose} isNotPhone={isNotPhone} />
						</div>
                       
						<div onClick={(e) => e.stopPropagation()}>
							 {/* Instructions */}
                        	{isNotPhone && <Instructions language={language} />}
						</div>
                    </div>
                </>
            )}

            {/* Dark background overlay */}
        </>
    );
};

export default TrainModelModal;
