import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate} from 'react-router-dom';
import { ArrowBigRightDashIcon, CameraIcon, LucideArrowBigDownDash } from 'lucide-react';
import RatingSection from './RatingSection';
import ProductShowcase from './ProductShowcase';

interface HeroProps {
  className?: string;
  heroUser: {
    hero_upload_photo: string;
    hero_generated_photos: { image_url: string }[];
  };
  isMobile: boolean;
}

const Hero: React.FC<HeroProps> = ({ className = '', heroUser, isMobile }) => {
  const { t } = useTranslation('landing');
  const navigate = useNavigate();
  const [isPending, startTransition] = React.useTransition();

  const handleGetStarted = () => {
    startTransition(() => {
      navigate('/pricing');
    });
  };

  const titles = [
    // {
    //     line1: "Studio Quality",
    //     line2: "Photos.",
    //     emphasis: "in <2 hours"
    //   },
      {
        line1: "Studio Quality",
        line2: "Photos.",
        emphasis: "Instantly."
      },
      {
        line1: "Perfect Photos, ",
        line2: "",
        emphasis: "Every Time."
      },
      {
        line1: "Unlimited Quality Photos",
        line2: "",
        emphasis: "No Camera Needed."
      },
  ];

  const [selectedTitle] = React.useState(() => 
    titles[Math.floor(Math.random() * titles.length)]
  );

  return (
<section className="max-w-7xl mx-auto flex flex-col lg:flex-row items-center justify-center gap-16 lg:gap-20 lg:items-start px-8 py-16 lg:py-32">
  <div className="flex flex-col gap-10 lg:gap-14 items-center justify-center text-center lg:text-left lg:items-start">
        <h1 className="max-w-xl hover:brightness-110 transition-all duration-100 cursor-default font-extrabold text-4xl lg:text-6xl tracking-tight md:-mb-4 flex flex-col gap-3 items-center lg:items-start">
      <span className="relative text-nowrap overflow-x-visible">{selectedTitle.line1} </span>
      <span className="whitespace-nowrap relative">
        <span className="mr-3 sm:mr-4 md:mr-5">{selectedTitle.line2}</span>
        <span className="relative whitespace-nowrap group cursor-default">
          <span className="absolute bg-neutral-100 group-hover:bg-neutral-900 transition-all duration-100   -left-2 -top-1 -bottom-1 -right-2 md:-left-3 md:-top-0 md:-bottom-0 md:-right-3 -rotate-1"></span>
          <span className="relative text-stone-950 group-hover:text-neutral-100">{selectedTitle.emphasis}</span>
        </span>
      </span>
    </h1>

    <p className="hover:text-stone-50 text-stone-200 transition-all duration-100 cursor-default text-lg opacity-80 leading-relaxed">{t("hero.subtitle")}</p>
    <div className="space-y-4">
      <button 
        className="
          btn btn-wide 
          bg-gradient-to-r from-emerald-400 via-teal-500 to-cyan-600
          hover:from-emerald-500 hover:via-teal-600 hover:to-cyan-700
          text-ruby-velvet-pink text-xl font-extrabold
          shadow-lg shadow-teal-500/50 hover:shadow-teal-600/90
          transform hover:scale-105 transition-all duration-300
          border-2 border-emerald-300 hover:border-emerald-400
          rounded-[7.5px] py-4 px-8
          relative group
        "
        title="Get Started with Narkis.ai"
        onClick={handleGetStarted}
      >
        <div className="flex items-center whitespace-nowrap group-hover:text-yellow-100 text-stone-900">
          <CameraIcon className="mr-2 h-7 w-7 z-10 group-hover:text-yellow-100 transition duration-1000" />
          <span>Create My Photos</span>
        </div>
      </button>
      <p className="text-sm md:text-base flex justify-center items-center gap-2 ">
        <span className="text-orange-600 font-extrabold animate-pulse duration-2000">40% off</span> 
        for the first 100 customers
      </p>
    </div>
    <RatingSection />
  </div>
  <div className="relative max-md:-m-4 lg:w-full">
    <ProductShowcase heroUser={heroUser}  isMobile={isMobile}/>
  </div>
</section>
  );
};

export default Hero;
