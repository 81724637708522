// src/components/Waitlist.js
import { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { createWaitlistUser } from "../../../services/api";
import Page from "../../Page";
import { Metadata } from "../../types/pageMetadata";
import "./Waitlist.css";

import macbookImage from "../../../assets/macbook.webp";
import iphoneImage from "../../../assets/iphone.webp";

const waitlistMetadata: Metadata = {
    title: "Join the Waitlist - Narkis.ai | Exclusive Access to AI-Powered Personal Photo Generation",
    h1: "Join the Waitlist",
    description:
        "Be among the first to experience Narkis.ai's revolutionary AI image generation. Sign up for our waitlist and get early access to create stunning, personalized AI photos.",
    canonical: "https://narkis.ai/waitlist",
    keywords: [
        "Narkis.ai Waitlist",
        "AI Image Generation",
        "Early Access",
        "Personalized AI Photos",
        "Exclusive AI Technology",
        "AI Photography Waitlist",
        "Custom AI Portraits",
        "AI Art Creation",
        "Innovative Image Generation",
        "Digital Identity Transformation",
        "AI-Powered Creativity",
        "Next-Gen Photo Creation",
        "Personalized AI Models",
        "Cutting-Edge AI Technology",
    ],
    author: "Narkis.ai",
    robots: "index, follow",
    ogTitle: "Join Narkis.ai Waitlist - Be First to Create AI-Powered Images",
    ogDescription:
        "Sign up for exclusive early access to Narkis.ai. Be among the pioneers to create stunning, personalized AI-generated images and transform your digital presence.",
    ogImage: "https://narkis.ai/images/waitlist-preview.jpg", // TODO: Update with CloudFront URL
    ogUrl: "https://narkis.ai/waitlist",
    twitterCard: "summary_large_image",
    twitterTitle: "Narkis.ai Waitlist - Exclusive Access to AI Image Creation",
    twitterDescription:
        "Join the waitlist for Narkis.ai and be first to experience the future of personal AI photography. Create incredible, custom AI images of yourself!",
    twitterImage: "https://narkis.ai/images/waitlist-preview-twitter.jpg", // TODO: Update with CloudFront URL
    twitterCreator: "@narkisai",
};

const Waitlist = () => {
    const { t } = useTranslation("waitlist");
    const REACT_APP_NARKIS_PUBLIC_CLOUDFRONT_URL = process.env.REACT_APP_NARKIS_PUBLIC_CLOUDFRONT_URL;

    const [email, setEmail] = useState("");
    const [userMessage, setUserMessage] = useState("");

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        console.log("Submitting waitlist:", email);

        try {
            await createWaitlistUser(email);
            console.log("Waitlist submission successful");
            toast.success("Waitlist submission successful");
            setUserMessage(t("confirmation.successMessage"));
        } catch (error: any) {
            console.error("Error confirming waitlist:", error);
            var err = t("confirmation.errorTryAgainLaterMessage");
            if (error.errorType) {
                if (error.errorType === "server_error") {
                    err = t("confirmation.errorTryAgainLaterMessage");
                } else if (error.errorType === "retry_limit_exceeded") {
                    err = t("confirmation.errorRateLimitMessage");
                } else if (error.errorType === "already_registered") {
                    err = t("confirmation.errorAlreadyRegisteredMessage");
                }
            }
            toast.error(err);
            setUserMessage(err);
        }
    };

    const imageNames = [
        "example1.png",
        "example2.png",
        "example3.png",
        "example4.png",
        "example5.png",
        "example6.png",
        "example7.png",
        "example8.png",
        "example9.png",
        "example10.png",
        "example11.png",
        "example12.png",
        "galaxy2.gif",
    ];

    const images = imageNames.reduce((acc: any, imageName: string) => {
        acc[imageName.split(".")[0]] = `${REACT_APP_NARKIS_PUBLIC_CLOUDFRONT_URL}/${imageName}`;
        return acc;
    }, {});

    // <img src={images.macbook} alt="macbook" />

    return (
        <Page {...waitlistMetadata}>
            <div className="waitlistContainer">
                <div className="waitlistContentWrapper">
                    <div className="waitlistImage">
                        <div className="pictureRows">
                            <div className="pictureRow row1">
                                <img src={images.example1} alt="" />
                                <img src={images.example2} alt="" />
                                <img src={images.example3} alt="" />
                                <img src={images.example4} alt="" />
                                <img src={images.example1} alt="" />
                                <img src={images.example2} alt="" />
                                <img src={images.example3} alt="" />
                                <img src={images.example4} alt="" />
                            </div>
                            <div className="pictureRow row2">
                                <img src={images.example5} alt="" />
                                <img src={images.example6} alt="" />
                                <img src={images.example7} alt="" />
                                <img src={images.example8} alt="" />
                                <img src={images.example5} alt="" />
                                <img src={images.example6} alt="" />
                                <img src={images.example7} alt="" />
                                <img src={images.example8} alt="" />
                            </div>
                            <div className="pictureRow row3">
                                <img src={images.example9} alt="" />
                                <img src={images.example10} alt="" />
                                <img src={images.example11} alt="" />
                                <img src={images.example12} alt="" />
                                <img src={images.example9} alt="" />
                                <img src={images.example10} alt="" />
                                <img src={images.example11} alt="" />
                                <img src={images.example12} alt="" />
                            </div>
                        </div>
                        {/* Make sure to replace 'macbook.png' with the path to your new image */}
                        <img src={macbookImage} className="macbookImg" alt="macbook" />
                        <img src={iphoneImage} className="iphoneImg" alt="iphone" />
                    </div>
                    <div className="waitlistFormContainer">
                        <div className="logoContainer">
                            <div className="logoTitleContainer">
                                <div className="logoGradientTitle" style={{ backgroundImage: `url(${images.galaxy2})` }}>
                                    {t("title_narkis")}
                                </div>
                            </div>
                        </div>
                        <h1>{t("title")}</h1>
                        <p>{t("subtitle")}</p>
                        <form>
                            <input
                                type="email"
                                placeholder="Email address"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <button type="submit" onClick={handleSubmit} disabled={!email.match(/^\S+@\S+\.\S+$/)}>
                                {t("join_button")}
                            </button>
                        </form>
                        <div className="userErrorContainer">
                            <p>{userMessage}</p>
                        </div>
                        <p style={{ color: "floralwhite" }}>
                            {t("privacy_policy1")} <a href="/terms">{t("privacy_policy2")}</a> {t("privacy_policy3")}{" "}
                            <a href="/privacy">{t("privacy_policy4")}</a>.
                        </p>
                    </div>
                </div>
            </div>
        </Page>
    );
};

export default Waitlist;
