// ModelManagementView.tsx
import React from "react";
import { Button } from "../../../shadcn/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../../shadcn/ui/card";
import { Check, Clock, Cross } from "lucide-react";
import { useTranslation } from "react-i18next";

interface ModelManagementViewProps {
    userModels: any;
    deleteModel: (modelId: string) => void;
    onClickCreateModel: () => void;
}

const ModelManagementView: React.FC<ModelManagementViewProps> = ({ userModels, deleteModel, onClickCreateModel }) => {
    const { t } = useTranslation("my_models");

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat("en-US", {
            dateStyle: "short",
            timeStyle: "short",
        }).format(date);
    };

    const getStatusIcon = (status: string) => {
        switch (status) {
            case "FAILED":
                return <Cross className="text-red-500" />;
            case "COMPLETED":
                return <Check className="text-green-500" />;
            default:
                return <Clock className="text-orange-500" />;
        }
    };

    return (
        <div className="flex flex-col text-narkis-mobile-text overflow-hidden gap-2 m-7 max-w-full">
            <div className="flex items-center justify-between mb-4">
                <h2 className="text-lg font-semibold flex-1">{t("title")}</h2>
                <Button className="text-black" variant="outline" size="sm" onClick={onClickCreateModel}>
                    {t("createModel")}
                </Button>
            </div>
            {userModels.map((model: any) => (
                <Card className="text-narkis-mobile-text flex flex-col bg-narkis-mobile-bg-lighter border border-narkis-mobile-border-darker2 rounded-[9px]">
                    <CardHeader>
                        <CardTitle>{model.model_name}</CardTitle>
                        <CardDescription>
                            {" "}
                            <div className="flex items-center gap-2">
                                <span className="capitalize text-lg font-semibold">{model.status}</span>
                                {getStatusIcon(model.status)}
                            </div>
                        </CardDescription>
                    </CardHeader>
                    <CardContent>
                        <div className="flex flex-col">
                            <div>
                                <span className="font-semibold">{t("modelDetails.gender")}:</span>{" "}
                                <span className="capitalize">{model.gender}</span>
                            </div>
                            <div>
                                <span className="font-semibold">{t("modelDetails.timeCreated")}:</span>{" "}
                                <span>{model.date_created ? formatDate(model.date_created) : "-"}</span>
                            </div>
                            <div>
                                <span className="font-semibold">{t("modelDetails.timeCompleted")}:</span>{" "}
                                <span>{model.completed_est ? formatDate(model.completed_est) : "-"}</span>
                            </div>
                        </div>
                    </CardContent>
                    <CardFooter>
                        <div className="flex justify-end">
                            <Button
                                variant="destructive"
                                size="sm"
                                onClick={() => deleteModel(model.model_id)}
                                className={`
                                font-bold border-narkis-mobile-border-brighter border rounded-[3px]
                                bg-gradient-to-r from-red-500 to-red-600
                                hover:border-red-700                                transition-transform ease-in-out
                                active:scale-99 active:translate-y-0.5
                                `}
                            >
                                {t("deleteButton")}
                            </Button>
                        </div>
                    </CardFooter>
                </Card>
            ))}
        </div>
    );
};

export default ModelManagementView;
