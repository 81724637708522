// VersionWatermark.js
import React from 'react';
import { versionInfo } from '../version'; // path to your generated version.js
import './VersionWatermark.css';

const VersionWatermark = () => {
  return (
    <div className='versionWatermark'>
      {`v${versionInfo.version} - ${versionInfo.buildTime} - ${versionInfo.hash}`}
    </div>
  );
};

export default VersionWatermark;
