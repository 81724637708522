import React, { useRef, useLayoutEffect, useCallback, useMemo } from "react";

interface GradientMeshBackgroundProps {
    className?: string;
}

const GradientMeshBackground: React.FC<GradientMeshBackgroundProps> = ({ className }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const allGradients = [
        { str: 0.8, x: 0.2, y: 0.2, color: "rgba(255, 107, 107, 0.7)" },
        { str: 0.9, x: 0.8, y: 0.3, color: "rgba(78, 205, 196, 0.7)" },
        { str: 1, x: 0.5, y: 0.7, color: "rgba(69, 183, 209, 0.7)" },
        { str: 0.7, x: 0.1, y: 0.8, color: "rgba(249, 213, 110, 0.7)" },
        { str: 0.6, x: 0.9, y: 0.6, color: "rgba(45, 52, 54, 0.5)" },
        { str: 0.85, x: 0.3, y: 0.4, color: "rgba(155, 89, 182, 0.7)" },
        { str: 0.75, x: 0.7, y: 0.1, color: "rgba(52, 152, 219, 0.7)" },
        { str: 0.95, x: 0.4, y: 0.9, color: "rgba(46, 204, 113, 0.7)" },
        { str: 0.65, x: 0.6, y: 0.5, color: "rgba(241, 196, 15, 0.7)" },
        { str: 0.55, x: 0.1, y: 0.3, color: "rgba(231, 76, 60, 0.7)" },
    ];

    const gradients = useMemo(() => 
        allGradients.sort(() => 0.5 - Math.random()).slice(0, 5),
        []
    );

    const resizeCanvas = useCallback(() => {
        if (canvasRef.current) {
            canvasRef.current.width = canvasRef.current.offsetWidth;
            canvasRef.current.height = canvasRef.current.offsetHeight;
        }
    }, []);
    
    const animate = useCallback((time: number) => {
        const canvas = canvasRef.current;
        const ctx = canvas?.getContext("2d");
        if (!canvas || !ctx) return;
    
        ctx.clearRect(0, 0, canvas.width, canvas.height);
    
        gradients.forEach((gradient, index) => {
            const angle = (time / 500 + index * Math.PI / 2.5) % (Math.PI * 2);
            const xOffset = Math.cos(angle) * 0.1;
            const yOffset = Math.sin(angle) * 0.1;
            const strOffset = (Math.sin(angle) + 1) / 2 * 0.3;

            const x = (gradient.x + xOffset) * canvas.width;
            const y = (gradient.y + yOffset) * canvas.height;
            const str = gradient.str + strOffset;

            const radialGradient = ctx.createRadialGradient(
                x, y, 0,
                x, y, canvas.width * str * 0.7
            );
            radialGradient.addColorStop(0, gradient.color);
            radialGradient.addColorStop(1, "rgba(0, 0, 0, 0)");

            ctx.fillStyle = radialGradient;
            ctx.fillRect(0, 0, canvas.width, canvas.height);
        });

        requestAnimationFrame(animate);
    }, [gradients]);
    

    useLayoutEffect(() => {
        resizeCanvas();
        const ctx = canvasRef.current?.getContext("2d");
        if (ctx) {
            ctx.filter = "blur(20px)";
            animate(0);
        }
    
        window.addEventListener('resize', resizeCanvas);
    
        return () => {
            window.removeEventListener('resize', resizeCanvas);
        };
    }, [animate, resizeCanvas]);

    return <canvas ref={canvasRef} className={className} style={{ width: '100%' }} />;
};

export default GradientMeshBackground;