import React from 'react';
import blogAvatar from './blog-avatar.jpeg';


const AuthorInfo: React.FC = () => {
  return (
    <div className="relative bg-gradient-to-br from-gray-50 to-white p-8 rounded-lg shadow-lg overflow-hidden">
      <div className="absolute inset-0 bg-blue-100 opacity-10 transform -skew-y-6"></div>
      <h2 className="relative text-3xl font-bold mb-6 text-gray-800 border-b-2 border-blue-300 pb-2">About the Author</h2>
      <div className="relative flex items-center mb-6">
        <div className="w-20 h-20 mr-6 rounded-full overflow-hidden shadow-md transform hover:scale-105 transition-transform duration-300">
          <img src={blogAvatar} alt="Author" className="w-full h-full object-cover" />
        </div>
        <div className="flex flex-col justify-between h-20">
          <div>
            <h3 className="text-xl leading-tight font-semibold text-gray-800">Adam</h3>
            <p className="text-blue-600 font-medium">AI Researcher & Tech Innovator</p>
          </div>
          <a
            href="https://www.linkedin.com/in/adam-albastov"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center px-3 py-0.5 bg-blue-500 text-white text-sm font-medium rounded-md shadow-sm hover:bg-blue-600 hover:text-stone-50 transition-colors duration-300 ease-in-out w-max"
          >
            <svg className="w-5 h-5 mr-2 mt-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
            </svg>
            Connect on LinkedIn
          </a>
        </div>
      </div>
      <p className="relative text-gray-700 leading-relaxed">
        Adam is a tech innovator with a rich background in high-performance computing and large-scale data systems. His career evolution mirrors the rapid advancement of technology itself. Now, as an AI researcher and startup founder, Adam is on a mission to harness the power of machine learning, natural language processing, and generative AI. His purpose? <s>To create accessible, impactful AI solutions that push the boundaries of what's possible in our digital world.</s> World domination.
      </p>
      <div className="absolute bottom-0 right-0 w-32 h-32 bg-gradient-to-tl from-blue-200 to-transparent opacity-30 transform rotate-45"></div>
    </div>
  );
};

export default AuthorInfo;
