// src/components/ModelSelection.js
import React, { useState, startTransition, useEffect } from "react";
import { useAuth } from "../../../../contexts/AuthContext";
import { Button } from "../../../shadcn/ui/button";
import TrainModelModal from "./TrainModelModal";
import SubMenuHeader from "../../SubMenuHeader";
import { useLocalStorageState } from "../../../../hooks/useLocalStorageState";
import { useTranslation } from "react-i18next";
import { Label } from "@radix-ui/react-label";

const ModelSelectionMenu = ({ models, onModelSelect }: { models: any; onModelSelect: any }) => {
    const { t } = useTranslation("model_selection");
    const contextValue = useAuth();
    const { user } = contextValue;
    const maxConcurrentTrainings = user?.max_concurrent_training_runs || 0;
    const [lastSelectedModel, setLastSelectedModel] = useLocalStorageState("lastSelectedModel", "-1");

    const ongoingTrainings = models.filter((model: any) => model.status === "IN_PROGRESS");

    const handleSelectedModelChange = (selectedModel: any) => {
        onModelSelect(selectedModel); // Pass the selected model ID to the parent component
        setLastSelectedModel(selectedModel.model_id);
    };


    const [isTrainModelModalOpen, setIsTrainModelModalOpen] = useState(false);
    const openTrainModelModal = () => {
        startTransition(() => {
            setIsTrainModelModalOpen(true);
        });
    };

    const closeTrainModelModal = () => {
        setIsTrainModelModalOpen(false);
    };

    return (
        <div className="p-1 flex flex-col bg-narkis-bg-lighter">
            <SubMenuHeader title={t("title")} />

            <Label className="text-md font-semibold text-center mt-1">{t("selectModel.mainLabel")}</Label>
            <div className="relative w-full max-w-4xl mx-auto">
                <div className="overflow-hidden">
                    <div className="flex items-center justify-start p-4 space-x-2 overflow-x-auto scrollbar-hide">
                        {models
                            .filter((model: any) => model.status === "COMPLETED")
                            .map((model: any) => (
                                <div
                                    key={model.model_id}
                                    className={`
                                    flex-shrink-0 w-24 h-32 rounded-lg overflow-hidden
                                    transform transition-all duration-300 ease-in-out
                                    hover:scale-105 hover:shadow-xl
                                    ${lastSelectedModel === model.model_id ? "ring-2 ring-blue-500 scale-105 shadow-xl" : ""}
                                    `}
                                    onClick={() => handleSelectedModelChange(model)}
                                >
                                    <div 
                                        className="w-full h-full bg-gradient-to-br from-indigo-500 to-purple-600 relative"
                                        style={{
                                            backgroundImage: model.favorited_image_url ? `url(${model.favorited_image_url})` : "none",
                                            backgroundSize: "cover",
                                            backgroundPosition: "top center",
                                        }}
                                    >
                                        <div className={`absolute inset-0 bg-black transition-opacity duration-300 ${lastSelectedModel === model.model_id ? 'bg-opacity-0' : 'bg-opacity-40 hover:bg-opacity-10'}`} />
                                        <div className="absolute bottom-0 left-0 right-0 p-2 bg-gradient-to-t from-black to-transparent">
                                            <span className="text-xs text-center font-bold text-white truncate block">
                                                {model.model_name}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
                <div className="absolute inset-y-0 left-0 bg-gradient-to-r from-narkis-bg-lighter to-transparent w-8" />
                <div className="absolute inset-y-0 right-0 bg-gradient-to-l from-narkis-bg-lighter to-transparent w-8" />
            </div>

            {ongoingTrainings.length > maxConcurrentTrainings ? (
                <div className="centeredText">
                    <label>
                        {t("tooManyTrainings.mainLabel")} ({maxConcurrentTrainings})
                    </label>
                </div>
            ) : (
                <div className="flex flex-col items-center justify-center m-2 gap-4">
                    {models.length > 0 && (
                        <div className="text-center font-extrabold">
                            <span>{t("or.mainLabel")}</span>
                        </div>
                    )}

                    {/* if the user has no models yet */}
                    <Button
                        variant="default"
                        onClick={openTrainModelModal}
                        className={`
                        font-extrabold border-narkis-border border rounded-[6px] w-full
                        bg-gradient-to-tr from-orange-400 via-orange-200 to-orange-600 bg-clip-text text-transparent 
                        transition-transform ease-in-out
                        hover:-translate-y-0.5  
                        animate-gradient-x bg-[length:400%_400%]
                        `}
                    >
                        {models.length === 0 ? t("createFirstModel.mainLabel") : t("createNewModel.mainLabel")}
                    </Button>

                    <TrainModelModal isOpen={isTrainModelModalOpen} onClose={closeTrainModelModal} />
                </div>
            )}
        </div>
    );
};

export default ModelSelectionMenu;
