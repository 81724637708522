import React from 'react';

export const SquareIcon: React.FC = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="Square layout">
        <rect x="3" y="3" width="18" height="18" stroke="currentColor" strokeWidth="2"/>
    </svg>
);

export const LandscapeIcon: React.FC = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="Landscape layout">
        <rect x="2" y="6" width="20" height="12" stroke="currentColor" strokeWidth="2"/>
    </svg>
);

export const PortraitIcon: React.FC = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="Portrait layout">
        <rect x="6" y="2" width="12" height="20" stroke="currentColor" strokeWidth="2"/>
    </svg>
);
