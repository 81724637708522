// BottomNavMenu.tsx
import { CameraIcon, ImageIcon, PersonIcon } from "@radix-ui/react-icons";
import { Camera, User2Icon } from "lucide-react";
import React from "react";
import { useTranslation } from "react-i18next";


interface BottomNavMenuProps {
    onGenerateClick: () => void;
    onPhotosClick: () => void;
    onMyModelsClick: () => void;
    currentView: string;
}

const BottomNavMenu: React.FC<BottomNavMenuProps> = ({ onGenerateClick, onPhotosClick, onMyModelsClick, currentView }) => {
    const { t } = useTranslation('dashboard');
    return (
        <nav className="flex text-sm font-extrabold text-narkis-mobile-text-bright justify-evenly items-center py-0.5 border-t-2 border-narkis-mobile-border-darker bg-gradient-to-r from-stone-800 via-stone-700 to-stone-950 transition-all duration-500 ease-in-out">
            <button
                onClick={onGenerateClick}
                className={`flex-1 flex flex-col items-center bg-narkis-mobile-lightest2 bg-opacity-5 border border-narkis-mobile-border-darker backdrop-filter backdrop-blur-lg rounded-sm p-2 shadow-md mx-0.5 transition-transform duration-1 ${
                    currentView === "generate"
                        ? "z-20 transform scale-103 ring-2 ring-stone-100 ring-opacity-50"
                        : "transform scale-100"
                }`}
            >
                <CameraIcon className="h-6 w-6 text-teal-500 transition-opacity duration-1"></CameraIcon>
                <span>{t('nav.generate')}</span>
            </button>
            <button
                onClick={onPhotosClick}
                className={`flex-1 flex flex-col items-center bg-narkis-mobile-lightest2 bg-opacity-5 border border-narkis-mobile-border-darker backdrop-filter backdrop-blur-lg rounded-sm p-2 shadow-md mx-0.5 transition-transform duration-1 ${
                    currentView === "photos"
                        ? "z-20 transform scale-104 ring-2 ring-stone-100 ring-opacity-50"
                        : "transform scale-100"
                }`}
            >
                <ImageIcon className="h-6 w-6 text-pink-500 transition-opacity duration-1"></ImageIcon>
                <span>{t('nav.photos')}</span>
            </button>
            <button
                onClick={onMyModelsClick}
                className={`flex-1 flex flex-col items-center bg-narkis-mobile-lightest2 bg-opacity-5 border border-narkis-mobile-border-darker backdrop-filter backdrop-blur-lg rounded-sm p-2 shadow-md mx-0.5 transition-transform duration-1 ${
                    currentView === "models"
                        ? "z-20 transform scale-103 ring-2 ring-stone-100 ring-opacity-50"
                        : "transform scale-100"
                }`}
            >
                <PersonIcon className="h-6 w-6 text-orange-500 transition-opacity duration-1"></PersonIcon>
                <span>{t('nav.my_models')}</span>
            </button>
        </nav>
    );
};

export default BottomNavMenu;
