// TrainModelForm.tsx
import React, { useState, useRef, useEffect } from "react";
import { uploadImages, trainModel } from "../../../../services/api";
import { Button } from "../../../shadcn/ui/button";
import { Input } from "../../../shadcn/ui/input";
import { Label } from "../../../shadcn/ui/label";
import { Progress } from "../../../shadcn/ui/progress";
import { Switch } from "../../../shadcn/ui/switch";
import { useToast } from "../../../shadcn/ui/use-toast";
import { Loader2 } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../contexts/AuthContext";

interface TrainModelFormProps {
    onClose: () => void;
}

const TrainModelForm: React.FC<TrainModelFormProps> = ({ onClose }) => {
    const { t } = useTranslation("training");
    const [imagePreviews, setImagePreviews] = useState<string[]>([]);
    const [imageUUIDs, setImageUUIDs] = useState<string[]>([]);
    const [modelName, setModelName] = useState("");
    const [gender, setGender] = useState("male");
    const [uploadingCount, setUploadingCount] = useState(0);
    const [totalUploadingCount, setTotalUploadingCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [useMasking, setUseMasking] = useState(false);
    const [useMirroring, setUseMirroring] = useState(false);
    const [useDualPurpose, setUseDualPurpose] = useState(false);
    const [useTrimming, setUseTrimming] = useState(false);

    const { user } = useAuth();
    const isTester = user?.is_tester;

    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const { toast } = useToast();

    useEffect(() => {
        resetImages();
    }, []);

    const resetImages = () => {
        setImagePreviews([]);
        setImageUUIDs([]);
    };

    const onClickSelectImages = () => {
        fileInputRef.current?.click();
    };

    const handleFileSelect = async (event: React.ChangeEvent<any>) => {
        console.log("Uploading...");
        let files = Array.from(event.target.files) as File[];

        // Increment upload count by the number of files selected
        setUploadingCount((c) => c + files.length);
        setTotalUploadingCount((c) => c + files.length);

        // Check if the uploaded images meet the minimum size requirement
        const validFiles: File[] = [];
        for (let file of files) {
            const image = new Image();
            image.src = URL.createObjectURL(file as File);
            await new Promise((resolve, reject) => {
                image.onload = () => {
                    const allowedFormats = ["image/jpeg", "image/png", "image/jpg"];
                    if (image.width < 512 || image.height < 512) {
                        reject(new Error("Image too small"));
                    } else if (!allowedFormats.includes(file.type)) {
                        reject(new Error("Invalid image format"));
                    } else {
                        validFiles.push(file as File);
                        resolve(true);
                    }
                };
            }).catch((error: any) => {
                console.log("Image must be at least 512x512 pixels");
                toast({
                    variant: "destructive",
                    title: t("training.imageTooSmall.title"),
                    description: t("training.imageTooSmall.description"),
                });
                setUploadingCount((c) => c - 1);
                setTotalUploadingCount((c) => c - 1);
                URL.revokeObjectURL(image.src); // Clean up memory by revoking the object URL
            });
        }
        files = validFiles; // Update files array to only include valid files

        const newImagePreviews = files.map((file: File) => {
            return URL.createObjectURL(file);
        });

        setImagePreviews((prevPreviews) => [...prevPreviews, ...newImagePreviews]); // Add to the existing previews

        // Upload all files at once and collect UUIDs asynchronously
        uploadImages(files)
            .then((response) => {
                console.log("Uploaded images:", response.data);
                setImageUUIDs((prevUuids) => [...prevUuids, ...response.data.uploadedImageIDs]);
                // Decrement upload count by the number of files uploaded
                setUploadingCount((c) => c - files.length);
            })
            .catch((error) => {
                console.error("Error uploading files:", error);
                toast({
                    variant: "destructive",
                    title: t("training.errorUploading.title"),
                    description: t("training.errorUploading.description", { error: error.message }),
                });

                // Decrement upload count by the number of files attempted to upload
                setUploadingCount((c) => c - files.length);
                setTotalUploadingCount((c) => c - files.length);
            });

        console.log(imageUUIDs);
    };

    const handleModelTraining = async () => {
        if (uploadingCount > 0) {
            toast({
                title: t("training.uploadCountWarning.title"),
            });
            return;
        }

        const { dismiss } = toast({
            title: t("training.creationInitiated.title"),
            description: t("training.creationInitiated.description"),
        });

        try {
            setIsLoading(true);
            const response = await trainModel("", imageUUIDs, gender === "male" ? "male" : "female", modelName, useMasking, useMirroring, useDualPurpose, useTrimming);
            toast({
                title: t("training.creationInitiated.title"),
                description: t("training.creationInitiated.description"),
            });
        } catch (error: any) {
            toast({
                variant: "destructive",
                title: t("training.errorCreatingModel.title"),
                description: t("training.errorCreatingModel.description", { error: error.message }),
            });
        } finally {
            setIsLoading(false);
            dismiss();
        }
        onClose();
    };

    const handleToggleGender = () => {
        setGender((prevGender) => (prevGender === "male" ? "female" : "male"));
    };

    const getImageCountClass = (imageCount: number) => {
        if (imageCount < 15) return "text-red-500";
        else if (imageCount >= 15 && imageCount <= 25) return "text-yellow-500";
        else if (imageCount >= 25 && imageCount <= 40) return "text-green-500";
        else if (imageCount > 40) return "text-green-500";
    };

    const handleDeleteUploadedImage = (index: number) => {
        setImagePreviews((prevPreviews) => prevPreviews.filter((_, i) => i !== index));
        setImageUUIDs((prevUuids) => prevUuids.filter((_, i) => i !== index));
    };

    return (
        <div className="space-y-2 border p-2 bg-narkis-mobile-bg-lighter border-narkis-mobile-border-darker rounded-[6px]">
            <div className="text-center text-sm text-red-500 mb-2">{t("training.minimumPhotosMessage")}</div>
            <div
                className="border-2 border-dashed border-narkis-mobile-border-brighter rounded-md p-4 text-center"
                onClick={onClickSelectImages}
            >
                {t("training.uploadImages")}
            </div>
            <input type="file" multiple ref={fileInputRef} style={{ display: "none" }} onChange={handleFileSelect} />
            <div className="grid grid-cols-4 gap-1 overflow-y-auto max-h-[40vh]">
                {imagePreviews.map((previewUrl, index) => (
                    <div key={index} className="relative">
                        <img src={previewUrl} alt={`Preview ${index + 1}`} className="w-full h-auto" />
                        <button
                            className="absolute top-0 right-0 p-1 bg-narkis-mobile-mid text-narkis-mobile-text rounded-bl-md"
                            onClick={() => handleDeleteUploadedImage(index)}
                        >
                            X
                        </button>
                    </div>
                ))}
            </div>
            <div className="flex items-center justify-center">
                <div className={`text-sm font-medium ${getImageCountClass(imagePreviews.length)}`}>{imagePreviews.length}/40</div>
            </div>

            {isTester && (
                <div className="flex flex-row justify-evenly gap-2 mt-2">
                    <div className="flex items-center gap-2">
                        <input
                            type="checkbox"
                            id="masking"
                            checked={useMasking}
                            onChange={(e) => setUseMasking(e.target.checked)}
                        />
                        <label htmlFor="masking" className="text-sm font-medium">
                            {t("training.useMasking")}
                        </label>
                    </div>
                    <div className="flex items-center gap-2">
                        <input
                            type="checkbox"
                            id="mirroring"
                            checked={useMirroring}
                            onChange={(e) => setUseMirroring(e.target.checked)}
                        />
                        <label htmlFor="mirroring" className="text-sm font-medium">
                            {t("training.useMirroring")}
                        </label>
                    </div>
                    <div className="flex items-center gap-2">
                        <input
                            type="checkbox"
                            id="dualPurpose"
                            checked={useDualPurpose}
                            onChange={(e) => setUseDualPurpose(e.target.checked)}
                        />
                        <label htmlFor="dualPurpose" className="text-sm font-medium">
                            {t("training.useDualPurpose")}
                        </label>
                    </div>
                    <div className="flex items-center gap-2">
                        <input
                            type="checkbox"
                            id="trimming"
                            checked={useTrimming}
                            onChange={(e) => setUseTrimming(e.target.checked)}
                        />
                        <label htmlFor="trimming" className="text-sm font-medium">
                            {t("training.useTrimming")}
                        </label>
                    </div>
                </div>
            )}

            <div className="px-2 flex flex-row justify-between gap-2 mt-2">
                <div className="flex flex-row items-center gap-1">
                    <Label className="text-sm font-medium  ">{t("training.modelName")}</Label>
                    <Input
                        type="text"
                        value={modelName}
                        onChange={(e) => setModelName(e.target.value)}
                        placeholder={t("training.modelName")}
                        className="w-[53%] h-8 rounded-[7.5px] border-narkis-mobile-border-lighter bg-narkis-mobile-bg text-narkis-mobile-text border"
                    />
                </div>
                <div className="flex flex-row items-center gap-1">
                    <Label className="text-sm font-medium">{t("training.gender")}</Label>
                    <Button
                        onClick={handleToggleGender}
                        className={`w-20 h-8 rounded-[7.5px] font-semibold border border-narkis-border-darker2 hover:bg-white hover:text-black ${
                            gender === "male" ? "bg-narkis-bg text-blue-500" : "bg-narkis-bg text-pink-500"
                        }`}
                    >
                        {gender === "male" ? t("training.male") : t("training.female")}
                    </Button>
                </div>
            </div>
            <div>
                {isLoading ? (
                    <Button disabled>
                        <Loader2 className=" h-4 w-4 animate-spin" />
                        {t("training.createModelButtonCreating")}
                    </Button>
                ) : (
                    <Button
                        onClick={handleModelTraining}
                        disabled={uploadingCount > 0}
                        className={`
                        font-extrabold border-narkis-border border rounded-[6px] w-full
                        bg-gradient-to-tr from-orange-400 via-orange-200 to-orange-600 bg-clip-text text-transparent 
                        transition-transform ease-in-out
                        hover:-translate-y-0.5  
                        animate-gradient-x bg-[length:400%_400%]
                        `}
                    >
                        {t("training.createModelButton")}
                    </Button>
                )}
            </div>
            {uploadingCount > 0 && (
                <div>
                    <Progress value={((totalUploadingCount - uploadingCount) / totalUploadingCount) * 100} />
                    <div className="text-sm text-narkis-mobile-border-brighter text-center">
                        {t("training.uploadingCountHead")} {uploadingCount} {t("training.uploadingCountTail")}
                    </div>
                </div>
            )}
        </div>
    );
};

export default TrainModelForm;
