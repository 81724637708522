import React, { useState } from "react";
import { Input } from "../../../shadcn/ui/input";
import { Label } from "../../../shadcn/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../shadcn/ui/select";
import { Slider } from "../../../shadcn/ui/slider";
import { Switch } from "../../../shadcn/ui/switch";
import { Button } from "../../../shadcn/ui/button";
import { generateImages } from "../../../../services/api";
import { useToast } from "../../../shadcn/ui/use-toast";
import { Loader2, UnfoldVerticalIcon } from "lucide-react";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "../../../shadcn/ui/collapsible";
import { useLocalStorageState } from "../../../../hooks/useLocalStorageState";
import { useTranslation } from "react-i18next";
import { Textarea } from "../../../shadcn/ui/textarea";
import { useUserDataContext } from "../../../../contexts/UserDataContext";
import GradientMeshBackground from "../../../../components/GradientMeshButton";

const CustomSettingsTab = ({
    trainedModel,
    addGeneratedImage,
    deleteGeneratedImage,
}: {
    trainedModel: any;
    addGeneratedImage: (image: any) => void;
    deleteGeneratedImage: (imageId: string) => void;
}) => {
    const { t } = useTranslation("generate");
    const [width, setWidth] = useLocalStorageState("width", 768);
    const [height, setHeight] = useLocalStorageState("height", 768);
    const [positivePrompt, setPositivePrompt] = useLocalStorageState("positivePrompt", "");
    const [negativePrompt, setNegativePrompt] = useLocalStorageState("negativePrompt", "");
    const [positiveLoras, setPositiveLoras] = useLocalStorageState("positiveLoras", "");
    const [negativeLoras, setNegativeLoras] = useLocalStorageState("negativeLoras", "");
    const [steps, setSteps] = useLocalStorageState("steps", 15);
    const [sampler, setSampler] = useLocalStorageState("sampler", "DPM++ 2M Karras");
    const [useAdetailer, setUseAdetailer] = useLocalStorageState("useAdetailer", false);
    const [adetailerDenoisingStrength, setAdetailerDenoisingStrength] = useLocalStorageState("adetailerDenoisingStrength", 0.5);
    const [adetailerModelName, setAdetailerModelName] = useLocalStorageState("adetailerModelName", "face_yolov8n.pt");
    const [improveHands, setImproveHands] = useLocalStorageState("improveHands", false);
    const [useUpscaler, setUseUpscaler] = useLocalStorageState("useUpscaler", false);
    const [upscalerName, setUpscalerName] = useLocalStorageState("upscalerName", "R-ESRGAN 4x+");
    const [upscaleWidth, setUpscaleWidth] = useLocalStorageState("upscaleWidth", 2048);
    const [upscaleHeight, setUpscaleHeight] = useLocalStorageState("upscaleHeight", 2048);
    const [count, setCount] = useLocalStorageState("count", 1);
    const [selectedCfgScale, setSelectedCfgScale] = useLocalStorageState("selectedCfgScale", 7.5);
    const [seed, setSeed] = useLocalStorageState("seed", -1);
    const [enableSeed, setEnableSeed] = useLocalStorageState("enableSeed", false);

    const [loraCollapsed, setLoraCollapsed] = useLocalStorageState("loraCollapsed", false);
    const [adetailerCollapsed, setAdetailerCollapsed] = useLocalStorageState("adetailerCollapsed", false);
    const [upscalerCollapsed, setUpscalerCollapsed] = useLocalStorageState("upscalerCollapsed", false);

    const { toast } = useToast();
    const { setTrainingCredits, setImageCredits } = useUserDataContext();

    const [isLoading, setIsLoading] = useState(false);

    const handleResolutionChange = (value: string) => {
        const [width, height] = value.split("x");
        setWidth(parseInt(width));
        setHeight(parseInt(height));
    };

    const handleSamplerChange = (e: any) => {
        setSampler(e);
    };

    const handleAdetailerModelChange = (value: string) => {
        setAdetailerModelName(value);
    };

    const handleUpscalerChange = (value: string) => {
        setUpscalerName(value);
    };

    const handleUpscalerResolutionChange = (value: string) => {
        const [width, height] = value.split("x");
        setUpscaleWidth(parseInt(width));
        setUpscaleHeight(parseInt(height));
    };

    const handleSelectedCfgChange = (value: any) => {
        setSelectedCfgScale(value[0]);
    };

    const validateInputs = () => {
        const errors = [];
        if (!positivePrompt || positivePrompt.trim() === "") errors.push("Positive prompt cannot be empty");

        if (errors.length > 0) {
            toast({
                variant: "destructive",
                title: t("invalidInput.title"),
                description: errors.join(`\n - `),
            });
            return false;
        }
        return true;
    };

    const handleGenerateClick = async () => {
        setIsLoading(true);

        if (!validateInputs()) {
            setIsLoading(false);
            return;
        }

        try {
            const response = await generateImages(
                "", // userId: not used
                trainedModel.model_id,
                count,
                width,
                height,
                positivePrompt,
                negativePrompt,
                positiveLoras,
                negativeLoras,
                steps,
                sampler,
                selectedCfgScale,
                seed,

                useAdetailer,
                adetailerDenoisingStrength,
                adetailerModelName,
                improveHands,

                useUpscaler,
                upscalerName,
                upscaleWidth,
                upscaleHeight
            );
            const { credits, gift_credits, images } = response.data;

            images.forEach((image: any) => {
                // Assuming addGeneratedImage is a function to handle the image data
                addGeneratedImage(image);
            });
            setImageCredits(credits, gift_credits);
            toast({
                title: t("yourPhotographsAreQueued.title"),
                description: t("yourPhotographsAreQueued.description"),
            });
        } catch (error: any) {
            console.error("Failed to generate images:", error);
            toast({
                variant: "destructive",
                title: t("failedToGenerateImages.title"),
                description: t("failedToGenerateImages.description", { error: error.message }),
            });
        } finally {
            setIsLoading(false);
        }
    };

    const imageResolutionOptions = ["768x768", "1024x1024", "768x1024", "512x1024", "1024x512", "1024x768"];

    // Options for upscalerResolution dropdown
    const upscalerResolutionOptions = ["1536x1536", "2048x2048"];
    const upscalerOptions = ["R-ESRGAN 4x+", "LDSR"];
    const samplerOptions = ["DPM++ 2M Karras", "Euler a", "DPM adaptive"];
    const adetailerModelOptions = [
        "face_yolov8n.pt",
        "face_yolov8s.pt",
        "person_yolov8n-seg.pt",
        "person_yolov8s-seg.pt",
        "mediapipe_face_full",
        "mediapipe_face_short",
        "mediapipe_face_mesh",
    ];

    return (
        <div className="flex flex-col h-full gap-2 text-sm text-narkis-text">
            {/* resolution & steps */}
            <div className="flex items-center text-center gap-2 justify-evenly">
                {/* resolution */}
                <div>
                    <Label htmlFor="resolution" className="block">
                        {t("resolution.mainLabel")}
                    </Label>
                    <Select onValueChange={handleResolutionChange}>
                        <SelectTrigger
                            aria-label="Choose resolution"
                            className="h-7 rounded-[7.5px] bg-narkis-bg border-narkis-border mt-1"
                        >
                            <SelectValue placeholder={`${width}x${height}`} />
                        </SelectTrigger>
                        <SelectContent>
                            {imageResolutionOptions.map((resolution) => (
                                <SelectItem key={resolution} value={resolution}>
                                    {resolution}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>

                {/* steps */}
                <div>
                    <Label htmlFor="steps" className="block">
                        {t("steps.mainLabel")}
                    </Label>
                    <div className="flex items-center mt-1 h-7 bg-narkis-bg border border-narkis-border rounded-[7.5px]">
                        <Button
                            className="h-full w-1 bg-narkis-bg hover:bg-narkis-bg-lighter font-normal text-lg pt-1"
                            onClick={() => setSteps(steps - 1)}
                        >
                            -
                        </Button>
                        <input
                            className="rounded-none text-center h-full w-7 border-0 m-0 p-0 bg-narkis-bg focus:outline-none"
                            value={steps}
                            onChange={(e) => setSteps(parseInt(e.target.value))}
                        />
                        <Button
                            className="h-full w-1 bg-narkis-bg hover:bg-narkis-bg-lighter font-normal text-lg pt-1"
                            onClick={() => setSteps(steps + 1)}
                        >
                            +
                        </Button>
                    </div>
                </div>

                {/* count */}
                <div>
                    <Label htmlFor="count" className="block">
                        {t("count.mainLabel")}
                    </Label>
                    <div className="flex items-center mt-1 h-7 bg-narkis-bg border border-narkis-border rounded-[7.5px]">
                        <Button
                            className="h-full w-1 bg-narkis-bg hover:bg-narkis-bg-lighterpt-1"
                            onClick={() => setCount(count - 1)}
                        >
                            -
                        </Button>
                        <input
                            className="rounded-none text-center h-full w-7 border-0 m-0 p-0 bg-narkis-bg focus:outline-none"
                            value={count}
                            onChange={(e) => setCount(parseInt(e.target.value))}
                        />
                        <Button
                            className="h-full w-1 bg-narkis-bg hover:bg-narkis-bg-lighterpt-1"
                            onClick={() => setCount(count + 1)}
                        >
                            +
                        </Button>
                    </div>
                </div>
            </div>

            <div>
                {/* positive prompt */}
                <Label htmlFor="positivePrompt" className="block">
                    {t("positivePrompt.mainLabel")}
                </Label>
                <Textarea
                    id="positivePrompt"
                    value={positivePrompt}
                    onChange={(e) => setPositivePrompt(e.target.value)}
                    className="mt-1 w-full rounded-[7.5px] bg-narkis-bg border-narkis-border"
                />

                {/* negative prompt */}
                <Label htmlFor="negativePrompt" className="block mt-4">
                    {t("negativePrompt.mainLabel")}
                </Label>
                <Textarea
                    id="negativePrompt"
                    value={negativePrompt}
                    onChange={(e) => setNegativePrompt(e.target.value)}
                    className="mt-1 w-full rounded-[7.5px] bg-narkis-bg border-narkis-border"
                    placeholder="(optional) ex: red, dark, rainy"
                />
            </div>

            {/* adetailer */}
            <Collapsible
                defaultOpen={adetailerCollapsed}
                onOpenChange={setAdetailerCollapsed}
                className={`
                    bg-narkis-bg border-narkis-border-darker 
                    rounded-[7.5px]
                    transition-all duration-100 ease-in-out p-2
                    ${adetailerCollapsed ? " border-b-4" : " border-b"} 
                `}
            >
                <CollapsibleTrigger className="cursor-pointer text-narkis-text hover:text-narkis-text-bright">
                    <div className="flex items-center justify-between text-lg">
                        <span>{t("adetailerCollapse.mainLabel")}</span>
                        <UnfoldVerticalIcon className="ml-1 w-5 h-5"> </UnfoldVerticalIcon>
                    </div>
                </CollapsibleTrigger>

                <CollapsibleContent className="my-2">
                    <div className="adetailerOptions">
                        {/* use adetailer */}
                        <div className="flex justify-between items-center gap-4">
                            <div className="flex items-center gap-2">
                                <Label htmlFor="useAdetailerSwitch" className="flex items-center">
                                    {t("useAdetailer.mainLabel")}
                                </Label>
                                <Switch
                                    id="useAdetailerSwitch"
                                    checked={useAdetailer}
                                    onCheckedChange={(checked) => setUseAdetailer(checked)}
                                />
                            </div>
                            {/* improve hands */}
                            <div className="flex items-center gap-2">
                                <Label htmlFor="improveHandsSwitch" className="flex items-center">
                                    {t("improveHands.mainLabel")}
                                </Label>
                                <Switch
                                    id="improveHandsSwitch"
                                    checked={improveHands}
                                    onCheckedChange={(checked) => setImproveHands(checked)}
                                />
                            </div>
                        </div>

                        <div className="flex justify-between items-center gap-4">
                            <div className="block mt-4">
                                <Label htmlFor="adetailerDenoisingStrength" className="block">
                                    {t("adetailerDenoisingStrength.mainLabel")}
                                </Label>
                                <div className="flex items-center mt-1 h-7 bg-narkis-bg border border-narkis-border rounded-[7.5px]">
                                    <Button
                                        className="h-full w-1 bg-narkis-bg hover:bg-narkis-bg-lighterpt-1"
                                        onClick={() => setAdetailerDenoisingStrength(adetailerDenoisingStrength - 0.05)}
                                    >
                                        -
                                    </Button>
                                    <input
                                        className="rounded-none text-center h-full w-8 border-0 m-0 p-0 bg-narkis-bg focus:outline-none"
                                        value={adetailerDenoisingStrength.toFixed(2)}
                                        onChange={(e) => setAdetailerDenoisingStrength(parseFloat(e.target.value))}
                                    />
                                    <Button
                                        className="h-full w-1 bg-narkis-bg hover:bg-narkis-bg-lighterpt-1"
                                        onClick={() => setAdetailerDenoisingStrength(adetailerDenoisingStrength + 0.05)}
                                    >
                                        +
                                    </Button>
                                </div>
                            </div>

                            <div className="block">
                                <Label htmlFor="adetailerModelNameSelect" className="block mt-4">
                                    {t("adetailerModelName.mainLabel")}
                                </Label>
                                <Select
                                    name="adetailerModelNameSelect"
                                    value={adetailerModelName}
                                    onValueChange={handleAdetailerModelChange}
                                    disabled={!useAdetailer}
                                >
                                    <SelectTrigger
                                        aria-label="Select Adetailer Model"
                                        className="mt-1 h-7 text-sm rounded-[7.5px] bg-narkis-bg border-narkis-border"
                                    >
                                        <SelectValue placeholder="Select model" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {adetailerModelOptions.map((modelName) => (
                                            <SelectItem key={modelName} value={modelName}>
                                                {t(`${modelName}.mainLabel`)}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>
                    </div>
                </CollapsibleContent>
            </Collapsible>

            {/* loras */}
            <Collapsible
                defaultOpen={loraCollapsed}
                onOpenChange={setLoraCollapsed}
                className={`
                    bg-narkis-bg border-narkis-border-darker 
                    rounded-[7.5px]
                    transition-all duration-100 ease-in-out p-2
                    ${loraCollapsed ? " border-b-4" : " border-b"} 
                `}
            >
                <CollapsibleTrigger className="cursor-pointer text-narkis-text hover:text-narkis-text-bright">
                    <div className="flex items-center justify-between text-lg">
                        <span>{t("loraCollapse.mainLabel")}</span>
                        <UnfoldVerticalIcon className="ml-1 w-5 h-5"> </UnfoldVerticalIcon>
                    </div>
                </CollapsibleTrigger>

                <CollapsibleContent className="my-2">
                    {/* positive loras */}
                    <Label htmlFor="positiveLoras">{t("positiveLoras.mainLabel")}</Label>
                    <Input
                        id="positiveLoras"
                        value={positiveLoras}
                        onChange={(e) => setPositiveLoras(e.target.value)}
                        className="mt-1 h-7 text-sm rounded-[7.5px] w-full bg-narkis-bg border-narkis-border"
                        disabled={true}
                    />

                    {/* negative loras */}
                    <Label htmlFor="negativeLoras" className="block mt-4">
                        {t("negativeLoras.mainLabel")}
                    </Label>
                    <Input
                        id="negativeLoras"
                        value={negativeLoras}
                        onChange={(e) => setNegativeLoras(e.target.value)}
                        className="mt-1 h-7 text-sm rounded-[7.5px] w-full bg-narkis-bg border-narkis-border"
                        disabled={true}
                    />
                </CollapsibleContent>
            </Collapsible>

            {/* upscaler */}
            <Collapsible
                defaultOpen={upscalerCollapsed}
                onOpenChange={setUpscalerCollapsed}
                className={`
                    bg-narkis-bg border-narkis-border-darker 
                    rounded-[7.5px]
                    transition-all duration-100 ease-in-out p-2
                    ${upscalerCollapsed ? " border-b-4" : " border-b"} 
                    `}
            >
                <CollapsibleTrigger className="cursor-pointer text-narkis-text hover:text-narkis-text-bright">
                    <div className="flex items-center justify-between  text-lg">
                        <span>{t("upscalerCollapse.mainLabel")}</span>
                        <UnfoldVerticalIcon className="ml-1 w-5 h-5"> </UnfoldVerticalIcon>
                    </div>
                </CollapsibleTrigger>
                <CollapsibleContent className="my-2">
                    <div className="flex flex-col gap-2">
                        <div className="flex justify-between items-center gap-4">
                            <div className="flex items-center gap-2">
                                <Label htmlFor="useUpscalerSwitch" className="flex items-center">
                                    {t("useUpscaler.mainLabel")}
                                </Label>
                                <Switch
                                    id="useUpscalerSwitch"
                                    checked={useUpscaler}
                                    onCheckedChange={(checked) => setUseUpscaler(checked)}
                                />
                            </div>
                        </div>

                        <div className="flex justify-between items-center gap-4">
                            {/* upscaler */}
                            <div className="block mt-4">
                                <Label htmlFor="upscalerSelect" className="block">
                                    {t("upscaler.mainLabel")}
                                </Label>
                                <Select
                                    defaultValue={upscalerName}
                                    value={upscalerName}
                                    onValueChange={handleUpscalerChange}
                                    disabled={!useUpscaler}
                                >
                                    <SelectTrigger
                                        aria-label="Select Upscaler"
                                        className="mt-1 h-7 text-sm rounded-[7.5px] w-28 bg-narkis-bg border-narkis-border"
                                    >
                                        <SelectValue placeholder={upscalerName} />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {upscalerOptions.map((option) => (
                                            <SelectItem key={option} value={option}>
                                                {option}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>

                            <div className="block">
                                <Label htmlFor="upscalerResolutionSelect" className="block mt-4">
                                    {t("upscalerResolution.mainLabel")}
                                </Label>
                                <Select
                                    value={`${upscaleWidth}x${upscaleHeight}`}
                                    onValueChange={handleUpscalerResolutionChange}
                                    disabled={!useUpscaler}
                                >
                                    <SelectTrigger
                                        aria-label="Select Resolution"
                                        className="w-fit mt-1 h-7 text-sm rounded-[7.5px] bg-narkis-bg border-narkis-border"
                                    >
                                        <SelectValue placeholder={`${upscaleWidth}x${upscaleHeight}`} />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {upscalerResolutionOptions.map((resolution) => (
                                            <SelectItem key={resolution} value={resolution}>
                                                {resolution}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>
                    </div>
                </CollapsibleContent>
            </Collapsible>

            {/* Seed section */}
            <div className="p-3 py-1 border border-narkis-border-darker2 rounded-[7.5px] bg-narkis-bg">
                <div className="flex items-center justify-between gap-2">
                    <div className="flex items-center space-x-2">
                        <Label htmlFor="enableSeed" className="text-sm">
                            {t("enableSeed.mainLabel")}
                        </Label>
                        <Switch
                            id="enableSeed"
                            checked={enableSeed}
                            onCheckedChange={(checked) => {
                                setEnableSeed(checked);
                                if (checked) {
                                    setSeed(Math.floor(Math.random() * 1000000));
                                } else {
                                    setSeed(-1);
                                }
                            }}
                        />
                    </div>
                    <div className="flex items-center space-x-2">
                        <Label htmlFor="seedInput" className="text-sm">
                            {t("seed.mainLabel")}
                        </Label>
                        <Input
                            id="seedInput"
                            type="number"
                            value={seed === -1 ? "" : seed}
                            onChange={(e) => setSeed(parseInt(e.target.value) || -1)}
                            disabled={!enableSeed}
                            className="w-24 h-7 text-sm rounded-[7.5px] bg-narkis-bg border-narkis-border [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                        />
                    </div>
                </div>
            </div>

            {/* cfg scale & sampler */}
            <div className="flex items-center text-center gap-2 justify-evenly">
                {/* cfg scale */}
                <div className="w-2/5 flex flex-row items-center gap-1">
                    <Label htmlFor="cfgScale" className="block">
                        {t("cfgScale.mainLabel")}
                    </Label>
                    <Slider
                        id="cfgScale"
                        min={0}
                        max={20}
                        defaultValue={[selectedCfgScale]}
                        onValueChange={handleSelectedCfgChange}
                    />

                    <Label htmlFor="cfgScale">{selectedCfgScale}</Label>
                </div>

                {/* sampler */}
                <div className="flex items-center">
                    <Select onValueChange={handleSamplerChange} defaultValue={sampler}>
                        <SelectTrigger
                            aria-label="Choose sampler"
                            className="mt-1 w-full h-7 text-sm rounded-[7.5px] border-narkis-border"
                        >
                            <SelectValue defaultValue={sampler} />
                        </SelectTrigger>
                        <SelectContent>
                            {samplerOptions.map((option) => (
                                <SelectItem key={option} value={option}>
                                    {option}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
            </div>

            <div className="relative overflow-hidden border-narkis-border brightness-125 border rounded-[7.5px]">
                <GradientMeshBackground className="absolute inset-0" />
                <button
                    disabled={isLoading}
                    onClick={handleGenerateClick}
                    className="
                     rounded-[10px]
                    relative w-full px-7 py-4 bg-black brightness-125 bg-opacity-0 leading-none flex items-center justify-center overflow-hidden 
                    transition-all duration-300 ease-in-out
                    hover:bg-opacity-10  shadow-black shadow-inner hover:brightness-200
                    active:bg-opacity-0 transition-transform-200 active:scale-95 active:shadow-sm
                    disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-opacity-30 disabled:hover:shadow-none disabled:active:scale-100"
                >
                    <span className="relative z-10 flex items-center justify-center">
                        {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                        {t("generateButton.mainLabel")}
                    </span>
                </button>
            </div>

        </div>
    );
};

export default CustomSettingsTab;
