import React, { useState, useEffect } from "react";
import { ThumbsUp, ThumbsDown } from "lucide-react";
import { reactToArticle, getArticleReactions } from "../../../../services/api";
import { useToast } from "../../../shadcn/ui/use-toast";

interface VoteButtonsProps {
    articleId: string;
}

const VoteButtons: React.FC<VoteButtonsProps> = ({ articleId }) => {
    const [upvotes, setUpvotes] = useState(0);
    const [downvotes, setDownvotes] = useState(0);
    const [userVote, setUserVote] = useState<"like" | "dislike" | null>(null);
    const { toast } = useToast();

    useEffect(() => {
        getArticleReactions(articleId)
            .then((data) => {
                console.log("data", data);
                setUpvotes(data.counts.likes);
                setDownvotes(data.counts.dislikes);
                setUserVote(data.user_reaction);
            })
            .catch((error) => console.error("Error fetching reactions:", error));
    }, [articleId]);

    const handleVote = async (voteType: "like" | "dislike") => {
        console.log("articleId", articleId);
        console.log("voteType", voteType);
        if (userVote === voteType) {
            // User is un-voting
            setUserVote(null);
            if (voteType === "like") {
                setUpvotes(upvotes - 1);
            } else {
                setDownvotes(downvotes - 1);
            }
        } else {
            // User is voting or changing vote
            if (userVote) {
                // Change vote
                if (userVote === "like") {
                    setUpvotes(upvotes - 1);
                }
                if (userVote === "dislike") {
                    setDownvotes(downvotes - 1);
                }
            }
            setUserVote(voteType);
            if (voteType === "like") {
                setUpvotes(upvotes + 1);
            } else {
                setDownvotes(downvotes + 1);
            }
        }

        try {
            await reactToArticle(articleId, voteType);
        } catch (error) {
            console.error("Error voting:", error);
            // Revert the vote if the API call fails
            toast({
                title: "Error",
                description: "Failed to register your vote. Please try again.",
                variant: "destructive",
            });
            // Revert the local state
            if (voteType === "like") {
                setUpvotes((prevUpvotes) => prevUpvotes - 1);
            } else {
                setDownvotes((prevDownvotes) => prevDownvotes - 1);
            }
            setUserVote(null);
        }
    };

    return (
        <div className="flex items-center space-x-4">
            <button
                onClick={() => handleVote("like")}
                className={`flex items-center space-x-2 px-4 py-2 rounded-full ${
                    userVote === "like" ? "bg-green-500 text-white" : "bg-gray-200 text-gray-700"
                } transition-colors duration-200`}
            >
                <ThumbsUp size={20} />
                <span>{upvotes}</span>
            </button>
            <button
                onClick={() => handleVote("dislike")}
                className={`flex items-center space-x-2 px-4 py-2 rounded-full ${
                    userVote === "dislike" ? "bg-red-500 text-white" : "bg-gray-200 text-gray-700"
                } transition-colors duration-200`}
            >
                <ThumbsDown size={20} />
                <span>{downvotes}</span>
            </button>
        </div>
    );
};

export default VoteButtons;
