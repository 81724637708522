import React, { useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { X } from "lucide-react";
import TrainedModelManager from "../../TrainedModelManager";

interface ModelsDrawerProps {
    isDrawerOpen: boolean;
    setIsDrawerOpen: (value: boolean) => void;
}

const ModelsDrawer: React.FC<ModelsDrawerProps> = ({ isDrawerOpen, setIsDrawerOpen }) => {
    const handleClose = useCallback(() => setIsDrawerOpen(false), [setIsDrawerOpen]);

    return (
        <AnimatePresence>
            {isDrawerOpen && (
                <>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className="fixed inset-0 z-40 bg-black bg-opacity-50"
                        onClick={handleClose}
                    />
                    <motion.div
                        initial={{ x: "100%" }}
                        animate={{ x: 0 }}
                        exit={{ x: "100%" }}
                        transition={{ type: "spring", stiffness: 300, damping: 30 }}
                        className="fixed right-0 top-0 z-50 h-full w-full max-w-[500px] bg-narkis-bg shadow-lg"
                    >
                        <div className="flex h-full flex-col">
                            <div className="flex items-center justify-between border-b border-narkis-border-darker p-4">
                            <h2 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-400 via-purple-400 to-indigo-400">Your Models</h2>
                                <button
                                    onClick={handleClose}
                                    className="rounded-full p-1 text-narkis-text hover:bg-narkis-bg-darker"
                                    aria-label="Close drawer"
                                >
                                    <X size={24} />
                                </button>
                            </div>
                            <div className="flex-grow overflow-y-auto p-4">
                                <TrainedModelManager />
                            </div>
                        </div>
                    </motion.div>
                </>
            )}
        </AnimatePresence>
    );
};

export default ModelsDrawer;
