import ReactGA from 'react-ga4';

export const initGA = () => {
    const trackingId = "G-5QVT06BFCW"; // Replace with your GA4 Measurement ID
    ReactGA.initialize(trackingId);
    console.log("Google Analytics initialized with ID:", trackingId);
};

export const logPageView = (page: string) => {
    ReactGA.send({ hitType: "pageview", page });
    console.log("Page view logged:", page);
};

export const logEvent = (category: string, action: string, label?: string) => {
    ReactGA.event({
        category,
        action,
        label,
    });
    console.log("Event logged:", { category, action, label });
};

export const logException = (description: string, fatal: boolean = false) => {
    ReactGA.event({
        category: 'Exception',
        action: 'Error',
        label: description,
        nonInteraction: true,
    });
    console.log("Exception logged:", { description, fatal });
};