import React, { useState, useEffect, useRef } from "react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../shadcn/ui/select";
import { ToggleGroup, ToggleGroupItem } from "../../../shadcn/ui/toggle-group";
import { HeartFilledIcon, HeartIcon } from "@radix-ui/react-icons";
import { Trash2 as Trash2Icon, Loader2 } from "lucide-react";
import { useLocalStorageState } from "../../../../hooks/useLocalStorageState";
import { useToast } from "../../../shadcn/ui/use-toast";
import ImageViewer from "../../narkis-ui/image-viewer";
import { listFalImages, likeFalImage, unlikeFalImage, deleteFalImages } from "../../../../services/api";
import StatusLabel from "../generate/ImageStatusLabel";

interface FalImageMenuProps {
    validModels: any[];
    images: any[];
    onImageUpdate: (updatedImages: any[]) => void;
    isLoggedIn: boolean;
}

const FalImageMenu: React.FC<FalImageMenuProps> = ({ validModels, images, onImageUpdate, isLoggedIn }) => {
    const [localImages, setLocalImages] = useState<any[]>([]);

    useEffect(() => {
        if (!isLoggedIn) {
            const storedImages = JSON.parse(localStorage.getItem('generatedImages') || '[]');
            setLocalImages(storedImages);
        }
    }, [isLoggedIn]);

    const handleLocalImageDelete = (imageId: string) => {
        const updatedImages = localImages.filter(img => img.id !== imageId);
        setLocalImages(updatedImages);
        localStorage.setItem('generatedImages', JSON.stringify(updatedImages));
    };
    const { toast } = useToast();
    const [sortBy, setSortBy] = useLocalStorageState("falImageGen_sortBy", "newest");
    const [likedOnly, setLikedOnly] = useLocalStorageState("falImageGen_likedOnly", false);
    const [selectedGridSize, setSelectedGridSize] = useLocalStorageState("falImageGen_selectedGridSize", 4);
    const [selectedImage, setSelectedImage] = useState<any>(null);

    const [pageToken, setPageToken] = useState(1);
    const [pageSize] = useState(20);
    const [loading, setLoading] = useState(false);
    const [hasMoreImages, setHasMoreImages] = useState(true);
    const lastImageRef = useRef(null);

    const loadImages = async (reset = false) => {
        if (!isLoggedIn || loading || (!hasMoreImages && !reset)) return;
        setLoading(true);
        try {
            const response = await listFalImages("", reset ? 1 : pageToken, pageSize, sortBy, likedOnly);
            const newImages = response.data;
            if (newImages.length === 0) {
                setHasMoreImages(false);
            } else {
                setPageToken(reset ? 2 : pageToken + 1);
                onImageUpdate(reset ? newImages : [...images, ...newImages]);
            }
        } catch (error) {
            console.error("Error loading images:", error);
            toast({
                variant: "destructive",
                title: "Failed to load images",
                description: "Please try again later.",
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isLoggedIn) {
            loadImages(true);
        }
    }, [sortBy, likedOnly, isLoggedIn]);

    // the container that holds the image grid
    const container = useRef(null);

    useEffect(() => {
        if (!isLoggedIn) return;

        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && !loading) {
                    loadImages();
                }
            },
            {
                threshold: 0.1,
                root: container.current,
                rootMargin: "0px 0px 250% 0px",
            }
        );

        if (lastImageRef.current) {
            observer.observe(lastImageRef.current);
        }

        return () => {
            if (lastImageRef.current) {
                observer.unobserve(lastImageRef.current);
            }
        };
    }, [loading, hasMoreImages]);

    const handleLike = async (imageId: string) => {
        try {
            await likeFalImage(imageId);
            // setImages(images.map((img) => (img.id === imageId ? { ...img, is_liked: true } : img)));
            onImageUpdate(images.map((img) => (img.id === imageId ? { ...img, is_liked: true } : img)));
        } catch (error) {
            console.error("Error liking image:", error);
            toast({
                variant: "destructive",
                title: "Failed to like image",
                description: "Please try again later.",
            });
        }
    };

    const handleUnlike = async (imageId: string) => {
        try {
            await unlikeFalImage(imageId);
            // setImages(images.map((img) => (img.id === imageId ? { ...img, is_liked: false } : img)));
            onImageUpdate(images.map((img) => (img.id === imageId ? { ...img, is_liked: false } : img)));
        } catch (error) {
            console.error("Error unliking image:", error);
            toast({
                variant: "destructive",
                title: "Failed to unlike image",
                description: "Please try again later.",
            });
        }
    };

    const handleDelete = async (imageId: string) => {
        try {
            await deleteFalImages([imageId]);
            // setImages(images.filter((img) => img.id !== imageId));
            onImageUpdate(images.filter((img) => img.id !== imageId));
            toast({
                title: "Image deleted successfully",
            });
        } catch (error) {
            console.error("Error deleting image:", error);
            toast({
                variant: "destructive",
                title: "Failed to delete image",
                description: "Please try again later.",
            });
        }
    };

    return (
        <div className="text-narkis-text bg-gradient-to-br from-neutral-900 to-neutral-800 border-narkis-border-darker2 border rounded-md">
            {/* image menu header */}
            <div
                className="
                sticky z-10 flex justify-between items-center 
                gap-0.5  h-8 w-full p-2
                "
            >
                <div className="flex items-center">
                    <ToggleGroup
                        type="single"
                        value={likedOnly ? "favorite" : "all"}
                        onValueChange={(value) => setLikedOnly(value === "favorite")}
                    >
                        <ToggleGroupItem value="favorite" aria-label="Show favorite images">
                            <HeartFilledIcon className="h-5 w-5 text-narkis-icons-favorite" />
                        </ToggleGroupItem>
                    </ToggleGroup>
                </div>


                <div className="flex items-center gap-1">
                    <Select onValueChange={setSortBy} value={sortBy}>
                        <SelectTrigger
                            aria-label="Sort Images"
                            className="border-narkis-border-lighter hover:border-narkis-border bg-narkis-bg rounded-[7.5px] h-7 px-2"
                        >
                            <SelectValue placeholder="Sort by" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="newest">Newest</SelectItem>
                            <SelectItem value="oldest">Oldest</SelectItem>
                        </SelectContent>
                    </Select>
                    <Select onValueChange={(value) => setSelectedGridSize(parseInt(value))}>
                        <SelectTrigger
                            aria-label="Select Grid Size"
                            className="border-narkis-border-lighter hover:border-narkis-border bg-narkis-bg rounded-[7.5px] h-7 px-2"
                        >
                            <SelectValue placeholder={`grid`} defaultValue={selectedGridSize} />
                        </SelectTrigger>
                        <SelectContent>
                            {[3, 4, 5, 6].map((size) => (
                                <SelectItem key={size} value={size.toString()}>
                                    {size}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
            </div>

            {/* image grid */}
            <div
                ref={container}
                className={`grid grid-cols-${selectedGridSize} gap-0.5 max-h-[calc(100vh-132px)] pt-1 rounded-[5px] overflow-y-auto`}
                style={{ scrollbarWidth: "thin" }}
            >
                {(isLoggedIn ? images : images).length > 0 ? (
                    (isLoggedIn ? images : images).map((image: any, index: any) => (
                    <div
                        key={image.id}
                        className="relative w-full aspect-square group hover:brightness-105 transition-brightness duration-200"
                        ref={index === (isLoggedIn ? images : images).length - 1 ? lastImageRef : null}
                    >
                        <div className="w-full h-full">
                            {image.is_deleted ? (
                                <div className="w-full h-full flex justify-center items-center border border-gray-800 rounded-[5px]">
                                    <div className="text-gray-500">Image deleted</div>
                                </div>
                            ) : image.status === "COMPLETED" ? (
                                <div className="relative w-full h-full overflow-hidden">
                                    <img
                                        key={image.id}
                                        src={image.url}
                                        alt={`Generated image ${index + 1}`}
                                        className="w-full h-full object-cover absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                                        loading="lazy"
                                        onClick={() => setSelectedImage(image)}
                                    />
                                    <div className="absolute bottom-1 right-1 z-10">
                                        {/* <div
                                            className="text-xs p-1 rounded border-2 border-black font-extrabold
                                            bg-yellow-400 text-black opacity-85 hover:opacity-100 transition-opacity cursor-pointer"
                                        >
                                            {image.inference_time}
                                        </div> */}
                                    </div>

                                    {/* Favorite heart icon */}
                                    <div
                                        className={`absolute top-1 left-1 transition-opacity duration-200 ${
                                            image.is_liked ? "opacity-100" : "opacity-50 group-hover:opacity-100"
                                        }`}
                                    >
                                        <button
                                            className="p-1"
                                            onClick={() => (image.is_liked ? handleUnlike(image.id) : handleLike(image.id))}
                                        >
                                            {image.is_liked ? (
                                                <HeartFilledIcon className="h-5 w-5 text-red-400 animate-[pulse_2s_ease-in-out_infinite]" />
                                            ) : (
                                                <HeartIcon className="rounded-full h-5 w-5 text-white hover:text-red-700 transition-colors" />
                                            )}
                                        </button>
                                    </div>

                                    {/* Delete button */}
                                    <div className="absolute top-1 right-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                                        <button
                                            className="p-1 rounded-full transition-colors"
                                            onClick={() => isLoggedIn ? handleDelete(image.id) : handleLocalImageDelete(image.id)}
                                        >
                                            <Trash2Icon className="h-5 w-5 text-red-700 hover:text-red-500 transition-colors" />
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className="w-full h-full flex justify-center items-center border border-gray-800 rounded-[5px]">
                                    <StatusLabel status={image.status} />
                                </div>
                            )}
                        </div>
                    </div>
                    ))
                ) : (
                    // Placeholder grid items when there are no images
                    Array.from({ length: 12 }).map((_, index) => (
                        <div
                            key={index}
                            className="relative w-full aspect-square bg-neutral-950 rounded-[5px]"
                        >
                            <div className="absolute inset-0 flex items-center justify-center text-neutral-400">
                                <svg
                                    className="w-12 h-12"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                                    />
                                </svg>
                            </div>
                        </div>
                    ))
                )}
            </div>

            {loading && images.length > 0 && (
                <div className="col-span-full flex justify-center items-center p-4">
                    <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
                </div>
            )}

            {selectedImage && (
                <ImageViewer
                    image={{
                        image_id: selectedImage.id,
                        image_url: selectedImage.url,
                        time_elapsed: selectedImage.time_elapsed || 0,
                        ...selectedImage,
                    }}
                    onClose={() => setSelectedImage(null)}
                    onPrevious={() => {
                        const currentIndex = images.findIndex((img) => img.id === selectedImage.id);
                        if (currentIndex > 0) {
                            setSelectedImage(images[currentIndex - 1]);
                        }
                    }}
                    onNext={() => {
                        const currentIndex = images.findIndex((img) => img.id === selectedImage.id);
                        if (currentIndex < images.length - 1) {
                            setSelectedImage(images[currentIndex + 1]);
                        }
                    }}
                />
            )}

            {/* Grid classes for Tailwind */}
            <div className="hidden grid-cols-3 grid-cols-4 grid-cols-5 grid-cols-6"></div>
        </div>
    );
};

export default FalImageMenu;
