import React, { useEffect, useState, startTransition } from "react";
import "./Pricing.css"; // Make sure to link the CSS file
import Page from "../../../Page";
import { create_stripe_checkout_session, listProducts } from "../../../../services/api";
import { useToast } from "../../../shadcn/ui/use-toast";
import { BadgeCheck, LockKeyholeIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
import { logEvent, logException } from "../../../../utils/analytics";
import { Metadata } from "../../../types/pageMetadata";

import visaLogo from "../../../../assets/visa.svg";
import mastercardLogo from "../../../../assets/mastercard.svg";
import jcbLogo from "../../../../assets/jcb.svg";
import amexLogo from "../../../../assets/amex.svg";
import discoverLogo from "../../../../assets/discover.svg";
import unionpayLogo from "../../../../assets/unionpay.svg";
import dinersLogo from "../../../../assets/diners.svg";

//CAVALIER CAVALIER CAVALIER   CAVALIER CAVALIER CAVALIER   CAVALIER CAVALIER CAVALIER
//CAVALIER CAVALIER CAVALIER   CAVALIER CAVALIER CAVALIER   CAVALIER CAVALIER CAVALIER
//CAVALIER CAVALIER CAVALIER   CAVALIER CAVALIER CAVALIER   CAVALIER CAVALIER CAVALIER
//CAVALIER CAVALIER CAVALIER   CAVALIER CAVALIER CAVALIER   CAVALIER CAVALIER CAVALIER
//CAVALIER CAVALIER CAVALIER   CAVALIER CAVALIER CAVALIER   CAVALIER CAVALIER CAVALIER

const pricingMetadata: Metadata = {
    title: "Pricing - Narkis.ai | AI-Powered Image Generation",
    description:
        "Choose the plan that fits your needs. Upload photos and let Narkis.ai's advanced AI generate incredible images beyond your imagination.",
    h1: "AI Personal Photo Creation Plans | Narkis.ai",
    canonical: "https://narkis.ai/pricing",
    keywords: [
        "Custom AI Photography",
        "Personal Branding Service",
        "Affordable AI Portraits",
        "AI Photography Subscription",
        "Buy AI Photos",
        "Hire AI Photographer",
        "Hire Personal Photographer",
        "Personal Photoshoot Online",
        "Cheap AI Photography",
        "Cheap Photoshoot",
        "Cheap AI Headshots",
        "Cheap AI Portraits",
    ],
    author: "Narkis.ai",
    robots: "index, follow",
    ogTitle: "Narkis.ai - Flexible Pricing for Advanced AI Image Generation",
    ogDescription:
        "Transform your photos into stunning AI-generated images using cutting-edge technology. Find the perfect package for your creative needs.",
    ogImage: "summary-large-pricing-price-usd.png", // TODO: Update with CloudFront URL
    ogUrl: "https://narkis.ai/pricing",
    twitterCard: "summary_large_image",
    twitterTitle: "Narkis.ai Pricing - Unlock Advanced AI Image Creation",
    twitterDescription:
        "Transform your photos into stunning AI-generated images using cutting-edge technology. Find the perfect package for your creative needs.",
    twitterImage: "summary-large-pricing-price-usd.png", // TODO: Update with CloudFront URL
    twitterCreator: "@narkisai",
};

const PricingPage = () => {
    const { t } = useTranslation("pricing");

    const { toast } = useToast();

    const [paymentsEnabled, setPaymentsEnabled] = useState(false);

    const getTiers = async () => {
        const productsResponse = await listProducts();
        const response = productsResponse.data;
        console.log(response);
        // Map product names to tier names to ensure correct ordering
        const productMap = {
            spark: response.products.find((p: any) => p.name === "spark"),
            flow: response.products.find((p: any) => p.name === "flow"),
            avantgarde: response.products.find((p: any) => p.name === "avantgarde"),
            zenith: response.products.find((p: any) => p.name === "zenith"),
        };

        setPaymentsEnabled(response.payments_enabled);

        return [
            {
                id: productMap.spark.id,
                name: t("plans.planNameTier1"),
                tag: t("tags.tier1"),
                description: t("plans.descriptionTier1"),
                perks: [t("perks.perkHQImages")],
                price: productMap.spark.price_usd,
                discount: productMap.spark.discount_price_usd,
                discountEnabled: productMap.spark.discounts_enabled,
                credits: productMap.spark.credits,
                bonusCredits: productMap.spark.bonus_credits,
                bonusCreditsEnabled: productMap.spark.bonus_credits_enabled,
                models: productMap.spark.monthly_trainings,
                className: "spark",
                creditsClassName: "sapphire",
                //image: sparkImage,
                imageUrl: "../../../eso-seed-of-life.webp",
                internal_id: productMap.spark.id,
                // how many images can be generated with this plan, roughly
                imgCount: "200 - 300",
            },
            {
                id: productMap.flow.id,
                name: t("plans.planNameTier2"),
                tag: t("tags.tier2"),
                description: t("plans.descriptionTier2"),
                perks: [t("perks.perkHQImages")],
                price: productMap.flow.price_usd,
                discount: productMap.flow.discount_price_usd,
                discountEnabled: productMap.flow.discounts_enabled,
                credits: productMap.flow.credits,
                bonusCredits: productMap.flow.bonus_credits,
                bonusCreditsEnabled: productMap.flow.bonus_credits_enabled,
                models: productMap.flow.monthly_trainings,
                className: "flow",
                creditsClassName: "emerald",
                //image: flowImage,
                imageUrl: "../../../eso-flower-of-life.webp",
                internal_id: productMap.flow.id,
                imgCount: "800 - 1200",
            },
            {
                id: productMap.avantgarde.id,
                name: t("plans.planNameTier3"),
                tag: t("tags.tier3"),
                description: t("plans.descriptionTier3"),
                perks: [t("perks.perkHQImages"), t("perks.perkEarlyAccess")],
                price: productMap.avantgarde.price_usd,
                discount: productMap.avantgarde.discount_price_usd,
                discountEnabled: productMap.avantgarde.discounts_enabled,
                credits: productMap.avantgarde.credits,
                bonusCredits: productMap.avantgarde.bonus_credits,
                bonusCreditsEnabled: productMap.avantgarde.bonus_credits_enabled,
                models: productMap.avantgarde.monthly_trainings,
                className: "avantgarde",
                creditsClassName: "ruby",
                //image: avantgardeImage,
                imageUrl: "../../../eso-fruit-of-life.webp",
                internal_id: productMap.avantgarde.id,
                imgCount: "2500 - 3500",
            },

        ];
    };
    const [tiers, setTiers] = useState<any[]>([]);
    useEffect(() => {
        getTiers().then((tiers) => {
            console.log(tiers);
            setTiers(tiers);
        });
    }, []);

    const handleBuyButtonClick = async (tier: any) => {
        logEvent("Pricing", "Buy Button Clicked", tier.name);

        setIsLoading(true);
        try {
            const response = await create_stripe_checkout_session(tier.internal_id);
            setIsLoading(false);
            if (response && response.url) {
                window.location.href = response.url;
            } else {
                console.error("No redirect URL received");
                toast({
                    variant: "destructive",
                    title: t("checkoutError.title"),
                    description: t("checkoutError.description"),
                });
            }
        } catch (error) {
            console.error("Error processing payment:", error);
            logException("Payment Processing Error", true);
            toast({
                variant: "destructive",
                title: t("checkoutError.title"),
                description: t("checkoutError.description"),
            });
        } finally {
            setIsLoading(false);
        }
    };

    const [isLoading, setIsLoading] = useState(false);

    // background-color: #101010;
    // border-radius: 8px;
    // border: 1px solid rgb(45, 45, 45);
    // box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    // padding: 10px 20px 20px 20px;
    // width: 300px;
    // text-align: left;
    const PricingCard = ({ tier }: { tier: any }) => (
        <div key={tier.id} className={`plan-card ${tier.className}`}>
            {/* <img src={tier.imageUrl} alt={tier.name} /> */}
            <div className="header mb-2">
                <div className={`tag ${tier.className}`}>{tier.tag}</div>
                <h3 className="tracking-wide text-3xl font-extrabold pt-2 shadow-slate-400 w-fit ">{tier.name}</h3>
            </div>
            <div className="price">
                {tier.discountEnabled ? (
                    <>
                        <span className="currency text-lg line-through">${tier.price.toFixed(0)}</span>
                        <span className="ml-2 currency font-bold bg-gradient-to-tr from-gold-100 to-gold-600 bg-clip-text text-transparent">
                            ${(tier.price - tier.discount).toFixed(0)}
                        </span>
                    </>
                ) : (
                    <span className="currency">${tier.price.toFixed(0)}</span>
                )}
                <span className="per-month"> / {t("price.perMonth")}</span>
            </div>
            <div className="flex flex-row justify-between -mx-3 whitespace-nowrap font-extrabold text-gold-100 items-start">
                <div className="flex flex-row items-center">
                    <div
                        className={`flex gap-1 items-start ${
                            tier.name === "Zenith" ? "text-fruity-lemonYellow animate-neon-glow" : ""
                        } ${tier.name === "Avant-garde" ? "animate-red-neon-ultra-glow" : ""} ${
                            tier.name === "Avant-garde" ? "" : ""
                        }`}
                    >
                        {tier.creditsClassName === "sapphire" && (
                            <img
                                src="data:image/svg+xml;utf8,<svg width='26px' height='26px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M12 3L3 14L12 21L21 14L12 3Z' stroke='%2300bfff' stroke-linecap='round' stroke-linejoin='round'/><path d='M12 3L17 15L12 21' stroke='%2300bfff' stroke-linecap='round' stroke-linejoin='round'/><path d='M12 3L7 15L12 21' stroke='%2300bfff' stroke-linecap='round' stroke-linejoin='round'/><path d='M3 14L7 15H17L21 14' stroke='%2300bfff' stroke-linecap='round' stroke-linejoin='round'/></svg>"
                                alt="sapphire icon"
                                className="w-7 h-7 animate-[sapphire-radiance_2s_infinite_alternate]"
                            />
                        )}
                        {tier.creditsClassName === "ruby" && (
                            <img
                                src="data:image/svg+xml;utf8,<svg fill='%23ff1a1a' width='20px' height='20px' viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg'><title>ruby</title><path d='M7.415 7.344c4.45-4.415 10.193-7.024 12.399-4.799 2.202 2.221-0.131 7.629-4.59 12.042-4.453 4.414-10.126 7.165-12.326 4.945-2.207-2.221 0.056-7.769 4.514-12.184zM1.004 24.859v0zM26.192 1.108c2.718 0.255 4.828 2.525 4.828 5.288 0 0.237-0.016 0.471-0.046 0.7l0.003-0.027 0.019-0.039-1.706 22.354-22.155 1.52c-0.197 0.025-0.426 0.04-0.658 0.04-3.036 0-5.498-2.461-5.498-5.498 0-0.184 0.009-0.367 0.027-0.546l-0.002 0.023 2.056-3.749 4.151 9.695 3.505-11.427-0.037 0.009 0.02-0.037 11.565 3.694-1.744-6.787-1.237-4.874 11.022-0.711-0.769-0.637-7.913-6.451 4.572-2.55z'></path></svg>"
                                alt="ruby icon"
                                className="w-5 h-5 animate-[ruby-glisten_3s_infinite]"
                            />
                        )}
                        {tier.creditsClassName === "emerald" && (
                            <img
                                src="data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'><path fill='%2300ff00' stroke='none' d='M310.375 16.75L89.405 75.72l58.126 50.905L282.563 90.28l2.032-.53 25.78-73zm17.063 7.844l-27.157 76.812 91.69 91.875 95.624-8.78L327.438 24.594zm-41.813 12.062l-8.594 33.657c-.28-15.516-38.03-17.018-107.56-4.376l116.155-29.28zm51.063 14.625l123.5 123.407-58.844 7.563c16.2-21.37-32.277-91.112-64.656-130.97zM74.75 87.72L15.594 308.405l79-31.47 37.28-139.155L74.75 87.72zm207.438 22l-133.032 35.81-35.72 133.376 97.25 97.53 133.064-35.81 35.72-133.376-97.283-97.53zm-201.72 5.686l32.844 30.5-30.156 118.97-39.03 15.812c50.817-30.543 65.667-130.132 36.343-165.282zm195.876 14.78L359 213.377l-30.156 113.81-44.688 11.97c119.527-107.872-34.816-238.375-131.5-140.875l9.875-37.405 113.814-30.688zM490.564 203l-92.877 8.53-35.968 134.19 71.342 71.842L490.563 203zm-17.283 13.875L444.03 333.03c6.73-68.874-.03-90.85-30.655-111.5l59.906-4.655zm-371.155 77.188L20.22 326.688l161.75 161.468 17.31-96.72-97.155-97.373zm.094 20l78.124 82.437-7.438 61.375c-5.23-44.565-28.34-85.92-70.687-143.813zm246.124 44.687l-130.53 35.125-17.564 98.188 221.688-59.157-73.594-74.156zm18.625 42.5l24.28 24.844-115.22 32.72c61.28-26.446 83.34-37.418 90.94-57.564z'/></svg>"
                                alt="emerald icon"
                                className="w-6 h-6 animate-[emerald-glow_2s_infinite]"
                            />
                        )}
                        {tier.creditsClassName === "diamond" && (
                            <img
                                src="data:image/svg+xml;utf8,<svg stroke='none' fill='%2300bfff' width='24px' height='24px' viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg'><path d='M2.103 12.052l13.398 16.629-5.373-16.629h-8.025zM11.584 12.052l4.745 16.663 4.083-16.663h-8.828zM17.051 28.681l12.898-16.629h-7.963l-4.935 16.629zM29.979 10.964l-3.867-6.612-3.869 6.612h7.736zM24.896 3.973h-7.736l3.867 6.839 3.869-6.839zM19.838 10.964l-3.867-6.612-3.868 6.612h7.735zM14.839 3.973h-7.735l3.868 6.839 3.867-6.839zM5.889 4.352l-3.867 6.612h7.735l-3.868-6.612z'></path></svg>"
                                alt="diamond icon"
                                className="w-6 h-6 animate-[diamond-dazzle_4s_infinite_ease-in]"
                            />
                        )}
                        <div className="flex flex-col gap-x-1 h-14">
                            {tier.credits.toLocaleString()} {t("price.monthlyCredits")}
                            {tier.bonusCreditsEnabled && (
                                <span className="bonus-credits bg-gradient-to-tl from-gold-100 to-gold-600 bg-clip-text text-transparent">
                                    + {tier.bonusCredits.toLocaleString()} {t("price.bonusCredits")}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex flex-row items-start gap-1">
                    <img src={require("../../../../assets/models.svg").default} alt="models icon" className="w-9 h-9 -mt-1.5" />
                    {tier.models === 1 ? t("price.monthlyModel") : `${tier.models} ${t("price.monthlyModels")}`}
                </div>
            </div>
            <div className="pt-0 pb-4 h-36 text-sm text-stone-200 font-semibold">{tier.description}</div>
            <hr className="mb-5 border-narkis-border-darker"></hr>
            <div className="h-32 text-narkis-text">
                <ul>
                    <li className="mb-1 flex items-center cursor-pointer hover:text-narkis-text-bright">
                        <BadgeCheck className="w-5 h-5 mr-1" />
                        {tier.imgCount} {t("price.monthlyImgCount")}
                    </li>
                    {tier.perks.map((perk: any) => (
                        <li className="mb-1 flex items-center cursor-pointer hover:text-narkis-text-bright">
                            <BadgeCheck className="w-5 h-5 mr-1" />
                            {perk}
                        </li>
                    ))}
                </ul>
            </div>

            <div className={`button-container ${tier.className}`}>
                {paymentsEnabled ? (
                    <button onClick={() => handleBuyButtonClick(tier)} className={`${tier.className} btn`}>
                        {t("buttonBuy")}
                    </button>
                ) : (
                    <button disabled className={`${tier.className} btn`}>
                        {t("buttonComingSoon")}
                    </button>
                )}
            </div>
        </div>
    );

    return (
        <Page {...pricingMetadata}>
            <div className="flex flex-col-reverse md:flex-col w-full">
                <div className="w-full bg-narkis-bg-darker2 py-4 shadow-lg">
                    <div className="container mx-auto text-center">
                        <h2 className="text-xl md:text-3xl font-extrabold text-narkis-text-bright tracking-wider">
                            🎉 Launch Event: Enjoy <span className="text-yellow-300">40% OFF</span> on all plans! 🎉
                        </h2>
                        <p className="text-lg md:text-xl text-narkis-text-bright mt-2">
                            Use code <span className="font-bold text-yellow-300">NARKIS27</span> at checkout. Limited time offer!
                        </p>
                    </div>
                </div>

                {isLoading && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center z-50">
                        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-fruity-lemonYellow"></div>
                        {t("loading.mainLabel")}
                    </div>
                )}
                <div
                    className="
                text-white gap-0.5 p-8
                flex flex-col sm:max-h-[calc(100vh-80px)] overflow-y-auto w-full
                "
                >
                    <h2 className="text-center text-4xl mb-3">{t("title")}</h2>
                    <p className="text-center text-xl mb-10 text-fruity-lemonYellow">{t("subtitle")}</p>

                    <div className="flex flex-col-reverse md:flex-col">
                        <div className="flex  flex-row justify-center gap-3 flex-wrap overflow-auto">
                            {tiers.map((tier: any) => (
                                <PricingCard key={tier.id} tier={tier} />
                            ))}
                        </div>
                        <div className="justify-center w-full px-2 flex flex-col md:flex-row items-center md:gap-4">
                            <div className="flex flex-row items-center justify-end gap-1 md:w-1/2 hover:animate-neon-glow">
                                <LockKeyholeIcon className="text-stone-300 w-10 h-10 md:w-4 md:h-4" />
                                <p className="text-stone-300 text-balance text-center font-semibold text-xs ">
                                    {t("checkoutSecurity")}
                                </p>
                                <div className="py-2 bg-gray-800 text-stone-200 px-2 h-[22px] rounded-[5px] border border-gray-700 shadow-md flex items-center justify-center hover:animate-neon-glow">
                                    <span className="-ml-3 -mr-2.5 text-xs scale-75 text-nowrap font-medium">
                                        {t("poweredBy")}
                                    </span>
                                    <span className="font-bold ml-1 -mr-1 tracking-tighter pb-0.5">stripe</span>
                                </div>
                            </div>
                            <div className="flex flex-row items-start justify-start gap-1 md:w-1/2">
                                <div className="grid grid-cols-7 gap-1 w-full md:hidden">
                                    <div className="flex justify-center items-center">
                                        <img
                                            src={visaLogo}
                                            alt="Visa Logo"
                                            className="w-full h-full object-contain rounded-[2.5px] hover:brightness-110 hover:scale-110 transition-all duration-200"
                                        />
                                    </div>
                                    <div className="flex justify-center items-center">
                                        <img
                                            src={mastercardLogo}
                                            alt="Mastercard Logo"
                                            className="w-full h-full object-contain rounded-[2.5px] hover:brightness-110 hover:scale-110 transition-all duration-200"
                                        />
                                    </div>
                                    <div className="flex justify-center items-center">
                                        <img
                                            src={amexLogo}
                                            alt="Amex Logo"
                                            className="w-full h-full object-contain rounded-[2.5px] hover:brightness-110 hover:scale-110 transition-all duration-200"
                                        />
                                    </div>
                                    <div className="flex justify-center items-center">
                                        <img
                                            src={jcbLogo}
                                            alt="JCB Logo"
                                            className="w-full h-full object-contain rounded-[2.5px] hover:brightness-110 hover:scale-110 transition-all duration-200"
                                        />
                                    </div>
                                    <div className="flex justify-center items-center">
                                        <img
                                            src={unionpayLogo}
                                            alt="Unionpay Logo"
                                            className="w-full h-full object-contain rounded-[2.5px] hover:brightness-110 hover:scale-110 transition-all duration-200"
                                        />
                                    </div>
                                    <div className="flex justify-center items-center">
                                        <img
                                            src={discoverLogo}
                                            alt="Discover Logo"
                                            className="w-full h-full object-contain rounded-[2.5px] hover:brightness-110 hover:scale-110 transition-all duration-200"
                                        />
                                    </div>
                                    <div className="flex justify-center items-center">
                                        <img
                                            src={dinersLogo}
                                            alt="Diners Logo"
                                            className="w-full h-full object-contain rounded-[2.5px] hover:brightness-110 hover:scale-110 transition-all duration-200"
                                        />
                                    </div>
                                </div>
                                <div className="hidden md:flex flex-row items-start justify-start gap-1 w-full">
                                    <img
                                        src={visaLogo}
                                        alt="Visa Logo"
                                        className="w-8 h-8 rounded-[2.5px] hover:brightness-110 hover:scale-110 transition-all duration-200"
                                    />
                                    <img
                                        src={mastercardLogo}
                                        alt="Mastercard Logo"
                                        className="w-8 h-8 rounded-[2.5px] hover:brightness-110 hover:scale-110 transition-all duration-200"
                                    />
                                    <img
                                        src={amexLogo}
                                        alt="Amex Logo"
                                        className="w-8 h-8 rounded-[2.5px] hover:brightness-110 hover:scale-110 transition-all duration-200"
                                    />
                                    <img
                                        src={jcbLogo}
                                        alt="JCB Logo"
                                        className="w-8 h-8 rounded-[2.5px] hover:brightness-110 hover:scale-110 transition-all duration-200"
                                    />
                                    <img
                                        src={unionpayLogo}
                                        alt="Unionpay Logo"
                                        className="w-8 h-8 rounded-[2.5px] hover:brightness-110 hover:scale-110 transition-all duration-200"
                                    />
                                    <img
                                        src={discoverLogo}
                                        alt="Discover Logo"
                                        className="w-8 h-8 rounded-[2.5px] hover:brightness-110 hover:scale-110 transition-all duration-200"
                                    />
                                    <img
                                        src={dinersLogo}
                                        alt="Diners Logo"
                                        className="w-8 h-8 rounded-[2.5px] hover:brightness-110 hover:scale-110 transition-all duration-200"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
};

export default PricingPage;
