import React, { useState, useEffect, startTransition } from "react";
import Page from "../../../Page";
import Header from "../../../Header";
import logo from "../../../../assets/logo.png";
import waves from "../../../../assets/waves.svg";
import waves2 from "../../../../assets/waves2.svg";
import { useNavigate } from "react-router-dom";
import { listExampleUserPhotos } from "../../../../services/api";
import landinghero from "../../../../assets/landing_bg.jpg";
import * as Tooltip from "@radix-ui/react-tooltip";
import * as Popover from "@radix-ui/react-popover";
import Newsletter from "../blog/newsletter";
import {
    ArrowBigRight,
    ArrowBigRightDashIcon,
    LockKeyholeIcon,
    LucideArrowBigDownDash,
    LucideArrowBigLeft,
    LucideArrowBigRight,
} from "lucide-react";
import { Card, CardHeader, CardDescription, CardContent, CardTitle, CardFooter } from "../../../shadcn/ui/card";

import { useTranslation } from "react-i18next";

import { FAQ } from "./faq";

import { Metadata } from "../../../types/pageMetadata";
import Footer from "../../Footer";
import Hero from "./hero";

const landingMetadata: Metadata = {
    title: "Narkis.ai - Create Stunning AI-Generated Images of Yourself",
    description:
        "Narkis.ai - AI-Powered Personal Photography Studio. Create stunning, lifelike photographs of yourself in various styles without a physical photoshoot.",
    h1: "Narkis.ai - Create Stunning AI-Generated Images of Yourself",
    canonical: "https://narkis.ai",
    keywords: [
        "Narkis.ai",
        "AI Photography",
        "AI Portrait Generation",
        "Custom AI Photography",
        "AI Photos of real people",
        "AI Photos of yourself",
        "AI Selfie Generator",
        "AI Headshots",
        "AI-powered Photoshoot",
        "Virtual Photography",
        "AI Generated Photos",
        "Personal Branding",
        "AI Image Generation",
        "Custom AI Model Training",
        "AI Model Fine-tuning",
        "Text-to-Image AI",
        "Generative AI",
        "AI Image Editing",
        "AI Profile Pictures",
        "Personalized AI Portraits",
    ],
    author: "Narkis.ai",
    robots: "index, follow",
    ogTitle: "Narkis.ai - AI-Powered Personal Photography Studio",
    ogDescription:
        "Create personalized, high-quality AI-generated photos for social media, professional use, and more. Create your own AI model now.",
    ogImage: "summary-large-landing.png", // TODO: Update with actual CloudFront URL
    ogUrl: "https://narkis.ai",
    twitterCard: "summary_large_image",
    twitterTitle: "Narkis.ai - AI-Powered Personal Photography Studio",
    twitterDescription:
        "Create personalized, high-quality AI-generated photos for social media, professional use, and more. Create your own AI model now.",
    twitterImage: "summary-large-landing.png", // TODO: Update with actual CloudFront URL
    twitterCreator: "@narkisai",
};

export default function Landing() {
    const navigate = useNavigate();
    const { t } = useTranslation("landing");

    const [exampleUsers, setExampleUsers] = useState<any>([]);
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [heroUser, setHeroUser] = useState<any>(null);
    const [selectedGeneratedPhoto, setSelectedGeneratedPhoto] = useState<any>(null);
    const [heroGeneratedPhoto, setHeroGeneratedPhoto] = useState<any>(null);
    const [isNewUserView, setIsNewUserView] = useState(true);
    const allGeneratedPhotos = exampleUsers.flatMap((user: any) => user.generated_photos);

    const imageNames = [
        "narkis_engine.jpg",

        // user 1
        "user_1_main.jpeg",

        "user_1_up_1.jpeg",
        "user_1_up_2.jpeg",
        "user_1_up_3.jpeg",

        "user_1_gen_1.jpeg",
        "user_1_gen_2.jpeg",
        "user_1_gen_3.jpeg",
        "user_1_gen_4.jpeg",
        "user_1_gen_5.jpeg",
        "user_1_gen_6.jpg",
        "user_1_gen_7.jpg",
        "user_1_gen_8.jpg",
        "user_1_gen_9.jpg",
        "user_1_gen_10.jpg",
        "user_1_gen_11.jpg",
        "user_1_gen_12.jpg",
        "user_1_gen_13.jpg",
        "user_1_gen_14.jpg",
        "user_1_gen_15.jpg",
        "user_1_gen_16.jpg",
        "user_1_gen_17.jpg",
        "user_1_gen_18.jpg",
        "user_1_gen_19.jpg",
        "user_1_gen_20.jpg",
        "user_1_gen_21.jpg",
        "user_1_gen_22.jpg",
        "user_1_gen_23.jpg",
        "user_1_gen_24.jpg",
        "user_1_gen_25.jpg",
        "user_1_gen_26.jpg",
        "user_1_gen_27.jpg",
        "user_1_gen_28.jpg",
        "user_1_gen_29.jpg",
        "user_1_gen_30.jpg",

        // user 2
        "user_2_main.jpg",

        "user_2_up_1.jpeg",
        "user_2_up_2.jpeg",
        "user_2_up_3.jpeg",

        "user_2_gen_1.jpg",
        "user_2_gen_2.jpg",
        "user_2_gen_3.jpg",
        "user_2_gen_4.jpg",
        "user_2_gen_5.jpg",
        "user_2_gen_6.jpg",
        "user_2_gen_7.jpg",
        "user_2_gen_8.jpg",
        "user_2_gen_9.jpg",
        "user_2_gen_10.jpg",
        "user_2_gen_11.jpg",
        "user_2_gen_12.jpg",
        "user_2_gen_13.jpg",
        "user_2_gen_14.jpg",
        "user_2_gen_15.jpg",
        "user_2_gen_16.jpg",
        "user_2_gen_17.jpg",
        "user_2_gen_18.jpg",
        "user_2_gen_19.jpg",
        "user_2_gen_20.jpg",
        "user_2_gen_21.jpg",
        "user_2_gen_22.jpg",
        "user_2_gen_23.jpg",
        "user_2_gen_24.jpg",
        "user_2_gen_25.jpg",
        "user_2_gen_26.jpg",
        "user_2_gen_27.jpg",
        "user_2_gen_28.jpg",
        "user_2_gen_29.jpg",
        "user_2_gen_30.jpg",
    ];

    const REACT_APP_NARKIS_PUBLIC_CLOUDFRONT_URL = process.env.REACT_APP_NARKIS_PUBLIC_CLOUDFRONT_URL;
    const images = imageNames.reduce((acc: any, imageName: string) => {
        acc[imageName.split(".")[0]] = `${REACT_APP_NARKIS_PUBLIC_CLOUDFRONT_URL}/example_users/${imageName}`;
        return acc;
    }, {});

    const narkisEngineImage = `${REACT_APP_NARKIS_PUBLIC_CLOUDFRONT_URL}/narkis_engine.jpg`;
    const narkisPublicImage = `${REACT_APP_NARKIS_PUBLIC_CLOUDFRONT_URL}/narkis_public.jpg`;

    const getGeneratedPhotos = (userId: number) => {
        return Array.from({ length: 30 }, (_, i) => ({
            image_url: images[`user_${userId}_gen_${i + 1}`],
        }));
    };

    const exampleUsersFixed = [
        {
            id: 1,
            name: "The King",
            main_photo: images.user_2_main,
            hero_upload_photo: images.user_2_up_1,
            uploaded_photos: [
                { image_url: images.user_2_up_1 },
                { image_url: images.user_2_up_2 },
                { image_url: images.user_2_up_3 },
            ],
            generated_photos: getGeneratedPhotos(2),
            hero_generated_photos: [getGeneratedPhotos(2)[10], getGeneratedPhotos(2)[0], getGeneratedPhotos(2)[8]],
        },
        {
            id: 1,
            name: "Adam",
            main_photo: images.user_1_main,
            uploaded_photos: [
                { image_url: images.user_1_up_1 },
                { image_url: images.user_1_up_2 },
                { image_url: images.user_1_up_3 },
            ],
            generated_photos: getGeneratedPhotos(1),
            hero_generated_photos: [getGeneratedPhotos(1)[1], getGeneratedPhotos(1)[1], getGeneratedPhotos(1)[3]],
        },
        // Add more users as needed
    ];

    console.log(exampleUsersFixed);

    useEffect(() => {
        startTransition(() => {
            setSelectedUser(exampleUsersFixed[0]);
            setHeroUser(exampleUsersFixed[0]);
            setExampleUsers(exampleUsersFixed);
            setSelectedGeneratedPhoto(exampleUsersFixed[0].generated_photos[0]);
            setHeroGeneratedPhoto(exampleUsersFixed[0].generated_photos[0]);
        });

        const fetchExampleUserPhotos = async () => {
            try {
                const data = await listExampleUserPhotos();
            } catch (error) {
                console.error("Failed to fetch example user photos:", error);
            }
        };

        //fetchExampleUserPhotos();
    }, []);

    // TODO: add logic to handle already logged in user
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const isMobile = window.innerWidth <= 768;

    const handlePreviousPhoto = () => {
        const currentIndex = selectedUser.generated_photos.findIndex(
            (photo: any) => photo.image_url === selectedGeneratedPhoto.image_url
        );
        if (currentIndex > 0) {
            setSelectedGeneratedPhoto(selectedUser.generated_photos[currentIndex - 1]);
        }
    };

    const handleNextPhoto = () => {
        const currentIndex = selectedUser.generated_photos.findIndex(
            (photo: any) => photo.image_url === selectedGeneratedPhoto.image_url
        );
        const nextIndex = (currentIndex + 1) % selectedUser.generated_photos.length;
        if (currentIndex < selectedUser.generated_photos.length - 1) {
            setSelectedGeneratedPhoto(selectedUser.generated_photos[nextIndex]);
        }
    };

    return (
        <Page {...landingMetadata}>
            <div className="flex flex-col items-center justify-center w-full overflow-x-hidden">
                {/* Discount Banner */}
                {/* <div className="w-full bg-narkis-bg-darker2 py-1 shadow-lg">
                    <div className="container mx-auto text-center">
                        <h2 className="text-xl md:text-2xl font-extrabold text-narkis-text-bright tracking-wider">
                            🎉 Launch Event: Enjoy <span className="text-yellow-300">40% OFF</span> on all plans! 🎉
                        </h2>
                        <p className="text-lg md:text-xl text-narkis-text-bright mt-1">
                            Use code <span className="font-bold text-yellow-300">NARKIS27</span> at checkout. Limited time offer!
                        </p>
                    </div>
                </div> */}
                {/* hero */}
                <Hero heroUser={heroUser} isMobile={isMobile} />

                <div className="h-2 bg-gradient-to-b from-stone-800 w-full to-stone-200"></div>

                {/* example user photos selector */}
                <div className="example-photos pt-2 w-full md:px-56 bg-gradient-to-b from-stone-200 via-stone-300 via-70% to-stone-400">
                    <div className="flex justify-center w-full gap-2 max-w-[1500px] mx-auto">
                        {exampleUsers.map((user: any) => (
                            <div
                                key={user.id + user.name}
                                className="flex flex-col items-center gap-1"
                                onClick={() => {
                                    setSelectedUser(user);
                                    setSelectedGeneratedPhoto(user.generated_photos[0]);
                                }}
                            >
                                <img
                                    key={user.id}
                                    src={user.main_photo} // Assuming photoUrls is an array of URLs
                                    alt={user.name}
                                    className="w-24 h-24 rounded-[7.5px] border border-gold-300 cursor-pointer aspect-square hover:border-gold-400 hover:scale-105 transition-all duration-200"
                                />
                                <p className="text-narkis-text-dark text-center font-semibold text-sm">{user.name}</p>
                            </div>
                        ))}
                    </div>
                    <hr className="my-2 mx-5 w-full border border-stone-400" />
                </div>

                <div className="py-1 bg-gradient-to-b from-stone-400 w-full to-stone-950"></div>

                <div className="bg-gradient-to-b from-stone-900 to-narkis-bg w-full shadow-lg gap-x-4 py-2 pb-12">
                    <p className="text-[#e2d6c8] font-semibold text-center text-lg w-full pb-2">{t("premiumGeneration.title")}</p>

                    <div
                        className="flex w-full
                        flex-col items-center
                        md:px-28 md:flex-row
                        "
                    >
                        <div className="md:w-1/2 flex items-center justify-end lg:pr-12">
                            <div className="flex flex-col items-center w-fit justify-start">
                                {/* user uploaded */}
                                {selectedUser && (
                                    <div className="text-narkis-text-bright font-semibold w-full">
                                        <div className="flex justify-center gap-2">
                                            {selectedUser.uploaded_photos.map((photo: any, index: any) => (
                                                <img
                                                    key={index}
                                                    src={photo.image_url}
                                                    alt={`Uploaded ${index + 1}`}
                                                    className="w-24 h-24 rounded-[7.5px] object-cover border border-narkis-border-lighter hover:brightness-110 hover:border-narkis-border-brighter transition-all duration-200"
                                                />
                                            ))}
                                        </div>
                                        <p className="text-narkis-text-bright text-center w-full">
                                            {t("premiumGeneration.uploadedPhotos")}
                                        </p>
                                    </div>
                                )}

                                {/* arrow to narkis engine */}
                                <div className="flex justify-center items-center hover:brightness-110 transition-all duration-200">
                                    <LucideArrowBigDownDash size={50} className="text-narkis-text-label" />
                                </div>

                                {/* narkis engine */}
                                <div className="relative max-w-[335px] text-narkis-text-bright font-semibold w-full items-center flex  overflow-hidden justify-center border border-narkis-border-darker2 rounded-[28px] bg-narkis-bg-darker2 hover:brightness-125 transition-all duration-200">
                                    <img src={narkisEngineImage} alt="Logo" className="" />
                                    <div className="absolute inset-0 opacity-10 animate-animate-ray-beam max-w-[65%] aspect-square rounded-[25px] pb-4 m-auto"></div>
                                </div>
                            </div>
                        </div>
                        {/* arrow to generated photos */}
                        {isMobile ? (
                            <div className="flex justify-center items-center hover:brightness-110 transition-all duration-200">
                                <LucideArrowBigDownDash size={50} className="text-narkis-text-label" />
                            </div>
                        ) : (
                            <div className="flex justify-center items-center hover:brightness-110 transition-all duration-200">
                                <ArrowBigRightDashIcon size={50} className="text-narkis-text-label" />
                            </div>
                        )}

                        <div className="w-full md:w-1/2 md:max-w-[450px] px-4 md:px-0">
                            {/* generated */}
                            <div className=" md:max-h-[600px] lg:pl-12">
                                <div className="border-narkis-border-darker2 border rounded-[7.5px] overflow-hidden ">
                                    {/* Main large photo display */}
                                    <div className="w-full relative">
                                        <img
                                            src={selectedGeneratedPhoto?.image_url}
                                            alt="Selected Generated"
                                            className="w-full md:h-full object-cover"
                                        />
                                        <div className="absolute inset-0 flex justify-between items-center">
                                            <button
                                                className="hover:brightness-150 hover:bg-opacity-25 p-4"
                                                onClick={handlePreviousPhoto}
                                            >
                                                <LucideArrowBigLeft size={30} className="text-stone-500" />
                                            </button>
                                            <button
                                                className="hover:brightness-150 hover:bg-opacity-25 p-4"
                                                onClick={handleNextPhoto}
                                            >
                                                <LucideArrowBigRight size={30} className="text-stone-500" />
                                            </button>
                                        </div>
                                    </div>
                                    {/* Thumbnails row for photo selection */}
                                    <div className="flex overflow-x-scroll mt-2 border border-narkis-border-brighter bg-narkis-bg rounded-[7.5px]">
                                        {selectedUser?.generated_photos.map((photo: any, index: any) => (
                                            <img
                                                key={index}
                                                src={photo.image_url}
                                                alt={`Generated ${index + 1}`}
                                                className="w-24 h-24 object-cover mr-2 cursor-pointer hover:brightness-110 rounded-[7.5px] hover:border hover:border-narkis-border-brighter transition-all duration-100"
                                                onClick={() => setSelectedGeneratedPhoto(photo)}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col text-xl -mb-8 w-full items-center justify-center">
                        <div
                            className="ml-4 py-2 
                        flex items-end flex-row gap-1 w-fit text-center
                        bg-gradient-to-r from-sky-300 via-fuchsia-300 to-yellow-300 via-50% bg-clip-text text-transparent font-extrabold hover:animate-color-cycle animate-gradient-x"
                        >
                            {/* <div className="bg-gray-800 text-stone-200 px-2 h-[22px] rounded-[5px] border border-gray-700 shadow-md flex items-center justify-center"> */}
                            <span className="text-xs my-1 font-medium">{t("poweredBy")}</span>
                            <span className="font-bold tracking">Narkis Engine</span>
                        </div>
                    </div>
                </div>
                <div className="w-full bg-stone-300">
                    <img src={waves} alt="Waves" className="w-full h-auto" />
                </div>

                {/* quick instructions */}
                {/* gif showing upload 15 photos */}
                {/* gif showing selecting a model and generating photos */}

                {/* features */}
                <div className="px-4 md:px-28 lg:px-56 overflow-visible bg-gradient-to-b text-narkis-text-dark from-stone-300 w-full to-stone-400">
                    <h2 className="text-3xl tracking-widest font-header text-center mb-8 md:-mt-28">{t("features.title")}</h2>
                    <div className="flex tracking-tight font-medium flex-col gap-16 items-center ">
                        <div className=" w-full flex justify-end ">
                            <div className="border pt-4 px-2 w-[600px] border-stone-400 min-h-[150px] bg-stone-900 shadow-2xl rounded-[7.5px] hover:scale-[101%] hover:brightness-125 hover:shadow-black transition-all duration-300">
                                <h3 className="font-header text-2xl font-semibold text-center text-stone-300">
                                    {t("features.photorealisticPortraits.title")}
                                </h3>
                                <div className="py-4 px-8 text-stone-300">
                                    <p className="text-justify">{t("features.photorealisticPortraits.description")}</p>
                                    <br></br>
                                    <p className="tracking-wide font-semibold bg-gradient-to-br from-fruity-peach via-50% via-fruity-mangoOrange to-fruity-tangerine bg-clip-text text-transparent -ml-1 -mr-1">
                                        {t("features.photorealisticPortraits.highlight")}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex justify-between">
                            <div className="border pt-4 w-[800px] border-stone-400 min-h-[150px] bg-stone-900 shadow-2xl rounded-[7.5px] hover:scale-[101%] hover:brightness-125 hover:shadow-black transition-all duration-300 flex flex-col overflow-hidden">
                                <h3 className="font-header text-2xl font-semibold text-center text-stone-300 mb-4">
                                    {t("features.publicImageGenerator.title")}
                                </h3>
                                <div className="flex">
                                    <div className="w-1/2 py-2 px-8 text-stone-300 z-10">
                                        <p className="text-justify">{t("features.publicImageGenerator.description")}</p>
                                        <p className="text-center tracking-wide font-semibold bg-gradient-to-tr from-fruity-kiwiGreen via-25% via-lime-500 to-green-600 bg-clip-text text-transparent mt-4">
                                            {t("features.publicImageGenerator.highlight")}
                                            <span className="font-extrabold">
                                                {t("features.publicImageGenerator.highlightBold")}
                                            </span>
                                        </p>
                                        <div className="flex flex-col justify-center items-center">
                                            <button
                                                onClick={() => (window.location.href = "/image-gen")}
                                                className="
                                                    justify-center
                                                    px-4 py-2 mt-4
                                                    bg-gradient-to-r from-pink-500 to-orange-600
                                                    text-white font-semibold text-sm
                                                    animate-text-color-change
                                                    rounded-full shadow-textGlow
                                                    transform transition ease-in-out
                                                    hover:scale-105 hover:shadow-xl
                                                    focus:outline-none
                                                    active:scale-95
                                                "
                                            >
                                                {t("features.publicImageGenerator.tryNowLink")}
                                            </button>
                                            <span
                                                className="
                                                    mt-1
                                                    bg-gradient-to-r from-pink-500 to-orange-600
                                                    bg-clip-text text-transparent
                                                    font-semibold text-xs
                                                    animate-gradient-x
                                                    transition ease-in-out
                                                    transform hover:scale-105
                                                "
                                            >
                                                {t("features.publicImageGenerator.tryNowEmphasis")}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="w-1/2 relative">
                                        <div className="absolute inset-0 bg-gradient-to-r from-stone-900 via-transparent to-transparent z-10"></div>
                                        <div className="absolute -right-20 -bottom-22 w-[120%] h-[120%] overflow-hidden">
                                            <img
                                                src={narkisPublicImage}
                                                alt="Public Image Generator"
                                                className="w-full h-full object-cover transition-transform duration-300 transform scale-110 hover:scale-125"
                                            />
                                        </div>
                                        <div className="absolute inset-0 bg-gradient-radial from-transparent via-transparent to-stone-900 opacity-75 z-20"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex justify-end">
                            <div className="border p-4 w-[600px] border-stone-400 min-h-[150px] bg-stone-900 shadow-2xl rounded-[7.5px] hover:scale-[101%] hover:brightness-125 hover:shadow-black transition-all duration-300">
                                <h3 className="font-header text-2xl font-semibold text-center text-stone-300">
                                    {t("features.imageEditingStudio.title")}
                                </h3>
                                <div className="py-4 px-8 text-stone-300">
                                    <p className="text-justify">{t("features.imageEditingStudio.description")}</p>
                                    <br></br>
                                    <p className="font-extrabold text-center">({t("features.comingSoon")})</p>
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex justify-start">
                            <div className="md:-mb-24 overflow-auto z-10 border pt-4 w-[600px] border-stone-400 min-h-[150px] bg-stone-900 shadow-2xl rounded-[7.5px] hover:scale-[101%] hover:brightness-125 hover:shadow-black transition-all duration-300">
                                <h3 className="font-header text-2xl font-semibold text-center text-stone-300">
                                    {t("features.downloadYourModel.title")}
                                </h3>
                                <div className="py-4 px-8 text-stone-300">
                                    <p className="text-justify">{t("features.downloadYourModel.description")}</p>
                                    <br />
                                    <p className="text-justify">{t("features.downloadYourModel.description2")}</p>
                                    <br></br>
                                    <p className="tracking-wide font-semibold bg-gradient-to-tr from-soft-pink via-25% via-soft-orange to-soft-yellow bg-clip-text text-transparent -ml-1 -mr-1">
                                        {t("features.downloadYourModel.guideText")}
                                        <a href="/blog/iphone-model" className="text-blue-500 underline">
                                            {t("features.downloadYourModel.guideLink")}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full bg-stone-900">
                    <img src={waves2} alt="Waves" className="w-full h-auto" />
                </div>

                {/* testimonials */}
                <div className="px-4 md:px-28 lg:px-56 overflow-visible py-12 bg-gradient-to-b from-stone-900 w-full to-narkis-bg">
                    <h2 className="text-3xl tracking-widest font-header text-center mb-8">What Our Users Say</h2>

                    <div className="flex justify-center space-x-4">
                        <Card className="text-lg font-semibold bg-narkis-bg-lighter p-4 w-[200px] h-[200px]">
                            <CardTitle>Adam</CardTitle>
                            <CardDescription>CEO, Narkis.ai</CardDescription>
                            <CardContent className="m-0 p-1">
                                <p className="text-sm">
                                    {/* testimonial from ceo of narkis.ai */}
                                    We don't have many users yet, but I'm looking forward to add your feedback here!
                                </p>
                            </CardContent>
                        </Card>
                    </div>
                </div>
                <div className="h-2 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 w-full opacity-50 shadow-lg"></div>

                {/* FAQ */}
                <div className="px-4 md:px-28 lg:px-56 overflow-visible py-12 bg-gradient-to-b from-stone-900 w-full to-stone-950">
                    <h2 className="text-3xl tracking-widest font-header text-center mb-8">Frequently Asked Questions</h2>
                    <FAQ />
                </div>

                <div className="h-2 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 w-full opacity-50 shadow-lg"></div>
                <div className="h-2 bg-gradient-to-b from-stone-900 to-stone-950 w-full shadow-lg"></div>

                <Footer />
            </div>
        </Page>
    );
}
