// src/components/Generate.js
import Page from "../../../Page";
import { useState, useEffect } from "react";
import ModelSelectionMenu from "../train/ModelSelectionMenu";
import GenerationMenu from "./GenerationMenu";
import ImageMenu from "./ImageMenu";
import { listTrainedModels, listGeneratedImages, listPresets, listPendingPhotos } from "../../../../services/api";
import { useLocalStorageState } from "../../../../hooks/useLocalStorageState";
import { Metadata } from "../../../types/pageMetadata";
import { useToast } from "../../../shadcn/ui/use-toast";



const generateMetadata: Metadata = {
    title: "Dashboard - Narkis.ai",
    description: "Unleash your creativity with Narkis.ai. Generate stunning images of yourself in any scene, role, or world you can imagine.",
    h1: "Narkis.ai Dashboard - Create Your AI Generated Photos",
    canonical: "https://narkis.ai/dashboard",
    keywords: ["AI Image Generation", "Personalized Photos", "Creative Dashboard", "AI-Powered Imagery", "Narkis.ai"],
    author: "Narkis.ai",
    robots: "index, follow",
    ogTitle: "Narkis.ai - Your Creative AI Image Dashboard",
    ogDescription: "Transform your ideas into reality with Narkis.ai's AI-powered image generation. Create unique, personalized images for any scenario or style.",
    ogImage: "summary-large-dashboard.png", // TODO: Update with actual CloudFront URL
    ogUrl: "https://narkis.ai/dashboard",
    twitterCard: "summary_large_image",
    twitterTitle: "Narkis.ai Dashboard - Create Your AI-Generated Images",
    twitterDescription: "Step into your personalized AI image studio. Generate stunning, unique images of yourself in any setting or style with Narkis.ai.",
    twitterImage: "summary-large-dashboard.png", // TODO: Update with actual CloudFront URL
    twitterCreator: "@narkisai"
};

const Generate = () => {
    const [lastSelectedModel, setLastSelectedModel] = useLocalStorageState("lastSelectedModel", "-1");
    const [selectedModel, setSelectedModel] = useState<any>(null);

    const [models, setModels] = useState<any[]>([]);
    const [images, setImages] = useState<any[]>([]);

    const fetchImages = async (
        page: number,
        size: number,
        sortBy: string,
        filterSelectedModelId: string,
        filterFavorites: boolean,
        filterDeleted: boolean
    ) => {
        try {
            const response = await listGeneratedImages(
                "",
                page,
                size,
                sortBy,
                filterSelectedModelId,
                filterFavorites,
                filterDeleted
            );
            setImages(response.data.images);
            //console.log("Images loaded:", response.data.images);
        } catch (humanError: any) {
            console.error("Error loading images:", humanError);
        }
    };

    const [filterFavorites, setFilterFavorites] = useState(false);
    const [filterDeleted, setFilterDeleted] = useState(false);

    const { toast } = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const [presets, setPresets] = useState<any[]>([]);

    useEffect(() => {
        const fetchTrainedModels = async () => {
            try {
                const response = await listTrainedModels("", 0, 40); // Fetch the first 10 models
                var models = response.data.models;
                setModels(models);

                if (lastSelectedModel && lastSelectedModel !== "-1") {
                    console.log("Last selected model found, setting as selected");
                    setSelectedModel(models.find((model: any) => model.model_id === lastSelectedModel));
                } else {
                    console.log("No last selected model found, setting first model as selected");
                    setSelectedModel(models[0]);
                }
            } catch (humanError: any) {
                console.error("Error fetching trained models:", humanError);
            }
        };

        const fetchPresets = async () => {
            setIsLoading(true);
            try {
                const response = await listPresets();
                console.log(response);
                if (response && response.presets) {
                    setPresets(response.presets as any);
                }
            } catch (error: any) {
                console.error("Failed to fetch presets:", error);
                toast({
                    variant: "destructive",
                    title: "Failed to fetch presets",
                    description: error.message,
                });
            } finally {
                setIsLoading(false);
            }
        };

        fetchTrainedModels();
        fetchImages(1, 10, "newest", "", false, false);
        fetchPresets();
    }, []); // Empty array means this effect runs once on mount

    const handleModelSelect = (model: any) => {
        // called when user selects a model in the model selection menu
        console.log("Model selected:", model);
        setSelectedModel(model);
    };

    const onImageGridModelChange = (e: any) => {
        // called when user changes the model filter in the image grid
    };

    const onImageGridSortChange = (e: any) => {
        // called when user changes the sort order in the image grid
    };

    const onImageGridFilterChange = (e: any) => {
        // called when user changes the filter settings in the image grid
    };

    const addGeneratedImage = (image: any) => {
        setImages((prevImages) => [image, ...prevImages]);
    };

    const deleteGeneratedImage = async (imageId: string) => {
        // try {
        // 	setImages(prevImages => prevImages.filter(image => image.image_id !== imageId));
        // 	await deleteGeneratedImages([imageId], '');
        // } catch (error: any) {
        // 	console.error('Error deleting image:', error);
        // }
    };

    // Manual update mechanism
    useEffect(() => {
        let updateInterval: NodeJS.Timeout;
        let checkInterval = 1500; // Start with 1.5 seconds
        const maxInterval = 10000; // Max interval of 10 seconds

        const checkForUpdates = () => {
            const pendingStatuses = ["GENERATING", "QUEUED", "IN_PROGRESS", "PREPARING"];
            const pendingImages = images.filter((image) => pendingStatuses.includes(image.status));

            if (pendingImages.length > 0) {
                // Only fetch pending images
                listPendingPhotos(
                    "",
                    pendingImages.map((img) => img.image_id)
                )
                    .then((response) => {
                        const updatedImages = response.data.images;
                        setImages((prevImages) => {
                            return prevImages.map((prevImage) => {
                                const updatedImage = updatedImages.find((img: any) => img.image_id === prevImage.image_id);
                                if (updatedImage && updatedImage.status !== prevImage.status) {
                                    return {
                                        ...prevImage,
                                        ...updatedImage,
                                        status: updatedImage.status,
                                        deleteTimeout: updatedImage.status === "FAILED" ? Date.now() + 5000 : undefined,
                                    };
                                }
                                return prevImage;
                            });
                        });

                        // Reset interval if there are still pending images
                        checkInterval = 1500;
                    })
                    .catch((error) => {
                        console.error("Error fetching updated images:", error);
                    });
            } else {
                // Increase interval if no pending images
                checkInterval = Math.min(checkInterval * 2, maxInterval);
            }

            updateInterval = setTimeout(checkForUpdates, checkInterval);
        };

        // Initial delay before starting the checks
        const initialDelay = setTimeout(() => {
            checkForUpdates();
        }, 1500);

        return () => {
            clearTimeout(initialDelay);
            clearTimeout(updateInterval);
        };
    }, [images]);

    return (
        <Page
            {...generateMetadata}
        >
            <div
                className="font-medium
                flex flex-col md:flex-row sm:max-h-[calc(100vh-80px)] overflow-y-auto w-full
                text-narkis-text gap-0.5
                border-2 rounded-[10px] shadow-md border-narkis-border-darker2
                "
            >
                {/* user image generation menu */}
                <div
                    className="
                    md:flex-none md:w-[22%] md:max-w-[377px] md:min-w-[325px] md:mb-0
                     mb-4
                    max-h-[calc(100vh-80px)] overflow-y-auto
                    flex flex-col h-full
                    border-2 rounded-[8px] shadow-md border-narkis-border
                    "
                >
                    {/* model selection submenu */}
                    <div
                        className="
                        mb-1 overflow-y-auto flex-shrink-0 
                        border-2 rounded-[5px] shadow-md border-narkis-border"
                    >
                        <ModelSelectionMenu models={models} onModelSelect={handleModelSelect} />
                    </div>
                    {/* image modal submenu */}
                    <div
                        className="
                        flex-grow overflow-y-auto
                        border-2 rounded-[6px] shadow-md border-narkis-border-darker"
                    >
                        <GenerationMenu
                            selectedModel={selectedModel}
                            addGeneratedImage={addGeneratedImage}
                            deleteGeneratedImage={deleteGeneratedImage}
                            presets={presets}
                        />
                    </div>
                </div>
                {/* image grid */}
                <div
                    className="
                    md:flex-1 p-0.5 
                    overflow-auto
                    border-2 rounded-[8px] shadow-md border-narkis-border"
                >
                    <ImageMenu
                        models={models}
                        images={images}
                        onImageGridModelChange={onImageGridModelChange}
                        onImageGridSortChange={onImageGridSortChange}
                        onImageGridFilterChange={onImageGridFilterChange}
                        onImageFavoritesFilterChange={setFilterFavorites}
                        onImageDeletedFilterChange={setFilterDeleted}
                        fetchImages={fetchImages}
                        setImages={setImages}
                    />
                </div>
            </div>
        </Page>
    );
};

export default Generate;
