import { Metadata } from "../../../types/pageMetadata";

const REACT_APP_NARKIS_PUBLIC_CLOUDFRONT_URL = process.env.REACT_APP_NARKIS_PUBLIC_CLOUDFRONT_URL;
const blogImage1 = `${REACT_APP_NARKIS_PUBLIC_CLOUDFRONT_URL}/blog/blog1.jpg`;
const flowersImage1 = `${REACT_APP_NARKIS_PUBLIC_CLOUDFRONT_URL}/blog/flowers1.jpg`;
const flowersImage2 = `${REACT_APP_NARKIS_PUBLIC_CLOUDFRONT_URL}/blog/flowers2.jpg`;
const flowersImage3 = `${REACT_APP_NARKIS_PUBLIC_CLOUDFRONT_URL}/blog/flowers3.jpg`;
const flowersImage4 = `${REACT_APP_NARKIS_PUBLIC_CLOUDFRONT_URL}/blog/flowers4.jpg`;
const seahorseImage1 = `${REACT_APP_NARKIS_PUBLIC_CLOUDFRONT_URL}/blog/seahorse1.jpeg`;

export const blogMetadata: Metadata = {
    title: "Narkis.ai Blog - AI News, Tutorials, and Feature Updates",
    description:
        "Explore the latest AI news, in-depth tutorials, feature releases, and insights into AI image generation. Stay informed about advancements in AI technology and learn how to make the most of our platform.",
    h1: "Narkis.ai Blog - AI News, Tutorials, and Feature Updates",
    canonical: "https://narkis.ai/blog",
    keywords: [
        // General AI Keywords
        "AI News",
        "AI Technology Blog",
        "AI Technology Trends",
        "AI Trends and Insights",
        "AI Applications and Use Cases",
        "AI Tools and Applications",
        "AI Tutorials and Guides",
    ],
    author: "Narkis.ai",
    robots: "index, follow",
    ogTitle: "Narkis.ai Blog - Your Source for AI Insights and Tutorials",
    ogDescription:
        "Dive into the world of AI with Narkis.ai's blog. Get the latest news, learn from our tutorials, and stay updated on new features in AI image generation.",
    ogImage: "summary-large-blog.png",
    ogUrl: "https://narkis.ai/blog",
    twitterCard: "summary_large_image",
    twitterTitle: "Narkis.ai Blog - AI News, Tutorials & Feature Updates",
    twitterDescription:
        "Explore AI insights, learn from our tutorials, and stay informed about the latest in AI image generation. Your go-to resource for AI knowledge and Narkis.ai updates.",
    twitterImage: "summary-large-blog.png",
    twitterCreator: "@narkisai",
};

export const getBlogArticleMetadata = (articleId: string): Metadata => {
    const article = blogPosts.find(post => post.id === articleId);
    return {
        ...blogMetadata,
        title: article ? `${article.title} | Narkis.ai Blog` : blogMetadata.title,
        description: article ? article.description : blogMetadata.description,
        ogTitle: article ? article.title : blogMetadata.ogTitle,
        ogDescription: article ? article.description : blogMetadata.ogDescription,
        canonical: article ? article.canonical : blogMetadata.canonical,
        ogUrl: article ? `https://narkis.ai/blog/${articleId}` : blogMetadata.ogUrl,
        ogImage: article ? article.ogImage : blogMetadata.ogImage,
        twitterImage: article ? article.twitterImage : blogMetadata.twitterImage,
        twitterTitle: article ? article.title : blogMetadata.twitterTitle,
        twitterDescription: article ? article.description : blogMetadata.twitterDescription,
        keywords: article ? article.keywords : blogMetadata.keywords,
    };
};



export interface BlogPost {
    id: string;
    title: string;
    description: string;
    h1: string;
    canonical: string;
    date: string;
    author: string;
    imageUrl: string;
    newsletterTagline: string;
    keywords: string[];
    ogImage: string;
    twitterImage: string;
}

export const blogPosts: BlogPost[] = [
    // {
    //     id: "ai-usage-guide",
    //     title: "How to Actually Use AI to Improve Your Life",
    //     description: "Cut through the AI hype and learn practical ways to leverage AI in your daily life. From text processing to tool building, discover how to stay ahead in the AI revolution.",
    //     h1: "How to Actually Use AI to Improve Your Life",
    //     canonical: "https://narkis.ai/blog/ai-usage-guide",
    //     date: "2023-07-07",
    //     author: "Adam",
    //     imageUrl: `${blogImage1}`,
    //     ogImage: "summary-large-blog-ai-usage-guide.png",
    //     twitterImage: "summary-large-blog-ai-usage-guide.png",
    //     newsletterTagline: "Let's face it, you need this.",
    //     keywords: [
    //         "how to use AI in daily life",
    //         "practical AI applications",
    //         "AI text processing tips",
    //         "ChatGPT for productivity",
    //         "ChatGPT tips",
    //         "AI productivity hacks",

    //         "AI tool building examples",
    //         "AI content creation",
    //         "AI task management",
    //         "AI job market impact",
    //         "AI workflow optimization",
    //         "staying ahead with AI",

    //         "AI hype vs reality",
    //         "Leveraging AI tools"
    //     ],
    // },
];

export const getGuideMetadata = (articleId: string): Metadata => {
    const guide = guides.find(g => g.id === articleId);
    return {
        ...blogMetadata,
        title: guide ? `${guide.title} | Narkis.ai Guides` : blogMetadata.title,
        description: guide ? guide.description : blogMetadata.description,
        ogTitle: guide ? guide.title : blogMetadata.ogTitle,
        ogDescription: guide ? guide.description : blogMetadata.ogDescription,
        canonical: guide ? guide.canonical : blogMetadata.canonical,
        ogUrl: `https://narkis.ai/blog/${articleId}`,
        ogImage: guide ? guide.ogImage : blogMetadata.ogImage,
        twitterImage: guide ? guide.twitterImage : blogMetadata.twitterImage,
        twitterTitle: guide ? guide.title : blogMetadata.twitterTitle,
        twitterDescription: guide ? guide.description : blogMetadata.twitterDescription,
        keywords: guide ? guide.keywords : blogMetadata.keywords,
    };
};


export const guides: BlogPost[] = [
    {
        id: "iphone-model",
        title: "Guide to Using Your Narkis.ai Models on iOS",
        description: "Learn how to download and use your Narkis.ai models on iPhone with the Draw Things app for offline, free AI image generation.",
        h1: "Using Your Narkis.ai AI Model on iOS with the Draw Things App",
        canonical: "https://narkis.ai/blog/iphone-model",
        date: "2023-07-07",
        author: "Adam",
        imageUrl: `${seahorseImage1}`,
        ogImage: "summary-large-blog-iphone-model.png",
        twitterImage: "summary-large-blog-iphone-model.png",
        newsletterTagline: "Get exclusive mobile AI tips - subscribe now!",
        keywords: [            
            "free AI art creation on iPhone",
            "create AI art on iPhone",
            "free AI art creation app for iPhone",
            "generate free AI images on iPhone",
            "generate images with AI on iPhone",
            "install and use AI model on iPhone",
            "Draw Things app for AI image creation",
            "generate offline AI art on iOS",
            "top AI tools for iPhone art creation",
            "creating digital paintings on iPhone",
            "how to use AI model on iOS",
            "AI photo editing on iPhone",
            "comprehensive guide to iPhone AI photography apps",
        ],
    },
];