import React, { useState, useEffect } from "react";
import { trainingWorkerRedownloadDeps, listConfigurations, updateConfiguration } from "../../../../services/api";
import { Input } from "../../../shadcn/ui/input";
import { Table, TableBody, TableCell, TableHeader, TableHead, TableRow } from "../../../shadcn/ui/table";
import { Button } from "../../../shadcn/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../../../shadcn/ui/card";
import { useToast } from "../../../shadcn/ui/use-toast";
import { Switch } from "../../../shadcn/ui/switch";

const ConfigAdmin = () => {
    const { toast } = useToast();
    const [configurations, setConfigurations] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [trainingUseRunpod, setTrainingUseRunpod] = useState(false);
    const [inferenceUseRunpod, setInferenceUseRunpod] = useState(false);

    const handleRedownloadDeps = async () => {
        try {
            const response = await trainingWorkerRedownloadDeps();
            console.log("Dependencies redownload initiated:", response.data);
            toast({
                title: "Success",
                description: "Dependencies redownload initiated " + response.data.message,
            });
        } catch (error: any) {
            console.error("Error initiating dependencies redownload:", error);
            toast({
                title: "Error",
                description: `Error initiating dependencies redownload: ${error.message}`,
                variant: "destructive",
            });
        }
    };

    useEffect(() => {
        const fetchConfigurations = async () => {
            try {
                const response = await listConfigurations();
                setConfigurations(response.data.configurations);
                const trainingConfig = response.data.configurations.find((config: any) => config.key === "TRAINING_USE_RUNPOD");
                const inferenceConfig = response.data.configurations.find((config: any) => config.key === "INFERENCE_USE_RUNPOD");
                setTrainingUseRunpod(trainingConfig?.value === "true");
                setInferenceUseRunpod(inferenceConfig?.value === "true");
                toast({
                    title: "Success",
                    description: `Loaded ${response.data.configurations.length} configurations`,
                });
            } catch (error: any) {
                console.error("Error fetching configurations:", error);
                toast({
                    title: "Error",
                    description: `Error fetching configurations: ${error.message}`,
                    variant: "destructive",
                });
            }
        };

        fetchConfigurations();
    }, []);

    const handleConfigurationChange = (id: any, field: any, value: any) => {
        setConfigurations((prevConfigurations: any) =>
            prevConfigurations.map((config: any) => (config.id === id ? { ...config, [field]: value } : config))
        );
    };

    const handleUpdateConfiguration = async (config: any) => {
        try {
            const response: any = await updateConfiguration(config);
            toast({
                title: "Success",
                description: response.data.message,
            });
        } catch (error: any) {
            console.error("Error updating configuration:", error);
            toast({
                title: "Error",
                description: `Error updating configuration: ${error.message}`,
                variant: "destructive",
            });
        }
    };

    const handleToggleRunpod = async (configKey: string, newValue: boolean) => {
        const config = configurations.find((c: any) => c.key === configKey);
        if (config && typeof config === 'object') {
            const updatedConfig = Object.assign({}, config, { value: newValue.toString() });
            await handleUpdateConfiguration(updatedConfig);
            if (configKey === "TRAINING_USE_RUNPOD") {
                setTrainingUseRunpod(newValue);
            } else if (configKey === "INFERENCE_USE_RUNPOD") {
                setInferenceUseRunpod(newValue);
            }
        }
    };

    const filteredConfigurations = configurations.filter((config: any) =>
        Object.values(config).some(
            (value) =>
                typeof value === "string" &&
                value.toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    return (
        <div className="space-y-4 p-4 bg-gray-900 rounded-lg shadow-md">
            <Card className="bg-gray-800 text-white">
                <CardHeader>
                    <CardTitle className="text-xl">Configuration Controls</CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="flex flex-wrap justify-between items-center gap-4">
                        <Button onClick={handleRedownloadDeps} className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded">
                            Redownload Worker Dependencies
                        </Button>
                        <div className="flex items-center space-x-4">
                            <div className="flex items-center space-x-2">
                                <span className="text-gray-300">Training Use Runpod:</span>
                                <Switch
                                    checked={trainingUseRunpod}
                                    onCheckedChange={(checked) => handleToggleRunpod("TRAINING_USE_RUNPOD", checked)}
                                />
                            </div>
                            <div className="flex items-center space-x-2">
                                <span className="text-gray-300">Inference Use Runpod:</span>
                                <Switch
                                    checked={inferenceUseRunpod}
                                    onCheckedChange={(checked) => handleToggleRunpod("INFERENCE_USE_RUNPOD", checked)}
                                />
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
            <Card className="bg-gray-800">
                <CardHeader className="flex flex-row items-center justify-between">
                    <CardTitle className="text-xl font-bold text-white">Configurations</CardTitle>
                    <Input
                        type="text"
                        placeholder="Search configurations..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-64 bg-gray-700 text-white border-gray-600"
                    />
                </CardHeader>
                <CardContent>
                    <div className="overflow-x-auto">
                        <Table className="w-full">
                            <TableHeader>
                                <TableRow className="bg-gray-700">
                                    <TableHead className="text-gray-300 font-semibold py-2 px-3">Key</TableHead>
                                    <TableHead className="text-gray-300 font-semibold py-2 px-3">Value</TableHead>
                                    <TableHead className="text-gray-300 font-semibold py-2 px-3">Description</TableHead>
                                    <TableHead className="text-gray-300 font-semibold py-2 px-3">Actions</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {filteredConfigurations.map((config: any) => (
                                    <TableRow key={config.id} className="bg-white hover:bg-gray-50">
                                        <TableCell className="py-2 px-3 font-medium text-gray-900">{config.key}</TableCell>
                                        <TableCell className="py-2 px-3">
                                            <Input
                                                type="text"
                                                value={config.value}
                                                onChange={(e) =>
                                                    handleConfigurationChange(config.id, "value", e.target.value)
                                                }
                                                className="w-full bg-white text-gray-800 border-gray-300"
                                            />
                                        </TableCell>
                                        <TableCell className="py-2 px-3 text-gray-600">{config.description}</TableCell>
                                        <TableCell className="py-2 px-3">
                                            <Button
                                                onClick={() => handleUpdateConfiguration(config)}
                                                className="bg-green-500 hover:bg-green-600 text-white font-semibold py-1 px-2 rounded text-xs"
                                            >
                                                Update
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default ConfigAdmin;
