"use client"

import * as React from "react"
import * as SwitchPrimitives from "@radix-ui/react-switch"

import { cn } from "../../../lib/utils"

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      `peer inline-flex h-5 w-9 shrink-0 cursor-pointer items-center 
      rounded-full border-transparent shadow-sm transition-colors  duration-500
      focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring 
      focus-visible:ring-offset-2 focus-visible:ring-offset-background 
      disabled:cursor-not-allowed disabled:opacity-50 
    data-[state=checked]:bg-narkis-border-colorful data-[state=checked]:border-narkis-border-colorful
    data-[state=unchecked]:bg-narkis-border-darker2 border-narkis-border-darker border`,
      className
    )}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        `pointer-events-none block h-4 w-4 rounded-full bg-narkis-border-colorful shadow-lg ml-[1px]
        ring-0 duration-300
        data-[state=checked]:translate-x-4 data-[state=checked]:bg-narkis-bg
        data-[state=unchecked]:translate-x-0`
      )}
    />
  </SwitchPrimitives.Root>
))
Switch.displayName = SwitchPrimitives.Root.displayName

export { Switch }
