import React, { useState } from "react";
import Page from "../../../Page";
import UsersAdmin from "./UsersAdmin";
import ConfigAdmin from "./ConfigAdmin";
import PresetsAdmin from "./PresetsAdmin";
import { Badge } from "../../../shadcn/ui/badge";
import { Metadata } from "../../../types/pageMetadata";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../shadcn/ui/tabs";

const adminMetadata: Metadata = {
    title: "Admin Panel - Narkis.ai",
    description: "Admin Panel for Narkis.ai",
    h1: "Admin Panel",
    keywords: ["Admin", "Narkis.ai"],
    canonical: "https://narkis.ai/admin",
    author: "Narkis.ai",
    robots: "noindex, nofollow",
    ogTitle: "Admin Panel - Narkis.ai",
    ogDescription: "Admin Panel for Narkis.ai",
    ogImage: "https://narkis.ai/images/admin-panel-preview.jpg", // TODO: Update with actual image URL
    ogUrl: "https://narkis.ai/admin",
    twitterCard: "summary",
    twitterTitle: "Admin Panel - Narkis.ai",
    twitterDescription: "Admin Panel for Narkis.ai",
    twitterImage: "https://narkis.ai/images/admin-panel-preview.jpg", // TODO: Update with actual image URL
    twitterCreator: "@narkisai"
}

const AdminPanel = () => {
    const [activeTab, setActiveTab] = useState("users");

    return (
        <Page {...adminMetadata}>
            <div className="flex flex-col w-full min-h-screen bg-gray-900 text-white">
                <header className="bg-gray-800 p-4 flex justify-between items-center">
                    <h1 className="text-2xl font-bold">Admin Panel</h1>
                    <Badge variant="outline" color="red">
                        <a href="/dashboard" className="hover:text-gray-300 transition-colors">
                            Back to Dashboard
                        </a>
                    </Badge>
                </header>

                <main className="flex-grow p-6">
                    <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
                        <TabsList className="grid w-full grid-cols-3 mb-6">
                            <TabsTrigger value="users">Users</TabsTrigger>
                            <TabsTrigger value="configurations">Configurations</TabsTrigger>
                            <TabsTrigger value="presets">Presets</TabsTrigger>
                        </TabsList>
                        <TabsContent value="users" className="border-none p-0">
                            <UsersAdmin />
                        </TabsContent>
                        <TabsContent value="configurations" className="border-none p-0">
                            <ConfigAdmin />
                        </TabsContent>
                        <TabsContent value="presets" className="border-none p-0">
                            <PresetsAdmin />
                        </TabsContent>
                    </Tabs>
                </main>
            </div>
        </Page>
    );
};

export default AdminPanel;
