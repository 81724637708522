interface StructuredData {
  "@context": string;
  "@type": string;
  [key: string]: any;
}

export const generateStructuredData = (): StructuredData[] => {
  const websiteSchema: StructuredData = {
    "@context": "https://schema.org",
      "@type": "WebSite",
      "name": "Narkis.ai",
      "url": "https://narkis.ai",
      "description": "AI-Powered Personal Photography Studio. Create stunning, lifelike photographs of yourself in various styles without a physical photoshoot."
    };
  
    const softwareSchema: StructuredData = {
      "@context": "https://schema.org",
      "@type": "SaaS",
      "name": "Narkis.ai",
      "serviceType": "AI Image Generation",
      "offers": {
        "@type": "AggregateOffer",
        "offerCount": 3,
        "lowPrice": 16,
        "highPrice": 108,
        "priceCurrency": "USD",
        "description": "Monthly subscription plans ranging from basic to power user"
      },
      "description": "Narkis.ai is an AI-powered personal photography studio that creates custom, high-quality images based on user-provided photos, without the need for physical photoshoots.",
      "featureList": [
        "High-quality AI image generation",
        "Personalized AI model training",
        "Customizable styles and settings",
        "High-quality, lifelike results",
        "Quick turnaround time",
        "No physical photoshoot required"
      ],
      "url": "https://narkis.ai",
      "provider": {
        "@type": "Organization",
        "name": "Narkis.ai"
      }
    };
  
    return [websiteSchema, softwareSchema];
  };