// GenerateView.tsx
import React from "react";
import { useAuth } from "../../../../contexts/AuthContext";
import { Card, CardContent } from "../../../shadcn/ui/card";
import { Button } from "../../../shadcn/ui/button";
import { useLocalStorageState } from "../../../../hooks/useLocalStorageState";
import { useTranslation } from "react-i18next";
import TrainedModel from "../../../types/trainedModel";

import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
    type CarouselApi,
} from "../../../shadcn/ui/carousel";

interface ModelSelectorProps {
    models: any;
    onModelSelect: (model: any) => void;
    onClickCreateNewModel: () => void;
}

const ModelSelector: React.FC<ModelSelectorProps> = ({ models, onModelSelect, onClickCreateNewModel }) => {
    const { t } = useTranslation("model_selection");
    const { user } = useAuth();
    const maxConcurrentTrainings = user?.max_concurrent_training_runs || 0;
    const [lastSelectedModel, setLastSelectedModel] = useLocalStorageState("lastSelectedModel", "-1");

    const ownedModels = models.filter((model: TrainedModel) => !model.is_shared);
    const sharedModels = models.filter((model: TrainedModel) => model.is_shared);
    const ongoingTrainings = ownedModels.filter((model: TrainedModel) => model.status === "IN_PROGRESS");

    const handleSelectedModelChange = (selectedModel: TrainedModel) => {
        onModelSelect(selectedModel);
        setLastSelectedModel(selectedModel?.model_id);
    };

    const [ownedModelsApi, setOwnedModelsApi] = React.useState<CarouselApi>();
    const [sharedModelsApi, setSharedModelsApi] = React.useState<CarouselApi>();

    React.useEffect(() => {
        if (!ownedModelsApi || ownedModels.length === 0) return;

        const modelIndex = ownedModels.findIndex((model: TrainedModel) => model.model_id === lastSelectedModel);
        if (modelIndex !== -1) {
            ownedModelsApi.scrollTo(modelIndex);
            handleSelectedModelChange(ownedModels[modelIndex]);
        } else {
            handleSelectedModelChange(ownedModels[0]);
            ownedModelsApi.scrollTo(0);
        }

        ownedModelsApi.on("select", () => {
            const selected = ownedModelsApi.selectedScrollSnap();
            const selectedModel = ownedModels[selected];
            if (selectedModel && selectedModel.status === "COMPLETED") {
                handleSelectedModelChange(selectedModel);
            }
        });
    }, [ownedModelsApi, ownedModels]);

    React.useEffect(() => {
        if (!sharedModelsApi || sharedModels.length === 0) return;

        sharedModelsApi.on("select", () => {
            const selected = sharedModelsApi.selectedScrollSnap();
            const selectedModel = sharedModels[selected];
            if (selectedModel && selectedModel.status === "COMPLETED") {
                handleSelectedModelChange(selectedModel);
            }
        });
    }, [sharedModelsApi, sharedModels]);

    const renderModelCarousel = (models: TrainedModel[], title: string, setApi: (api: CarouselApi) => void, isShared: boolean) => (
        <div>
            <Carousel
                opts={{
                    loop: true,
                }}
                className="w-2/4 h-auto mx-auto"
                setApi={setApi}
            >
                <CarouselContent>
                    {models
                        .filter((model) => model.status === "COMPLETED")
                        .map((model) => (
                            <CarouselItem key={model.model_id} className="sm:basis-1/3 md:basis-1/3 lg:basis-1/3">
                                <div className="p-1">
                                    <Card className={`bg-narkis-mobile-bg rounded-md ${isShared ? 'border-2 border-blue-500' : 'border-narkis-mobile-border-darker'}`}>
                                        <CardContent
                                            className="flex aspect-square items-center justify-center rounded-[15px] drop-shadow-xl relative"
                                            style={{
                                                backgroundImage: model.favorited_image_url ? `url(${model.favorited_image_url})` : 'none',
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                            }}
                                        >
                                            {!model.favorited_image_url && (
                                                <span className="text-xs text-narkis-mobile-text font-semibold">
                                                    {model.model_name}
                                                </span>
                                            )}
                                            {isShared && (
                                                <div className="absolute top-2 right-2 bg-blue-500 text-white text-xs px-2 py-1 rounded-full">
                                                    Shared
                                                </div>
                                            )}
                                        </CardContent>
                                    </Card>
                                </div>
                            </CarouselItem>
                        ))}
                </CarouselContent>
                <CarouselPrevious className="text-narkis-mobile-text border-2 border-narkis-mobile-border-darker2 bg-narkis-mobile-bg absolute -left-10 top-1/2 -translate-y-1/2 z-10" />
                <CarouselNext className="text-narkis-mobile-text border-2 border-narkis-mobile-border-darker2 bg-narkis-mobile-bg absolute -right-10 top-1/2 -translate-y-1/2 z-10" />
            </Carousel>
        </div>
    );

    return (
        <div className="flex flex-col">
            <div className="text-narkis-mobile-text text-center text-lg font-bold mt-5">
                <label>{t("selectModel.mainLabel")}</label>
            </div>
            <div className="space-y-8">
                {ownedModels.length > 0 ? (
                    renderModelCarousel(ownedModels, "Your Models", setOwnedModelsApi, false)
                ) : (
                    <p className="text-center text-narkis-mobile-text">You don't have any models yet.</p>
                )}
                {sharedModels.length > 0 && renderModelCarousel(sharedModels, "Shared Models", setSharedModelsApi, true)}
            </div>

            {ongoingTrainings.length > maxConcurrentTrainings ? (
                <div className="centeredText">
                    <label>
                        {t("tooManyTrainings.mainLabel")} ({maxConcurrentTrainings})
                    </label>
                </div>
            ) : (
                <div className="flex flex-col items-center justify-center m-2 gap-4">
                    {models.length > 0 && (
                        <div className="text-center font-extrabold text-narkis-mobile-text">
                            <span>{t("or.mainLabel")}</span>
                        </div>
                    )}

                    <Button
                        variant="default"
                        onClick={onClickCreateNewModel}
                        className={`
                        font-extrabold border-narkis-border border rounded-[6px] w-full
                        bg-gradient-to-tr from-orange-400 via-orange-200 to-orange-600 bg-clip-text text-transparent 
                        transition-transform ease-in-out
                        hover:-translate-y-0.5  
                        animate-gradient-x bg-[length:400%_400%]
                        `}
                    >
                        {models.length === 0 ? t("createFirstModel.mainLabel") : t("createNewModel.mainLabel")}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default ModelSelector;
