// GenerateView.tsx
import React, { useState } from "react";
import CreateModelForm from "./CreateModelForm";
import { Separator } from "@radix-ui/react-select";
import { Button } from "../../../shadcn/ui/button";
import { Label } from "@radix-ui/react-label";
import { useTranslation } from "react-i18next";

const CreateModelView = ({ onFinish }: { onFinish: () => void }) => {
    const { t } = useTranslation('training');

    return (
        <div className="flex items-center justify-center">
            <div className="w-full flex flex-col p-0 gap-4 bg-narkis-mobile-bg text-narkis-mobile-text">
                <h2 className="text-xl font-bold mb-4 text-narkis-mobile-text-title text-center">Create New Model</h2>
                <CreateModelForm onClose={onFinish} />

                {/* instructions */}
                <hr></hr>
                <div className="bg-narkis-mobile-bg-lighter space-y-2 border p-4 text-narkis-mobile-text border-narkis-mobile-border-darker rounded-[6px]">
                    <h3 className="text-lg font-semibold mb-2">{t("instructions.title")}</h3>
                    <ul className="list-disc pl-5 space-y-2 text-sm">
                        <li>{t("instructions.instruct1")}</li>
                        <li>{t("instructions.instruct2")}</li>
                        <li>{t("instructions.instruct3")}</li>
                        <li>{t("instructions.instruct4")}</li>
                    </ul>
                    <p className="mt-4 text-[15px] text-wrap text-narkis-text-bright text-center whitespace-nowrap">
                        {t("instructions.footer")}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default CreateModelView;
