// GenerateMobile.tsx
import React, { useState, useEffect, startTransition } from "react";
import Header from "./Header";
import GenerateView from "./GenerateView";
import PhotoGalleryView from "./PhotoGalleryView";
import ModelManagementView from "./ModelManagementView";
import UserAccountView from "./UserAccountView";
import BottomNavMenu from "./BottomNavMenu";
import CreateModelView from "./CreateModelView";
import { listGeneratedImages, listPendingPhotos, listTrainedModels, deleteModel, listPresets } from "../../../../services/api";
import { Helmet } from "react-helmet";
import { Metadata } from "../../../types/pageMetadata";
import { useLocalStorageState } from "../../../../hooks/useLocalStorageState";
import { useToast } from "../../../shadcn/ui/use-toast";

const dashboardMobileMetadata: Metadata = {
    title: "Mobile Dashboard - Narkis.ai",
    description: "Your personal AI image studio, now in your pocket. Create personal photos with AI.",
    h1: "Narkis.ai Mobile Dashboard - Create AI-Powered Images",
    keywords: [], // Empty as this page shouldn't be indexed
    author: "Narkis.ai",
    robots: "noindex, nofollow", // Prevent indexing of user-specific pages
    canonical: "https://narkis.ai/dashboard",
    ogTitle: "Narkis.ai - Mobile Dashboard",
    ogDescription: "Your personal AI image studio, now in your pocket. Create and manage your Narkis.ai content on the go.",
    ogImage: "https://your-cloudfront-url.com/images/mobile-dashboard-preview.jpg", // TODO: Update with actual CloudFront URL
    ogUrl: "https://narkis.ai/dashboard", // This might need to be adjusted based on your routing
    twitterCard: "summary",
    twitterTitle: "Narkis.ai Mobile Dashboard",
    twitterDescription: "Access your Narkis.ai account and create AI-powered images anywhere, anytime.",
    twitterImage: "https://your-cloudfront-url.com/images/mobile-dashboard-preview.jpg", // TODO: Update with actual CloudFront URL
    twitterCreator: "@narkisai",
};

const DashboardMobile: React.FC = () => {
    // State for selected view
    const [selectedView, setSelectedView] = useLocalStorageState("selectedView", "generate");

    const [userModels, setUserModels] = useState<any[]>([]);
    const [images, setImages] = useState<any[]>([]);

    // Functions to handle view changes
    const handleGenerateClick = () => {
        startTransition(() => {
            setSelectedView("generate");
        });
    };
    const handlePhotosClick = () => {
        startTransition(() => {
            setSelectedView("photos");
        });
    };
    const handleMyModelsClick = () => {
        startTransition(() => {
            setSelectedView("models");
        });
    };
    const handleUserAvatarClick = () => {
        startTransition(() => {
            setSelectedView("account");
        });
    };
    const handleCreateModelClick = () => {
        startTransition(() => {
            setSelectedView("createModel");
        });
    };

    const handleDeleteModel = async (modelId: string) => {
        await deleteModel(modelId);
        setUserModels(userModels.filter((model) => model.model_id !== modelId));
    };

    const fetchImages = async (
        page: number,
        size: number,
        sortBy: string,
        filterSelectedModelId: string,
        filterFavorites: boolean,
        filterDeleted: boolean
    ) => {
        try {
            const response = await listGeneratedImages(
                "",
                page,
                size,
                sortBy,
                filterSelectedModelId,
                filterFavorites,
                filterDeleted
            );
            setImages(response.data.images);
            //console.log("Images loaded:", response.data.images);
        } catch (humanError: any) {
            console.error("Error loading images:", humanError);
        }
    };
    const { toast } = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const [presets, setPresets] = useState<any[]>([]);

    useEffect(() => {
        const fetchPresets = async () => {
            setIsLoading(true);
            try {
                const response = await listPresets();
                console.log(response);
                if (response && response.presets) {
                    setPresets(response.presets as any);
                }
            } catch (error: any) {
                console.error("Failed to fetch presets:", error);
                toast({
                    variant: "destructive",
                    title: "Failed to fetch presets",
                    description: error.message,
                });
            } finally {
                setIsLoading(false);
            }
        };

        const fetchTrainedModels = async () => {
            try {
                const response = await listTrainedModels("", 0, 100); // Fetch models
                var models = response.data.models;
                setUserModels(models);
            } catch (humanError: any) {
                console.error("Error fetching trained models:", humanError);
            }
        };

        fetchTrainedModels();
        fetchImages(1, 10, "newest", "", false, false);
        fetchPresets();
    }, []); // Empty array means this effect runs once on mount

    // Manual update mechanism
    useEffect(() => {
        let updateInterval: NodeJS.Timeout;
        let checkInterval = 1500; // Start with 1.5 seconds
        const maxInterval = 10000; // Max interval of 10 seconds

        const checkForUpdates = () => {
            const pendingStatuses = ["GENERATING", "QUEUED", "IN_PROGRESS", "PREPARING"];
            const pendingImages = images.filter((image) => pendingStatuses.includes(image.status));

            if (pendingImages.length > 0) {
                // Only fetch pending images
                listPendingPhotos(
                    "",
                    pendingImages.map((img) => img.image_id)
                )
                    .then((response) => {
                        const updatedImages = response.data.images;
                        setImages((prevImages) => {
                            return prevImages.map((prevImage) => {
                                const updatedImage = updatedImages.find((img: any) => img.image_id === prevImage.image_id);
                                if (updatedImage && updatedImage.status !== prevImage.status) {
                                    return {
                                        ...prevImage,
                                        ...updatedImage,
                                        status: updatedImage.status,
                                        deleteTimeout: updatedImage.status === "FAILED" ? Date.now() + 5000 : undefined,
                                    };
                                }
                                return prevImage;
                            });
                        });

                        // Reset interval if there are still pending images
                        checkInterval = 1500;
                    })
                    .catch((error) => {
                        console.error("Error fetching updated images:", error);
                    });
            } else {
                // Increase interval if no pending images
                checkInterval = Math.min(checkInterval * 2, maxInterval);
            }

            updateInterval = setTimeout(checkForUpdates, checkInterval);
        };

        // Initial delay before starting the checks
        const initialDelay = setTimeout(() => {
            checkForUpdates();
        }, 1500);

        return () => {
            clearTimeout(initialDelay);
            clearTimeout(updateInterval);
        };
    }, [images]);

    // Function to render the current view based on selectedView state
    const renderCurrentView = () => {
        switch (selectedView) {
            case "generate":
                return (
                    <GenerateView
                        images={images}
                        setImages={setImages}
                        models={userModels}
                        onClickCreateNewModel={handleCreateModelClick}
                        presets={presets}
                    />
                );
            case "photos":
                return <PhotoGalleryView models={userModels} images={images} fetchImages={fetchImages} setImages={setImages} />;
            case "models":
                return (
                    <ModelManagementView
                        userModels={userModels}
                        deleteModel={handleDeleteModel}
                        onClickCreateModel={handleCreateModelClick}
                    />
                );
            case "account":
                return <UserAccountView user={{}} />;
            case "createModel":
                return <CreateModelView onFinish={handleCreateModelClick} />;
            default:
                return null;
        }
    };

    return (
        <>
            <Helmet>
                <title>{dashboardMobileMetadata.title}</title>
                <meta name="description" content={dashboardMobileMetadata.description} />
                <meta name="robots" content={dashboardMobileMetadata.robots} />
                <meta property="og:title" content={dashboardMobileMetadata.ogTitle} />
                <meta property="og:description" content={dashboardMobileMetadata.ogDescription} />
                <meta property="og:image" content={dashboardMobileMetadata.ogImage} />
                <meta property="og:url" content={dashboardMobileMetadata.ogUrl} />
                <meta name="twitter:card" content={dashboardMobileMetadata.twitterCard} />
                <meta name="twitter:title" content={dashboardMobileMetadata.twitterTitle} />
                <meta name="twitter:description" content={dashboardMobileMetadata.twitterDescription} />
                <meta name="twitter:image" content={dashboardMobileMetadata.twitterImage} />
                <meta name="twitter:creator" content={dashboardMobileMetadata.twitterCreator} />
                <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
            </Helmet>
            <div className="flex flex-col h-screen w-screen bg-narkis-mobile-bg overflow-hidden font-medium">
                <div className="sticky top-0 w-full z-10 flex-none">
                    <Header onUserAvatarClick={handleUserAvatarClick} />
                </div>
                <main className="flex-auto w-full overflow-y-auto p-1 mb-16">{renderCurrentView()}</main>
                <div className="fixed bottom-0 w-full z-10 p-safe-bottom">
                    <BottomNavMenu
                        currentView={selectedView}
                        onGenerateClick={handleGenerateClick}
                        onPhotosClick={handlePhotosClick}
                        onMyModelsClick={handleMyModelsClick}
                    />
                </div>
            </div>
        </>
    );
};

export default DashboardMobile;
