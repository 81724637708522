import React from 'react';
import { useParams } from 'react-router-dom';
import { guides } from './blogMetadata';

const Guides: React.FC = () => {
  const { articleId } = useParams<{ articleId: string }>();

  const otherArticles = guides.filter(post => post.id !== articleId).slice(0, 5);

  return (
    <div className="bg-gray-900 px-8 py-4 rounded-xl shadow-lg transform transition-all duration-300">
      <h2 className="text-3xl font-bold mb-4 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 text-transparent bg-clip-text animate-gradient-x">Related Articles</h2>
      <ul className="space-y-2">
        {otherArticles.map((article) => (
          <li key={article.id} className="transform hover:translate-x-2 transition-transform duration-200">
            <a
              href={`/blog/${article.id}`}
              className="block text-lg text-gray-300 hover:bg-gradient-to-r hover:from-blue-400 hover:via-purple-500 hover:to-pink-500 hover:text-transparent hover:bg-clip-text hover:animate-gradient-x font-semibold transition-all duration-300"
            >
              <span className="mr-2">→</span>
              {article.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Guides;
