import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "../../../shadcn/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../../shadcn/ui/card";
import { Input } from "../../../shadcn/ui/input";
import { Label } from "../../../shadcn/ui/label";
import { useToast } from "../../../shadcn/ui/use-toast";
import { Loader2 } from "lucide-react";
import { useTranslation } from "react-i18next";
import { resetPassword } from "../../../../services/api";

const ResetPasswordForm = ({ onSwitchForm }: { onSwitchForm: any }) => {
    const { t } = useTranslation("login");
    const { toast } = useToast();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [resetToken, setResetToken] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [username, setUsername] = useState<string>("");

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        const urlResetToken = urlParams.get("token");
        if (urlResetToken) {
            console.log("Login token found:", urlResetToken);
            setResetToken(urlResetToken);

            const userEmail = urlParams.get("email");
            if (userEmail) {
                console.log("User email found:", userEmail);
                const safe = encodeURIComponent(userEmail);
                const sanitizedEmail = decodeURIComponent(safe);
                setUsername(sanitizedEmail);
                setEmail(sanitizedEmail);
            }
        }
    }, []);

    const handleSubmit = async () => {
        setLoading(true);
        try {
            if (!resetToken) {
                toast({
                    variant: "destructive",
                    title: t("resetPassword.invalidToken"),
                });
                return;
            }
            if (password !== confirmPassword) {
                toast({
                    variant: "destructive",
                    title: t("resetPassword.passwordsDoNotMatch"),
                });
                return;
            }
            await resetPassword(resetToken, password);
            toast({
                variant: "default",
                title: t("resetPassword.passwordResetSuccessfully"),
            });
            onSwitchForm("login");
        } catch (error: any) {
            console.error("Error resetting password:", error);
            toast({
                variant: "destructive",
                title: t("resetPassword.failedToResetPassword"),
                description: t("resetPassword.error", { error: error.message }),
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Card className="w-full max-w-sm mx-auto align-middle rounded-[7.5px] bg-narkis-bg-lighter border-narkis-border-darker2 border font-semibold">
                <CardHeader>
                    <CardTitle className="text-2xl">{t("resetPassword.title")}</CardTitle>
                    <CardDescription>{t("resetPassword.description", { username })}</CardDescription>
                </CardHeader>
                <CardContent>
                    <div className="grid gap-4">
                        <div className="grid gap-2">
                            <Label htmlFor="email">{t("resetPassword.email.mainLabel")}</Label>
                            <Input
                                id="email"
                                type="email"
                                placeholder={t("resetPassword.email.mainLabel")}
                                required
                                value={email}
                                className="rounded-[7.5px] bg-black"
                                tabIndex={0}
                                autoCapitalize="none"
                                disabled
                            />
                        </div>
                        <div className="grid gap-2">
                            <Label htmlFor="password">{t("resetPassword.newPassword.mainLabel")}</Label>
                            <Input
                                id="password"
                                type="password"
                                placeholder={t("resetPassword.newPassword.mainLabel")}
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="rounded-[7.5px] bg-black"
                                tabIndex={1}
                                autoCapitalize="none"
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleSubmit();
                                    }
                                }}
                            />
                        </div>
                        <div className="grid gap-2">
                            <Label htmlFor="confirmPassword">{t("resetPassword.confirmPassword.mainLabel")}</Label>
                            <Input
                                id="confirmPassword"
                                type="password"
                                placeholder={t("resetPassword.confirmPassword.mainLabel")}
                                required
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className="rounded-[7.5px] bg-black"
                                tabIndex={2}
                                autoCapitalize="none"
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleSubmit();
                                    }
                                }}
                            />
                        </div>
                        {loading ? (
                            <Button
                                disabled
                                className="rounded-[7.5px] w-full bg-gradient-to-r border border-narkis-border-darker from-stone-800 via-stone-900 to-stone-800 shadow-lg transform transition-all duration-200 ease-in-out font-extrabold text-narkis-text-bright"
                            >
                                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                {t("resetPassword.resetting")}
                            </Button>
                        ) : (
                            <Button
                                type="submit"
                                className="hover:shadow-buttonGlow rounded-[7.5px] w-full bg-gradient-to-r border border-narkis-border-darker from-stone-800 via-stone-900 to-stone-800 shadow-2xl transform active:translate-y-0.5 transition-all duration-200 ease-in-out font-extrabold text-narkis-text-bright"
                                onClick={handleSubmit}
                            >
                                {t("resetPassword.buttonReset")}
                            </Button>
                        )}
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};
export default ResetPasswordForm;
