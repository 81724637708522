import React, { useState, useRef, useEffect } from 'react';
import { Clock, Lock, Zap } from 'lucide-react';

const ScheduleCall: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const callOptions = [
        {
            icon: <Clock className="w-4 h-4" />,
            text: "Quick Chat",
            duration: "15 min",
            link: "https://cal.com/narkisai/15min",
        },
        {
            icon: <Zap className="w-4 h-4" />,
            text: "Product Demo",
            duration: "30 min",
            link: "https://cal.com/narkisai/30min",
        },
        {
            icon: <Lock className="w-4 h-4" />,
            text: "Business Inquiry",
            duration: "Flexible",
            link: "https://cal.com/narkisai/secret",
        },
    ];

    useEffect(() => {
        if (dropdownRef.current) {
            if (isOpen) {
                dropdownRef.current.style.maxHeight = `${dropdownRef.current.scrollHeight}px`;
                dropdownRef.current.style.opacity = '1';
            } else {
                dropdownRef.current.style.maxHeight = '0';
                dropdownRef.current.style.opacity = '0';
            }
        }
    }, [isOpen]);

    return (
        <div>
            <h4 className="font-bold mb-2">Connect with Us</h4>
            <p className="text-sm mb-2">
                Explore partnership opportunities or discuss how Narkis.ai can revolutionize your business.
            </p>
            <div className="relative">
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="w-full text-left px-4 py-2 rounded-md transition duration-300 font-semibold text-white bg-gradient-to-r from-purple-600 via-pink-500 to-red-500 hover:from-purple-700 hover:via-pink-600 hover:to-red-600 shadow-lg hover:shadow-xl transform overflow-hidden group"
                >
                    <span className="inline-block transition-transform duration-300 group-hover:translate-x-1">
                        Schedule a Call
                    </span>
                    <span className="float-right transition-transform duration-300 group-hover:-translate-x-1">
                        {isOpen ? '▼' : '▲'}
                    </span>
                </button>
                <div 
                    ref={dropdownRef}
                    className="absolute bottom-full left-0 right-0 mb-2 bg-white rounded-md shadow-xl z-10 overflow-hidden transition-all duration-300 ease-in-out border border-purple-200"
                    style={{ width: 'calc(100% - 16px)', marginLeft: '8px', marginRight: '8px', maxHeight: '0', opacity: '0' }}
                >
                    {callOptions.map((option, index) => (
                        <a
                            key={index}
                            href={option.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="px-4 py-2 hover:bg-purple-100 hover:text-purple-900 transition duration-200 flex items-center border-b border-purple-100 last:border-b-0"
                        >
                            {option.icon}
                            <span className="ml-2 font-medium">{option.text}</span>
                            <span className="ml-auto text-sm text-stone-500">{option.duration}</span>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ScheduleCall;