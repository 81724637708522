import React, { createContext, useContext, useState, useCallback } from 'react';

interface UserDataContextType {
    credits: number;
    giftCredits: number;
    trainingCredits: number;

    setCredits: (amount: number) => void;
    setGiftCredits: (amount: number) => void;
    setImageCredits: (amount: number, giftCredits: number) => void;
    setTrainingCredits: (amount: number) => void;
}

const useUserData = () => {
    const [credits, setCredits] = useState<number>(0);
    const [giftCredits, setGiftCredits] = useState<number>(0);
    const [trainingCredits, setTrainingCredits] = useState<number>(0);

    const setImageCredits = useCallback((credits: number, giftCredits: number) => {
        console.log("Setting image credits", credits, giftCredits);
        setCredits(credits);
        setGiftCredits(giftCredits);
    }, []);

    return {
        credits,
        giftCredits,
        trainingCredits,
 
        // methods
        setCredits: useCallback((amount: number) => setCredits(amount), []),
        setGiftCredits: useCallback((amount: number) => setGiftCredits(amount), []),
        setImageCredits,
        setTrainingCredits: useCallback((amount: number) => setTrainingCredits(amount), []),
    };
}

const UserDataContext = createContext<UserDataContextType | null>(null);

export const UserDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { credits, giftCredits, trainingCredits, setCredits, setGiftCredits, setImageCredits, setTrainingCredits } = useUserData();
    return (
        <UserDataContext.Provider value={{ credits, giftCredits, trainingCredits, setCredits, setGiftCredits, setImageCredits, setTrainingCredits }}>
            {children}
        </UserDataContext.Provider>
    )
}

export const useUserDataContext = () => {
    const context = useContext(UserDataContext);
    if (!context) {
        throw new Error("useUserDataContext must be used within an UserDataProvider");
    }
    return context;
};

export default UserDataProvider;
