import React, { useState, useEffect } from "react";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import TokenLoginForm from "./TokenLoginForm";
import ResetPasswordForm from "./ResetPasswordForm";
import Page from "../../../Page";
import { Metadata } from "../../../types/pageMetadata";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../../contexts/AuthContext";

interface AuthContainerProps {
    formName: string;
}

const AuthContainer = ({ formName }: AuthContainerProps) => {
    const [currentForm, setCurrentForm] = useState(formName || "login");
    const [emailConfirmed, setEmailConfirmed] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { login, updateUserTokens } = useAuth();

    const switchForm = (formName: string) => {
        console.log("Switching form to: ", formName);
        setCurrentForm(formName);
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const confirmed = params.get("confirmed");
        const accessToken = params.get("access_token");
        const refreshToken = params.get("refresh_token");

        if (confirmed === "true" && accessToken && refreshToken) {
            console.log("Email confirmed");
            // Handle successful email confirmation
            updateUserTokens(accessToken, refreshToken);
            setEmailConfirmed(true);
            setTimeout(() => {
                navigate("/dashboard");
            }, 3000); // Redirect to dashboard after 3 seconds
        } else {
            console.log("Email not confirmed");
        }
    }, [location, login, navigate]);

    const getMetadata = (): Metadata => {
        const baseMetadata: Partial<Metadata> = {
            keywords: [
                "Narkis.ai",
                "AI Image Generation",
                "User Account",
                "Personalized Photos",
                "AI-Powered Creativity",
                "Custom AI Images",
                "Digital Self-Expression",
                "AI Photography",
            ],
            author: "Narkis.ai",
            canonical: "https://narkis.ai/auth",
            robots: "noindex, nofollow",
            ogUrl: "https://narkis.ai/auth",
            twitterCard: "summary_large_image",
            twitterCreator: "@narkisai",
            ogImage: "summary-large-login.png",
            twitterImage: "summary-large-login.png",
        };

        switch (currentForm) {
            case "login":
                return {
                    ...baseMetadata,
                    title: "Login to Your Narkis.ai Account - Unleash Your AI Creativity",
                    description:
                        "Access your Narkis.ai account and dive into a world of AI-powered image creation. Transform your photos into stunning, personalized artworks.",
                    ogTitle: "Login to Narkis.ai - Your AI Image Journey Awaits",
                    ogDescription:
                        "Sign in to your Narkis.ai account and start creating captivating AI-generated images of yourself in any scenario imaginable.",
                    ogImage: "summary-large-login.png",
                    twitterTitle: "Login to Narkis.ai - Unlock Your AI Image Potential",
                    twitterDescription:
                        "Access your account and let our AI transform your photos into extraordinary, personalized images. Your creative journey starts here!",
                    twitterImage: "summary-large-login.png",
                } as Metadata;
            case "register":
                return {
                    ...baseMetadata,
                    title: "Join Narkis.ai - Start Your AI Image Creation Journey",
                    description:
                        "Sign up for Narkis.ai and embark on a journey of creative self-expression. Use AI to see yourself in ways you've never imagined before.",
                    ogTitle: "Register for Narkis.ai - Begin Your AI Image Adventure",
                    ogDescription:
                        "Create your Narkis.ai account and unlock the power to generate incredible, personalized AI images in any style or setting.",
                    ogImage: "summary-large-register.png",
                    twitterTitle: "Join Narkis.ai - Transform Your Photos with AI",
                    twitterDescription:
                        "Sign up now and start creating stunning, AI-generated images of yourself. Unleash your creativity with Narkis.ai!",
                    twitterImage: "summary-large-register.png",
                } as Metadata;
        }
        return baseMetadata as Metadata;
    };

    const metaData = getMetadata();

    return (
        <Page {...metaData}>
            <div className="flex flex-col md:justify-center items-center w-full mx-auto mt-5">
                {currentForm === "login" && <LoginForm onSwitchForm={switchForm} />}
                {currentForm === "register" && <RegisterForm onSwitchForm={switchForm} />}
                {currentForm === "tokenLogin" && <TokenLoginForm onSwitchForm={switchForm} />}
                {currentForm === "resetPassword" && <ResetPasswordForm onSwitchForm={switchForm} />}
                {emailConfirmed && (
                    <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                        <strong className="font-bold">Success!</strong>
                        <span className="block sm:inline"> Your email has been confirmed. You are now logged in.</span>
                        <span className="block sm:inline"> Redirecting to dashboard in 3 seconds...</span>
                    </div>
                )}
            </div>
        </Page>
    );
};

export default AuthContainer;
