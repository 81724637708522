import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SitemapRedirect: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.location.href = '/sitemap.xml';
  }, [navigate]);

  return <div>Redirecting to sitemap...</div>;
};

export default SitemapRedirect;