// GenerateView.tsx
import React, { useState, useEffect } from "react";
import ModelSelectionMenu from "../train/ModelSelectionMenu";
import ModelSelector from "./ModelSelector";
import GenerationMenu from "./GenerationMenu";
import { listTrainedModels, listGeneratedImages } from "../../../../services/api";

interface GenerateViewProps {
    images: any[];
    setImages: (images: any[]) => void;
    models: any[];
    onClickCreateNewModel: () => void;
    presets: any[];
}

const GenerateView: React.FC<GenerateViewProps> = ({ images, setImages, models, onClickCreateNewModel, presets }) => {
    const [selectedModel, setSelectedModel] = useState<any>(null);


    const handleModelSelect = (model: any) => {
        // called when user selects a model in the model selection menu
        setSelectedModel(model);
    };

    const addGeneratedImage = (image: any) => {
        setImages([image, ...images]);
    };

    const deleteGeneratedImage = async (imageId: string) => {
        // try {
        // 	setImages(prevImages => prevImages.filter(image => image.image_id !== imageId));
        // 	await deleteGeneratedImages([imageId], '');
        // } catch (error: any) {
        // 	console.error('Error deleting image:', error);
        // }
    };

    return (
        <div className="flex flex-col gap-4 items-center w-full bg-narkis-mobile-bg-lighter border border-narkis-mobile-border-darker rounded[6px]">
            <div className="w-full">
                <ModelSelector models={models} onModelSelect={handleModelSelect} onClickCreateNewModel={onClickCreateNewModel} />
                <div className="border-t border-narkis-mobile-border-lighter my-4 mx-2"></div>
                <GenerationMenu
                    selectedModel={selectedModel}
                    addGeneratedImage={addGeneratedImage}
                    deleteGeneratedImage={deleteGeneratedImage}
                    presets={presets}
                />
            </div>
        </div>
    );
};

export default GenerateView;
