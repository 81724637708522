import React from "react";
import { Card, CardContent } from "../../../shadcn/ui/card";
import { Metadata } from "../../../types/pageMetadata";
import Page from "../../../Page";
import Footer from "../../Footer";
import { Input } from "../../../shadcn/ui/input";
import { Textarea } from "../../../shadcn/ui/textarea";
import { Button } from "../../../shadcn/ui/button";

const contactMetadata: Metadata = {
    title: "Contact Us - Narkis.ai | Get in Touch with Our AI Photography Experts",
    description:
        "Have questions about Narkis.ai's AI-powered photography services? Reach out to our team for support, inquiries, or feedback. We're here to help you create stunning AI-generated images.",
    h1: "Contact Narkis.ai | Your AI Photography Partner",
    canonical: "https://narkis.ai/contact-us",
    keywords: [
        "Narkis.ai Contact",
        "AI Photography Support",
        "Customer Service",
        "Inquiries",
        "Feedback",
        "AI Image Generation Help",
        "Technical Support",
    ],
    author: "Narkis.ai",
    robots: "index, follow",
    ogTitle: "Contact Narkis.ai - Get Support for Your AI Photography Needs",
    ogDescription:
        "Reach out to Narkis.ai for any questions, support, or feedback about our AI-powered photography services. We're here to help you create amazing AI-generated images.",
    // ogImage: "summary-large-contact.png", // TODO: Update with CloudFront URL
    ogUrl: "https://narkis.ai/contact-us",
    twitterCard: "summary",
    twitterTitle: "Contact Narkis.ai - AI Photography Support and Inquiries",
    twitterDescription:
        "Need help with Narkis.ai's AI photography services? Contact our team for support, inquiries, or to share your feedback. We're here to assist you!",
    // twitterImage: "summary-large-contact.png", // TODO: Update with CloudFront URL
    twitterCreator: "@narkisai",
};

const ContactUs = () => {
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // TODO: Implement form submission logic
        console.log("Form submitted");
    };

    return (
        <Page {...contactMetadata}>
            <div className="flex flex-col items-center justify-center w-full overflow-x-hidden">
                <Card className="w-full max-w-4xl mx-auto my-8 p-4 bg-narkis-bg-lighter">
                    <CardContent>
                        <article className="prose lg:prose-xl">
                            <h1 className="text-4xl font-bold mb-6 text-primary">Contact Us</h1>

                            <p className="lead text-xl mb-8">
                                We value your feedback and are here to assist you with any questions or concerns. You can easily reach us by clicking the "Contact" button in the top menu or by using any of the below methods.
                            </p>

                            <h2 className="text-3xl font-semibold mt-12 mb-4 text-primary">Direct Contact</h2>
                            <ul className="list-disc pl-6 mb-4">
                                <li>Email: <a href="mailto:support@narkis.ai" className="text-blue-500 underline">support@narkis.ai</a></li>
                                <li>Twitter: <a href="https://twitter.com/narkisai" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">@narkisai</a></li>
                            </ul>

                            <h2 className="text-3xl font-semibold mt-12 mb-4 text-primary">Support Hours</h2>
                            <p className="mb-4">
                                Our support team is available Monday through Friday, 9:00 AM to 5:00 PM (EST). We strive to respond to all inquiries within 24 business hours.
                            </p>

                            <h2 className="text-3xl font-semibold mt-12 mb-4 text-primary">Feedback</h2>
                            <p className="mb-4">
                                Your feedback is crucial in helping us improve our services. Whether you have suggestions for new features or improvements to existing ones, we'd love to hear from you.
                            </p>

                            <p className="mt-8 text-sm text-gray-500">
                                For urgent matters outside of business hours, please use the emergency contact information provided in your account dashboard.
                            </p>
                        </article>
                    </CardContent>
                </Card>
            </div>
            <Footer />
        </Page>
    );
};

export default ContactUs;
