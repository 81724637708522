import React, { useState, useEffect, startTransition } from "react";
import logo from "../assets/logo.png";
import { useNavigate } from "react-router-dom";
import SpinningCoin from "./SpinningCoin";
import { useUserDataContext } from "../contexts/UserDataContext";
import { useAuth } from "../contexts/AuthContext";
import { useToast } from "../components/shadcn/ui/use-toast";
import * as Tooltip from "@radix-ui/react-tooltip";
import * as Popover from "@radix-ui/react-popover";
import { contact } from "../services/api";

import { Link } from "react-router-dom";
import { Button } from "../components/shadcn/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "../components/shadcn/ui/dropdown-menu";
import {
    Mail,
    CircleUser,
    CreditCard,
    Users,
    Origami,
    Wrench,
    LogIn,
    LogOut,
    Diamond,
    VenetianMask,
    PaintBucket,
    Paintbrush,
    CameraIcon,
} from "lucide-react";
import { columns, Model } from "../components/v2/models/columns";
import api, { listTrainedModels, deleteModel } from "../services/api";
import ModelsDrawer from "../components/v2/models/models-drawer";
import { Cross2Icon, HamburgerMenuIcon } from "@radix-ui/react-icons";
import { useTranslation } from "react-i18next";
import { GlobeIcon } from "@radix-ui/react-icons";
import {
    DropdownMenu as RadixDropdownMenu,
    DropdownMenuContent as RadixDropdownMenuContent,
    DropdownMenuItem as RadixDropdownMenuItem,
    DropdownMenuTrigger as RadixDropdownMenuTrigger,
} from "@radix-ui/react-dropdown-menu";
import { hasFlag, countries } from "country-flag-icons";
import { FcGlobe, FcPhotoReel } from "react-icons/fc";

async function getModels(): Promise<Model[]> {
    // Fetch data from your API here.
    try {
        const response = await listTrainedModels("", 0, 20);
        return response.data.models.map((model: any) => ({
            id: model.model_id,
            name: model.model_name,
            createdAt: model.date_created,
            completedAt: model.completed_est,
            status: model.status,
            gender: model.gender,
        }));
    } catch (error: any) {
        console.error("Error fetching models:", error);
        return [];
    }
}
const Header = () => {
    const { i18n } = useTranslation();

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    const navigate = useNavigate();
    const { toast } = useToast();
    const { user, token, logout } = useAuth();
    const { credits, giftCredits, trainingCredits } = useUserDataContext();

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isTooltipOpen, setIsTooltipOpen] = useState(true);
    const [isContactModalOpen, setIsContactModalOpen] = useState(false);
    const [contactMessage, setContactMessage] = useState("");
    // Remove userModels state as it's no longer needed

    const [testerExtraOptionsSwitch, setTesterExtraOptionsSwitch] = useState(localStorage.getItem("tester_options") === "true");
    const [userModels, setUserModels] = useState<any[]>([]);
    const [contactEmail, setContactEmail] = useState("");

    const labels = {
        title: {
            mainLabel: "Narkis.ai",
        },
        adminButton: {
            mainLabel: "Admin",
        },
        accountButton: {
            mainLabel: "Account",
        },
        loginButton: {
            mainLabel: "Login",
        },
        logoutButton: {
            mainLabel: "Logout",
        },
        enableTesterSwitch: {
            mainLabel: "Tester Tools",
        },
        playgroundButton: {
            mainLabel: "Playground",
        },
        falGenClick: {
            mainLabel: "Image Gen",
            loggedOutLabel: "Free Image Gen",
        },
        dashboardButton: {
            mainLabel: "Photo Gen",
        },
        pricingButton: {
            mainLabel: "Pricing",
        },
        contactButton: {
            mainLabel: "Contact",
            extraLabel: "Need help? Questions? Feature requests? Contact us, we're right here.",
        },
        contactMenu: {
            inputPlaceholder:
                "We're here for you! Share your thoughts, ideas, or anything on your mind. Your feedback is gold to us and we will most definitely get back to you!",
            emailInputPlaceholder: "Enter your email",
        },
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsTooltipOpen(false);
        }, 5000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (user && token) {
            getModels()
                .then((models) => {
                    startTransition(() => {
                        setUserModels(models);
                    });
                })
                .catch((error: any) => {
                    toast({
                        variant: "destructive",
                        title: "Failed to fetch your models",
                        description: `An error occurred while fetching your models: ${error.message}`,
                    });
                });
        }
    }, [user, token, toast]);

    const adminButtonClick = () => {
        if (user?.is_privileged) {
            navigate("/admin");
        }
    };

    const handleContactClick = () => {
        setIsContactModalOpen(true);
    };

    const handleContactSubmit = () => {
        // Handle form submission logic here
        console.log("Message:", contactMessage);
        try {
            const emailToUse = contactEmail || user?.email;
            contact(emailToUse as string, user?.user_id as string, contactMessage);
            toast({
                variant: "default",
                title: "Message sent",
                description: "Your message has been sent to our team. Talk to you soon!",
            });
        } catch (error: any) {
            toast({
                variant: "destructive",
                title: "Failed to send message",
                description: `An error occurred while sending message: ${error.message}`,
            });
        }
        setIsContactModalOpen(false);
        setContactMessage("");
    };

    const handleTesterOptionsChange = (isChecked: boolean) => {
        localStorage.setItem("tester_options", isChecked.toString());
        setTesterExtraOptionsSwitch(isChecked);
        window.location.reload();
    };

    const handlePlaygroundClick = () => {
        if (user?.is_tester || user?.is_privileged) {
            startTransition(() => {
                navigate("/playground");
            });
        } else {
            toast({
                variant: "destructive",
                title: "Access Denied",
                description: "Only testers and privileged users can access the playground.",
            });
        }
    };

    const handleDashboardClick = () => {
        startTransition(() => {
            navigate("/dashboard");
        });
    };

    const handleFalGenClick = () => {
        startTransition(() => {
            navigate("/image-gen");
        });
    };

    const handleDeleteModel = async (modelId: string) => {
        if (!userModels.find((model) => model.model_id === modelId && model.owner_id === user?.user_id)) {
            toast({
                variant: "destructive",
                title: "Access Denied",
                description: "You can only delete models you own.",
            });
            return;
        }
        await deleteModel(modelId);
        setUserModels(userModels.filter((model) => model.model_id !== modelId));
    };

    const onLogoutClick = () => {
        startTransition(() => {
            logout();
            navigate("/login");
        });
    };

    const handleLoginClick = () => {
        startTransition(() => {
            navigate("/login");
        });
    };

    const handlePricingClick = () => {
        startTransition(() => {
            navigate("/pricing");
        });
    };

    const getTierClass = (user_tier: string) => {
        switch (user_tier) {
            case "Free":
                return "text-blue-500";
            case "spark":
                return "text-yellow-500 animate-pulse";
            case "flow":
                return "text-emerald-400 animate-pulse";
            case "avantgarde":
                return "text-pink-500 animate-bounce";
            case "zenith":
                return "text-purple-500 animate-pulse";
            default:
                return "text-gray-500";
        }
    };

    const isMobile = window.innerWidth < 768;

    const isBlog = window.location.pathname.startsWith("/blog");
    const isFalGen = window.location.pathname.startsWith("/image-gen");
    const isDashboard = window.location.pathname.startsWith("/dashboard");

    return (
        <header className=" sticky top-0 z-50 flex h-16 items-center justify-between border-b border-narkis-border-darker2 bg-narkis-bg px-4 shadow-sm sm:px-6 lg:px-8">
            <div className="flex items-center gap-4">
                <Link to="/" className="flex items-center space-x-2">
                    <img src={logo} alt="Logo" className="h-8 md:h-10 w-auto transform scale-x-[-1]" />
                    <span
                        className="
                        bg-gradient-to-r from-purple-400 via-pink-500 to-yellow-500
                        bg-[length:1000%_400%] 
                        bg-fixed
                        bg-clip-text 
                        text-3xl 
                        font-extrabold 
                        text-transparent 
                        sm:text-4xl
                        animate-holographic
                        "
                    >
                        {labels.title.mainLabel}
                        {isBlog && <span className="text-2xl font-sans font-light ml-3">Blog</span>}
                    </span>
                </Link>
            </div>

            <div className="flex right items-center gap-1 text-narkis-icons">
                {/* Credits, gift credits and training runs */}
                {user && token && !isMobile && (
                    <div className="flex items-center ml-6 mr-2 md:mr-6 md:gap-4">
                        {/* credits */}
                        <div className={`text-fruity-mangoOrange flex items-center space-x-1 ${getTierClass(user?.user_tier)}`}>
                            <SpinningCoin />
                            <span className="text-sm font-medium">{credits}</span>
                        </div>

                        {/* gift credits */}
                        {giftCredits > 0 && (
                            <div className={`flex text-fruity-kiwiGreen items-center space-x-1 ${getTierClass(user?.user_tier)}`}>
                                <SpinningCoin className="h-4 w-4 fill-fruity-kiwiGreen cursor-pointer" />
                                <span className="text-sm font-medium">+{giftCredits}</span>
                            </div>
                        )}

                        {/* training credits */}
                        <div className={`flex items-center space-x-1 ${getTierClass(user?.user_tier)}`}>
                            <VenetianMask className="h-5 w-5" />
                            <span className="text-sm font-medium">{trainingCredits}</span>
                        </div>
                    </div>
                )}

                {/* Tester Tools */}
                {user && token && (user.is_tester || user.is_privileged) && !isMobile && (
                    <Button
                        className={`
                            ${
                                testerExtraOptionsSwitch
                                    ? "bg-fruity-kiwiGreen text-black animate-color-cycle font-extrabold border border-black"
                                    : "text-narkis-icons-tester"
                            }
                        `}
                        variant="outline"
                        size="sm"
                        onClick={handlePlaygroundClick}
                    >
                        <Wrench className="md:mr-1 h-5 w-5" />
                        {!isMobile ? <span className="text-sm font-medium">{labels.playgroundButton.mainLabel}</span> : null}
                    </Button>
                )}

                {!user ? (
                    <Button className="relative group" variant="outline" size="sm" onClick={handleFalGenClick}>
                        <Paintbrush className="md:mr-1 h-5 w-5 text-fuchsia-400 z-10 group-hover:text-yellow-100 transition duration-1000" />
                        {!isMobile ? (
                            <span className="text-sm font-medium relative z-10">
                                <span className="group-hover:text-yellow-100 text-transparent bg-clip-text bg-gradient-to-r brightness-125 from-fuchsia-400 via-purple-400 to-blue-400 group-hover:opacity-100 transition duration-300">
                                    {labels.falGenClick.loggedOutLabel}
                                </span>
                            </span>
                        ) : null}
                        <div className="absolute inset-0 rounded-md opacity-0 group-hover:opacity-100 transition duration-300">
                            <div
                                className="
                                    bg-gradient-to-r from-fuchsia-400 via-purple-400 to-blue-400 
                                    absolute inset-0 rounded-md blur opacity-75  
                                    group-hover:duration-200 group-hover:opacity-100 animate-tilt transition duration-1000
                                "
                            ></div>
                        </div>
                    </Button>
                ) : isDashboard ? (
                    <Button className="relative group" variant="outline" size="sm" onClick={handleFalGenClick}>
                        <Paintbrush className="md:mr-1 h-5 w-5 text-fuchsia-400 z-10 group-hover:text-yellow-100 transition duration-1000" />
                        {!isMobile ? (
                            <span className="text-sm font-medium relative z-10">
                                <span className="group-hover:text-yellow-100 text-transparent bg-clip-text bg-gradient-to-r brightness-125 from-fuchsia-400 via-purple-400 to-blue-400 group-hover:opacity-100 transition duration-300">
                                    {labels.falGenClick.mainLabel}
                                </span>
                            </span>
                        ) : null}
                        <div className="absolute inset-0 rounded-md opacity-0 group-hover:opacity-100 transition duration-300">
                            <div
                                className="
                                        bg-gradient-to-r from-fuchsia-400 via-purple-400 to-blue-400 
                                        absolute inset-0 rounded-md blur opacity-75  
                                        group-hover:duration-200 group-hover:opacity-100 animate-tilt transition duration-1000
                                    "
                            ></div>
                        </div>
                    </Button>
                ) : (
                    <Button className="relative group" variant="outline" size="sm" onClick={handleDashboardClick}>
                        <CameraIcon className="md:mr-1 h-5 w-5 text-pink-600 z-10 group-hover:text-yellow-100 transition duration-1000" />
                        {!isMobile ? (
                            <span className="text-sm font-medium relative z-10">
                                <span className="group-hover:text-yellow-100 text-transparent bg-clip-text bg-gradient-to-r brightness-125 from-pink-600 via-purple-600 to-blue-500 group-hover:opacity-100 transition duration-300">
                                    {labels.dashboardButton.mainLabel}
                                </span>
                            </span>
                        ) : null}
                        <div className="absolute inset-0 rounded-md opacity-0 group-hover:opacity-100 transition duration-300">
                            <div
                                className="
                                        bg-gradient-to-r from-pink-500 via-purple-500 to-blue-400 
                                        absolute inset-0 rounded-md blur opacity-75  
                                        group-hover:duration-200 group-hover:opacity-100 animate-tilt transition duration-1000
                                    "
                            ></div>
                        </div>
                    </Button>
                )}

                {/* contact button */}
                {!isMobile && (
                    <div>
                        <Tooltip.Provider>
                            <Tooltip.Root open={isTooltipOpen}>
                                <Tooltip.Trigger asChild>
                                    <Popover.Root open={isContactModalOpen} onOpenChange={setIsContactModalOpen}>
                                        <Popover.Trigger asChild>
                                            <Button
                                                variant="outline"
                                                size="sm"
                                                className="relative group text-narkis-icons-contact"
                                                onClick={handleContactClick}
                                            >
                                                <Mail className="md:mr-1 h-5 w-5 z-10" />
                                                {!isMobile ? (
                                                    <span className="text-sm font-medium z-10">
                                                        {labels.contactButton.mainLabel}
                                                    </span>
                                                ) : null}
                                                <div className="absolute inset-0 rounded-md opacity-0 group-hover:opacity-100 transition duration-300">
                                                    <div
                                                        className="
                                                            bg-gradient-to-r from-pink-600 via-purple-600 to-blue-500 
                                                            absolute inset-0 rounded-md blur opacity-75  
                                                            group-hover:duration-200 group-hover:opacity-100 animate-tilt transition duration-1000
                                                            group-hover:text-stone-950
                                                        "
                                                    ></div>
                                                </div>
                                            </Button>
                                        </Popover.Trigger>
                                        <Popover.Content className="bg-narkis-bg p-4 rounded-[6px] shadow-lg w-80 border-narkis-border border">
                                            <h3 className="text-lg font-bold  mb-2">Contact Us</h3>
                                            {!user && !token && (
                                                <input
                                                    type="email"
                                                    placeholder="Email"
                                                    value={contactEmail}
                                                    onChange={(e) => setContactEmail(e.target.value)}
                                                    className="w-full h-10 p-2 mb-2 border text-sm border-narkis-border rounded focus:outline-none focus:ring-2 focus:ring-purple-500 bg-narkis-bg text-narkis-text-label font-medium"
                                                />
                                            )}
                                            <textarea
                                                className="w-full h-32 p-2 border text-sm border-narkis-border rounded resize-none focus:outline-none focus:ring-2 focus:ring-purple-500 bg-narkis-bg text-narkis-text-label font-medium"
                                                placeholder={labels.contactMenu.inputPlaceholder}
                                                value={contactMessage}
                                                onChange={(e) => setContactMessage(e.target.value)}
                                            ></textarea>
                                            <div className="flex justify-end mt-4">
                                                <Button
                                                    variant="outline"
                                                    size="sm"
                                                    className={`
                                                        font-extrabold border-narkis-border border rounded-[3px]
                                                        bg-gradient-to-tr from-orange-400 via-orange-200 to-orange-600 bg-clip-text text-transparent 
                                                        transition-transform ease-in-out
                                                        active:scale-99 active:translate-y-0.5
                                                        animate-gradient-x bg-[length:400%_400%]
                                                        `}
                                                    onClick={handleContactSubmit}
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                            <Popover.Close className="absolute top-2 right-2 text-stone-500 hover:text-stone-700">
                                                <Cross2Icon className="h-6 w-6" />
                                            </Popover.Close>
                                        </Popover.Content>
                                    </Popover.Root>
                                </Tooltip.Trigger>
                                <Tooltip.Content
                                    side="right"
                                    align="center"
                                    className="z-20 bg-narkis-lightest2 rounded-lg text-sm h-6 text-stone-950 p-2 shadow animate-bounce flex items-center justify-center"
                                >
                                    {" "}
                                    <span className="text-sm font-medium">{labels.contactButton.extraLabel}</span>
                                </Tooltip.Content>
                            </Tooltip.Root>
                        </Tooltip.Provider>
                    </div>
                )}

                {/* My Models */}
                {user && token && !isMobile && (
                    <Button variant="outline" size="sm" className="relative" onClick={() => setIsDrawerOpen(true)}>
                        <Origami className="md:mr-1 h-5 w-5" />
                        <span className="text-sm font-medium">My Models</span>
                    </Button>
                )}

                {!isMobile && (
                    <Button variant="outline" size="sm" onClick={() => startTransition(() => navigate("/pricing"))}>
                        <CreditCard className="mr-2 h-4 w-4" />
                        <span>Pricing</span>
                    </Button>
                )}

                {/* Admin */}
                {user && token && user.is_privileged && !isMobile && (
                    <Button variant="outline" size="sm" onClick={adminButtonClick}>
                        <Users className="md:mr-1 h-5 w-5" />
                        {!isMobile ? <span className="text-sm font-medium">{labels.adminButton.mainLabel}</span> : null}
                    </Button>
                )}

                {/* Language Switcher */}
                <RadixDropdownMenu>
                    <RadixDropdownMenuTrigger asChild>
                        <Button variant="outline" size="sm" className="relative">
                            <FcGlobe className="h-5 w-5 text-narkis-icons" />
                            <span className="sr-only">Language</span>
                        </Button>
                    </RadixDropdownMenuTrigger>
                    <RadixDropdownMenuContent
                        align="end"
                        className="bg-narkis-bg p-4 rounded-[6px] shadow-lg w-fit border-narkis-border border
                    text-sm text-narkis-text-bright font-semibold"
                    >
                        <RadixDropdownMenuItem
                            className="flex items-center space-x-2 px-3 py-2 rounded cursor-pointer hover:bg-narkis-bg-darker"
                            onClick={() => changeLanguage("en")}
                        >
                            <img
                                src="http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg"
                                alt="English"
                                className="h-4 w-4"
                            />
                            <span>English</span>
                        </RadixDropdownMenuItem>
                        <RadixDropdownMenuItem
                            className="flex items-center space-x-2 px-3 py-2 rounded cursor-pointer hover:bg-narkis-bg-darker"
                            onClick={() => changeLanguage("ru")}
                        >
                            <img
                                src="http://purecatamphetamine.github.io/country-flag-icons/3x2/RU.svg"
                                alt="Russian"
                                className="h-4 w-4"
                            />{" "}
                            <span>Русский</span>
                        </RadixDropdownMenuItem>
                        <RadixDropdownMenuItem
                            className="flex items-center space-x-2 px-3 py-2 rounded cursor-pointer hover:bg-narkis-bg-darker"
                            onClick={() => changeLanguage("uae")}
                        >
                            <img
                                src="http://purecatamphetamine.github.io/country-flag-icons/3x2/AE.svg"
                                alt="Arabic"
                                className="h-4 w-4"
                            />{" "}
                            <span>العربية</span>
                        </RadixDropdownMenuItem>
                    </RadixDropdownMenuContent>
                </RadixDropdownMenu>

                {/* Account/Logout */}
                {user && token ? (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="outline" size="sm" className="relative">
                                {!isMobile ? <CircleUser className="h-5 w-5" /> : <HamburgerMenuIcon className="h-5 w-5" />}
                                <span className="sr-only">User menu</span>
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            {isMobile && (
                                <>
                                    <DropdownMenuItem className="cursor-pointer">
                                        <Diamond className="mr-2 h-4 w-4" />
                                        <span>Credits: {credits}</span>
                                    </DropdownMenuItem>
                                    {giftCredits > 0 && (
                                        <DropdownMenuItem className="cursor-pointer">
                                            <Diamond className="mr-2 h-4 w-4" />
                                            <span>Gift Credits: +{giftCredits}</span>
                                        </DropdownMenuItem>
                                    )}
                                    <DropdownMenuItem className="cursor-pointer">
                                        <VenetianMask className="mr-2 h-4 w-4" />
                                        <span>Training Credits: {trainingCredits}</span>
                                    </DropdownMenuItem>
                                    <DropdownMenuSeparator />
                                    <DropdownMenuItem onClick={() => setIsDrawerOpen(true)} className="cursor-pointer">
                                        <Origami className="mr-2 h-4 w-4" />
                                        <span>My Models</span>
                                    </DropdownMenuItem>
                                    <DropdownMenuSeparator />
                                </>
                            )}

                            {isMobile && (
                                <>
                                    <DropdownMenuItem
                                        className="cursor-pointer"
                                        onClick={() => startTransition(() => navigate("/pricing"))}
                                    >
                                        <CreditCard className="mr-2 h-4 w-4" />
                                        <span>Pricing</span>
                                    </DropdownMenuItem>
                                    <DropdownMenuSeparator />
                                </>
                            )}

                            <DropdownMenuItem className="cursor-pointer">
                                <CircleUser className="mr-2 h-4 w-4" />
                                <span>{labels.accountButton.mainLabel}</span>
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem onClick={onLogoutClick} className="cursor-pointer">
                                <LogOut className="mr-2 h-4 w-4" />
                                {labels.logoutButton.mainLabel}
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                ) : (
                    <Button
                        variant="outline"
                        size="sm"
                        className="relative"
                        onClick={() => startTransition(() => navigate("/login"))}
                    >
                        {!isMobile ? <CircleUser className="h-5 w-5" /> : <HamburgerMenuIcon className="h-5 w-5" />}
                        <span className="sr-only">Login</span>
                    </Button>
                )}
            </div>
            <ModelsDrawer isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
        </header>
    );
};

export default Header;
