// PhotoGalleryView.tsx
import React from "react";
import ImageMenu from "../../../v2/pages/generate/ImageMenu";
import { Card, CardTitle, CardDescription, CardContent } from "../../../shadcn/ui/card";
import { useTranslation } from "react-i18next";

interface PhotoGalleryViewProps {
    models: any;
    images: any;
    fetchImages: (
        page: number,
        size: number,
        sortBy: string,
        filterSelectedModelId: string,
        filterFavorites: boolean,
        filterDeleted: boolean
    ) => Promise<any>;
    setImages: any;
}

const PhotoGalleryView: React.FC<PhotoGalleryViewProps> = ({ models, images, fetchImages, setImages }) => {
    const { t } = useTranslation("imagemenu");
    const doNothing = () => {};

    return (
        <div className="">
            {images && images.length !== 0 ? (
                <ImageMenu
                    images={images}
                    models={models}
                    setImages={setImages}
                    fetchImages={fetchImages}
                    onImageDeletedFilterChange={doNothing}
                    onImageFavoritesFilterChange={doNothing}
                    onImageGridFilterChange={doNothing}
                    onImageGridModelChange={doNothing}
                    onImageGridSortChange={doNothing}
                />
            ) : (
                <div className="text-center p-4 text-narkis-mobile-text">
                    <Card className="text-lg font-semibold bg-narkis-mobile-bg-lighter border border-narkis-mobile-border-darker2 p-4 space-y-4">
                    <CardTitle>{t("welcome.title")}</CardTitle>
                        <CardDescription>
                            {t("welcome.description")}
                        </CardDescription>
                        <CardContent>
                            <p className="text-sm mt-2">
                                {t("welcome.moreInfo")}
                                <a href="/tutorial" className="text-blue-500 underline">
                                    {t("welcome.tutorial1")}
                                </a>{" "}
                                {t("welcome.tutorial2")}
                            </p>
                        </CardContent>
                    </Card>
                </div>
            )}
        </div>
    );
};

export default PhotoGalleryView;
