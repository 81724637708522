import React from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../shadcn/ui/tabs";
import SubMenuHeader from "../../SubMenuHeader";
import PresetsGenerate from "./PresetsGenerate";
import CustomGenerate from "./CustomGenerate";
import { useLocalStorageState } from "../../../../hooks/useLocalStorageState";
import { useTranslation } from "react-i18next";

const GenerationMenu = ({
    selectedModel,
    addGeneratedImage,
    deleteGeneratedImage,
    presets
}: {
    selectedModel: any;
    addGeneratedImage: (image: any) => void;
    deleteGeneratedImage: (imageId: string) => void;
    presets: any[];
}) => {
    const { t } = useTranslation("generate");
    const [activeTab, setActiveTab] = useLocalStorageState("activeTab", "preset");


    return (
        <div className="p-1 relative flex flex-col min-w-full bg-narkis-mobile-bg-lighter">
            <div className="flex-grow-0 flex-shrink-0 flex-1">
                <SubMenuHeader title={t("title")} />
            </div>
            <div className="flex-grow overflow-y-auto">
                <Tabs defaultValue={activeTab} onValueChange={setActiveTab}>
                    <TabsList
                        aria-label="Photo Generation Modes"
                        className="w-full p-0 rounded border-b-2 border-pink-700 bg-narkis-bg overflow-auto"
                    >
                        <TabsTrigger value="preset">{t("presetsTab.title")}</TabsTrigger>
                        <TabsTrigger value="custom">{t("customTab.title")}</TabsTrigger>
                    </TabsList>
                    {/* preset tab */}
                    <TabsContent value="preset" className="p-4">
                        <PresetsGenerate selectedModel={selectedModel} addGeneratedImage={addGeneratedImage} presets={presets} />
                    </TabsContent>

                    {/* custom prompting tab */}
                    <TabsContent value="custom" className="p-4">
                        <CustomGenerate
                            trainedModel={selectedModel}
                            addGeneratedImage={addGeneratedImage}
                            deleteGeneratedImage={deleteGeneratedImage}
                        />
                    </TabsContent>
                </Tabs>
            </div>
        </div>
    );
};

export default GenerationMenu;
