// Page.js
import React, { useEffect, useState } from "react";
import Header from "./Header";
import VersionWatermark from "./VersionWatermark";
import "./Page.css";
import { useAuth } from "../contexts/AuthContext";
import { TesterOptionsProvider } from "./TesterOptionsContext";
import { Helmet } from "react-helmet-async";
import { Metadata } from "./types/pageMetadata";
import { generateStructuredData } from "../utils/structuredData";
import DailyGiftModal from "./DailyGiftModal";

interface PageProps extends Metadata {
    children: React.ReactNode;
}

const Page: React.FC<PageProps> = ({
    title,
    description,
    canonical,
    keywords,
    author,
    robots,
    ogTitle,
    ogDescription,
    ogImage,
    ogUrl,
    twitterCard,
    twitterTitle,
    twitterDescription,
    twitterImage,
    twitterCreator,
    twitterUrl,
    children,
}) => {
    const { user, isDailyGiftAvailable, redeemDailyGift } = useAuth();
    const [darkMode, setDarkMode] = useState(true);
    const structuredData = generateStructuredData();
    const [showGiftModal, setShowGiftModal] = useState(false);

    const SITE_URL = process.env.REACT_APP_SITE_URL || 'https://narkis.ai';
  
    const getImageUrl = (imageName: string) => {        
        // Otherwise, construct the full path for images in the icons folder
        return `${SITE_URL}/icons/${imageName}`;
    };

    useEffect(() => {
        if (user && isDailyGiftAvailable()) {
          setShowGiftModal(true);
        }
      }, [user, isDailyGiftAvailable]);



    useEffect(() => {
        document.body.classList.add("min-h-screen");
        document.body.classList.add("bg-background");
        document.body.classList.add("font-sans");
        document.body.classList.add("antialiased");
        document.body.classList.toggle("dark", darkMode);
    }, [darkMode]);

    function generateShareUrl(baseUrl: string, platform: "twitter" | "facebook" | "linkedin" = "twitter") {
        const url = new URL(baseUrl);

        url.searchParams.append("utm_source", platform);
        url.searchParams.append("utm_medium", "social");
        url.searchParams.append("utm_campaign", "share");

        return url.toString();
    }

    return (
        <TesterOptionsProvider>
            <Helmet>
                {title && <title>{title}</title>}
                {description && <meta name="description" content={description} />}
                {canonical && <link rel="canonical" href={canonical} />}
                {keywords && <meta name="keywords" content={keywords.join(", ")} />}
                <meta name="author" content={author || "Narkis.ai"} />
                <meta name="robots" content={robots || "index, follow"} />
                {/* og fields are for displaying how the url is displayed when shared on social media
                    note: the URL should in general be the same as the canonical
                */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content={ogUrl || canonical} />
                <meta property="og:title" content={ogTitle || title} />
                <meta property="og:description" content={ogDescription || description} />
                {ogImage && <meta property="og:image" content={getImageUrl(ogImage) + "?twitter"} />}
                {/* these twitter fields are for displaying how the shared urls are displayed when shared on twitter
                    for example you might want a different image for twitter, or different UTM tracking
                */}
                <meta property="twitter:card" content={twitterCard || "summary_large_image"} />
                <meta property="twitter:url" content={twitterUrl || generateShareUrl(canonical, "twitter")} />
                <meta property="twitter:title" content={twitterTitle || ogTitle || title} />
                <meta property="twitter:description" content={twitterDescription || ogDescription || description} />
                {twitterImage && <meta property="twitter:image" content={getImageUrl(twitterImage) + "?twitter"} />}
                {twitterCreator && <meta property="twitter:creator" content={twitterCreator || "@narkisai"} />}
                {structuredData.map((data, index) => (
                    <script key={index} type="application/ld+json">
                        {JSON.stringify(data)}
                    </script>
                ))}
                )
            </Helmet>
            <div
                className="
                bg-fixed flex flex-col
                w-screen max-w-full items-center
                animate-[PageGradientFlow_15s_linear_infinite] 
                overflow-x-hidden overflow-y-auto min-h-screen bg-narkis-bg
                "
            >
                <DailyGiftModal
                    isOpen={showGiftModal}
                    onClose={() => setShowGiftModal(false)}
                />
                <div className="w-full max-w-full top-0">
                    <Header />
                </div>

                <div className="w-full max-w-full flex flex-grow">
                    <div
                        className="flex flex-grow flex-col
                        text-stone-200 w-full max-w-full h-min-full
                        drop-shadow-[0_0_0.75rem_#000] box-border rounded-[7.5px]"
                    >
                        {children}
                    </div>
                </div>
                {user && (user.is_privileged || user.is_tester) && <VersionWatermark />}
            </div>
        </TesterOptionsProvider>
    );
};

export default Page;
