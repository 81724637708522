import React, { useEffect, useState, useTransition } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { isTokenExpired, refreshAccessToken } from '../services/api';

const ProtectedRoute = () => {
  const contextValue = useAuth();
  const { user, token } = contextValue;
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);
  const [isPending, startTransition] = useTransition();
  const location = useLocation();

  useEffect(() => {
    let isMounted = true; // Track if component is mounted

    const checkToken = async () => {
      if (token && isTokenExpired(token) && location.pathname.toLowerCase() !== '/login') {
        try {
          const newToken = await refreshAccessToken();
          if (isMounted) {
            if (newToken) {
              startTransition(() => {
                setIsAuthenticated(true);
              });
            } else {
              startTransition(() => {
                setIsAuthenticated(false);
              });
            }
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
          if (isMounted) {
            startTransition(() => {
              setIsAuthenticated(false);
            });
          }
        }
      }
    };

    checkToken();

    return () => {
      isMounted = false; // Set to false when component unmounts
    };
  }, [location, token]);

  if (!isAuthenticated) {
    console.log("User not authenticated. Navigating to login page");
    return <Navigate to="/login" replace />;
  }

  if (location.pathname.toLowerCase() === '/admin' && !user?.is_privileged) {
    console.log("User not privileged. Navigating to home page");
    return <Navigate to="/dashboard" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
