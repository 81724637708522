import React, { useEffect, useCallback, useState, useMemo } from "react";
import { Dialog, DialogContent } from "../../../components/shadcn/ui/dialog";
import { Button } from "../../../components/shadcn/ui/button";
import { ChevronLeft, ChevronLeftCircle, ChevronRight, Copy, Download, Edit3, Menu, Share2, Wand2, X } from "lucide-react";
import { CaretLeftIcon, CaretRightIcon } from "@radix-ui/react-icons";
import { Switch } from "antd";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@radix-ui/react-tooltip";

interface ImageViewerProps {
    image: {
        image_id: string;
        image_url: string;
        time_elapsed: number;
        [key: string]: any;
    };
    onClose: () => void;
    onPrevious: () => void;
    onNext: () => void;
}

const ImageViewer: React.FC<ImageViewerProps> = ({ image, onClose, onPrevious, onNext }) => {
    const [isMobile, setIsMobile] = useState(() => window.innerWidth < 768);
    const [showControls, setShowControls] = useState(true);
    const [watermarkEnabled, setWatermarkEnabled] = useState(false);

    useEffect(() => {
        const checkMobile = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);


    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === "ArrowLeft") {
                onPrevious();
            } else if (event.key === "ArrowRight") {
                onNext();
            }
        },
        [onPrevious, onNext]
    );

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    const handleDownload = () => {
        const link = document.createElement("a");
        link.href = image.image_url;
        link.download = `${image.image_id}.jpg`;
        link.click();
    };

    const ImageComponent = useMemo(() => (
        <div className="relative group">
            <img src={image.image_url} alt={`${image.image_id}`} className="w-full h-auto max-h-[80vh] object-contain" />
            <div className={`absolute inset-0 flex items-center justify-between px-4 ${isMobile ? 'opacity-100' : 'opacity-50'} group-hover:opacity-100 transition-opacity duration-300`}>
                <Button
                    variant="ghost"
                    onClick={onPrevious}
                    className="p-2 rounded-full bg-black bg-opacity-50 hover:bg-opacity-70 transition-all"
                >
                    <ChevronLeft className="h-8 w-8" />
                </Button>
                <Button
                    variant="ghost"
                    onClick={onNext}
                    className="p-2 rounded-full bg-black bg-opacity-50 hover:bg-opacity-70 transition-all"
                >
                    <ChevronRight className="h-8 w-8" />
                </Button>
            </div>
        </div>
    ), [image.image_url, image.image_id, onPrevious, onNext]);


    
    const ControlsComponent = useMemo(() => (
        <div className={`p-6 space-y-6 ${isMobile ? 'bg-black bg-opacity-75' : ''}`}>
                    <div className="flex items-center gap-1 justify-end">
                        <TooltipProvider>
                            <Tooltip delayDuration={10}>
                                <TooltipTrigger asChild>
                                    <Button
                                        onClick={handleDownload}
                                        variant="outline"
                                        className="bg-blue-600 hover:bg-blue-700 text-white border-none"
                                    >
                                        <Download className="h-4 w-4" />
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                    <p>Download this image</p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                        <div className="flex items-center flex-col">
                            <span className="text-xs font-medium">add watermark</span>
                            <Switch checked={watermarkEnabled} onChange={setWatermarkEnabled} />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button
                                        onClick={() => {}}
                                        variant="outline"
                                        className="w-full bg-green-600 hover:bg-green-700 text-white border-none"
                                    >
                                        <Copy className="mr-2 h-4 w-4" />
                                        More Like This
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                    <p>Generate similar images</p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button
                                        onClick={() => {}}
                                        variant="outline"
                                        className="w-full bg-red-600 hover:bg-red-700 text-white border-none"
                                    >
                                        <Wand2 className="mr-2 h-4 w-4" />
                                        Make 4K
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                    <p>Enhance to 4K resolution</p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button
                                        onClick={() => {}}
                                        variant="outline"
                                        className="w-full bg-yellow-600 hover:bg-yellow-700 text-white border-none"
                                    >
                                        <Edit3 className="mr-2 h-4 w-4" />
                                        Edit
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                    <p>Edit this image</p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button
                                        onClick={() => {}}
                                        variant="outline"
                                        className="w-full bg-purple-600 hover:bg-purple-700 text-white border-none"
                                    >
                                        <Share2 className="mr-2 h-4 w-4" />
                                        Share
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                    <p>Share on social media</p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    </div>
        </div>
    ), [isMobile, handleDownload, watermarkEnabled]);

    const MobileView = useMemo(() => (
        <div className="fixed inset-0 bg-black z-50 flex flex-col">
            <div className="relative ">
                {ImageComponent}
                <Button
                    variant="ghost"
                    onClick={onClose}
                    className="absolute top-4 left-4 p-2 rounded-full bg-black bg-opacity-50"
                >
                    <X className="h-6 w-6 text-white" />
                </Button>
                <Button
                    variant="ghost"
                    onClick={() => setShowControls(!showControls)}
                    className="absolute top-4 right-4 p-2 rounded-full bg-black bg-opacity-50"
                >
                    <Menu className="h-6 w-6 text-white" />
                </Button>
            </div>
            {showControls && ControlsComponent}
        </div>
    ), [ImageComponent, ControlsComponent, onClose, showControls]);


    const DesktopView = useMemo(() => (
        <Dialog open={true} onOpenChange={onClose}>
            <DialogContent className="max-w-4xl p-0 overflow-hidden bg-gradient-to-br from-gray-900 to-gray-800 text-white">
                {ImageComponent}
                {ControlsComponent}
            </DialogContent>
        </Dialog>
    ), [ImageComponent, ControlsComponent, onClose]);



return isMobile ? MobileView : DesktopView;

};

export default ImageViewer;