import React, { useState, useEffect } from "react";
import { listPresets, createPreset, updatePreset, deletePreset } from "../../../../services/api";
import { Button } from "../../../shadcn/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../../../shadcn/ui/card";
import { Input } from "../../../shadcn/ui/input";
import { Label } from "../../../shadcn/ui/label";
import { Textarea } from "../../../shadcn/ui/textarea";
import { Checkbox } from "../../../shadcn/ui/checkbox";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../shadcn/ui/select";
import { Table, TableBody, TableCell, TableHeader, TableHead, TableRow } from "../../../shadcn/ui/table";
import PresetAdmin from "./PresetAdmin";
import { useToast } from "../../../shadcn/ui/use-toast";
import { ScrollArea } from "../../../shadcn/ui/scroll-area";
import { Popover, PopoverContent, PopoverTrigger } from "../../../shadcn/ui/popover";
import { ChevronDownIcon } from "lucide-react";
import { useLocalStorageState } from "../../../../hooks/useLocalStorageState";

const PresetsAdmin = () => {
    const { toast } = useToast();
    const [presets, setPresets] = useState<any[]>([]);
    const [selectedPreset, setSelectedPreset] = useState<any>(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedColumns, setSelectedColumns] = useLocalStorageState("presetsAdmin_selectedColumns", [
        "name",
        "description",
        "gender",
        "base_model",
        "sampler",
    ]);

    const baseModels = ["hyperrealism_30.safetensors"];
    const samplerOptions = ["DPM++ 2M Karras", "Euler a", "DPM adaptive"];
    const adetailerModelOptions = [
        "face_yolov8n.pt",
        "face_yolov8s.pt",
        "person_yolov8n-seg.pt",
        "person_yolov8s-seg.pt",
        "mediapipe_face_full",
        "mediapipe_face_short",
        "mediapipe_face_mesh",
    ];

    const [newPreset, setNewPreset] = useState({
        name: "",
        description: "",
        positive_prompt: "",
        negative_prompt: "",
        gender: "man",
        positive_lora: "",
        negative_lora: "",
        base_model: baseModels[0],
        sampler: samplerOptions[0],
        steps: 15,
        use_adetailer: false,
        adetailer_strength: 0.15,
        adetailer_improve_hands: false,
        adetailer_model: adetailerModelOptions[0],
        cfg_scale: 7,
        clip_skip: 1,
    });

    useEffect(() => {
        const fetchPresets = async () => {
            try {
                const response = await listPresets();
                console.log(response);
                setPresets(response.presets);
                toast({
                    title: "Presets fetched successfully",
                    description: `${response.presets.length} presets have been fetched successfully`,
                });
            } catch (error) {
                toast({
                    title: "Error fetching presets",
                    description: "An error occurred while fetching the presets",
                });
                console.error("Error fetching presets:", error);
            }
        };

        fetchPresets();
    }, []);

    const handleCreatePresetClick = async () => {
        try {
            const response = await createPreset(newPreset);
            setPresets([...presets, response.preset]);
            toast({
                title: "Preset created successfully",
                description: "The preset has been created successfully",
            });
            // Reset the form
            setNewPreset({
                name: "",
                description: "",
                positive_prompt: "",
                negative_prompt: "",
                gender: "man",
                positive_lora: "",
                negative_lora: "",
                base_model: baseModels[0],
                sampler: samplerOptions[0],
                steps: 15,
                use_adetailer: false,
                adetailer_strength: 0.15,
                adetailer_improve_hands: false,
                adetailer_model: adetailerModelOptions[0],
                cfg_scale: 7,
                clip_skip: 1,
            });
        } catch (error) {
            toast({
                title: "Error creating preset",
                description: "An error occurred while creating the preset",
            });
            console.error("Error creating preset:", error);
        }
    };

    const handleDeletePresetClick = async (presetId: string) => {
        try {
            await deletePreset(presetId);
            setPresets(presets.filter((preset) => preset.id !== presetId));
            toast({
                title: "Preset deleted successfully",
                description: "The preset has been deleted successfully",
            });
        } catch (error) {
            toast({
                title: "Error deleting preset",
                description: "An error occurred while deleting the preset",
            });
            console.error("Error deleting preset:", error);
        }
    };

    const filteredPresets = presets.filter((preset) =>
        Object.values(preset).some(
            (value) =>
                typeof value === "string" &&
                value.toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const columns = [
        { key: "name", label: "Name" },
        { key: "description", label: "Description" },
        { key: "gender", label: "Gender" },
        { key: "base_model", label: "Base Model" },
        { key: "sampler", label: "Sampler" },
        { key: "steps", label: "Steps" },
        { key: "cfg_scale", label: "CFG Scale" },
        { key: "clip_skip", label: "Clip Skip" },
        { key: "use_adetailer", label: "Use Adetailer" },
        { key: "adetailer_strength", label: "Adetailer Strength" },
        { key: "adetailer_improve_hands", label: "Improve Hands" },
        { key: "adetailer_model", label: "Adetailer Model" },
    ];

    return (
        <div className="space-y-4">
            <Card className="bg-gray-800 text-white">
                <CardHeader>
                    <CardTitle>Create New Preset</CardTitle>
                </CardHeader>
                <CardContent>
                    <form onSubmit={(e) => { e.preventDefault(); handleCreatePresetClick(); }} className="space-y-4">
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <Label htmlFor="name">Name</Label>
                                <Input
                                    id="name"
                                    value={newPreset.name}
                                    onChange={(e) => setNewPreset({ ...newPreset, name: e.target.value })}
                                    className="bg-gray-700 text-white"
                                />
                            </div>
                            <div>
                                <Label htmlFor="description">Description</Label>
                                <Input
                                    id="description"
                                    value={newPreset.description}
                                    onChange={(e) => setNewPreset({ ...newPreset, description: e.target.value })}
                                    className="bg-gray-700 text-white"
                                />
                            </div>
                        </div>
                        
                        <div>
                            <Label htmlFor="positive_prompt">Positive Prompt</Label>
                            <Textarea
                                id="positive_prompt"
                                value={newPreset.positive_prompt}
                                onChange={(e) => setNewPreset({ ...newPreset, positive_prompt: e.target.value })}
                                className="bg-gray-700 text-white"
                            />
                        </div>
                        
                        <div>
                            <Label htmlFor="negative_prompt">Negative Prompt</Label>
                            <Textarea
                                id="negative_prompt"
                                value={newPreset.negative_prompt}
                                onChange={(e) => setNewPreset({ ...newPreset, negative_prompt: e.target.value })}
                                className="bg-gray-700 text-white"
                            />
                        </div>
                        
                        <div className="grid grid-cols-3 gap-4">
                            <div>
                                <Label htmlFor="gender">Gender</Label>
                                <Select
                                    value={newPreset.gender}
                                    onValueChange={(value) => setNewPreset({ ...newPreset, gender: value })}
                                >
                                    <SelectTrigger id="gender" className="bg-gray-700 text-white">
                                        <SelectValue placeholder="Select Gender" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="man">Man</SelectItem>
                                        <SelectItem value="woman">Woman</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                            <div>
                                <Label htmlFor="base_model">Base Model</Label>
                                <Select
                                    value={newPreset.base_model}
                                    onValueChange={(value) => setNewPreset({ ...newPreset, base_model: value })}
                                >
                                    <SelectTrigger id="base_model" className="bg-gray-700 text-white">
                                        <SelectValue placeholder="Select Base Model" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {baseModels.map((model) => (
                                            <SelectItem key={model} value={model}>{model}</SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>
                            <div>
                                <Label htmlFor="sampler">Sampler</Label>
                                <Select
                                    value={newPreset.sampler}
                                    onValueChange={(value) => setNewPreset({ ...newPreset, sampler: value })}
                                >
                                    <SelectTrigger id="sampler" className="bg-gray-700 text-white">
                                        <SelectValue placeholder="Select Sampler" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {samplerOptions.map((option) => (
                                            <SelectItem key={option} value={option}>{option}</SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>
                        
                        <div className="grid grid-cols-3 gap-4">
                            <div>
                                <Label htmlFor="steps">Steps</Label>
                                <Input
                                    id="steps"
                                    type="number"
                                    value={newPreset.steps}
                                    onChange={(e) => setNewPreset({ ...newPreset, steps: parseInt(e.target.value) })}
                                    className="bg-gray-700 text-white"
                                />
                            </div>
                            <div>
                                <Label htmlFor="cfg_scale">CFG Scale</Label>
                                <Input
                                    id="cfg_scale"
                                    type="number"
                                    value={newPreset.cfg_scale}
                                    onChange={(e) => setNewPreset({ ...newPreset, cfg_scale: parseFloat(e.target.value) })}
                                    className="bg-gray-700 text-white"
                                />
                            </div>
                            <div>
                                <Label htmlFor="clip_skip">Clip Skip</Label>
                                <Input
                                    id="clip_skip"
                                    type="number"
                                    value={newPreset.clip_skip}
                                    onChange={(e) => setNewPreset({ ...newPreset, clip_skip: parseInt(e.target.value) })}
                                    className="bg-gray-700 text-white"
                                />
                            </div>
                        </div>
                        
                        <div className="grid grid-cols-2 gap-4">
                            <div className="flex items-center space-x-2">
                                <Checkbox
                                    id="use_adetailer"
                                    checked={newPreset.use_adetailer}
                                    onCheckedChange={(checked) => setNewPreset({ ...newPreset, use_adetailer: checked as boolean })}
                                />
                                <Label htmlFor="use_adetailer">Use Adetailer</Label>
                            </div>
                            <div className="flex items-center space-x-2">
                                <Checkbox
                                    id="adetailer_improve_hands"
                                    checked={newPreset.adetailer_improve_hands}
                                    onCheckedChange={(checked) => setNewPreset({ ...newPreset, adetailer_improve_hands: checked as boolean })}
                                />
                                <Label htmlFor="adetailer_improve_hands">Improve Hands</Label>
                            </div>
                        </div>
                        
                        {newPreset.use_adetailer && (
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <Label htmlFor="adetailer_strength">Adetailer Strength</Label>
                                    <Input
                                        id="adetailer_strength"
                                        type="number"
                                        value={newPreset.adetailer_strength}
                                        onChange={(e) => setNewPreset({ ...newPreset, adetailer_strength: parseFloat(e.target.value) })}
                                        className="bg-gray-700 text-white"
                                    />
                                </div>
                                <div>
                                    <Label htmlFor="adetailer_model">Adetailer Model</Label>
                                    <Select
                                        value={newPreset.adetailer_model}
                                        onValueChange={(value) => setNewPreset({ ...newPreset, adetailer_model: value })}
                                    >
                                        <SelectTrigger id="adetailer_model" className="bg-gray-700 text-white">
                                            <SelectValue placeholder="Select Model" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {adetailerModelOptions.map((model) => (
                                                <SelectItem key={model} value={model}>{model}</SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </div>
                            </div>
                        )}
                        
                        <Button type="submit" className="w-full bg-blue-600 hover:bg-blue-700">
                            Create Preset
                        </Button>
                    </form>
                </CardContent>
            </Card>

            <Card className="bg-gray-800 text-white">
                <CardHeader>
                    <CardTitle className="text-xl">Preset List</CardTitle>
                    <div className="flex justify-between items-center">
                        <Input
                            type="text"
                            placeholder="Search presets..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-64 bg-gray-700 text-white border-gray-600"
                        />
                        <Popover>
                            <PopoverTrigger asChild>
                                <Button variant="outline" className="bg-gray-700 text-white border-gray-600">
                                    Columns <ChevronDownIcon className="ml-2 h-4 w-4" />
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-80 bg-gray-700 border border-gray-600 p-4">
                                <h3 className="font-semibold text-white mb-2">Select Columns</h3>
                                <ScrollArea className="h-[300px] pr-4">
                                    <div className="space-y-2">
                                        {columns.map((column) => (
                                            <div key={column.key} className="flex items-center space-x-2">
                                                <Checkbox
                                                    id={column.key}
                                                    checked={selectedColumns.includes(column.key)}
                                                    onCheckedChange={(checked) => {
                                                        setSelectedColumns(
                                                            checked
                                                                ? [...selectedColumns, column.key]
                                                                : selectedColumns.filter((key) => key !== column.key)
                                                        );
                                                    }}
                                                />
                                                <label
                                                    htmlFor={column.key}
                                                    className="text-sm font-medium text-white leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                                >
                                                    {column.label}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </ScrollArea>
                            </PopoverContent>
                        </Popover>
                    </div>
                </CardHeader>
                <CardContent>
                    <div className="overflow-x-auto">
                        <Table>
                            <TableHeader>
                                <TableRow className="bg-gray-700">
                                    {columns
                                        .filter((column) => selectedColumns.includes(column.key))
                                        .map((column) => (
                                            <TableHead key={column.key} className="text-gray-300 font-semibold py-2 px-3">
                                                {column.label}
                                            </TableHead>
                                        ))}
                                    <TableHead className="text-gray-300 font-semibold py-2 px-3">Actions</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {filteredPresets.map((preset) => (
                                    <TableRow key={preset.id} className="bg-white hover:bg-gray-50">
                                        {columns
                                            .filter((column) => selectedColumns.includes(column.key))
                                            .map((column) => (
                                                <TableCell key={column.key} className="py-2 px-3 text-gray-600">
                                                    {typeof preset[column.key] === "boolean"
                                                        ? preset[column.key]
                                                            ? "Yes"
                                                            : "No"
                                                        : preset[column.key]}
                                                </TableCell>
                                            ))}
                                        <TableCell className="py-2 px-0">
                                            <div className="flex space-x-0.5">
                                            <Button variant="outline" onClick={() => setSelectedPreset(preset)} className="mr-2">
                                                Edit
                                            </Button>
                                            <Button variant="destructive" onClick={() => handleDeletePresetClick(preset.id)}>
                                                    Delete
                                                </Button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </CardContent>
            </Card>

            {selectedPreset && (
                <Card className="mt-4 bg-gray-800 text-white">
                    <CardHeader>
                        <CardTitle>Edit Preset: {selectedPreset.name}</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <PresetAdmin
                            selectedPreset={selectedPreset}
                            setSelectedPreset={setSelectedPreset}
                            onUpdate={(updatedPreset) => {
                                if (updatedPreset && updatedPreset.id) {
                                    setPresets(presets.map((p) => (p.id === updatedPreset.id ? updatedPreset : p)));
                                } else {
                                    console.error("Updated preset is missing an id:", updatedPreset);
                                }
                                setSelectedPreset(null);
                            }}
                        />
                    </CardContent>
                </Card>
            )}
        </div>
    );
};

export default PresetsAdmin;
