import React from "react";
import Page from "../../../Page";
import { Metadata } from "../../../types/pageMetadata";
import Newsletter from "../blog/newsletter";
import Footer from "../../Footer";

const aboutMetadata: Metadata = {
    title: "About Narkis.ai - Our Story and Mission",
    description: "Learn about Narkis.ai, our journey in AI-powered personal photo generation, and our mission to revolutionize personal photography.",
    h1: "About Narkis.ai: Pioneering AI Personal Photo Generation",
    canonical: "https://narkis.ai/about",
    keywords: [
        "Narkis.ai mission",
        "Narkis.ai company story",
        "Narkis.ai vision",
    ],
    author: "Narkis.ai Team",
    robots: "index, follow",
    ogTitle: "About Narkis.ai - Pioneering AI-Powered Image Generation",
    ogDescription: "Discover the story behind Narkis.ai, our innovative approach to AI photography, and our vision for the future of personal branding.",
    // ogImage: "summary-large-about.png",
    ogUrl: "https://narkis.ai/about",
    twitterCard: "summary_large_image",
    twitterTitle: "About Narkis.ai - Transforming Personal Branding with AI",
    twitterDescription: "Explore Narkis.ai's journey in revolutionizing personal branding and creative expression through cutting-edge AI technology.",
    // twitterImage: "summary-large-about.png",
    twitterCreator: "@narkisai"
};

const About: React.FC = () => {
    return (
        <Page {...aboutMetadata}>
            <div className="max-w-4xl mx-auto px-4 py-8">
                <h1 className="text-4xl font-bold mb-6">About Narkis.ai</h1>
                
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Our Story</h2>
                    <p className="mb-4">
                        Narkis.ai was born from a simple yet powerful idea: to democratize the creation of high-quality, personalized images using cutting-edge AI technology. Our journey began when a group of AI enthusiasts and creative professionals came together, united by the vision of making advanced image generation accessible to everyone.
                    </p>
                    <p className="mb-4">
                        We recognized that in today's digital age, personal branding and visual storytelling have become more important than ever. Yet, not everyone has access to professional photography or the skills to create stunning visuals. That's where Narkis.ai comes in.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Our Mission</h2>
                    <p className="mb-4">
                        At Narkis.ai, our mission is to empower individuals and businesses to express themselves creatively and professionally through AI-generated imagery. We strive to:
                    </p>
                    <ul className="list-disc pl-6 mb-4">
                        <li>Make high-quality, personalized image creation accessible to everyone</li>
                        <li>Push the boundaries of AI technology in image generation</li>
                        <li>Provide a user-friendly platform that brings your creative visions to life</li>
                        <li>Foster a community of creators and innovators</li>
                        <li>Continuously improve and expand our offerings based on user feedback</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Our Technology</h2>
                    <p className="mb-4">
                        Narkis.ai leverages state-of-the-art AI models, including advanced implementations of Stable Diffusion and proprietary enhancements. Our team of AI researchers and engineers work tirelessly to refine our algorithms, ensuring that Narkis.ai remains at the forefront of AI-powered image generation.
                    </p>
                    <p className="mb-4">
                        We're committed to ethical AI development and use, prioritizing user privacy and data security in everything we do.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Join Us on This Journey</h2>
                    <p className="mb-4">
                        As we continue to grow and evolve, we invite you to be part of our story. Whether you're an individual looking to enhance your personal brand, a business aiming to create unique visual content, or simply someone fascinated by the possibilities of AI, Narkis.ai is here to bring your ideas to life.
                    </p>
                    <p className="mb-4">
                        Thank you for choosing Narkis.ai. We're excited to see what you'll create!
                    </p>
                </section>

                <Newsletter tagline="Stay updated with our latest features and AI advancements." />
            </div>
            <Footer />
        </Page>
    );
};

export default About;
