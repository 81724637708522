import { Card, CardContent } from "../../../shadcn/ui/card";
import { Metadata } from "../../../types/pageMetadata";
import Page from "../../../Page";
import Footer from "../../Footer";


const cookieMetadata: Metadata = {
    title: "Cookie Policy - Narkis.ai | Understanding Our Use of Cookies",
    description:
        "Learn about how Narkis.ai uses cookies to enhance your experience. Our cookie policy explains what cookies are, how we use them, and your choices regarding cookies.",
    h1: "Narkis.ai Cookie Policy | Enhancing Your AI Photography Experience",
    canonical: "https://narkis.ai/cookie-policy",
    keywords: [
        "Narkis.ai Cookie Policy",
        "AI Photography Cookies",
        "Website Cookies",
        "Data Privacy",
        "User Tracking",
        "Cookie Consent",
        "Online Privacy",
        "Website Analytics",
    ],
    author: "Narkis.ai",
    robots: "index, nofollow",
    ogTitle: "Narkis.ai Cookie Policy - Enhancing Your AI Photography Experience",
    ogDescription:
        "Understand how Narkis.ai uses cookies to improve your AI-powered photography experience. Learn about your choices and how we protect your privacy.",
    ogImage: "summary-large-cookies.png", // TODO: Update with CloudFront URL
    ogUrl: "https://narkis.ai/cookie-policy",
    twitterCard: "summary",
    twitterTitle: "Narkis.ai Cookie Policy - Improving Your AI Photography Experience",
    twitterDescription:
        "Discover how Narkis.ai uses cookies to enhance your AI photography experience. Learn about your options and how we safeguard your privacy.",
    twitterImage: "summary-large-cookies.png", // TODO: Update with CloudFront URL
    twitterCreator: "@narkisai",
};

const CookiePolicy = () => {
    return (
        <Page {...cookieMetadata}>
            <Card className="w-full max-w-4xl mx-auto my-8 p-4">
                <CardContent>
                    <article className="prose lg:prose-xl">
                        <h1 className="text-4xl font-bold mb-6 text-primary">Cookie Policy</h1>

                        <p className="lead text-xl mb-8">
                            At Narkis.ai, we use cookies to enhance your experience and improve our services. This Cookie Policy explains what cookies are, how we use them, and your choices regarding cookies.
                        </p>

                        <h2 className="text-3xl font-semibold mt-12 mb-4 text-primary">What are Cookies?</h2>
                        <p className="mb-4">
                            Cookies are small text files that are placed on your device when you visit a website. They are widely used to make websites work more efficiently and provide information to the owners of the site.
                        </p>

                        <h2 className="text-3xl font-semibold mt-12 mb-4 text-primary">How We Use Cookies</h2>
                        <p className="mb-4">
                            We use cookies for several purposes, including:
                        </p>
                        <ul className="list-disc pl-6 mb-4">
                            <li>Essential cookies: These are necessary for the website to function properly.</li>
                            <li>Analytical/performance cookies: These help us understand how visitors use our site.</li>
                            <li>Functional cookies: These remember your image generation settings and preferences, such as preferred styles, prompts, model configurations, frequently used presets, and custom parameter values. They enhance your user experience, streamline future image creations, and provide convenience by storing your customized settings for our image generation interface.</li>
                            <li>Targeting cookies: These record your visit to our website, the pages you have visited and the links you have followed.</li>
                        </ul>

                        <h2 className="text-3xl font-semibold mt-12 mb-4 text-primary">Your Choices Regarding Cookies</h2>
                        <p className="mb-4">
                            You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly.
                        </p>

                        <h2 className="text-3xl font-semibold mt-12 mb-4 text-primary">Changes to Our Cookie Policy</h2>
                        <p className="mb-4">
                            We may update our Cookie Policy from time to time. We will notify you of any changes by posting the new Cookie Policy on this page.
                        </p>

                        <h2 className="text-3xl font-semibold mt-12 mb-4 text-primary">Contact Us</h2>
                        <p className="mb-4">
                            If you have any questions about our Cookie Policy, please contact us at{" "}
                            <a href="mailto:support@narkis.ai" className="text-blue-500 underline">
                                support@narkis.ai
                            </a>
                            .
                        </p>

                        <p className="mt-8 text-sm text-gray-500">
                            Last updated: July 2024
                        </p>
                    </article>
                </CardContent>
            </Card>
            <Footer />
        </Page>
    );
};

export default CookiePolicy;
