import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../../../shadcn/ui/tabs";
import Page from "../../../Page";

import { getBlogArticleMetadata, getGuideMetadata } from './blogMetadata';
import RelatedArticles from './RelatedArticles';
import Guides from './Guides';
import { blogPosts, guides } from './blogMetadata';
import VoteButtons from './VoteButtons';
import AuthorInfo from './AuthorInfo';
import Newsletter from './newsletter';
import Footer from '../../Footer';

import AIUsageGuide from "./articles/ai-usage-guide";
import IphoneModel from "./articles/iphone-model";

import fbLogo from "../../../../assets/facebook2.svg";
import xLogo from "../../../../assets/twitter.svg";
import linkedinLogo from "../../../../assets/linkedin.svg";

const BlogArticle: React.FC = () => {
    const { articleId } = useParams<{ articleId: string }>();
    const articleMetadata = getBlogArticleMetadata(articleId || '');
    const guideMetadata = getGuideMetadata(articleId || '');

    const articleComponents: { [key: string]: React.ComponentType } = {
        'ai-usage-guide': AIUsageGuide,
        'iphone-model': IphoneModel,
        // Add other article and guide components here
    };

    const ContentComponent = articleComponents[articleId || ''] || (() => <div>Content not found</div>);
    const metadata = blogPosts.find(post => post.id === articleId) ? articleMetadata : guideMetadata;
    const tagline = blogPosts.find(post => post.id === articleId)?.newsletterTagline || 
                    guides.find(guide => guide.id === articleId)?.newsletterTagline || 
                    "Let's face it, you need this.";
    return (
        <Page {...metadata}>
            <div className="container mx-auto px-4 md:px-8 lg:px-16 xl:px-32 py-8">
                {/* Breadcrumb */}
                <nav aria-label="Breadcrumb" className="mb-6 text-sm text-gray-500">
                    <ol className="list-none p-0 inline-flex">
                        <li className="flex items-center">
                            <Link to="/" className="hover:underline">Home</Link>
                            <span className="mx-2">/</span>
                        </li>
                        <li className="flex items-center">
                            <Link to="/blog" className="hover:underline">Blog</Link>
                            <span className="mx-2">/</span>
                        </li>
                        <li className="flex items-center">
                            <span aria-current="page">{articleId}</span>
                        </li>
                    </ol>
                </nav>

                {/* Content */}
                <ContentComponent />

                <Newsletter tagline={tagline as string || "Let's face it, you need this."} />

                {/* Vote Buttons and Social Media Links */}
                <div className="mt-12 mb-8 flex justify-between items-center">
                    <VoteButtons articleId={articleId || ""} />
                    <ul className="flex space-x-2">
                        <li>
                            <a href="https://facebook.com/narkis_ai" className="text-narkis-text-label hover:text-narkis-text-bright hover:animate-neon-glow">
                                <img src={fbLogo} alt="Facebook" className="h-8 w-8" />
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/narkis_ai" className="text-narkis-text-label hover:text-narkis-text-bright hover:animate-neon-glow">
                                <img src={xLogo} alt="Twitter" className="h-8 w-8" />
                            </a>
                        </li>
                        <li>
                            <a href="https://linkedin.com/company/NarkisAI" className="text-narkis-text-label hover:text-narkis-text-bright hover:animate-neon-glow">
                                <img src={linkedinLogo} alt="LinkedIn" className="h-8 w-8" />
                            </a>
                        </li>
                    </ul>
                </div>

                {/* Footer */}
                <footer className="mt-16">
                    <AuthorInfo />
                    <Tabs defaultValue="related" className="mt-8">
                        <TabsList className="mb-4">
                            <TabsTrigger value="related" className="px-4 py-2">Related Articles</TabsTrigger>
                            <TabsTrigger value="guides" className="px-4 py-2">Guides</TabsTrigger>
                        </TabsList>
                        <TabsContent value="related">
                            <RelatedArticles />
                        </TabsContent>
                        <TabsContent value="guides">
                            <Guides />
                        </TabsContent>
                    </Tabs>
                </footer>
            </div>
            <Footer />
        </Page>
    );
};

export default BlogArticle;
