import { uploadImages, trainModel } from "../../../../services/api";
import { Dialog, DialogContent, DialogTitle } from "../../../shadcn/ui/dialog";
import { Button } from "../../../shadcn/ui/button";
import { Input } from "../../../shadcn/ui/input";
import { Label } from "../../../shadcn/ui/label";
import { Progress } from "../../../shadcn/ui/progress";
import { Switch } from "../../../shadcn/ui/switch";
import { toast } from "react-toastify";
import SubMenuHeader from "../../SubMenuHeader";
import { useToast } from "../../../shadcn/ui/use-toast";
import { Loader2 } from "lucide-react";
import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../contexts/AuthContext";

interface InstructionsProps {
    language: string;
    onClose: () => void;
    isNotPhone: boolean;
}

const TrainModelForm: React.FC<InstructionsProps> = ({ language, onClose, isNotPhone }) => {
    const { t } = useTranslation("training");

    const [imagePreviews, setImagePreviews] = useState<string[]>([]);
    const [imageUUIDs, setImageUUIDs] = useState<string[]>([]);
    const [modelName, setModelName] = useState("");
    const [gender, setGender] = useState("male");
    const [uploadingCount, setUploadingCount] = useState(0);
    const [totalUploadingCount, setTotalUploadingCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useAuth();
    const isTester = user?.is_tester;

    const [useMasking, setUseMasking] = useState(false);
    const [useMirroring, setUseMirroring] = useState(false);
    const [useDualPurpose, setUseDualPurpose] = useState(false);
    const [useTrimming, setUseTrimming] = useState(false);

    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const { toast } = useToast();

    useEffect(() => {
        resetImages();
    }, []);

    const resetImages = () => {
        setImagePreviews([]);
        setImageUUIDs([]);
    };

    const onClickSelectImages = () => {
        fileInputRef.current?.click();
    };

    const handleFileSelect = async (event: React.ChangeEvent<any>) => {
        console.log("Uploading...");
        let files = Array.from(event.target.files) as File[];

        // Increment upload count by the number of files selected
        setUploadingCount((c) => c + files.length);
        setTotalUploadingCount((c) => c + files.length);

        // Check if the uploaded images meet the minimum size requirement
        const validFiles: File[] = [];
        for (let file of files) {
            const image = new Image();
            image.src = URL.createObjectURL(file as File);
            await new Promise((resolve, reject) => {
                image.onload = () => {
                    const allowedFormats = ["image/jpeg", "image/png", "image/jpg"];
                    if (image.width < 512 || image.height < 512) {
                        reject(new Error("Image too small"));
                    } else if (!allowedFormats.includes(file.type)) {
                        reject(new Error("Invalid image format"));
                    } else {
                        validFiles.push(file as File);
                        resolve(true);
                    }
                };
            }).catch((error: any) => {
                console.log("Image must be at least 512x512 pixels");
                toast({
                    variant: "destructive",
                    title: t("training.imageTooSmall.title"),
                    description: t("training.imageTooSmall.description"),
                });
                setUploadingCount((c) => c - 1);
                setTotalUploadingCount((c) => c - 1);
                URL.revokeObjectURL(image.src); // Clean up memory by revoking the object URL
            });
        }
        files = validFiles; // Update files array to only include valid files

        const newImagePreviews = files.map((file: File) => {
            return URL.createObjectURL(file);
        });

        setImagePreviews((prevPreviews) => [...prevPreviews, ...newImagePreviews]); // Add to the existing previews

        // Upload all files at once and collect UUIDs asynchronously
        uploadImages(files)
            .then((response) => {
                console.log("Uploaded images:", response.data);
                setImageUUIDs((prevUuids) => [...prevUuids, ...response.data.uploadedImageIDs]);
                // Decrement upload count by the number of files uploaded
                setUploadingCount((c) => c - files.length);
            })
            .catch((error) => {
                console.error("Error uploading files:", error);
                toast({
                    variant: "destructive",
                    title: t("training.errorUploading.title"),
                    description: t("training.errorUploading.description", { error: error?.message }),
                });

                // Decrement upload count by the number of files attempted to upload
                setUploadingCount((c) => c - files.length);
                setTotalUploadingCount((c) => c - files.length);
            });

        console.log(imageUUIDs);
    };

    const handleModelTraining = async () => {
        if (uploadingCount > 0) {
            console.error("Please wait for all uploads to finish.");
            toast({
                title: t("training.uploadCountWarning.title"),
            });
            return;
        }

        try {
            setIsLoading(true);
            const response = await trainModel("", imageUUIDs, gender, modelName, useMasking, useMirroring, useDualPurpose, useTrimming);
            console.log("Model creation initiated:", response.data);
            toast({
                title: t("training.creationInitiated.title"),
                description: t("training.creationInitiated.description"),
            });
        } catch (error: any) {
            console.error("Error initiating model creation:", error);
            toast({
                variant: "destructive",
                title: t("training.errorCreatingModel.title"),
                description: t("training.errorCreatingModel.description", { error: error.message }),
            });
        } finally {
            setIsLoading(false);
        }
        onClose();
    };

    const handleToggleGender = () => {
        setGender((prevGender) => (prevGender === "male" ? "female" : "male"));
    };

    const getImageCountClass = (imageCount: number) => {
        if (imageCount < 15) return "text-red-500";
        else if (imageCount >= 15 && imageCount <= 25) return "text-yellow-500";
        else if (imageCount >= 25 && imageCount <= 40) return "text-green-500";
        else if (imageCount > 40) return "text-green-500";
    };

    const handleDeleteUploadedImage = (index: number) => {
        setImagePreviews((prevPreviews) => prevPreviews.filter((_, i) => i !== index));
        setImageUUIDs((prevUuids) => prevUuids.filter((_, i) => i !== index));
    };

    return (
        <div
            className={`${isNotPhone ? "" : ""} 
            text-narkis-text bg-narkis-bg text-md border border-narkis-border-darker2 
            rounded-[9px] shadow-md overflow-hidden 
            max-w-[425px] max-h-[90vh] w-[400px] `}
        >
            <div className=" rounded-[9px] shadow-md overflow-hidden rounded-b-none">
                <SubMenuHeader title={t("training.title")} />
            </div>
            <div className="mt-4  m-4 flex flex-col h-full">
                {imageUUIDs.length < 15 && (
                    <div className="text-center text-sm text-red-500 mb-2">{t("training.minimumPhotosMessage")}</div>
                )}

                <div
                    className="border-2 border-dashed border-narkis-border-brighter rounded-md p-4 text-center cursor-pointer"
                    onClick={onClickSelectImages}
                >
                    {t("training.uploadImages")}
                </div>
                <input type="file" multiple ref={fileInputRef} style={{ display: "none" }} onChange={handleFileSelect} />
                <div className="mt-4 grid grid-cols-4 gap-2 overflow-y-auto max-h-[200px] transition-all duration-500 ease-in-out">
                    {imagePreviews.map((previewUrl, index) => (
                        <div key={index} className="relative">
                            <img
                                src={previewUrl}
                                alt={`Preview ${index + 1}`}
                                className="w-full h-auto transition-transform duration-300 ease-in-out transform hover:scale-110"
                            />
                            <div
                                className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center opacity-0 hover:opacity-100 transition-opacity duration-300 ease-in-out cursor-pointer"
                                onClick={() => handleDeleteUploadedImage(index)}
                            >
                                <span className=" text-xl">{t("training.delete")}</span>
                            </div>
                        </div>
                    ))}
                </div>
                <div
                    className={`mt-2 text-center text-lg font-bold ${getImageCountClass(
                        imagePreviews.length
                    )} transition-opacity duration-500 ease-in-out`}
                >
                    {imagePreviews.length}/40
                </div>
                {isTester && (
                <div className="flex flex-row justify-evenly gap-2 mt-2">
                    <div className="flex items-center gap-2">
                        <input
                            type="checkbox"
                            id="masking"
                            checked={useMasking}
                            onChange={(e) => setUseMasking(e.target.checked)}
                        />
                        <label htmlFor="masking" className="text-sm font-medium">
                            {t("training.useMasking")}
                        </label>
                    </div>
                    <div className="flex items-center gap-2">
                        <input
                            type="checkbox"
                            id="mirroring"
                            checked={useMirroring}
                            onChange={(e) => setUseMirroring(e.target.checked)}
                        />
                        <label htmlFor="mirroring" className="text-sm font-medium">
                            {t("training.useMirroring")}
                        </label>
                    </div>
                    <div className="flex items-center gap-2">
                        <input
                            type="checkbox"
                            id="dualPurpose"
                            checked={useDualPurpose}
                            onChange={(e) => setUseDualPurpose(e.target.checked)}
                        />
                        <label htmlFor="dualPurpose" className="text-sm font-medium">
                            {t("training.useDualPurpose")}
                        </label>
                    </div>
                    <div className="flex items-center gap-2">
                        <input
                            type="checkbox"
                            id="trimming"
                            checked={useTrimming}
                            onChange={(e) => setUseTrimming(e.target.checked)}
                        />
                        <label htmlFor="trimming" className="text-sm font-medium">
                            {t("training.useTrimming")}
                        </label>
                    </div>
                </div>
            )}

                <div className="px-2 flex flex-row justify-between gap-2 mt-2">
                    <div className="flex flex-row items-center gap-1">
                        <Label className="text-sm font-medium  ">{t("training.modelName")}</Label>
                        <Input
                            type="text"
                            value={modelName}
                            onChange={(e) => setModelName(e.target.value)}
                            placeholder={t("training.modelName")}
                            className="w-[53%] h-8 rounded-[7.5px] border-narkis-mobile-border-lighter bg-narkis-mobile-bg text-narkis-mobile-text border"
                        />
                    </div>
                    <div className="flex flex-row items-center gap-1">
                        <Label className="text-sm font-medium">{t("training.gender")}</Label>
                        <Button
                            onClick={handleToggleGender}
                            className={`w-20 h-8 rounded-[7.5px] font-semibold border border-narkis-border-darker2 ${
                                gender === "male" ? "bg-narkis-bg text-blue-500" : "bg-narkis-bg text-pink-500"
                            }`}
                        >
                            {gender === "male" ? t("training.male") : t("training.female")}
                        </Button>
                    </div>
                </div>

                <div className="mt-6">
                    {isLoading ? (
                        <Button
                            disabled
                            className="
                        font-extrabold border-narkis-border border rounded-[6px] w-full
                        bg-gradient-to-tr from-orange-400 via-orange-200 to-orange-600 bg-clip-text text-transparent 
                        transition-transform ease-in-out
                        hover:-translate-y-0.5 hover:animate-neon-glow
                        animate-gradient-x bg-[length:400%_400%]
                        "
                        >
                            <Loader2 className="mr-2 h-4 w-4 animate-spin " />
                            {t("training.createModelButtonCreating")}
                        </Button>
                    ) : (
                        <Button
                            className={`
                        font-extrabold border-narkis-border border rounded-[6px] w-full
                        bg-gradient-to-tr from-orange-400 via-orange-200 to-orange-600 bg-clip-text text-transparent 
                        transition-transform ease-in-out
                        hover:-translate-y-0.5 hover:animate-neon-glow
                        animate-gradient-x bg-[length:400%_400%]
                        `}
                            onClick={handleModelTraining}
                            disabled={uploadingCount > 0 || !modelName || modelName === ""}
                        >
                            {t("training.createModelButton")}
                        </Button>
                    )}
                </div>
                {uploadingCount > 0 && (
                    <div className="mt-4 flex items-center justify-center flex-col">
                        <Progress
                            value={((totalUploadingCount - uploadingCount) / totalUploadingCount) * 100}
                            color="bg-slate-500"
                        />
                        <span className="ml-2 text-sm ">
                            {t("training.uploadingCountHead")} {uploadingCount} {t("training.uploadingCountTail")}
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TrainModelForm;
