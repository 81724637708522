import React, { useEffect, useState } from "react";
import { listMatrixGenerations, getMatrixGeneration, deleteMatrixGeneration } from "../../../../services/api";
import { format } from "date-fns";
import { Table, Modal, Button, Spin } from "antd";
import { MatrixGeneration } from "./types/matrixGeneration";
import MatrixGenerationDisplay from "./MatrixGenerationDisplay";
import { Space } from "antd";

const MatrixGenerationView: React.FC<{
    generations: MatrixGeneration[];
    setGenerations: React.Dispatch<React.SetStateAction<MatrixGeneration[]>>;
}> = ({ generations, setGenerations }) => {
    const [selectedGeneration, setSelectedGeneration] = useState<MatrixGeneration | null>(null);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [loadingGenerationId, setLoadingGenerationId] = useState<string | null>(null);

    useEffect(() => {
        fetchGenerations();
    }, [page, pageSize]);

    const fetchGenerations = async () => {
        setLoading(true);
        try {
            const response = await listMatrixGenerations(page, pageSize);
            setGenerations(response.matrix_generations);
            console.log("Generations:", response.matrix_generations);
            setTotal(response.total);
        } catch (error) {
            console.error("Error fetching matrix generations:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleGenerationClick = async (id: string) => {
        setDetailsLoading(true);
        setLoadingGenerationId(id);
        setModalVisible(true);
        try {
            const response = await getMatrixGeneration(id);
            setSelectedGeneration(response);
            console.log("Generated images length:", response.generated_images.images.length);
        } catch (error) {
            console.error(`Error fetching matrix generation ${id}:`, error);
        } finally {
            setDetailsLoading(false);
            setLoadingGenerationId(null);
        }
    };

    const deleteGeneration = async (id: string) => {
        try {
            await deleteMatrixGeneration(id);
            fetchGenerations();
        } catch (error) {
            console.error(`Error deleting matrix generation ${id}:`, error);
        }
    };

    const columns = [
        {
            title: "Created At",
            dataIndex: "created_at",
            key: "created_at",
            render: (date: string) => {
                const parsedDate = new Date(date);
                return isNaN(parsedDate.getTime()) ? 'Invalid date' : format(parsedDate, "yyyy-MM-dd HH:mm:ss");
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
        },
        {
            title: "Models",
            dataIndex: "models",
            key: "models",
            render: (models: string[] | undefined) => models?.join(", ") || "N/A",
        },
        {
            title: "Image Count",
            dataIndex: "total_images",
            key: "imageCount",
            render: (totalImages: number) => totalImages || 0,
        },
        {
            title: "Actions",
            key: "actions",
            render: (_: any, record: MatrixGeneration) => (
                <Space>
                    <Button 
                        onClick={() => handleGenerationClick(record.id)}
                        loading={detailsLoading && loadingGenerationId === record.id}
                    >
                        View Details
                    </Button>
                    <Button onClick={() => deleteGeneration(record.id)} danger>
                        Delete
                    </Button>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <Table
                columns={columns}
                dataSource={generations}
                rowKey="id"
                loading={loading}
                pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: total,
                    onChange: (page, pageSize) => {
                        setPage(page);
                        setPageSize(pageSize);
                    },
                }}
            />
            <Modal
                title="Matrix Generation Details"
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={null}
                width="90vw"
                style={{ maxWidth: "1600px" }}
            >
                {detailsLoading ? (
                    <div className="flex justify-center items-center h-64">
                        <Spin size="large" />
                    </div>
                ) : selectedGeneration ? (
                    <div className="flex flex-col gap-2">
                        <div className="flex flex-col gap-1 border rounded-lg p-4 shadow-md">
                            <p>
                                <strong>ID:</strong> {selectedGeneration.id}
                            </p>
                            <p>
                                <strong>Created At:</strong>{" "}
                                {format(new Date(selectedGeneration.created_at), "yyyy-MM-dd HH:mm:ss")}
                            </p>
                            <p>
                                <strong>Status:</strong> {selectedGeneration.status}
                            </p>
                            <p>
                                <strong>Models:</strong> {selectedGeneration.models.join(", ")}
                            </p>
                            <p>
                                <strong>Samplers:</strong> {selectedGeneration.samplers.join(", ")}
                            </p>
                            <p>
                                <strong>Resolutions:</strong> {selectedGeneration.resolutions.join(", ")}
                            </p>
                            <p>
                                <strong>Prompts:</strong>
                            </p>
                            <ul>
                                {selectedGeneration.prompts.map((prompt, index) => (
                                    <li key={index}>
                                        <p>Positive: {prompt.positive}</p>
                                        <p>Negative: {prompt.negative}</p>
                                    </li>
                                ))}
                            </ul>
                            <p>
                                <strong>Sampling Steps:</strong> {selectedGeneration.sampling_steps.join(", ")}
                            </p>
                            <p>
                                <strong>Adetailer Options:</strong> {JSON.stringify(selectedGeneration.adetailer_options)}
                            </p>
                            <p>
                                <strong>CFG Scales:</strong> {selectedGeneration.cfg_scales.join(", ")}
                            </p>
                            <p>
                                <strong>Seed:</strong> {selectedGeneration.seed}
                            </p>
                            <p>
                                <strong>Positive Lora:</strong> {selectedGeneration.positive_lora.join(", ")}
                            </p>
                            <p>
                                <strong>Negative Lora:</strong> {selectedGeneration.negative_lora.join(", ")}
                            </p>
                        </div>
                        <div className="flex flex-col gap-1 border rounded-lg p-4 shadow-md">
                            <h3>Generated Images</h3>
                            <MatrixGenerationDisplay selectedGeneration={selectedGeneration} />
                        </div>
                    </div>
                ) : null}
            </Modal>
        </div>
    );
};

export default MatrixGenerationView;
