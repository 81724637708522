import { ArrowBigRightDashIcon, LucideArrowBigDownDash, LucideArrowBigRightDash } from "lucide-react";
import React from "react";
import { useTranslation } from "react-i18next";

interface ProductShowcaseProps {
    heroUser: {
        hero_upload_photo: string;
        hero_generated_photos: { image_url: string }[];
    };
    isMobile: boolean;
}

const ProductShowcase: React.FC<ProductShowcaseProps> = ({ heroUser, isMobile }) => {
    const { t } = useTranslation("landing");

    return (
        <div className="flex flex-col md:flex-row items-center justify-center w-full gap-2 md:gap-2">
            <div className="flex flex-col items-center">
                <div className="relative group">
                    <img
                        src={heroUser?.hero_upload_photo}
                        alt="User Uploaded"
                        className="w-64 h-64 object-cover rounded-[7.5px] shadow-lg group-hover:brightness-110 transition duration-300"
                    />
                    <div className="absolute top-0 left-0 w-full h-full rounded-[7.5px] bg-gradient-to-tr transition duration-300"></div>
                </div>
                <h2 className="mt-4 text-lg font-semibold text-center">{t("hero.userUploaded")}</h2>
            </div>

            {isMobile ? (
                <div className="flex justify-center items-center hover:brightness-110 transition-all duration-200">
                    <LucideArrowBigDownDash size={50} className="text-narkis-text-label" />
                </div>
            ) : (
                <div className="flex justify-center items-center hover:brightness-110 transition-all duration-200">
                    <ArrowBigRightDashIcon size={50} className="text-narkis-text-label" />
                </div>
            )}
            <div className="flex flex-col items-center">
                <h2 className="text-2xl font-bold mb-4">AI-Generated Photos</h2>
                <div className="grid grid-cols-2 gap-4">
                    {heroUser?.hero_generated_photos.map((photo, index) => (
                        <div key={index} className="relative group">
                            <img
                                src={photo.image_url}
                                alt={`Generated ${index + 1}`}
                                className="w-40 h-40 object-cover group-hover:brightness-110 rounded-[7.5px] shadow-md hover:shadow-lg transition duration-300"
                            />
                            <div className="absolute top-0 left-0 w-full h-full rounded-[7.5px] bg-gradient-to-tr transition duration-300"></div>
                        </div>
                    ))}
                </div>
                {/* <div className="flex flex-col text-xl w-full items-center">
          <div
            className="scale-90 flex items-end flex-row gap-1 w-fit text-center overflow-visible text-nowrap bg-gradient-to-r from-sky-300 via-fuchsia-300 to-yellow-300 via-50% bg-clip-text text-transparent font-extrabold animate-gradient-x"
          >
            <span className="text-xs my-1 font-medium">{t("poweredBy")}</span>
            <span className="font-bold tracking">Narkis Engine</span>
          </div>
        </div> */}

                <div className="mt-8 text-center">
                    <p className="text-lg font-semibold">Powered by</p>
                    <h3 className="text-3xl font-bold bg-gradient-to-r from-sky-400 via-fuchsia-500 to-orange-500 bg-clip-text text-transparent">
                        Narkis Engine
                    </h3>
                </div>
            </div>
        </div>
    );
};

export default ProductShowcase;
