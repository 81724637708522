const SubMenuHeader = ({ title }: { title: string }) => {
  return (
    <div className="
    bg-gradient-to-r from-slate-800 via-gray-950 to-slate-400 
    p-2 rounded-[2.5px] shadow-md h-8 flex items-center justify-between">
      <h2 className="text-xl font-extrabold text-white">{title}</h2>
    </div>
  );
};

export default SubMenuHeader;
