

import React, { useState } from 'react';
import { subscribeNewsletter } from '../../../../services/api';
import { useToast } from "../../../shadcn/ui/use-toast";

interface NewsletterProps {
    tagline: string;
    compact?: boolean;
}

const Newsletter: React.FC<NewsletterProps> = ({ tagline, compact = false }) => {
    const { toast } = useToast();
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            await subscribeNewsletter(email);
            toast({
                title: "Subscription Pending",
                description: "Please check your email to confirm your subscription.",
                duration: 5000,
            });
            setEmail('');
        } catch (error) {
            toast({
                title: "Subscription Error",
                description: "Error subscribing to the newsletter. Please try again.",
                variant: "destructive",
                duration: 5000,
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    if (compact) {
        return (
            <div className="bg-gradient-to-br from-gray-800 via-gray-900 to-stone-950 text-white rounded-xl p-4 pt-1 shadow-lg relative overflow-hidden w-full">
                <div className="relative z-10">
                    <h4 className="font-bold mb-1">Newsletter</h4>
                    <p className="text-sm mb-3">{tagline}</p>
                    <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row items-center gap-2">
                        <input
                            type="email"
                            placeholder="Your email"
                            className="w-full sm:flex-1 px-4 py-2 rounded-md text-gray-900 bg-white bg-opacity-90 focus:bg-opacity-100 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <button
                            type="submit"
                            className="w-full sm:w-auto bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:from-blue-600 hover:via-blue-700 hover:to-blue-800 text-white font-semibold px-6 py-2 rounded-md transition duration-300 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50 shadow-md hover:shadow-lg hover:brightness-110"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? '...' : 'Join'}
                        </button>
                    </form>
                </div>
            </div>
        );
    }
    return (
        <div className="bg-gradient-to-br from-gray-800 via-gray-900 to-stone-950 text-white rounded-xl p-8 pb-4 my-12 shadow-lg relative overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-br from-gray-800 via-gray-900 to-gray-950 animate-gradient-x transition-all duration-3000"></div>
            <div className="relative z-10">
                <h2 className="text-3xl font-bold mb-4">Stay Ahead of the AI Curve</h2>
                <p className="mb-6">{tagline}</p>
                <form onSubmit={handleSubmit}>
                    <input
                        type="email"
                        placeholder="Your email address"
                        className="w-full px-4 py-2 rounded-xl text-gray-900 mb-4"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <div className="flex items-center space-x-4">
                        <button
                            type="submit"
                            className="bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:from-blue-600 hover:via-blue-700 hover:to-blue-800 text-white font-semibold px-6 py-2 rounded-xl transition duration-300 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50 shadow-lg hover:shadow-xl hover:brightness-110"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Joining...' : 'Join'}
                        </button>
                        <p className="text-sm text-gray-300 flex-1">
                            By joining our newsletter, you'll receive occasional updates on the latest AI trends, exclusive tips on
                            leveraging AI tools, and be among the first to know about our exciting new features.
                        </p>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Newsletter;
