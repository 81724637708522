import React, { useState, useEffect } from "react";
import { Button } from "../../../shadcn/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../../shadcn/ui/card";
import { Input } from "../../../shadcn/ui/input";
import { Label } from "../../../shadcn/ui/label";
import { Switch } from "../../../shadcn/ui/switch";
import { Checkbox } from "../../../shadcn/ui/checkbox";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../shadcn/ui/select";
import { useToast } from "../../../shadcn/ui/use-toast";

import { listTrainingData, updateUser, deleteModel, retrainModel, generateImagesPresets } from "../../../../services/api";
import { Table, TableBody, TableCell, TableHeader, TableHead, TableRow } from "../../../shadcn/ui/table";

interface UserAdminProps {
    workflows: any[];
    selectedUser: any;
    setSelectedUser: (user: any) => void;
    onDeleteUser: (userId: string) => void;
}

const UserAdmin: React.FC<UserAdminProps> = ({ workflows, selectedUser, setSelectedUser, onDeleteUser }) => {
    const [trainingImages, setTrainingImages] = useState<any[]>([]);
    const [selectedWorkflowModel, setSelectedWorkflowModel] = useState<any>(null);
    const [selectedWorkflow, setSelectedWorkflow] = useState<any>(null);
    const { toast } = useToast();

    useEffect(() => {
        console.log("selectedUser:", selectedUser);
        if (selectedUser?.trained_models?.length > 0) {
            setSelectedWorkflowModel(selectedUser.trained_models[0].model_id);
            setSelectedWorkflow(workflows[0]);
        }
    }, [selectedUser]);

    const handleDeleteUserClick = () => {
        onDeleteUser(selectedUser.user_id);
    };

    const [combinedImages, setCombinedImages] = useState([]);

    const handleListTrainingData = async (model: any) => {
        // TOAST const id = toast.loading("Please wait...")
        try {
            const response = await listTrainingData(model.model_id);
            console.log("Training data:", response.data);
            // TOAST toast.update(id, { render: "Training data loaded, comparing...", type: "info", isLoading: true });

            // Split the images into two groups: original uploaded and processed
            const originalImages = response.data.images.filter((image: any) => image.is_original);
            const processedImages = response.data.images.filter((image: any) => !image.is_original);
            console.log("Original images:", originalImages);
            console.log("Processed images:", processedImages);
            // Create a combined list to display them side by side
            const combinedImages = originalImages.map((originalImage: any) => {
                const relatedProcessedImages = processedImages.filter((proc: any) => proc.image_id === originalImage.image_id);
                return {
                    originalImage: originalImage,
                    processedImages: relatedProcessedImages.length > 0 ? relatedProcessedImages : ["No processed images found"],
                };
            });

            // Set the combined images to state or context for rendering
            setCombinedImages(combinedImages);
            console.log("Combined images:", combinedImages);
            if (Array.isArray(response.data.images)) {
                setTrainingImages(response.data.images);
            } else {
                console.error("Expected an array of training data, received:", response.data);
                // TOAST toast.update(id, { render: `Error loading training data. Expected an array of training data, received: ${response.data}`, type: "error", isLoading: false, autoClose: 4000 });
            }
        } catch (error: any) {
            console.error("Error listing training data:", error);
            // TOAST toast.update(id, { render: `Error loading training data ${error.message}`, type: "error", isLoading: false, autoClose: 2000 });
        }
    };

    // requires the user ID and any other attributes you want to update
    const handleUpdateUserClick = async (e: any) => {
        e.preventDefault();
        try {
            console.log(selectedUser);
            const response: any = await updateUser(
                selectedUser.user_id,
                selectedUser.is_tester,
                selectedUser.credits,
                selectedUser.gift_credits,
                selectedUser.max_concurrent_training_runs,
                selectedUser.max_concurrent_generation_runs,
                selectedUser.training_credits,
                selectedUser.is_active,
                selectedUser.is_banned,
                selectedUser.is_privileged,
                selectedUser.is_priority_user,
                selectedUser.is_deleted,
                selectedUser.force_reset_password
            );
            console.log(response.message); // Log the response message
            toast({
                title: "User updated successfully",
                description: "The user has been updated successfully",
            });
        } catch (error: any) {
            console.error("Error updating user:", error);
            toast({
                title: "Error updating user",
                description: "An error occurred while updating the user",
            });
        }
    };

    const handleDeleteModel = async (modelId: any) => {
        try {
            const response: any = await deleteModel(modelId);
            console.log(response.message);
            // Remove the deleted model from the selectedUser.trained_models list
            if (selectedUser && Array.isArray(selectedUser.trained_models)) {
                const updatedModels = selectedUser.trained_models.filter((model: any) => model.model_id !== modelId);
                setSelectedUser({ ...selectedUser, trained_models: updatedModels });
            }
            toast({
                title: "Model deleted successfully",
                description: "The model has been deleted successfully",
            });
        } catch (error: any) {
            console.error("Error deleting model:", error);
            toast({
                title: "Error deleting model",
                description: "An error occurred while deleting the model",
            });
        }
    };

    const handleRetrainModel = async (modelId: any) => {
        try {
            const response: any = await retrainModel(modelId, selectedUser.user_id);
            console.log(response.message);
            toast({
                title: "Model retraining initiated",
                description: "The model has been retrained successfully",
            });
        } catch (error: any) {
            console.error("Error retraining model:", error);
            toast({
                title: "Error retraining model",
                description: "An error occurred while retraining the model",
            });
        }
    };

    const [selectedModel, setSelectedModel] = useState<any>(null);
    const [trainingData, setTrainingData] = useState<any[]>([]);
    const handleModelClick = async (model: any) => {
        setSelectedModel(model);
        await fetchTrainingData(model);
    };

    const fetchTrainingData = async (model: any) => {
        try {
            const response = await listTrainingData(model.model_id);
            const images = response.data.images;

            const trainingDataGroups = images.reduce((acc: any, image: any) => {
                const { image_id, is_original, is_masked, is_mirrored, is_cropped } = image;

                if (is_original) {
                    acc[image_id] = { original: image, masked: null, mirrored: null, cropped: null };
                } else if (is_masked) {
                    acc[image_id].masked = image;
                } else if (is_mirrored) {
                    acc[image_id].mirrored = image;
                } else if (is_cropped) {
                    acc[image_id].cropped = image;
                }

                return acc;
            }, {});

            const trainingData = Object.values(trainingDataGroups);
            setTrainingData(trainingData);
            toast({
                title: "Training data loaded",
                description: `${trainingData.length} training images have been loaded successfully`,
            });
        } catch (error: any) {
            console.error("Error fetching training data:", error);
            toast({
                title: "Error fetching training data",
                description: "An error occurred while fetching the training data",
            });
        }
    };

    const [filterDeleted, setFilterDeleted] = useState(false);
    const [filterStatus, setFilterStatus] = useState("");
    const [sortBy, setSortBy] = useState("date_created");

    const modelStatusOptions = [
        { label: "All", value: "*" },
        { label: "Completed", value: "COMPLETED" },
        { label: "Preprocessing", value: "PREPROCESSING" },
        { label: "Training", value: "TRAINING" },
        { label: "Failed", value: "FAILED" },
        { label: "All in progress", value: "IN_PROGRESS" },
    ];

    const sortOptions = [
        { label: "Date Created", value: "date_created" },
        { label: "Completed Est", value: "completed_est" },
    ];

    return (
        // taking up half of the width is a user update container, and the right half with the user's trained models"

        <div className="bg-gray-900 rounded-lg shadow-md">
            <div className="flex flex-col space-y-4">
                <Card className="bg-gray-800 text-white">
                    <CardHeader>
                        <CardTitle className="text-xl">Edit User: {selectedUser.user_name}</CardTitle>
                        <CardDescription className="text-gray-400">Update user details and permissions</CardDescription>
                    </CardHeader>
                    <CardContent>
                        {" "}
                        <form onSubmit={handleUpdateUserClick}>
                            <div className="grid grid-cols-2 gap-2">
                                <div className="col-span-2 grid grid-cols-3 gap-2">
                                    <div className="">
                                        <Label htmlFor="email">Email:</Label>
                                        <Input
                                            type="email"
                                            id="email"
                                            name="email"
                                            value={selectedUser.email}
                                            onChange={(e: any) => setSelectedUser({ ...selectedUser, email: e.target.value })}
                                        />
                                    </div>
                                    <div className="">
                                        <Label htmlFor="username">Username:</Label>
                                        <Input
                                            type="text"
                                            id="username"
                                            name="username"
                                            value={selectedUser.username}
                                            onChange={(e: any) => setSelectedUser({ ...selectedUser, username: e.target.value })}
                                        />
                                    </div>
                                    <div className="">
                                        <Label htmlFor="real_name">Real Name:</Label>
                                        <Input
                                            type="text"
                                            id="real_name"
                                            name="real_name"
                                            value={selectedUser.real_name}
                                            onChange={(e: any) => setSelectedUser({ ...selectedUser, real_name: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-2 grid grid-cols-3 gap-2">
                                    <div className="">
                                        <Label htmlFor="credits">Credits:</Label>
                                        <Input
                                            type="number"
                                            id="credits"
                                            name="credits"
                                            value={selectedUser.credits}
                                            onChange={(e: any) => setSelectedUser({ ...selectedUser, credits: e.target.value })}
                                        />
                                    </div>
                                    <div className="">
                                        <Label htmlFor="gift_credits">Gift Credits:</Label>
                                        <Input
                                            type="number"
                                            id="gift_credits"
                                            name="gift_credits"
                                            value={selectedUser.gift_credits}
                                            onChange={(e: any) =>
                                                setSelectedUser({ ...selectedUser, gift_credits: e.target.value })
                                            }
                                        />
                                    </div>
                                    <div className="">
                                        <Label htmlFor="training_credits">Training Credits:</Label>
                                        <Input
                                            type="number"
                                            id="training_credits"
                                            name="training_credits"
                                            value={selectedUser.training_credits}
                                            onChange={(e: any) =>
                                                setSelectedUser({ ...selectedUser, training_credits: e.target.value })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 gap-4">
                                    <div className="flex items-center space-x-2">
                                        <Switch
                                            id="is_tester"
                                            name="is_tester"
                                            checked={selectedUser.is_tester}
                                            onCheckedChange={(e: any) => setSelectedUser({ ...selectedUser, is_tester: e })}
                                        />
                                        <Label htmlFor="is_tester">Is Tester</Label>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <Switch
                                            id="is_privileged"
                                            name="is_privileged"
                                            checked={selectedUser.is_privileged}
                                            onCheckedChange={(e: any) => setSelectedUser({ ...selectedUser, is_privileged: e })}
                                        />
                                        <Label htmlFor="is_privileged">Is Privileged</Label>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <Switch
                                            id="is_active"
                                            name="is_active"
                                            checked={selectedUser.is_active}
                                            onCheckedChange={(e: any) => setSelectedUser({ ...selectedUser, is_active: e })}
                                        />
                                        <Label htmlFor="is_active">Is Active</Label>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <Switch
                                            id="is_banned"
                                            name="is_banned"
                                            checked={selectedUser.is_banned}
                                            onCheckedChange={(e: any) => setSelectedUser({ ...selectedUser, is_banned: e })}
                                        />
                                        <Label htmlFor="is_banned">Is Banned</Label>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <Switch
                                            id="is_priority_user"
                                            name="is_priority_user"
                                            checked={selectedUser.is_priority_user}
                                            onCheckedChange={(e: any) =>
                                                setSelectedUser({ ...selectedUser, is_priority_user: e })
                                            }
                                        />
                                        <Label htmlFor="is_priority_user">Is Priority User</Label>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <Switch
                                            id="force_reset_password"
                                            name="force_reset_password"
                                            checked={selectedUser.force_reset_password}
                                            onCheckedChange={(e: any) =>
                                                setSelectedUser({ ...selectedUser, force_reset_password: e })
                                            }
                                        />
                                        <Label htmlFor="force_reset_password">Force Reset Password</Label>
                                    </div>
                                </div>
                                <div className="grid grid-rows-2 gap-2">
                                    <div className="">
                                        <Label htmlFor="max_concurrent_training_runs">Max Concurrent Training Runs:</Label>
                                        <Input
                                            className="w-24"
                                            type="number"
                                            id="max_concurrent_training_runs"
                                            name="max_concurrent_training_runs"
                                            value={selectedUser.max_concurrent_training_runs}
                                            onChange={(e: any) =>
                                                setSelectedUser({ ...selectedUser, max_concurrent_training_runs: e.target.value })
                                            }
                                        />
                                    </div>
                                    <div className="">
                                        <Label htmlFor="max_concurrent_generation_runs">Max Concurrent Generation Runs:</Label>
                                        <Input
                                            className="w-24"
                                            type="number"
                                            id="max_concurrent_generation_runs"
                                            name="max_concurrent_generation_runs"
                                            value={selectedUser.max_concurrent_generation_runs}
                                            onChange={(e: any) =>
                                                setSelectedUser({
                                                    ...selectedUser,
                                                    max_concurrent_generation_runs: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 gap-4">
                                    <Button type="button" variant="destructive" onClick={handleDeleteUserClick}>
                                        Delete User
                                    </Button>
                                    <Button type="submit" className="bg-indigo-600 text-gray-100">
                                        Save Changes
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </CardContent>
                </Card>

                <Card className="bg-gray-800 text-white">
                    <CardHeader className="flex flex-row items-center justify-between">
                        <CardTitle className="text-xl font-bold">Trained Models</CardTitle>
                        <div className="flex flex-row gap-4">
                            <div className="flex items-center">
                                <Label htmlFor="show_deleted_models">Deleted:</Label>
                                <Checkbox
                                    id="show_deleted_models"
                                    checked={filterDeleted}
                                    onCheckedChange={(checked) => {
                                        if (checked !== "indeterminate") {
                                            setFilterDeleted(checked);
                                        }
                                    }}
                                />
                            </div>
                            <div className="flex items-center">
                                <Label htmlFor="model_status_filter">Model Status:</Label>
                                <Select value={filterStatus} onValueChange={setFilterStatus}>
                                    <SelectTrigger className="w-[180px]">
                                        <SelectValue placeholder="Theme" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {modelStatusOptions.map((option) => (
                                            <SelectItem key={option.value} value={option.value}>
                                                {option.label}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>
                            <div className="flex items-center">
                                <Label htmlFor="sort_models">Sort By:</Label>
                                <Select value={sortBy} onValueChange={setSortBy}>
                                    <SelectTrigger className="w-[180px]">
                                        <SelectValue placeholder="Theme" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {sortOptions.map((option) => (
                                            <SelectItem key={option.value} value={option.value}>
                                                {option.label}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>
                    </CardHeader>
                    <CardContent>
                        <div className="overflow-x-auto">
                            <Table className="w-full">
                                <TableHeader>
                                    <TableRow className="bg-gray-700">
                                        <TableHead className="text-gray-300 font-semibold py-2 px-3">Model ID</TableHead>
                                        <TableHead className="text-gray-300 font-semibold py-2 px-3">Model Name</TableHead>
                                        <TableHead className="text-gray-300 font-semibold py-2 px-3">Created At</TableHead>
                                        <TableHead className="text-gray-300 font-semibold py-2 px-3">Completed At</TableHead>
                                        <TableHead className="text-gray-300 font-semibold py-2 px-3">Training Status</TableHead>
                                        <TableHead className="text-gray-300 font-semibold py-2 px-3">Actions</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {selectedUser.trained_models.map((model: any) => (
                                        <TableRow
                                            key={model.model_id}
                                            className="bg-gray-800 hover:bg-gray-700"
                                            onClick={() => handleModelClick(model)}
                                        >
                                            <TableCell
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    maxWidth: "200px",
                                                }}
                                            >
                                                {model.model_id}
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    maxWidth: "100px",
                                                }}
                                            >
                                                {model.model_name}
                                            </TableCell>
                                            <TableCell>{new Date(model.date_created).toLocaleDateString()}</TableCell>
                                            <TableCell>{new Date(model.completed_est).toLocaleDateString()}</TableCell>
                                            <TableCell>{model.status}</TableCell>
                                            <TableCell>
                                                <Button variant="destructive" onClick={() => deleteModel(model.model_id)}>
                                                    Delete
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </CardContent>
                </Card>
            </div>

            {selectedModel && (
                <Card className="bg-gray-800 text-white mt-4">
                    <CardHeader>
                        <CardTitle className="text-xl font-bold">Training Data for Model: {selectedModel.model_name}</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="overflow-x-auto">
                            <Table className="w-full">
                                <TableHeader>
                                    <TableRow className="bg-gray-700">
                                        <TableHead className="text-gray-300 font-semibold py-2 px-3">Original</TableHead>
                                        <TableHead className="text-gray-300 font-semibold py-2 px-3">Mirrored</TableHead>
                                        <TableHead className="text-gray-300 font-semibold py-2 px-3">Cropped</TableHead>
                                        <TableHead className="text-gray-300 font-semibold py-2 px-3">Masked</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {trainingData.map((data, index) => (
                                        <TableRow key={index} className="bg-gray-800 hover:bg-gray-700">
                                            <TableCell style={{ width: "200px", height: "200px" }}>
                                                <img src={data.original.url} alt="Original" />
                                            </TableCell>
                                            <TableCell style={{ width: "200px", height: "200px" }}>
                                                {data.mirrored ? <img src={data.mirrored.url} alt="Mirrored" /> : "-"}
                                            </TableCell>
                                            <TableCell style={{ width: "200px", height: "200px" }}>
                                                {data.cropped ? <img src={data.cropped.url} alt="Cropped" /> : "-"}
                                            </TableCell>
                                            <TableCell style={{ width: "200px", height: "200px" }}>
                                                {data.masked ? <img src={data.masked.url} alt="Masked" /> : "-"}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </CardContent>
                </Card>
            )}
        </div>
    );
};

export default UserAdmin;
