import React, { useState, useEffect } from "react";
import { listUsers, deleteUser, inviteUser, createUser, listWorkflows } from "../../../../services/api";
import { Button } from "../../../shadcn/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../../../shadcn/ui/card";
import { Input } from "../../../shadcn/ui/input";
import { Label } from "../../../shadcn/ui/label";
import { Table, TableBody, TableCell, TableHeader, TableHead, TableRow } from "../../../shadcn/ui/table";
import { Popover, PopoverContent, PopoverTrigger } from "../../../shadcn/ui/popover";
import { Checkbox } from "../../../shadcn/ui/checkbox";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../../shadcn/ui/dropdown-menu";
import { ChevronDownIcon, MoreHorizontalIcon } from "lucide-react";
import { ScrollArea } from "../../../shadcn/ui/scroll-area";
import UserAdmin from "./UserAdmin";
import { useLocalStorageState } from "../../../../hooks/useLocalStorageState";
import { useToast } from "../../../shadcn/ui/use-toast";

const UsersAdmin = () => {
    const [users, setUsers] = useState<any[]>([]);
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [workflows, setWorkflows] = useState<any[]>([]);
    const { toast } = useToast();
    const [newUser, setNewUser] = useState({
        email: "",
        username: "",
        real_name: "",
        password: "",
    });
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedColumns, setSelectedColumns] = useLocalStorageState("usersAdmin_selectedColumns", [
        "user_id",
        "username",
        "email",
        "credits",
        "is_active",
        "is_banned",
    ]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await listUsers();
                setUsers(response.data.users);
                toast({
                    title: "Users fetched successfully",
                    description: `${response.data.users.length} users have been fetched successfully`,
                });
            } catch (error: any) {
                toast({
                    title: "Error fetching users",
                    description: "An error occurred while fetching the users",
                });
                console.error("Error fetching users:", error);
            }
        };

        const fetchWorkflows = async () => {
            try {
                const response = await listWorkflows();
                setWorkflows(response.data.workflows);
                toast({
                    title: "Workflows fetched successfully",
                    description: `${response.data.workflows.length} workflows have been fetched successfully`,
                });
            } catch (error: any) {
                toast({
                    title: "Error fetching workflows",
                    description: "An error occurred while fetching the workflows",
                });
                console.error("Error fetching workflows:", error);
            }
        };

        fetchUsers();
        fetchWorkflows();
        
    }, []);

    const handleInviteUserClick = async () => {
        try {
            const response = await inviteUser(newUser.email, newUser.username);
            toast({
                title: "User invited successfully",
                description: "The user has been invited successfully",
            });
        } catch (error: any) {
            toast({
                title: "Error inviting user",
                description: "An error occurred while inviting the user",
            });
            console.error("Error inviting user:", error);
        }
    };

    const handleCreateUserClick = async () => {
        if (!newUser.email || !newUser.username || !newUser.password) {
            console.error("Missing required fields: email, username, or password");
            return;
        }
        try {
            const response = await createUser(newUser.email, newUser.username, newUser.real_name, newUser.password);
            const userToAppend = {
                user_id: response.data.user_id,
                email: newUser.email,
                username: newUser.username,
                credits: 0,
                gift_credits: 0,
                date_created: new Date().toISOString(),
                date_updated: new Date().toISOString(),
                is_privileged: false,
                is_tester: false,
                is_active: true,
                is_deleted: false,
                max_concurrent_training_runs: 1,
                max_concurrent_generation_runs: 1,
                max_training_runs_per_month: 10,
                total_models_trained: 0,
                total_images_generated: 0,
            };
            setUsers([...users, userToAppend]);
            toast({
                title: "User created successfully",
                description: "The user has been created successfully",
            });
        } catch (error: any) {
            toast({
                title: "Error creating user",
                description: "An error occurred while creating the user",
            });
            console.error("Error creating user:", error);
        }
    };

    const handleDeleteUserClick = async (userId: string) => {
        try {
            await deleteUser(userId);
            setUsers(users.filter((user) => user.user_id !== userId));
            setSelectedUser(null);
            toast({
                title: "User deleted successfully",
                description: "The user has been deleted successfully",
            });
        } catch (error: any) {
            toast({
                title: "Error deleting user",
                description: "An error occurred while deleting the user",
            });
            console.error("Error deleting user:", error);
        }
    };

    const filteredUsers = users.filter((user) =>
        Object.values(user).some((value) => typeof value === "string" && value.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const columns = [
        { key: "user_id", label: "ID" },
        { key: "username", label: "Username" },
        { key: "email", label: "Email" },
        { key: "real_name", label: "Real Name" },
        { key: "credits", label: "Credits" },
        { key: "gift_credits", label: "Gift Credits" },
        { key: "training_credits", label: "Training Credits" },
        { key: "total_models_trained", label: "# Trainings" },
        { key: "total_photos_generated", label: "# PGens" },
        { key: "total_images_generated", label: "# IGens" },
        { key: "total_gifts_claimed", label: "# Gifts" },
        { key: "date_created", label: "Date Created" },
        { key: "date_updated", label: "Date Updated" },
        { key: "is_privileged", label: "Privileged" },
        { key: "is_priority_user", label: "Priority" },
        { key: "is_tester", label: "Tester" },
        { key: "is_active", label: "Active" },
        { key: "is_deleted", label: "Deleted" },
        { key: "is_banned", label: "Banned" },
        { key: "max_concurrent_training_runs", label: "Max Trainings" },
        { key: "max_concurrent_generation_runs", label: "Max Gens" },
    ];

    return (
        <div className="space-y-4">
            <Card className="bg-gray-800 text-white">
                <CardHeader>
                    <CardTitle className="text-xl">Create New User</CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="flex flex-wrap gap-4">
                        <div className="flex-1 min-w-[200px]">
                            <Label className="text-white">Email</Label>
                            <Input
                                type="email"
                                value={newUser.email}
                                onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                                className="bg-gray-700 text-white border-gray-600"
                            />
                        </div>
                        <div className="flex-1 min-w-[200px]">
                            <Label className="text-white">Username</Label>
                            <Input
                                type="text"
                                value={newUser.username}
                                onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
                                className="bg-gray-700 text-white border-gray-600"
                            />
                        </div>
                        <div className="flex-1 min-w-[200px]">
                            <Label className="text-white">Real Name</Label>
                            <Input
                                type="text"
                                value={newUser.real_name}
                                onChange={(e) => setNewUser({ ...newUser, real_name: e.target.value })}
                                className="bg-gray-700 text-white border-gray-600"
                            />
                        </div>
                        <div className="flex-1 min-w-[200px]">
                            <Label className="text-white">Password</Label>
                            <Input
                                type="password"
                                value={newUser.password}
                                onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                                className="bg-gray-700 text-white border-gray-600"
                            />
                        </div>
                        <div className="flex gap-2 items-end ">
                            <Button onClick={handleCreateUserClick} className="bg-gray-700 text-emerald-400 font-extrabold border-gray-600 hover:bg-gray-600 hover:text-emerald-100">
                                Create User
                            </Button>
                            <Button onClick={handleInviteUserClick} className="bg-gray-700 text-sky-400 font-extrabold border-gray-600 hover:bg-gray-600 hover:text-sky-100">
                                Invite User
                            </Button>
                        </div>
                    </div>
                </CardContent>
            </Card>

            <Card className="bg-gray-800 text-white">
                <CardHeader>
                    <CardTitle className="text-xl">User List</CardTitle>
                    <div className="flex justify-between items-center">
                        <Input
                            type="text"
                            placeholder="Search users..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-64 bg-gray-700 text-white border-gray-600"
                        />
                        <Popover>
                            <PopoverTrigger asChild>
                                <Button variant="outline" className="bg-gray-700 text-white border-gray-600">
                                    Columns <ChevronDownIcon className="ml-2 h-4 w-4" />
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-80 bg-gray-700 border border-gray-600 p-4">
                                <h3 className="font-semibold text-white mb-2">Select Columns</h3>
                                <ScrollArea className="h-[300px] pr-4">
                                    <div className="space-y-2">
                                        {columns.map((column) => (
                                            <div key={column.key} className="flex items-center space-x-2">
                                                <Checkbox
                                                    id={column.key}
                                                    checked={selectedColumns.includes(column.key)}
                                                    onCheckedChange={(checked) => {
                                                        setSelectedColumns(
                                                            checked
                                                                ? [...selectedColumns, column.key]
                                                                : selectedColumns.filter((key) => key !== column.key)
                                                        );
                                                    }}
                                                />
                                                <label
                                                    htmlFor={column.key}
                                                    className="text-sm font-medium text-white leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                                >
                                                    {column.label}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </ScrollArea>
                            </PopoverContent>
                        </Popover>
                    </div>
                </CardHeader>
                <CardContent>
                    <div className="overflow-x-auto">
                        <Table>
                            <TableHeader>
                                <TableRow className="bg-gray-700">
                                    {columns
                                        .filter((column) => selectedColumns.includes(column.key))
                                        .map((column) => (
                                            <TableHead key={column.key} className="text-gray-300 font-semibold py-2 px-3">
                                                {column.label}
                                            </TableHead>
                                        ))}
                                    <TableHead className="text-gray-300 font-semibold py-2 px-3">Actions</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {filteredUsers.map((user) => (
                                    <TableRow key={user.user_id} className="bg-white hover:bg-gray-50">
                                        {columns
                                            .filter((column) => selectedColumns.includes(column.key))
                                            .map((column) => (
                                                <TableCell key={column.key} className="py-2 px-3 text-gray-600">
                                                    {typeof user[column.key] === "boolean"
                                                        ? user[column.key]
                                                            ? "Yes"
                                                            : "No"
                                                        : user[column.key]}
                                                </TableCell>
                                            ))}
                                        <TableCell className="py-2 px-0">
                                            <div className="flex space-x-0.5">
                                                <Button variant="outline" onClick={() => setSelectedUser(user)}>
                                                    Edit
                                                </Button>
                                                <Button variant="destructive" onClick={() => handleDeleteUserClick(user.user_id)}>
                                                    Delete
                                                </Button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </CardContent>
            </Card>

            {selectedUser && (
                <UserAdmin
                    selectedUser={selectedUser}
                    setSelectedUser={setSelectedUser}
                    workflows={workflows}
                    onDeleteUser={handleDeleteUserClick}
                />
            )}
        </div>
    );
};

export default UsersAdmin;
