import React from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../shadcn/ui/tabs";
import PresetsGenerate from "../../pages/generate/PresetsGenerate";
import CustomGenerate from "../../pages/generate/CustomGenerate";
import { useLocalStorageState } from "../../../../hooks/useLocalStorageState";
import { useTranslation } from "react-i18next";

const GenerationMenu = ({
    selectedModel,
    addGeneratedImage,
    deleteGeneratedImage,
    presets,
}: {
    selectedModel: any;
    addGeneratedImage: (image: any) => void;
    deleteGeneratedImage: (imageId: string) => void;
    presets: any[];
}) => {
    const { t } = useTranslation("generate");
    const [activeTab, setActiveTab] = useLocalStorageState("activeTab", "preset");

    return (
        <Tabs className="p-2" defaultValue={activeTab} onValueChange={setActiveTab}>
            <TabsList
                aria-label="Photo Generation Modes"
                className="w-full h-full rounded-none border-b border-narkis-mobile-border-darker2 bg-narkis-mobile-bg-lighter overflow-auto"
            >
                <TabsTrigger
                    className={`text-xs min-[370px]:text-sm min-[400px]:text-base min-[480px]:text-lg border rounded-[5px] text-orange-500 border-narkis-mobile-border-darker`}
                    value="preset"
                >
                    {t("presetsTab.title")}
                </TabsTrigger>
                <TabsTrigger
                    className={`text-xs min-[370px]:text-sm min-[400px]:text-base min-[480px]:text-lg border rounded-[5px] text-orange-500 border-narkis-mobile-border-darker`}
                    value="custom"
                >
                    {t("customTab.title")}
                </TabsTrigger>
            </TabsList>
            {/* preset tab */}
            <TabsContent value="preset">
                <PresetsGenerate selectedModel={selectedModel} addGeneratedImage={addGeneratedImage} presets={presets} />
            </TabsContent>

            {/* custom prompting tab */}
            <TabsContent value="custom">
                <CustomGenerate
                    trainedModel={selectedModel}
                    addGeneratedImage={addGeneratedImage}
                    deleteGeneratedImage={deleteGeneratedImage}
                />
            </TabsContent>
        </Tabs>
    );
};

export default GenerationMenu;
