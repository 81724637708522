// src/services/api.js
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://narkis-api:7771"; // Fallback URL

export const API_BASE_URL = API_URL;

const api = axios.create({
  baseURL: API_URL, // Replace with your actual API URL
});

const handleResponseError = (errorResponse: AxiosResponse) => {
  // Check if the error format matches the expected structure
  if (errorResponse.data) {
    return {
      message: errorResponse.data.message,
      errorType: errorResponse.data.error_type,
    };
  } else {
    // Fallback if the error does not match the expected structure
    return {
      message: "An error occurred, but it did not match the expected format.",
      errorType: "unknown_format",
    };
  }
}

const handleRequestError = (error: any) => {
  return {
    message: "No response received from server",
    errorType: "no_response",
  };
}

// Error handling unwrapper
const handleAxiosError = (error: any) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.error("intercepted from response:", error.response);
    return handleResponseError(error.response)
  } else if (error.request) {
    // The request was made but no response was received
    console.error("intercepted error during request:", error.request);
    error = handleRequestError(error.request)
  } else if (error.data && error.data.msg) {
    console.error("intercepted error in response (data):", error.data.msg);
    error = handleResponseError(error.data.msg)
  } else {
    // Something happened in setting up the request that triggered an error
    console.error("something happened in setting up the request that triggered an error? error message:", error);
    return {
      message: error.message,
      
      errorType: "request_setup_error",
    };
  }
};

// Add a response interceptor
api.interceptors.response.use(
  (response) => {
    if (response.config) {
      console.log({
        "Response endpoint": response.config.url,
        "Response payload": response.data
      });
    }else{
      console.log({
        "Response payload": response.data
      }); 
    }
 
    return response;
  },
  (error: AxiosError) => {
    const processedError = handleAxiosError(error);
    console.error('Processed Error:', processedError);
    return Promise.reject(processedError); // Reject the promise with the processed error
  }
);

export const isTokenExpired = (token: string | null) => {
  if (!token) return true;
  if (token === "undefined" || token == null) return true;

  const decodedToken = JSON.parse(atob(token.split(".")[1]));
  const currentTime = Date.now() / 1000; // Current time in seconds

  return decodedToken.exp < currentTime;
};

// Func to handle UTM params 
export const handleUTMParams = (config: AxiosRequestConfig) => {
  const utmParamsExist = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'].some(param => sessionStorage.getItem(param));
  if (utmParamsExist) {
    // If your backend expects UTM parameters in the body
    const utmParams = {
      utm_source: sessionStorage.getItem('utm_source'),
      utm_medium: sessionStorage.getItem('utm_medium'),
      utm_campaign: sessionStorage.getItem('utm_campaign'),
      utm_term: sessionStorage.getItem('utm_term'),
      utm_content: sessionStorage.getItem('utm_content'),
    };
    const utmParamsBase64 = btoa(JSON.stringify(utmParams));
    if (config?.method?.toLowerCase() === 'post' && config.data) {
      config.data = { ...config.data, x_ref: utmParamsBase64 };
    }
    // If your backend expects UTM parameters in query params for GET requests
    else if (config?.method?.toLowerCase() === 'get') {
      config.params = config.params || {};
      config.params['x_ref'] = utmParamsBase64;
    }
  }
  return config;
}

// func to determine if we should skip token check
export const shouldSkipTokenCheck = (config: any) => {
  const noTokenCheckEndpoints = [
    "/auth/login", "/auth/register", "/payments/checkout_session", "/auth/login_with_token", 
    "/auth/refresh", "/users/waitlist", "/users/confirm_waitlist", "/payments/products", "/users/contact", 
    "/auth/forgot-password", "/auth/reset-password", "/photos/list_example_user_photos",  "newsletter/subscribe" ,"/newsletter/unsubscribe", "/blog/article/react", "/images/generate_public"
  ];
  const noTokenCheckEndpointsWithParams = ["/users/waitlist",  "/auth/login_with_token", "/blog/article/react"];
  // some endpoints have dynamic parameters, so we need to check if the endpoint is in the url
  if (noTokenCheckEndpointsWithParams.some(endpoint => config.url.includes(endpoint))) {
    return true;
  }

  // Bypass token check for specific endpoints and waitlist endpoint with dynamic email
  if (noTokenCheckEndpoints.some(endpoint => config.url.endsWith(endpoint))) {
    return true;
  }
  return false;
}

api.interceptors.request.use(
  async (config: any) => {

    // Handling for UTM parameters
    config = handleUTMParams(config);

    // Skip token check for specific endpoints
    if (shouldSkipTokenCheck(config)) {
      console.log("skipping token check for", config.url);
      return config;
    }

    let accessToken = localStorage.getItem("token");

    if (!accessToken) {
      console.error("No token found. Log in again");
      // return Promise.reject("No token found. Log in again");
      return;
    }

    if (isTokenExpired(accessToken)) {
      console.info("Token expired. Refreshing token...");
      try {
        // Assume refreshAccessToken is a function that updates both tokens
        const refreshResponse = await refreshAccessToken();
        console.log("interceptor received new token: ", refreshResponse);
        accessToken = refreshResponse.access_token;
        localStorage.setItem("token", accessToken as string);
      } catch (error) {
        console.error("Error refreshing token:", error);
        // Handle error (e.g., clear tokens, redirect to login)
        // return Promise.reject(error);
        return;
      }
    }

    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    console.log({
      "Request endpoint": config.url,
      "Bearer Token": config.headers?.Authorization,
      "Request payload": config.data
    });

    return config;
    
  }, (error) => {
    console.error("Error with request:", error);
    // return Promise.reject(error);
  }
);

export const refreshAccessToken = async () => {
  // Retrieve the refresh token from storage
  const refreshToken = localStorage.getItem("refreshToken");
  if (!refreshToken) {
    // If no refresh token is found, handle as an error (e.g., redirect to login)
    console.error("No refresh token available.");
    return Promise.reject("No refresh token");
  }

  try {
    const config = {
      headers: { Authorization: `Bearer ${refreshToken}` }
    };
    const response = await api.post("/auth/refresh", {}, config);

    return response.data; // Return the new access token
  } catch (error) {
    console.error("Error refreshing tokens:", error);
    // Clear tokens from storage as they might be invalid now
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    // Handle error - e.g., redirecting to login
    return Promise.reject(error);
  }
};


// Authentication Endpoints
export const trainingWorkerRedownloadDeps = () => {
  return api.post("/admin/training_worker_redownload_deps");
};

export const refreshToken = () => {
  return api.post("/auth/refresh", {});
};

export const registerUser = (email: string, username: string, realName: string, password: string, invite_id: string) => {
  return api.post("/auth/register", { email, username, realName, password, invite_id });
};

// login is in ./hooks/useAuth.js

// Training Endpoints
export const uploadImages = (files: File[]) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("files", file);
  });
  return api.post("/upload/upload_images", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const trainModel = (userId: string, uploadedImageIds: string[], gender: string, modelName: string, useMasking: boolean, useMirroring: boolean, useDualPurpose: boolean, useTrimming: boolean) => {
  return api.post("/train/train", {
    userId,
    uploadedImageIds,
    gender,
    modelName,
    useMasking,
    useMirroring,
    useDualPurpose,
    useTrimming,
  });
};

// Image Generation Endpoints
export const generateImages = (
  userId: string,
  trained_model_id: string,
  count: number,
  // advanced options
  width = 0,
  height = 0,
  positivePrompt = "",
  negativePrompt = "",
  positiveLoras = "",
  negativeLoras = "",
  steps = 0,
  sampler = "",
  cfgScale = 1,
  seed = -1,

  useAdetailer: boolean = false,
  adetailerDenoisingStrength = 0,
  adetailerModelName = "",
  improveHands: boolean = false,

  useUpscaler: boolean = false,
  upscalerName = "",
  upscaleWidth: number = 2048,
  upscaleHeight: number = 2048,
) => {
  return api.post("/generate/custom_images", {
    userId,
    count,
    trained_model_id,
    positivePrompt,
    negativePrompt,
    positiveLoras,
    negativeLoras,
    steps,
    sampler,
    useAdetailer,
    useUpscaler,
    upscalerName,
    upscaleWidth,
    upscaleHeight,
    width,
    height,
    adetailerDenoisingStrength,
    adetailerModelName,
    improveHands,
    cfgScale,
    seed,
  });
};

export const generateImagesPresets = (
  user_id: string, 
  model_id: string, 
  preset_ids: string[], 
  count: number,
  imageLayout: string,
  useAdetailer: boolean,
  adetailerDenoisingStrength: number,
  seed: number = -1) => {
  return api.post("/generate/preset_images", { 
    user_id, 
    model_id, 
    preset_ids, 
    count, 
    imageLayout,
    useAdetailer,
    adetailerDenoisingStrength,
    seed,
  });
};

export const listGeneratedImages = (userId: string, page: number, perPage: number, sortBy: string, filterSelectedModelId: string, filterFavorites: boolean, filterDeleted: boolean) => {
  return api.get("/photos/list_photos", {
    params: { userId, page, per_page: perPage, sort_by: sortBy, filter_selected_model_id: filterSelectedModelId, filter_favorites: filterFavorites, filter_deleted: filterDeleted },
  });
};

export const listPendingPhotos = (userId: string, imageIds: string[]) => {
  return api.get("/photos/list_pending_photos", {
    params: { image_ids: imageIds.join(',') },
  });
};


export const listPendingImages = (userId: string, imageIds: string[]) => {
  return api.get("/images/list_pending_images", {
    params: { image_ids: imageIds.join(',') },
  });
};


export const deleteGeneratedImages = (image_ids: string[], user_id: string) => {
  return api.delete("/photos/delete_photos", {
    data: { image_ids, user_id },
  });
};


// Trained Model Endpoints
export const listTrainedModels = (userId: string, page: number, perPage: number) => {
  return api.get("/models/list_trained_models", {
    params: { userId, page, per_page: perPage },
  });
};

export const listSharedModels = () => {
  return api.get("/models/shared_models");
};

export const shareModel = (modelId: string, targetUserEmail: string, permissionType: string) => {
  return api.post("/models/share_model", { model_id: modelId, target_user_email: targetUserEmail, permission_type: permissionType });
};

export const revokeModelAccess = (modelId: string, targetUserId: string, permissionType: string) => {
  return api.post("/models/revoke_model_access", { model_id: modelId, target_user_id: targetUserId, permission_type: permissionType });
};

// User Endpoints
export const listUsers = () => {
  return api.get("/users/list_users");
};

export const deleteUser = (user_id: string) => {
  return api.delete("/users/delete_user/" + user_id);
};

export const inviteUser = (email: string, name: string) => {
  return api.post("/users/invite_user", { email, name });
};

export const createUser = (email: string, username: string, real_name: string, password: string) => {
  return api.post("/users/create_user", { email, username, real_name, password });
};

export const updateUser = (
  user_id: string,
  is_tester: boolean,
  credits: number,
  gift_credits: number,
  max_concurrent_training_runs: number,
  max_concurrent_generation_runs: number,
  training_credits: number,
  is_active: boolean,
  is_banned: boolean,
  is_privileged: boolean,
  is_priority_user: boolean,
  is_deleted: boolean,
  force_reset_password: boolean,
) => {
  return api.put("/users/update_user/" + user_id, {
    is_tester,
    credits,
    gift_credits,
    max_concurrent_training_runs,
    max_concurrent_generation_runs,
    training_credits,
    is_active,
    is_banned,
    is_privileged,
    is_priority_user,
    is_deleted,
    force_reset_password,
  });
};


export const listTrainingData = (model_id: string) => {
  return api.get("/users/list_training_data", {
    params: { model_id },
  });
}

export const deleteModel = (model_id: string) => {
  return api.delete("/models/delete_trained_model", {
    params: { model_id }
  });
}

export const upscaleImage = (image_id: string) => {
  return api.post("/generate/upscale/" + image_id, {});
}

export const retrainModel = (model_id: string, user_id: string) => {
  return api.post("/train/retrain/" + model_id, { user_id });
}

export const listWorkflows = () => {
  return api.get("/workflows/list_workflows");
};

export const listWaitlistUsers = () => {
  return api.get("/users/list_waitlist_users", {});
};

export const createWaitlistUser = (email: string) => {
  return api.post("/users/waitlist", { email });
};

export const confirmWaitlistUser = (email: string, token: string) => {
  return api.put("/users/confirm_waitlist", { email, token });
}

export const listConfigurations = () => {
  return api.get("/admin/list_configurations");
};

export const updateConfiguration = (config: any) => {
  return api.post("/admin/update_configuration", config);
};

export const favoritePhoto = (image_id: string, favorite: boolean) => {
  return api.post("/users/favorite_photo", {image_id: image_id, favorite: favorite});
}

export const downloadPhoto = (user_id: string, image_id: string, watermark: boolean = false) => {
  return api.get("/users/download_photo", { params: { user_id, image_id, watermark } });
}

export const listProducts = () => {
  return api.get("/payments/products");
}

export const create_stripe_checkout_session = (tier_id: string) => {
  return api.post("/payments/checkout_session", { tier_id }).then(response => response.data);
}

export const loginWithToken = (email: string, real_name: string, username: string, password: string, login_token: string) => {
  // Sanitize the email by removing any characters that are not allowed in an email address
  return api.post("/auth/login_with_token", { email, real_name, username, password, login_token });
}

export const contact = (email: string, user_id: string, message: string) => {
  return api.post("/users/contact", { email, user_id, message });
}

export const listPresets = async () => {
  try {
      const response = await api.get('/presets/list_presets');
      return response.data;
  } catch (error) {
      console.error("Error listing presets:", error);
      throw error;
  }
};

export const createPreset = async (presetData: any) => {
  try {
      const response = await api.post('/presets/create_preset', presetData);
      return response.data;
  } catch (error) {
      console.error("Error creating preset:", error);
      throw error;
  }
};

export const updatePreset = async (presetId: string, presetData: any) => {
  try {
      const response = await api.put(`/presets/update_preset/${presetId}`, presetData);
      return response.data;
  } catch (error) {
      console.error(`Error updating preset ${presetId}:`, error);
      throw error;
  }
};

export const deletePreset = async (presetId: string) => {
  try {
      const response = await api.delete(`/presets/delete_preset/${presetId}`);
      return response.data;
  } catch (error) {
      console.error(`Error deleting preset ${presetId}:`, error);
      throw error;
  }
};


export const forgotPassword = async (email: string) => {
  try {
    const response = await api.post('/auth/forgot-password', { email });
    return response.data;
  } catch (error) {
    console.error('Error requesting forgot password:', error);
    throw error;
  }
};

export const resetPassword = async (resetToken: string, newPassword: string) => {
  try {
    const response = await api.post('/auth/reset-password', { reset_token: resetToken, new_password: newPassword });
    return response.data;
  } catch (error) {
    console.error('Error resetting password:', error);
    throw error;
  }
};


export const listExampleUserPhotos = async () => {
  return api.get("/photos/list_example_user_photos");
}


export const createMatrixGeneration = async (data: {
  models: string[];
  samplers: string[];
  resolutions: string[];
  prompts: { positive: string, negative: string }[];
  sampling_steps: number[];
  adetailer_options: any[];
  cfg_scales: number[];
  seed: number;
  positive_lora: string[];
  negative_lora: string[];
  negative_prompt?: string;
}) => {
  try {
    const response = await api.post('/generate/matrix_generation', data);
    return response.data;
  } catch (error) {
    console.error('Error creating matrix generation:', error);
    throw error;
  }
};

export const listMatrixGenerations = async (page: number = 1, perPage: number = 10) => {
  try {
    const response = await api.get('/generate/matrix_generations', {
      params: { page, per_page: perPage }
    });
    return response.data;
  } catch (error) {
    console.error('Error listing matrix generations:', error);
    throw error;
  }
};

export const getMatrixGeneration = async (matrixGenerationId: string) => {
  try {
    const response = await api.get(`/generate/matrix_generations/${matrixGenerationId}`);
    return response.data;
  } catch (error) {
    console.error(`Error getting matrix generation ${matrixGenerationId}:`, error);
    throw error;
  }
};

export const deleteMatrixGeneration = async (matrixGenerationId: string) => {
  try {
    const response = await api.delete(`/generate/matrix_generations/${matrixGenerationId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting matrix generation ${matrixGenerationId}:`, error);
    throw error;
  }
};

export const subscribeNewsletter = async (email: string) => {
  try {
    const response = await api.post('/newsletter/subscribe', { email });
    return response.data;
  } catch (error) {
    console.error('Error subscribing to newsletter:', error);
    throw error;
  }
};

export const unsubscribeNewsletter = async (email: string) => {
  try {
    const response = await api.post('/newsletter/unsubscribe', { email });
    return response.data;
  } catch (error) {
    console.error('Error unsubscribing from newsletter:', error);
    throw error;
  }
};
export const reactToArticle = async (articleId: string, reactionType: 'like' | 'dislike') => {
  try {
    if (!articleId) {
      throw new Error('Article ID is required');
    }
    
    if (reactionType !== 'like' && reactionType !== 'dislike') {
      throw new Error('Invalid reaction type');
    }
    
    const response = await api.post('/blog/article/react', { 
      article_id: articleId,
      reaction_type: reactionType 
    });
    
    return response.data;
  } catch (error) {
    console.error(`Error reacting to article ${articleId}:`, error);
    throw error;
  }
};

export const getArticleReactions = async (articleId: string) => {
  try {
    if (!articleId) {
      throw new Error('Article ID is required');
    }
    
    const response = await api.get(`/blog/article/${articleId}/reactions`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching reactions for article ${articleId}:`, error);
    throw error;
  }
};

// FAL Image Generator API functions
export const generateFalImages = async (
  model: string,
  positivePrompt: string,
  negativePrompt: string,
  numImages: number,
  layout: string,
  steps: number,
  cfgScale: number,
  seed: string = "-1",
  isAdvancedMode: boolean = false
) => {
  try {
    const response = await api.post('/images/generate', {
      model,
      positive_prompt: positivePrompt,
      negative_prompt: negativePrompt,
      num_images: numImages,
      layout,
      steps,
      cfg_scale: cfgScale,
      seed: seed || undefined,
      is_advanced_mode: isAdvancedMode
    });
    return response;
  } catch (error) {
    console.error('Error generating FAL images:', error);
    throw error;
  }
};

// Public FAL Image Generator API function
export const generatePublicFalImages = async (
  model: string,
  positivePrompt: string,
  negativePrompt: string,
  numImages: number,
  layout: string,
  steps: number,
  cfgScale: number,
  seed: string = "-1"
) => {
  try {
    const response = await api.post('/images/generate_public', {
      model,
      positive_prompt: positivePrompt,
      negative_prompt: negativePrompt,
      num_images: numImages,
      layout,
      steps,
      cfg_scale: cfgScale,
      seed: seed || undefined,
    });
    return response;
  } catch (error) {
    console.error('Error generating public FAL images:', error);
    throw error;
  }
};

export const generateSimpleFalImages = async (
  model: string,
  prompt: string,
  numImages: number,
  layout: string
) => {
  try {
    const response = await api.post('/images/generate_simple', {
      model,
      prompt,
      num_images: numImages,
      layout,
    });
    return response;
  } catch (error) {
    console.error('Error generating simple FAL images:', error);
    throw error;
  }
};

export const listFalImages = async (
  userId: string,
  page: number,
  perPage: number,
  sortBy: string,
  filterFavorites: boolean,
): Promise<AxiosResponse<any, any>> => {
  try {
    const response = await api.get('/images/list', {
      params: {
        user_id: userId,
        page,
        per_page: perPage,
        sort_by: sortBy,
        filter_favorites: filterFavorites,
      },
    });
    return response;
  } catch (error) {
    console.error('Error listing generated images:', error);
    throw error;
  }
};

export const likeFalImage = async (imageId: string) => {
  try {
    const response = await api.post(`/images/${imageId}/like`);
    return response.data;
  } catch (error) {
    console.error(`Error liking FAL image ${imageId}:`, error);
    throw error;
  }
};

export const unlikeFalImage = async (imageId: string) => {
  try {
    const response = await api.post(`/images/${imageId}/unlike`);
    return response.data;
  } catch (error) {
    console.error(`Error unliking FAL image ${imageId}:`, error);
    throw error;
  }
};

export const deleteFalImages = async (imageIds: string[]) => {
  try {
    const response = await api.delete('/images/delete', { data: { image_ids: imageIds } });
    return response.data;
  } catch (error) {
    console.error('Error deleting FAL images:', error);
    throw error;
  }
};


export default api;
