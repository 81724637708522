import React, { useState } from "react";
import { Button } from "../../../shadcn/ui/button";
import { Input } from "../../../shadcn/ui/input";
import { Label } from "../../../shadcn/ui/label";
import { Textarea } from "../../../shadcn/ui/textarea";
import { Checkbox } from "../../../shadcn/ui/checkbox";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../shadcn/ui/select";
import { updatePreset } from "../../../../services/api";
import { useToast } from "../../../shadcn/ui/use-toast";

interface PresetAdminProps {
    selectedPreset: any;
    setSelectedPreset: (preset: any) => void;
    onUpdate: (updatedPreset: any) => void;
}

const PresetAdmin: React.FC<PresetAdminProps> = ({ selectedPreset, setSelectedPreset, onUpdate }) => {
    const [preset, setPreset] = useState(selectedPreset);
    const { toast } = useToast();

    const baseModels = ["hyperrealism_30.safetensors"];
    const samplerOptions = ["DPM++ 2M Karras", "Euler a", "DPM adaptive"];
    const adetailerModelOptions = [
        "face_yolov8n.pt",
        "face_yolov8s.pt",
        "person_yolov8n-seg.pt",
        "person_yolov8s-seg.pt",
        "mediapipe_face_full",
        "mediapipe_face_short",
        "mediapipe_face_mesh",
    ];

    const handleUpdatePresetClick = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await updatePreset(preset.id, preset);
            toast({
                title: "Preset updated successfully",
                description: "The preset has been updated successfully",
            });
            onUpdate(preset);
        } catch (error) {
            toast({
                title: "Error updating preset",
                description: "An error occurred while updating the preset",
            });
            console.error("Error updating preset:", error);
        }
    };

    return (
        <form onSubmit={handleUpdatePresetClick} className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <Label htmlFor="name">Name</Label>
                    <Input
                        id="name"
                        value={preset.name}
                        onChange={(e) => setPreset({ ...preset, name: e.target.value })}
                        className="bg-gray-700 text-white"
                    />
                </div>
                <div>
                    <Label htmlFor="description">Description</Label>
                    <Input
                        id="description"
                        value={preset.description}
                        onChange={(e) => setPreset({ ...preset, description: e.target.value })}
                        className="bg-gray-700 text-white"
                    />
                </div>
            </div>
            <div>
                <Label htmlFor="positive_prompt">Positive Prompt</Label>
                <Textarea
                    id="positive_prompt"
                    value={preset.positive_prompt}
                    onChange={(e) => setPreset({ ...preset, positive_prompt: e.target.value })}
                    className="bg-gray-700 text-white"
                />
            </div>
            <div>
                <Label htmlFor="negative_prompt">Negative Prompt</Label>
                <Textarea
                    id="negative_prompt"
                    value={preset.negative_prompt}
                    onChange={(e) => setPreset({ ...preset, negative_prompt: e.target.value })}
                    className="bg-gray-700 text-white"
                />
            </div>
            <div className="grid grid-cols-3 gap-4">
                <div>
                    <Label htmlFor="gender">Gender</Label>
                    <Select
                        value={preset.gender}
                        onValueChange={(value) => setPreset({ ...preset, gender: value })}
                    >
                        <SelectTrigger id="gender" className="bg-gray-700 text-white">
                            <SelectValue placeholder="Select Gender" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="man">Man</SelectItem>
                            <SelectItem value="woman">Woman</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
                <div>
                    <Label htmlFor="base_model">Base Model</Label>
                    <Select
                        value={preset.base_model}
                        onValueChange={(value) => setPreset({ ...preset, base_model: value })}
                    >
                        <SelectTrigger id="base_model" className="bg-gray-700 text-white">
                            <SelectValue placeholder="Select Base Model" />
                        </SelectTrigger>
                        <SelectContent>
                            {baseModels.map((model) => (
                                <SelectItem key={model} value={model}>{model}</SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
                <div>
                    <Label htmlFor="sampler">Sampler</Label>
                    <Select
                        value={preset.sampler}
                        onValueChange={(value) => setPreset({ ...preset, sampler: value })}
                    >
                        <SelectTrigger id="sampler" className="bg-gray-700 text-white">
                            <SelectValue placeholder="Select Sampler" />
                        </SelectTrigger>
                        <SelectContent>
                            {samplerOptions.map((option) => (
                                <SelectItem key={option} value={option}>{option}</SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
            </div>
            <div className="grid grid-cols-3 gap-4">
                <div>
                    <Label htmlFor="steps">Steps</Label>
                    <Input
                        id="steps"
                        type="number"
                        value={preset.steps}
                        onChange={(e) => setPreset({ ...preset, steps: parseInt(e.target.value) })}
                        className="bg-gray-700 text-white"
                    />
                </div>
                <div>
                    <Label htmlFor="cfg_scale">CFG Scale</Label>
                    <Input
                        id="cfg_scale"
                        type="number"
                        value={preset.cfg_scale}
                        onChange={(e) => setPreset({ ...preset, cfg_scale: parseFloat(e.target.value) })}
                        className="bg-gray-700 text-white"
                    />
                </div>
                <div>
                    <Label htmlFor="clip_skip">Clip Skip</Label>
                    <Input
                        id="clip_skip"
                        type="number"
                        value={preset.clip_skip}
                        onChange={(e) => setPreset({ ...preset, clip_skip: parseInt(e.target.value) })}
                        className="bg-gray-700 text-white"
                    />
                </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
                <div className="flex items-center space-x-2">
                    <Checkbox
                        id="use_adetailer"
                        checked={preset.use_adetailer}
                        onCheckedChange={(checked) => setPreset({ ...preset, use_adetailer: checked as boolean })}
                    />
                    <Label htmlFor="use_adetailer">Use Adetailer</Label>
                </div>
                <div className="flex items-center space-x-2">
                    <Checkbox
                        id="adetailer_improve_hands"
                        checked={preset.adetailer_improve_hands}
                        onCheckedChange={(checked) => setPreset({ ...preset, adetailer_improve_hands: checked as boolean })}
                    />
                    <Label htmlFor="adetailer_improve_hands">Improve Hands</Label>
                </div>
            </div>
            {preset.use_adetailer && (
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <Label htmlFor="adetailer_strength">Adetailer Strength</Label>
                        <Input
                            id="adetailer_strength"
                            type="number"
                            value={preset.adetailer_strength}
                            onChange={(e) => setPreset({ ...preset, adetailer_strength: parseFloat(e.target.value) })}
                            className="bg-gray-700 text-white"
                        />
                    </div>
                    <div>
                        <Label htmlFor="adetailer_model">Adetailer Model</Label>
                        <Select
                            value={preset.adetailer_model}
                            onValueChange={(value) => setPreset({ ...preset, adetailer_model: value })}
                        >
                            <SelectTrigger id="adetailer_model" className="bg-gray-700 text-white">
                                <SelectValue placeholder="Select Adetailer Model" />
                            </SelectTrigger>
                            <SelectContent>
                                {adetailerModelOptions.map((model) => (
                                    <SelectItem key={model} value={model}>{model}</SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                </div>
            )}
            <Button type="submit" className="w-full bg-blue-600 hover:bg-blue-700">Update Preset</Button>
        </form>
    );
};

export default PresetAdmin;
