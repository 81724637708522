import React from 'react';
import { GeneratedImage} from './types/generatedImage';

interface ImageModalProps {
  modalImage: GeneratedImage;
  currentImageIndex: number;
  totalImages: number;
  onClose: () => void;
  onNavigate: (direction: 'prev' | 'next') => void;
}

const ImageModal: React.FC<ImageModalProps> = ({
  modalImage,
  currentImageIndex,
  totalImages,
  onClose,
  onNavigate,
}) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={onClose}>
    <div className="bg-white p-4 rounded-lg max-w-4xl max-h-[90vh] overflow-auto relative" onClick={e => e.stopPropagation()}>
      <img
        src={modalImage.image_url}
        alt={`Generated ${modalImage.image_id}`}
        className="w-full h-auto"
      />
      <button
        className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-80 hover:bg-opacity-100 transition-colors duration-200 p-4 rounded-full shadow-md"
        onClick={(e) => {
          e.stopPropagation();
          onNavigate('prev');
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
        </svg>
      </button>
      <button
        className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-80 hover:bg-opacity-100 transition-colors duration-200 p-4 rounded-full shadow-md"
        onClick={(e) => {
          e.stopPropagation();
          onNavigate('next');
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
        </svg>
      </button>
      <div className="flex justify-between items-center py-1">
        <span className="inline-block px-2 py-1 text-sm font-semibold bg-blue-100 text-blue-800 rounded border shadow-sm">
          {modalImage.model_id}
        </span>
        <span className="inline-block px-2 py-1 text-sm font-semibold bg-gray-200 text-gray-800 rounded border border-gray-300">
          Image {currentImageIndex + 1} of {totalImages}
        </span>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-sm font">
        <div>
          <div 
            className="bg-green-50 p-3 rounded-md mb-2 cursor-pointer hover:bg-green-100 transition-colors duration-200"
            onClick={() => navigator.clipboard.writeText(modalImage.generation_parameters.positive_prompt)}
          >
            <h4 className="text-green-800 font-semibold mb-1">Positive:</h4>
            <p className="text-green-700 text-sm">{modalImage.generation_parameters.positive_prompt}</p>
          </div>
          <div 
            className="bg-red-50 p-3 rounded-md cursor-pointer hover:bg-red-100 transition-colors duration-200"
            onClick={() => navigator.clipboard.writeText(modalImage.generation_parameters.negative_prompt)}
          >
            <h4 className="text-red-800 font-semibold mb-1">Negative:</h4>
            <p className="text-red-700 text-sm">{modalImage.generation_parameters.negative_prompt}</p>
          </div>
        </div>
        <div>
          <h3 className="font-bold mb-2">Basic Parameters:</h3>
          <div className="flex flex-wrap gap-1 mt-2">
            <span className="px-2 py-1 bg-pink-100 text-pink-800 rounded text-sm font-semibold">
              Sampler: {modalImage.generation_parameters.sampler}
            </span>
            <span className="px-2 py-1 bg-blue-100 text-blue-800 rounded text-sm font-semibold">
              Steps: {modalImage.generation_parameters.steps}
            </span>
            <span className="px-2 py-1 bg-green-100 text-green-800 rounded text-sm font-semibold">
              CFG: {modalImage.generation_parameters.cfg_scale}
            </span>
            <span className="px-2 py-1 bg-purple-100 text-purple-800 rounded text-sm font-semibold">
              Seed: {modalImage.generation_parameters.seed}
            </span>
            <span className="px-2 py-1 bg-yellow-100 text-yellow-800 rounded text-sm font-semibold">
              Size: {modalImage.generation_parameters.width}x{modalImage.generation_parameters.height}
            </span>
          </div>
          <div className="mt-4">
            <h3 className="font-bold mb-2">Additional Features:</h3>
            <div className="flex flex-wrap gap-2">
              <span className={`px-2 py-1 rounded text-sm font-semibold ${modalImage.generation_parameters.use_adetailer ? 'bg-blue-100 text-blue-800' : 'bg-gray-100 text-gray-800'}`}>
                ADetailer: {modalImage.generation_parameters.use_adetailer ? `${modalImage.generation_parameters.adetailer_denoising_strength * 100}%` : 'OFF'}
              </span>
              <span className={`px-2 py-1 rounded text-sm font-semibold ${modalImage.generation_parameters.improve_hands ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'}`}>
                Improve Hands: {modalImage.generation_parameters.improve_hands ? 'On' : 'Off'}
              </span>
            </div>
            {/* {modalImage.generation_parameters.use_adetailer && (
              <div className="mt-3 border-t pt-3">
                <h4 className="font-semibold mb-2">ADetailer Settings:</h4>
                <div className="flex flex-wrap gap-2 text-sm">
                  <span className="bg-blue-100 text-blue-800 p-2 rounded"><span className="font-medium">Conf:</span> {modalImage.generation_parameters.adetailer_confidence}</span>
                  <span className="bg-blue-200 text-blue-950 p-2 rounded"><span className="font-medium">Model:</span> {modalImage.generation_parameters.adetailer_model_name}</span>
                </div>
              </div>
            )} */}
          </div>
        </div>
        {(modalImage.generation_parameters.positive_loras?.length > 0 || modalImage.generation_parameters.negative_loras?.length > 0) && (
          <div>
            <h3 className="font-bold mb-2">LoRAs:</h3>
            {modalImage.generation_parameters.positive_loras?.length > 0 && (
              <p><strong>Positive:</strong> {modalImage.generation_parameters.positive_loras.join(', ')}</p>
            )}
            {modalImage.generation_parameters.negative_loras?.length > 0 && (
              <p><strong>Negative:</strong> {modalImage.generation_parameters.negative_loras.join(', ')}</p>
            )}
          </div>
        )}
       
      </div>
    </div>
  </div>
);

export default ImageModal;
