import React, { createContext, useContext, useState, useCallback, useMemo } from "react";
import api, { refreshAccessToken } from "../services/api";
import { jwtDecode } from "jwt-decode";
import { useUserDataContext } from "./UserDataContext";

interface User {
    user_id: string;
    username: string;
    real_name: string;
    email: string;
    is_privileged: boolean;
    is_tester: boolean;
    is_active: boolean;
    is_banned: boolean;
    created_at: string;
    updated_at: string;
    stripe_customer_id: string;
    stripe_subscription_id: string;
    stripe_subscription_status: string;
    stripe_subscription_start_date: string;
    stripe_subscription_end_date: string;
    user_tier: string;
    force_reset_password: boolean;
    credits: number;
    gift_credits: number;
    training_credits: number;
    max_concurrent_training_runs: number;
    last_redeemed_gift: string;
}

interface DecodedToken {
    fresh: boolean;
    iat: number;
    jti: string;
    type: string;
    sub: User;
    nbf: number;
    exp: number;
}

interface AuthContextType {
    token: string | null;
    user: User | null;
    login: (email: string, password: string) => Promise<boolean>;
    logout: () => void;
    refreshToken: () => Promise<void>;
    redeemDailyGift: () => Promise<{ success: boolean; message: any } | undefined>;
    isDailyGiftAvailable: () => boolean;
    updateUserTokens: (access_token: string, refresh_token: string) => void;
}

const AuthContext = createContext<AuthContextType | null>(null);

const decodeToken = (token: string): User | null => {
    try {
        const decoded: DecodedToken = jwtDecode<DecodedToken>(token);
        //console.log("Decoded token:", decoded);
        if (decoded && decoded.sub) {
            return decoded.sub; // Directly return the User object from sub
        }
        return null;
    } catch (error) {
        console.error("Failed to decode token:", error);
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("user");
        return null;
    }
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const lsToken = localStorage.getItem("token");
    const [token, setToken] = useState<string | null>(lsToken);
    const lsUser = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : null;
    const [user, setUser] = useState<User | null>(lsUser);
    const { setTrainingCredits, setImageCredits } = useUserDataContext();

    const updateUserTokens = (access_token: string, refresh_token: string) => {
        localStorage.setItem("token", access_token);
        localStorage.setItem("refreshToken", refresh_token);
        setToken(access_token);
        const decodedUser = decodeToken(access_token);
        if (decodedUser) {
            localStorage.setItem("user", JSON.stringify(decodedUser));
            setUser(decodedUser);
            setImageCredits(decodedUser.credits, decodedUser.gift_credits);
            setTrainingCredits(decodedUser.training_credits);
            localStorage.setItem("max_concurrent_training_runs", decodedUser.max_concurrent_training_runs.toString());
        }
    };

    const login = useCallback(async (email: string, password: string): Promise<boolean> => {
        try {
            console.log("login");
            const response = await api.post("/auth/login", { email, password });
            console.log("login response", response);
            const { access_token, refresh_token } = response.data;
            updateUserTokens(access_token, refresh_token);

            return true;
        } catch (error: any) {
            console.error("Login failed:", error);
            if (error.message === "Network Error") {
                throw new Error("Unable to reach the server. Please check your internet connection and try again.");
            } else if (error.errorType === "no_response") {
                throw new Error("No response received from server. Please try again later.");
            } else {
                throw error;
            }
        }
    }, []);

    const refreshToken = useCallback(async () => {
        const refreshToken = localStorage.getItem("refreshToken");
        if (refreshToken) {
            try {
                const response = await refreshAccessToken();
                console.log("Refresh response:", response);

                const { access_token } = response;
                localStorage.setItem("token", access_token);

                updateUserTokens(access_token, refreshToken);
            } catch (error: any) {
                console.error("Failed to refresh token:", error);
                throw error;
            }
        }
    }, [setImageCredits, setTrainingCredits]);

    const logout = useCallback(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("user");
        setToken(null);
        setUser(null);
    }, []);

    const isDailyGiftAvailable = useCallback(() => {
        if (!user || !user.last_redeemed_gift) {
            return true;
        } else {
            const lastRedeemed = new Date(user.last_redeemed_gift);
            const now = new Date();
            const yesterday = new Date(now.setDate(now.getDate() - 1));
            return lastRedeemed < yesterday;
        }
        return false;
    }, [user]);

    const redeemDailyGift = useCallback(async () => {
        try {
            const response = await api.post("/users/redeem_daily_gift");
            const { gift_credits, last_redeemed_gift } = response.data;
            setUser((prevUser) => {
                if (prevUser) {
                    return {
                        ...prevUser,
                        gift_credits,
                        last_redeemed_gift,
                    };
                }
                return prevUser;
            });
            setImageCredits(user?.credits || 0, gift_credits);
            return { success: true, message: "Daily gift claimed successfully" };
        } catch (error: any) {
            return { success: false, message: error.message || "Failed to redeem daily gift" };
        }
    }, [setImageCredits]);

    const contextValue = useMemo(
        () => ({
            token,
            user,
            login,
            logout,
            refreshToken,
            isDailyGiftAvailable,
            redeemDailyGift,
            updateUserTokens,
        }),
        [token, user, login, logout, refreshToken, isDailyGiftAvailable, redeemDailyGift, updateUserTokens]
    );

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};

export default AuthProvider;
