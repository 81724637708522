import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loginWithToken } from "../../../../services/api";
import { Button } from "../../../shadcn/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../../shadcn/ui/card";
import { Input } from "../../../shadcn/ui/input";
import { Label } from "../../../shadcn/ui/label";
import { useToast } from "../../../shadcn/ui/use-toast";
import { Loader2 } from "lucide-react";
import { useTranslation } from "react-i18next";

const TokenLoginForm = ({ onSwitchForm }: { onSwitchForm: any }) => {
    const { t } = useTranslation("login");
    const [username, setUsername] = useState("");
    const [realName, setRealName] = useState(sessionStorage.getItem("userRealName") || "");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState(sessionStorage.getItem("userEmail") || "");
    const [loginToken, setLoginToken] = useState("");
    const [firstPassword, setFirstPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");

    const navigate = useNavigate();
    const { toast } = useToast();

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        const urlLoginToken = urlParams.get("login_token");
        if (urlLoginToken) {
            console.log("Login token found:", urlLoginToken);
            setLoginToken(urlLoginToken);

            const userEmail = urlParams.get("email");
            if (userEmail) {
                console.log("User email found:", userEmail);
                const safe = encodeURIComponent(userEmail);
                const sanitizedEmail = decodeURIComponent(safe);
                setUsername(sanitizedEmail);
                setEmail(sanitizedEmail);
            }
            const userRealName = urlParams.get("real_name");
            if (userRealName) {
                setRealName(userRealName);
            }
        }
    }, []);

    const handleSubmit = async () => {
        if (firstPassword !== confirmPassword) {
            console.log("Passwords don't match");
            setError("Passwords do not match");
            return;
        }

        try {
            setIsLoading(true);
            const response = await loginWithToken(email, realName, username, password, loginToken);
            const { access_token: accessToken, refresh_token: refreshToken } = response.data;
            console.log("Login successful");
            localStorage.setItem("token", accessToken);
            localStorage.setItem("refreshToken", refreshToken);

            navigate("/dashboard");
        } catch (error: any) {
            console.error("Login error:", error.message);
            toast({
                variant: "destructive",
                title: t("login.loginFailed"),
                description: error.message,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmitClick = (event: React.FormEvent) => {
        event.preventDefault();
        handleSubmit();
    };

    const comparePasswords = (pwd1: string, pwd2: string) => {
        if (!pwd1 || !pwd2) return true;
        return pwd1 === pwd2;
    };

    const handleFirstPasswordChanged = (pwd: string) => {
        setFirstPassword(pwd);
        if (!comparePasswords(pwd, confirmPassword)) {
            setError("Passwords do not match");
            return;
        }
        setPassword(pwd);
        setError("");
    };

    const handleConfirmPasswordChanged = (pwd: string) => {
        setConfirmPassword(pwd);
        if (!comparePasswords(firstPassword, pwd)) {
            setError(t("login.passwordsDoNotMatch"));
            return;
        }
        setError("");
    };

    return (
        <Card className="w-full max-w-sm mx-auto align-middle rounded-[7.5px] bg-narkis-bg-lighter border-narkis-border-darker2 border font-semibold">
            <CardHeader>
                <CardTitle className="text-2xl">{t("login.token_login_title", { realName })}</CardTitle>
                <CardDescription>{t("login.token_login_subtitle")}</CardDescription>
            </CardHeader>
            <CardContent>
                <div className="grid gap-4">
                    <div className="grid gap-2">
                        <Label htmlFor="email">{t("login.email.mainLabel")}</Label>
                        <Input id="email" type="email" autoCapitalize="none" value={email} disabled className="rounded-[7.5px] bg-black" />
                    </div>
                    <div className="grid gap-2">
                        <Label htmlFor="realName">{t("login.realName.mainLabel")}</Label>
                        <Input
                            id="realName"
                            type="text"
                            autoCapitalize="none"
                            value={realName}
                            onChange={(e) => setRealName(e.target.value)}
                            className="rounded-[7.5px] bg-black"
                        />
                    </div>
                    <div className="grid gap-2">
                        <Label htmlFor="username">{t("login.username.mainLabel")}</Label>
                        <Input
                            id="username"
                            type="text"
                            autoCapitalize="none"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className="rounded-[7.5px] bg-black"
                        />
                    </div>
                    <div className="grid gap-2">
                        <Label htmlFor="password">{t("login.password.mainLabel")}</Label>
                        <Input
                            id="password"
                            type="password"
                            value={firstPassword}
                            onChange={(e) => handleFirstPasswordChanged(e.target.value)}
                            className="rounded-[7.5px] bg-black"
                        />
                    </div>
                    <div className="grid gap-2">
                        <Label htmlFor="confirmPassword">{t("login.confirmPassword.mainLabel")}</Label>
                        <Input
                            id="confirmPassword"
                            type="password"
                            autoCapitalize="none"
                            value={confirmPassword}
                            onChange={(e) => handleConfirmPasswordChanged(e.target.value)}
                            className="rounded-[7.5px] bg-black"
                        />
                        {error && <p className="text-red-500 text-sm">{error}</p>}
                    </div>
                    {isLoading ? (
                        <Button disabled className="rounded-[7.5px] w-full bg-gradient-to-r border border-narkis-border-darker from-stone-800 via-stone-900 to-stone-800 shadow-lg 
                        transform transition-all duration-200 ease-in-out font-extrabold text-narkis-text-bright">
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                            {t("login.loggingYouIn")}
                        </Button>
                    ) : (
                        <Button
                            type="submit"
                            className="hover:shadow-buttonGlow rounded-[7.5px] w-full bg-gradient-to-r border border-narkis-border-darker from-stone-800 via-stone-900 to-stone-800 
                                shadow-2xl transform active:translate-y-0.5 transition-all duration-200 ease-in-out font-extrabold text-narkis-text-bright"
                            onClick={handleSubmitClick}
                            disabled={!realName || !username || !firstPassword || !confirmPassword || error !== ""}
                        >
                            {t("login.buttonLogin")}
                        </Button>
                    )}
                </div>
            </CardContent>
        </Card>
    );
};

export default TokenLoginForm;
