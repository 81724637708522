import React from "react";
import Page from "../../../Page";
import { Metadata } from "../../../types/pageMetadata";

const notFoundMetadata: Metadata = {
    title: "404 - Page Not Found",
    description: "The page you are looking for does not exist.",
    h1: "404 - Page Not Found",
    canonical: "https://narkis.ai/404",
    keywords: ["404", "Not Found", "Narkis.ai"],
    author: "Narkis.ai",
    robots: "noindex, nofollow",
    ogTitle: "404 - Page Not Found",
    ogDescription: "The page you are looking for does not exist.",
    ogImage: "summary-large-404.png",
    ogUrl: "https://narkis.ai/404",
    twitterCard: "summary",
    twitterTitle: "404 - Page Not Found",
    twitterDescription: "The page you are looking for does not exist.",
    twitterImage: "summary-large-404.png",
    twitterCreator: "@narkisai"
};

const NotFound: React.FC = () => {
    return (
        <Page {...notFoundMetadata}>
            <div className="container mt-16 mx-auto px-4 py-8 text-center">
                <h1 className="text-3xl font-bold mb-4 md:text-4xl">404 - Page Not Found</h1>
                <p className="mb-8 md:text-lg">Sorry, the page you are looking for does not exist.</p>
                <a href="/" className="text-narkis-text hover:underline hover:animate-color-cycle">Go back to the homepage.</a>
            </div>
        </Page>
    );
};

export default NotFound;
